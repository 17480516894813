<template>
  <div class="col-md-9 col-12 user_order_t_section_col2 py-md-0 py-5">
            <div style="text-align: left">
              <div class="row user_orders_heading">
                <div class="col-12 role_management_t_heading">
                  <h2 class="ac_pg_head">Cancel order</h2>
                </div>
              </div>
            
          <!-- Cancelation Reason -->
            <div class="canceltn-rsn-sec">
              <div class="added-adrs-list-sec">
                <form>
                <ul>
                  <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">Cancel order details 01</label>
                    </div>
                  </li>

                  <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">Cancel order details 01</label>
                    </div>
                  </li>

                  <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">Cancel order details 01</label>
                    </div>
                  </li>

                  <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">Cancel order details 01</label>
                    </div>
                  </li>

                  <li>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">Cancel order details 01</label>
                    </div>
                  </li>
                  <li>
                    <div class="input_field my-4 pb-2">
                      <label>MESSAGE*</label>
                      <textarea class="border-0" rows="4" cols="50"></textarea>
                    </div>
                  </li>
                </ul>
                
                <!-- Buttons Section -->
                <div class="ad_btns_cnt mb-5">
                  <button @click="cancel" class="btn_outline">CANCEL</button>
                  <button
                    :disabled="isLoader"
                    :class="{ 'disable-btn': isLoader }"
                    class="btn_default"
                  >
                  <div v-if="isLoader">
                    <img width="30" src="../../../assets/image/loader.gif" />
                  </div>
                  <span v-if="!isLoader">SUBMIT</span>
                  </button>
                </div>
                <!-- Buttons Section -->

              </form>
              </div>
            </div>
          <!-- Cancelation Reason -->  
          </div>
          </div>
</template>
<script>
import "./css/orders.css";

export default {
  name: "order-cancel-reason",
  data() {
    return {
      count: 34,
    };
  },
  setup() {},
};
</script>