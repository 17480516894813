<template>
  <div class="col-lg-9">
  <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
  <div class="alert alert-success" v-if="success">{{ success }}</div>
    <h2 class="ac_pg_head">Change Password</h2>
    <div class="row">
      <div class="col-xl-6 col-lg-8 ">
        <form v-on:submit.prevent="changePassword">
          <div>
            <div class="input_field inpt_icn">
              <label>CURRENT PASSWORD*</label>
              <input v-on="
                  passwordObj.oldPassword
                    ? { change: v$.passwordObj.oldPassword.$validate }
                    : {}
                "
                :type="passwordVisible == true ? 'text' : 'password'"
                v-model="passwordObj.oldPassword"
              />
              <div class="inp_fld_icn">
                <img
                  class="visibility_btn"
                  @click.prevent="togglePassword('old')"
                  :src="imgSrc"
                />
              </div>
            </div>
            <span
              v-if="v$.passwordObj.oldPassword.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="passwordObj.oldPassword == ''">Current Password is required</span>
              <span v-else>{{ v$.passwordObj.oldPassword.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="">
            <div class="input_field inpt_icn mt-lg-3 mt-md-3 mt-3">
              <label>NEW PASSWORD*</label>
              <input v-on="
                  passwordObj.newPassword
                    ? { change: v$.passwordObj.newPassword.$validate }
                    : {}
                "
                :type="newpasswordVisible == true ? 'text' : 'password'"
                v-model="passwordObj.newPassword"
              />

              <div class="inp_fld_icn">
                <img
                  class="visibility_btn"
                  @click.prevent="togglePassword('new')"
                  :src="imgNewSrc"
                />
              </div>
            </div>
            <span
              v-if="v$.passwordObj.newPassword.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="passwordObj.newPassword == ''">New Password is required</span>
              <span v-else>{{ v$.passwordObj.newPassword.$errors[0].$message }}</span>
            </span>
          </div>
          <div>
            <div class="input_field inpt_icn mt-lg-3 mt-md-3 mt-3">
              <label>RE-ENTER NEW PASSWORD*</label>
              <input v-on="
                  passwordObj.confirmPassword
                    ? { change: v$.passwordObj.confirmPassword.$validate }
                    : {}
                "
                :type="confirmPwdVisible == true ? 'text' : 'password'"
                v-model="passwordObj.confirmPassword"
              />

              <div class="inp_fld_icn">
                <img
                  class="visibility_btn"
                  @click.prevent="togglePassword('confirm')"
                  :src="imgReSrc"
                />
              </div>
            </div>
            <span
              v-if="v$.passwordObj.confirmPassword.$error"
              class="error_msg_log error_msg"
            >
               <span v-if="passwordObj.confirmPassword == ''">Confirm Password is required</span>
              <span v-else>{{
                v$.passwordObj.confirmPassword.$errors[0].$message
              }}</span>
            </span>
          </div>
          <div class="ad_btns_cnt mt-lg-3 mt-md-3 mt-3">
            <button @click="cancel" class="btn_outline">CANCEL</button>
            <button
              class="btn_default"
              :disabled="isLoader"
              :class="{ 'disable-btn': isLoader }"
            >
            <div v-if="isLoader">
              <img width="30" src="../../../assets/image/loader.gif" />
            </div>
            <span v-if="!isLoader">UPDATE</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/routes";
import {
  helpers,
  required,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Api from "@/apis/User";
import { Base64 } from "js-base64";
import "./css/change_password.css";
import showImg from "../../../assets/image/show_password_icon.png";
import hideImg from "../../../assets/image/hide_password_icon.png";
export default {
  name: "change-password",
  data() {
    return {
      passwordObj: {
        userId: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      passwordVisible: false,
      newpasswordVisible: false,
      confirmPwdVisible: false,
      errors: '',
      isLoader: false,
      success: '',
    };
  },
  methods: {
    async cancel(){
      router.push("/user/profile");
    },
    async changePassword() {
      let userId = localStorage.getItem("id");
      this.passwordObj.userId = Base64.decode(userId);
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        this.isLoader = true;
        Api.changePassword(this.passwordObj)
          .then((res) => {
            console.log(res);
            this.isLoader = false;
            this.success = res.data.message;
            setTimeout(() => {
              this.success = '';
            }, 3000);
          })
          .catch((err) => {
            this.isLoader = false;
            this.errors = err.response.data.errors;
             setTimeout(() => {
              this.errors = '';
            }, 5000);
          });
      } else {
        console.log("Form failed validation");
      }
    },
    async togglePassword(pwdType = "old") {
      if (pwdType == "old") {
        this.passwordVisible = !this.passwordVisible;
      }
      if (pwdType == "new") {
        this.newpasswordVisible = !this.newpasswordVisible;
      }
      if (pwdType == "confirm") {
        this.confirmPwdVisible = !this.confirmPwdVisible;
      }
    },
  },
  computed: {
    imgSrc: function () {
      return this.passwordVisible === true ? showImg : hideImg;
    },
    imgNewSrc: function () {
      return this.newpasswordVisible === true ? showImg : hideImg;
    },
    imgReSrc: function () {
      return this.confirmPwdVisible === true ? showImg : hideImg;
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      passwordObj: {
        oldPassword: { required },
        newPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(18),
          containsPasswordRequirement: helpers.withMessage(
            () =>
              `The password requires an uppercase, lowercase, number and special character`,
            (value) =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])^\S*$/.test(
                value
              )
          ),
        },
        confirmPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(18),
          containsPasswordRequirement: helpers.withMessage(
            () =>
              `The password requires an uppercase, lowercase, number and special character`,
            (value) =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])^\S*$/.test(
                value
              )
          ),
          sameAsPassword: helpers.withMessage(
            () => `The re entered password should match with new password`,
            sameAs(this.passwordObj.newPassword)
          ),
        },
      },
    };
  },
  created() {
    window.scrollTo(0,0);
    document.title = this.$route.meta.title;
  },
};
</script>
<style>
.new_password {
  border: 1px solid red;
}
.old_password {
  border: 1px solid red;
}
</style>
