<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width ">
      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->

      <!-- Contact Note and Form Sec -->
      <div class="container">
        <div class="row align-items-center">
          <!-- Contact Us -->
          <div class="col-lg-5">
            <!-- Contact Left sec -->
            <div class="contact-note-dtls-sec">
              <h4 class=" d-block d-lg-none">Contact us</h4>
              <p class="txt_none">
                Our experts will help you with your inquiries, queries, and issues. Please feel free to reach us at +974
                77266267 or email us at contact@thatsliving.com.
              </p>
              <p><strong>EMAIL US :</strong> CONTACT@THATSLIVING.COM</p>

              <p><strong>CALL US </strong> +974 77266267</p>

              <p><span>Available from 9AM - 6PM (GMT+3)</span></p>
            </div>
          </div>


          <div class="col-lg-7">
            <div class="alert alert-success" v-if="success">{{ success }}</div>
            <div class="alert alert-danger" v-if="errorShow" id="error_msg">
              <ul>
                <li v-for="(val, key) in errors" :key="key">
                  {{ errors[key][0] }}
                </li>
              </ul>
            </div>
            <!-- Contact Right sec -->
            <div class="contact-form-sec">
              <h4 class="d-none d-lg-block">Contact us</h4>
              <form class="contactus-form" v-on:submit.prevent="supportSubmit">
                <div class="mb-3">
                  <div class="input_field">
                    <label>NAME*</label><input type="text" v-model="supportForm.name" v-on="
                      supportForm.name
                        ? { change: v$.supportForm.name.$validate }
                        : {}
                    " />
                  </div>
                  <span v-if="v$.supportForm.name.$error" class="error_msg">
                    <span v-if="supportForm.name == ''">Name is required</span>
                    <span v-else>{{
                      v$.supportForm.name.$errors[0].$message.replace(
                        "FIELD",
                        "Name"
                      )
                    }}</span>
                  </span>
                </div>

                <div class="mb-3">
                  <div class="input_field">
                    <label>Email address*</label><input type="email" v-model="supportForm.email" v-on="
                      supportForm.email
                        ? { change: v$.supportForm.email.$validate }
                        : {}
                    " />
                  </div>
                  <span v-if="v$.supportForm.email.$error" class="error_msg">
                    <span v-if="supportForm.email == ''">Email is required</span>
                    <span v-else>{{
                      v$.supportForm.email.$errors[0].$message.replace(
                        "FIELD",
                        "Email"
                      )
                    }}</span>
                  </span>
                </div>

                <div class="mb-3">
                  <div class="input_field">
                    <label>Mobile Number*</label><input type="tel" v-model="supportForm.mobile" v-on="
                      supportForm.mobile
                        ? { change: v$.supportForm.mobile.$validate }
                        : {}
                    " />
                  </div>
                  <span v-if="v$.supportForm.mobile.$error" class="error_msg">
                    <span v-if="supportForm.mobile == ''">Mobile number is required</span>
                    <span v-else>{{
                      v$.supportForm.mobile.$errors[0].$message.replace(
                        "FIELD",
                        "Mobile number"
                      )
                    }}</span>
                  </span>
                </div>

                <div class="mb-3">
                  <div class="input_field">
                    <label>Message</label><textarea class="border-0" v-model="supportForm.message" rows="5" cols="25"
                      v-on="
                        supportForm.message
                          ? { change: v$.supportForm.message.$validate }
                          : {}
                      "></textarea>
                  </div>
                  <span v-if="v$.supportForm.message.$error" class="error_msg">
                    <span v-if="supportForm.message == ''">Message is required</span>
                    <span v-else>{{
                      v$.supportForm.message.$errors[0].$message.replace(
                        "FIELD",
                        "Message"
                      )
                    }}</span>
                  </span>
                </div>



                <div class="my-4 text-end">
                  <button :class="{ 'disable-btn': isLoader }" :disabled="isLoader" class="btn_default">
                    <div v-if="isLoader">
                      <img width="30" src="../assets/image/loader.gif" />
                    </div>
                    <span v-if="!isLoader">SUBMIT</span>
                  </button>
                </div>
              </form>
            </div>

            <!-- Contact Us -->
          </div>
        </div>
      </div>
      <!-- Contact Note and Form Sec -->

      <!-- We are Located Sec -->
      <div class="container">
        <div class="we-are-loctd-sec">
          <h3 class="text-center">We are located at</h3>

          <div class="loctd-adrs-sec">
            <div class="row">
              <div class="col-lg-6">
                <div class="loctd-adrs">
                  <h2>TAWAR MALL(TM) SHOWROOM</h2>
                  <address>
                    1st Floor, Tawar Mall, Al Markhiya St<br />
                    Doha, Qatar<br />
                    Sunday to Thursday 9AM - 10PM<br />
                    Friday- 3PM - 11PM
                  </address>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="loctd-adrs">
                  <h2>DOHA FESTIVAL CITY(DFC) SHOWROOM</h2>
                  <address>
                    1st Floor - VIP Area, Doha Festival City.<br />
                    Umm Salal Muhammed,Doha, Qatar.<br />
                    Sunday to Thursday 9AM - 10PM<br />
                    Friday- 3PM - 11PM
                  </address>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- We are Located Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import User from "@/apis/User";
export default {
  name: "user-support",
  data() {
    return {
      errors: "",
      supportForm: {
        name: "",
        mobile: "",
        email: "",
        message: "",
      },
      isLoader: false,
      messagesOverride: {
        required: "You must fill the {attribute} field to continue",
        email: "The email must be a genuine email address.",
      },
      success: "",
      errorShow: false,
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      supportForm: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
            () => `Name is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile Number is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        email: { required, email },
        message: {
          required
        },
      },
    };
  },
  methods: {
    async supportSubmit() {
      this.errors = [];
      this.errorShow = false;
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        this.isLoader = true;
        User.postSupport(this.supportForm)
          .then((res) => {
            this.isLoader = false;
            this.clearInputs();
            this.v$.supportForm.$reset();
            if (res.data.success) {
              this.success = res.data.message;
              setTimeout(() => {
                this.success = '';
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            this.errors = err?.response?.data?.error;
            this.errorShow = true;
            this.isLoader = false;
          });
      }
    },
    async clearInputs() {
      this.supportForm.name = "";
      this.supportForm.mobile = "";
      this.supportForm.email = "";
      this.supportForm.message = "";
    },
  },
};
</script>
