<template>
  <div class="account_activation">
    <BeforeLoginHeader />
    <div class="account_activation_body_part">
      <div class="inner_account_activation_body_part container">
        <form class="mt-lg-5 mt-md-4 mt-5">
          <div class="account_activation_box p-4">
            <div class=" inner_account_activation_box mt-xl-4 mt-md-2">
              <img src="../../../assets/image/right.gif" class="image-fluid" alt="">
              <h5 class="mt-xl-5 mt-md-2 mt-4">Email Verified</h5>
            </div>
            <p class="mt-lg-4 mt-md-3 mt-4">Congratulations! Your email address has been successfully verified.
            </p>
            <div class="my-lg-4 my-md-4 mt-3"><router-link to="/"> <button>LOGIN</button></router-link></div>
            <p></p>
          </div>
        </form>

      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";

export default ({
  name: 'account-verification',
  components: {
    LoginFooter,
    BeforeLoginHeader,
  },
  setup() {

  },
  created() {
    document.title = this.$route.meta.title;
  },
})
</script>
<style>
@import "../../../assets/css/account_activation.css";
</style>
