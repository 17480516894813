
import { createWebHistory, createRouter } from "vue-router";
import Register from "@/components/register/Register";
import Login from "@/components/login/Login";
import EmailVerification from "@/components/user/auth/EmailVerification";
import EmailVerify from "@/components/user/auth/EmailVerify";
import AccountActivation from "@/components/user/auth/AccountActivation";
import Dashboard from "@/components/user/Dashboard";
import ResetPasswordEmail from "@/components/login/ResetPasswordEmail";
import ResetPassword from "@/components/user/auth/ResetPassword";
import CompanyDetailsBusiness from "@/components/user/auth/CompanyDetailsBusiness";
import CompanyDetailsIndividual from "@/components/user/auth/CompanyDetailsIndividual";
import ForgotPassword from "@/components/user/auth/ForgotPassword";
import OtpVerification from "@/components/user/auth/OtpVerification";
import DocumentVerificationMsg from "@/components/user/auth/DocumentVerificationMsg";
import userLayout from "@/components/layouts/index";
import MyProfile from "@/components/user/user-profile/MyProfile";
import Roles from "@/components/user/roles/Roles";
import EditRole from "@/components/user/roles/EditRole";
import AddRole from "@/components/user/roles/AddRole";
import EditAddress from "@/components/user/address-book/EditAddress";
import Orders from "@/components/user/orders/Orders";
import ChangePassword from "@/components/user/change-password/ChangePassword";
import PriceSetting from "@/components/user/price-setting/PriceSetting";
import Support from "@/components/user/support/Supports";
import AddressBook from "@/components/user/address-book/AddressBook";
import DefaultAddressList from "@/components/user/address-book/DefaultAddressList";
import UserManagement from "@/components/user/user-management/UserManagement";
import EditUser from "@/components/user/user-management/EditUser";
import AddUser from "@/components/user/user-management/AddUser";
import ViewUserManagement from "@/components/user/user-management/ViewUserManagement";
import AddAddress from "@/components/user/address-book/AddAddress";
import UserSupport from "@/components/user-support/UserSupport";
import loggedIn from "@/components/middleware/auth"
import CartList from "@/components/cart/CartList";
import NotFound from "@/components/NotFound";
import beforeLoginNotFound from "@/components/beforeLoginNotFound";
import OrderSuccess from "@/components/user/orders/OrdersSuccess";
// import OrderFailure from "@/components/user/orders/OrderFailure";
import OrderCancel from "@/components/user/orders/OrdersCancel";
import OrdersCancelReason from "@/components/user/orders/OrdersCancelReason";
import OrderDetails from "@/components/user/orders/OrderDetails";
import OrderPayment from "@/components/user/orders/OrderPayment";
import MyCoupons from "@/components/user/coupons/MyCoupons";
import CartCheckout from "@/components/cart/CartCheckout";
import ContactUs from "@/components/ContactUs";
import Products from "@/components/products/Products";
import ProductDetails from "@/components/products/ProductDetails";
import WishList from "@/components/wishlist/WishList";
import HomePage from "@/components/home/HomePage";
import PrivacyPolicy from "@/components/PrivacyPolicy";
import TermsAndConditions from "@/components/TermsAndConditions";
import CookiesAndPolicy from "@/components/CookiesAndPolicy";
import Faqs from "@/components/Faq";
import AboutUs from "@/components/AboutUs";
import OurStores from "@/components/OurStores";
import ShippingInfo from "@/components/ShippingInfo";
import CollectionList from "@/components/CollectionList";
import SearchList from "@/components/SearchList";
import ProductSubMenu from "@/components/ProductSubMenu";
import BeforeLoginContactUs from "@/components/BeforeLoginContactUs";
import BeforeLoginTermsAndConditions from "@/components/BeforeLoginTermsAndConditions";
import BeforeLoginCookiesAndPolicy from "@/components/BeforeLoginCookiesAndPolicy";
import Invalid from "@/components/user/auth/Invalid";
import ProjectClient from "@/components/user/project/ProjectClient";
import AddClient from "@/components/user/project/AddClient";
import EditProjectClient from "@/components/user/project/EditProjectClient";
import OrderConfirmation from "../components/user/orders/OrderConfirmation";
import ProjectWishList from "@/components/wishlist/ProjectWishList.vue";


const routes = [{
        name: 'Login',
        path: '/',
        component: Login,
        meta: {
            title: 'B2B - Login',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'Login2',
        path: '/login',
        component: Login,
        meta: {
            title: 'B2B - Login',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'NotFound',
        path: '/notfound',
        component: NotFound,
        meta: {
            title: 'B2B - Not found',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'Error',
        path: '/error',
        component: beforeLoginNotFound,
        meta: {
            title: 'B2B - Not found',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            title: 'B2B - Privacy Policy',
            auth: true,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'TermsAndConditions',
        path: '/terms-conditions',
        component: TermsAndConditions,
        meta: {
            title: 'B2B - Terms and Conditions',
            auth: true,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'CookiesAndPolicy',
        path: '/cookies-policy',
        component: CookiesAndPolicy,
        meta: {
            title: 'B2B - Cookies Policy',
            auth: true,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'ContactUs',
        path: '/contactus',
        component: ContactUs,
        meta: {
            title: 'B2B - Contact Us',
            auth: true,
            disableIfLoggedIn: true,
            requiresAuth: true
        }

    },
    {
        name: 'BeforeLoginContact',
        path: '/contact-us',
        component: BeforeLoginContactUs,
        meta: {
            title: 'B2B - Contact Us',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'BeforeLoginTermsAndConditions',
        path: '/terms&conditions',
        component: BeforeLoginTermsAndConditions,
        meta: {
            title: 'B2B - Terms And Conditions',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'BeforeLoginCookiesAndPolicy',
        path: '/cookies&policy',
        component: BeforeLoginCookiesAndPolicy,
        meta: {
            title: 'B2B - Terms And Conditions',
            auth: false,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'OrderSuccess',
        path: '/order-success',
        component: OrderSuccess,
        meta: {
            title: 'B2B - Order Success',
            auth: true,
            requiresAuth: true,
            disableIfLoggedIn: true
        },
        props: (route) => ({
            cartData: route.params
        })

    },
    // {
    //     name: 'OrderFailure',
    //     path: '/order-failure',
    //     component: OrderFailure,
    //     meta: {
    //         title: 'B2B - Order Failure',
    //         auth: true,
    //         requiresAuth: true,
    //         disableIfLoggedIn: true
    //     }
    //
    // },
    {
        name: 'OrderFailure',
        path: '/order-failure',
        component: OrderConfirmation,
        meta: {
            title: 'B2B - Order Failure',
            auth: true,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'EmailVerification',
        path: '/email-verification',
        component: EmailVerification,
        meta: {
            title: 'B2B - Email Verification',
        }
    },
    {
        name: 'UnauthorizedAccess',
        path: '/unauthorized-access',
        component: Invalid,
        meta: {
            title: 'B2B - Unauthorized Access',
        }
    },
    {
        name: 'ResetPasswordEmail',
        path: '/reset-password-email/',
        component: ResetPasswordEmail,
        meta: {
            title: 'B2B - Reset Password Email',
        },
        props: (route) => ({
            email: route.params
        })

    },
    {
        name: 'AccountActivation',
        path: '/account-activation',
        component: AccountActivation,
        meta: {
            title: 'B2B - Account Activation',
        }
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard
    },
    {
        name: 'EmailVerify',
        path: '/email-verify/:id',
        component: EmailVerify,
        meta: {
            title: 'B2B - Email Verify',
        }
    },
    {
        name: 'Register',
        path: '/register',
        component: Register,
        meta: {
            title: 'B2B - Register',
            auth: false
        }
    },
    {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: ForgotPassword,
        meta: {
            title: 'B2B - Forgot Password',
        }
    },
    {
        name: 'ResetPassword',
        path: '/reset-password/:id',
        component: ResetPassword,
        meta: {
            title: 'B2B - Reset Password',
        }
    },
    {
        name: 'CompanyDetailsBusiness',
        path: '/company-business-details',
        component: CompanyDetailsBusiness,
        meta: {
            title: 'B2B - Company Business Details',
            requiresAuth: true,
        }
    },
    {
        name: 'CompanyDetailsIndividual',
        path: '/company-individual-details',
        component: CompanyDetailsIndividual,
        meta: {
            title: 'B2B - Company Individual Details',
            requiresAuth: true,
        }
    },
    {
        name: 'OtpVerification',
        path: '/otp-verification',
        component: OtpVerification,
        meta: {
            title: 'B2B - Otp Verification',
        }
    },
    {
        name: 'DocumentVerificationMsg',
        path: '/document-verification',
        component: DocumentVerificationMsg,
        meta: {
            title: 'B2B - Document Verification',
        }
    },
    {
        name: 'user',
        path: '/user',
        component: userLayout,
        meta: {
            title: 'B2B - My Profile',
            requiresAuth: true
        },
        children: [{
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                name: 'profile',
                path: 'profile',
                component: MyProfile,
                meta: { requiresAuth: true }
            },
            {
                name: 'AddressBook',
                path: 'address-book',
                component: AddressBook,
                meta: {
                    title: 'B2B - Address Book',
                    requiresAuth: true }
            },
            {
                name: 'DefaultAddressList',
                path: 'make-default/:id',
                component: DefaultAddressList,
                meta: {
                    title: 'B2B - Default Address',
                    requiresAuth: true }
            },
            {
                name: 'EditAddress',
                path: 'edit-address',
                component: EditAddress,
                meta: {
                    title: 'B2B - Edit Address',
                    requiresAuth: true },
                props: (route) => ({
                    userData: route.params
                })
            },
            {
                name: 'AddAddress',
                path: 'add-address',
                component: AddAddress,
                meta: {
                    title: 'B2B - Add New Address',
                    requiresAuth: true
                }
            },
            {
                name: 'Orders',
                path: 'orders',
                component: Orders,
                meta: {
                    title: 'B2B - Orders',
                    requiresAuth: true
                }
            },
            {
                name: 'OrderDetails',
                path: 'order-details/:id',
                component: OrderDetails,
                meta: {
                    title: 'B2B - Order Details',
                    auth: false,
                    disableIfLoggedIn: true
                }

            },
            {
                name: 'MyCoupons',
                path: 'my-coupon',
                component: MyCoupons,
                meta: {
                    title: 'B2B - My Coupons',
                    auth: false,
                    disableIfLoggedIn: true
                }
            },
            {
                name: 'Roles',
                path: 'roles',
                component: Roles,
                meta: {
                    title: 'B2B - Roles Management',
                    requiresAuth: true
                }
            },
            {
                name: 'AddRole',
                path: 'add-role',
                component: AddRole,
                meta: {
                    title: 'B2B - Add Role',
                    requiresAuth: true
                }
            },
            {
                name: 'EditRole',
                path: 'edit-role/:id',
                component: EditRole,
                meta: {
                    title: 'B2B - Edit Role',
                    requiresAuth: true
                }
            },
            {
                name: 'ChangePassword',
                path: 'change-password',
                component: ChangePassword,
                meta: {
                    title: 'B2B - Change Password',
                    requiresAuth: true
                }
            },
            {
                name: 'PriceSetting',
                path: 'price-setting',
                component: PriceSetting,
                meta: {
                    title: 'B2B - Price Setting',
                    requiresAuth: true
                }
            },
            {
                name: 'ProjectClient',
                path: 'projects',
                component: ProjectClient,
                meta: {
                    title: 'B2B - Projects',
                    requiresAuth: true
                }
            },
            {
                name: 'AddProjectClient',
                path: 'add-project',
                component: AddClient,
                meta: {
                    title: 'B2B - Add Project',
                    requiresAuth: true
                }
            },
            {
                name: 'EditProjectClient',
                path: 'edit-project',
                component: EditProjectClient,
                meta: {
                    title: 'B2B - Edit Project',
                    requiresAuth: true
                }
            },
            {
                name: 'UserManagement',
                path: 'user-management',
                component: UserManagement,
                meta: {
                    title: 'B2B - User Management',
                    requiresAuth: true
                }
            },
            {
                name: 'EditUser',
                path: 'edit-user/:id',
                component: EditUser,
                meta: {
                    title: 'B2B - Edit User',
                    requiresAuth: true
                }
            },
            {
                name: 'AddUser',
                path: 'add-user',
                component: AddUser,
                meta: {
                    title: 'B2B - Add User',
                    requiresAuth: true
                }
            },
            {
                name: 'ViewUserManagement',
                path: 'view-user',
                component: ViewUserManagement,
                meta: {
                    title: 'B2B - View User',
                    requiresAuth: true
                }
            },
            {
                name: 'Support',
                path: 'support',
                component: Support,
                meta: {
                    title: 'B2B - Support',
                    requiresAuth: true }
            },
            {
                name: 'OrderPayment',
                path: 'order-payment/:id',
                component: OrderPayment,
                meta: {
                    title: 'B2B - Order Payment',
                    auth: false,
                    requiresAuth: true,
                    disableIfLoggedIn: true
                }

            },
            {
                name: 'OrderCancel',
                path: 'order-cancel',
                component: OrderCancel,
                meta: {
                    title: 'B2B - Order Cancelled',
                    auth: false,
                    disableIfLoggedIn: true
                }

            },
            {
                name: 'OrdersCancelReason',
                path: 'order-cancel-reason',
                component: OrdersCancelReason,
                meta: {
                    title: 'B2B - Order Cancelled Reason',
                    auth: false,
                    disableIfLoggedIn: true
                }

            },
        ],
    },
    {
        name: 'UserSupport',
        path: '/user-support',
        component: UserSupport
    },
    {
        name: 'CarList',
        path: '/show-cart',
        component: CartList,
        meta: {
            title: 'B2B - Cart',
            requiresAuth: true
        }
    },
    {
        name: 'CartCheckout',
        path: '/cart-checkout',
        component: CartCheckout,
        meta: {
            title: 'B2B - Cart Checkout',
            requiresAuth: true
        }
    },
    {
        name: 'Products3',
        path: '/products/search/:searchTerm',
        component: Products,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true
        }
    },
    {
        name: 'Products2',
        path: '/products/:mainMenu/:subMenu/:childMenu/:id',
        component: Products,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true,
            reload: true,
        }
    },
    {
        name: 'Products4',
        path: '/products/:mainMenu/:subMenu/:id',
        component: Products,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true,
            reload: true,
        }
    },
    {
        name: 'Products1',
        path: '/products-list/:mainMenu/:subMenu/:id',
        component: ProductSubMenu,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true,
            reload: true,
        }
    },
    {
        name: 'Products',
        path: '/products/:mainMenu/:id',
        component: Products,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true,
            reload: true,
        }
    },
    {
        name: 'ProductDetails1',
        path: '/product/:title/:id',
        component: ProductDetails,
        meta: {
            title: 'B2B - Product Details',
            requiresAuth: true,
        }
    },
    {
        name: 'ProductDetails',
        path: '/product-details/:id',
        component: ProductDetails,
        meta: {
            title: 'B2B - Product Details',
            requiresAuth: true,
        }
    },
    {
        name: 'WishList',
        path: '/wishlist',
        component: WishList,
        meta: {
            title: 'B2B - Wish List',
            requiresAuth: true,
        },

    },
    {
        name: 'wishlistByProject',
        path: '/wishlist-project/:clientProjectId',
        component: ProjectWishList,
        meta: {
            title: 'B2B - Wishlist Project',
            requiresAuth: true
        }
    },
    {
        name: 'SearchList',
        path: '/products-list/:mainMenu/:categoryId',
        component: SearchList,
        meta: {
            title: 'B2B - Products List',
            requiresAuth: true,
        }
    },
    {
        name: 'HomePage',
        path: '/home',
        component: HomePage,
        meta: {
            title: 'B2B - Home',
            requiresAuth: true
        }

    },
    {
        name: 'Faqs',
        path: '/faq',
        component: Faqs,
        meta: {
            title: 'B2B - Faq',
            auth: false,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'AboutUs',
        path: '/about-us',
        component: AboutUs,
        meta: {
            title: 'B2B - About Us',
            auth: false,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'OurStores',
        path: '/our-stores',
        component: OurStores,
        meta: {
            title: 'B2B - Our Stores',
            auth: false,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'ShippingInfo',
        path: '/shipping-info',
        component: ShippingInfo,
        meta: {
            title: 'B2B - Shipping Info',
            auth: false,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
    {
        name: 'CollectionList',
        path: '/collection-list',
        component: CollectionList,
        meta: {
            title: 'B2B - Collection List',
            auth: false,
            requiresAuth: true,
            disableIfLoggedIn: true
        }

    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    window.previousUrl = from.path;
    window.previousTitle = from.meta.title;
    if (!to.matched.length) {
        next({ path: '/notfound' })
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            if(from.path!='/'){
                localStorage.setItem("previousUrl", from.path);
            }
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})
export default router;