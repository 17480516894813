<template>
    <div id="before-login-header">
        <div class="row col-12 m-0" >
            <div class="header_seacrch col-xl-12 col-lg-12 col-md-12 " >   
                <button class="logo_btn mx-auto"><router-link to="/"><img src="../assets/image/logo_thatsliving_800x-removebg-preview.png" class="img-fluid "></router-link></button>
            </div>
        </div>
    </div>
</template>
<script>

export default{
    name: 'before-login-header',
    data(){
        return{
            cartbadge:'0'
        }
    },
    setup() {

    },
}
</script>

<style scoped>
    @import '../assets/css/style.css';
   #before-login-header .row{
        border-bottom: 1px solid #707070;
   }
</style>
