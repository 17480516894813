<template>
  <!--  Header Start -->
  <CartHeader :cartCountUpdate="cartCount" />
  <!-- Header End -->
  <section class="products_sec product_list_main">
    <div class="page-width">
      <!-- Breadcrumb Sec -->
      <section class="breadcrumb_sec">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home">Home</router-link>
            </li>
            <li v-if="mainMenu && mainMenu != 'brands'" class="breadcrumb-item">
            <router-link data-target="trending" class="nav_trig"
                :to="`/products-list/${sanitizeTitle(mainMenu)}/${encryptIDs(mainId)}`">{{ mainMenu }}</router-link></li>
            <li
              v-if="!childMenu"
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ subMenu }} {{this.searchStr}}
            </li>
            <li v-if="subMenu && childMenu" class="breadcrumb-item">
              <router-link
                :to="`/products-list/${sanitizeTitle(mainMenu)}/${sanitizeTitle(
                  subMenu
                )}/${encryptIDs(mainId, subId)}`"
                >{{ subMenu }}</router-link
              >
            </li>
            <li
              v-if="childMenu"
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ childMenu }}
            </li>
          </ol>
        </nav>
      </section>



      <!-- Breadcrumb Sec -->
      <div class="row row_sidebar">
        <div class="col-lg-3 side_sticky">
          <!-- Start Filter Desktop Section -->
          <div class="mobile-view-filter-btns">
            <button class="toggle" v-on:click.prevent="openFilter()">
              Filter
            </button>
            <button v-on:click.prevent="openSort()">Sort</button>
          </div>
          <div class="product_filter_sec" id="target">
            <div class="product_filter_headsec">
              <span>Refine By </span>
              <a href="#" v-on:click.prevent="clearAll"  v-if="productTypeDisplay?.length > 0">Clear All</a>
            </div>

            <div class="filter_selected_sec">
              <ul>
                <li v-for="pro in productTypeDisplay" :key="pro">
                  {{ pro.value }}
                  <span><a href="#" v-on:click.prevent="removeProductType(pro.value, pro.id,pro.tag,pro.data)"
                      >X</a
                    ></span>
                </li>
              </ul>
            </div>

            <div class="accordion" id="accordionPanelsStayOpenExample">
              <!--Accordion Product Type Filter Starts-->
              <div v-if="productTypeData?.length > 0 || this.childMenu != ''" class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                          class="accordion-button down_arrow"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                  >
                    Product Type

                  </button>
                  <a v-if="productTypeApiProductList?.length > 0"
                     href="#"
                     style="font-size: 15px"
                     class="clear-filter"
                     v-on:click.prevent="clearProductTypes(productTypeApiProductList)"
                     type="button"
                  >
                    Clear
                  </a>

                </h2>
                <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div class="accordion-body">
                    <div
                            v-for="pro in productTypeData"
                            :key="pro.listSubCategoryId"
                            class="form-check"
                    >
                      <label class="form-check-label">
                        {{ pro.listSubCategoryName }}  
                        <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="productTypeBool[pro.listSubCategoryId]"
                                @click="selectProductTypes(pro.listSubCategoryId, pro.listSubCategoryName,pro.checked)"
                        />
                        <span class="checkmark"></span>
                      
                      </label>
                      <span class="frm_count">({{pro.productsCount}})</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--Accordion Product Type Filter Ends-->
              <!--********************************************-->
              <!--Accordion Brand Starts-->

              <div

                      v-if="this.brandData?.length > 0"
                      class="accordion-item"
              >
                <h2 class="accordion-header" id="panelsStayOpen-headingBrand">
                  <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"

                          aria-expanded="true"
                          data-bs-target="#panelsStayOpen-collapseBrand"

                          aria-controls="panelsStayOpen-collapseBrand"
                  >
                    Brands

                  </button>
                  <a v-if="productBrandApiProductList?.length > 0"
                     href="#"
                     style="font-size: 15px"
                     class="clear-filter"
                     v-on:click.prevent="clearBrands(productBrandApiProductList)"
                     type="button"
                  >
                    Clear
                  </a>
                </h2>
                <div
                        id="panelsStayOpen-collapseBrand"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-collapseBrand"
                >
                  <div class="accordion-body" >
                    <div class="form-check" v-for="brand in brandData"
                         :key="brand.id">
                      <label class="form-check-label">
                        {{ brand.brand }}  <!-- <span class="frm_count">({{brand.productsCount}})</span> -->
                        <input
                                class="form-check-input"
                                type="checkbox"
                                :checked="productBrandBool[brand.brand]"
                                @click="selectBrandFilter(brand.brandId, brand.brand)"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span class="frm_count">({{brand.productsCount}})</span>
                    </div>
                  </div>
                </div>
              </div>


              <!--Accordion Brand Ends-->


              <div class="accordion-item filter_block_coll" v-if="this.priceMax != 0">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    Price
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" class="filter_block_coll_main accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">

                  <div class="accordion-body">
                    <FilterTypeSlider
                            :data="this.range"
                            v-model="range"
                            v-on:input="setSelectedVariants"
                    />
                    <VueSimpleRangeSlider
                            style="width: 300px"
                            :min="this.priceMin"
                            :max="this.priceMax"
                            :logarithmic="true"
                            bar-color="#bebebe"
                            active-bar-color="#6699ff"
                            v-model="this.range"
                            v-on:click="setSelectedVariants"
                    />

                  </div>
                </div>
              </div>
<!--              Filter Start-->
              <div
                v-for="fil in filterTypes.filter((val)=>val.filterValue!='')"
                :key="fil.id"
                class="accordion-item"
              >
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"

                    aria-expanded="true"
                    :data-bs-target="`#${fil.filterName}`"

                    :aria-controls="fil.filterName"
                  >
                    {{ fil.filterName }}

                  </button>
                  <a
                    :id="'filter-'+fil.filterId"
                    href="#"
                    style="font-size: 15px; display: none"
                    class="clear-filter filters"
                    v-on:click.prevent="clearFilters(fil.filterValue,fil.filterId)"
                    type="button"
                  >
                    Clear
                  </a>
                </h2>
                <div

                  :id="fil.filterName"
                  class="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div
                    v-if="fil.filterValue?.length > 0"
                    class="accordion-body"
                  >
                    <div v-for="j in fil.filterValue"
                         :key="j">
                        <div class="form-check" v-if="j.productsCount != 0">
                            <label class="form-check-label" >
                                {{ j.filterName }}
                                <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :checked="filtersBool[j.filterName]"
                                        @click="selectFilters(j.filterName,fil.filterId,fil.filterValue)"
                                />
                                <span class="checkmark"></span>
                            </label>
                            <span class="frm_count"  v-if="j.productsCount != 0">({{ j.productsCount }})</span>
                        </div>




                    </div>
                  </div>
                </div>
              </div>
<!--              End Filter-->




            </div>
          </div>
        </div>

        <div class="col-lg-9 side_sticky2">
          <!-- Start Product List -->
          <div class="product_list_sec">
            <div class="products_ttl_sort">
              <div v-if="searchStr" class="page_ttl-sub">
                  {{ searchStr }} ({{
                    // productData?.length
                productCount
                  }})

                </div>

              <div v-else class="page_ttl-sub">

                <div v-if="this.productTypeDisplay.length >= 2">
                  Products ({{
                  productCount
                  }})
                </div>
                <div v-else>
                  {{ childMenu ? childMenu : subMenu ? subMenu : mainMenu }} ({{
                  productCount
                  }})
                </div>

              </div>

              <div class="sort_by_filter_drp">
                <!-- <select v-if="!openSortBool" v-model="sortBy" v-on:change.prevent="sortData()" class="form-select"
                  aria-label="Default select example">
                  <option value="title-asc">Title A-Z</option>
                  <option value="title-desc">Title Z-A</option>
                  <option value="price-asc">PRICE LOW TO High</option>
                  <option value="price-desc">PRICE HIGH TO LOW</option>
                </select> -->
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ sortByLabel ? sortByLabel : "Sort By:" }}
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        v-on:click.prevent="
                          sortData('title-asc', 'Title : A-Z')
                        "
                        href="#"
                        >Title : A-Z</a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        v-on:click.prevent="
                          sortData('title-desc', 'Title : Z-A')
                        "
                        href="#"
                        >Title : Z-A</a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        v-on:click.prevent="
                          sortData('price-asc', 'PRICE : LOW TO High')
                        "
                        href="#"
                        >PRICE : LOW TO High</a
                      >
                    </li>
                    <li>

                      <a
                        class="dropdown-item"
                        v-on:click.prevent="
                          sortData('price-desc', 'PRICE : HIGH TO LOW')
                        "
                        href="#"
                        >PRICE : HIGH TO LOW</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--FILTER SIDE NAV STARTS-->
            <div
              id="filterSidenav"
              :class="openFilterBool ? 'active' : ''"
              class="sidenav f_l_pdb"
            >
              <div class="nav_out">
                <a class="sub_holder" href="#">
                  <span class="feather-icon"> </span>
                  <span class="back_txt">Filters</span>
                </a>
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  v-on:click.prevent="openFilter()"
                  >&times;</a
                >
<!--                <div class="product_filter_headsec">-->
<!--&lt;!&ndash;                  <span>Refine By</span>&ndash;&gt;-->
<!--                  <a href="#" v-on:click.prevent="clearAll"  v-if="productTypeDisplay?.length > 0" class="clear-filter">Clear All</a>-->
<!--                </div>-->





<!--                <div class="filter_selected_sec">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      Benches <span><a href="#">X</a></span>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      Cocktail Tables <span><a href="#">X</a></span>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      Dining Chairs <span><a href="#">X</a></span>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      Beech <span><a href="#">X</a></span>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      Bar & Accessories <span><a href="#">X</a></span>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->

<!--                For Mobile View-->

                <div class="cf_cnt"  v-if="productTypeDisplay?.length > 0">
                  <a
                        href="#"
                        style="font-size: 12px;"
                        class="clear-filter"
                        v-on:click.prevent="clearAll"
                        type="button">
                  Clear All
                </a>
                  <div class="filter_selected_sec">
                    <ul>
                      <li v-for="pro in productTypeDisplay" :key="pro">
                        {{ pro.value }}<span><a href="#" v-on:click.prevent="removeProductType(pro.value, pro.id,pro.tag,pro.data)"
                      >X</a
                      ></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="accordion plp_fltr" id="accordionPanelsStayOpenExample">
                  <div v-if="productTypeData?.length > 0" class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button
                              class="accordion-button down_arrow"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                      >
                        Product Type

                      </button>
                      <a v-if="productTypeApiProductList?.length > 0"
                         href="#"
                         style="font-size: 15px"
                         class="clear-filter"
                         v-on:click.prevent="clearProductTypes(productTypeApiProductList)"
                         type="button"
                      >
                        Clear
                      </a>

                    </h2>
                    <div
                            id="panelsStayOpen-collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div class="accordion-body">
                        <div
                                v-for="pro in productTypeData"
                                :key="pro.listSubCategoryId"
                                class="form-check"
                        >
                          <label class="form-check-label">
                            {{ pro.listSubCategoryName }}  <span class="frm_count">({{pro.productsCount}})</span>
                            <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :checked="productTypeBool[pro.listSubCategoryId]"
                                    @click="selectProductTypes(pro.listSubCategoryId, pro.listSubCategoryName,pro.checked)"
                            />
                            <span class="checkmark"></span>
                          </label>
                          <!-- <span>(1)</span>-->
                        </div>
                      </div>
                    </div>
                  </div>


                  <div

                          v-if="this.brandData?.length > 0"
                          class="accordion-item"
                  >
                    <h2 class="accordion-header" id="panelsStayOpen-headingBrand">
                      <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseBrand"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseBrand"
                      >
                        Brands

                      </button>
                      <a v-if="productBrandApiProductList?.length > 0"
                         href="#"
                         style="font-size: 15px"
                         class="clear-filter"
                         v-on:click.prevent="clearBrands(productBrandApiProductList)"
                         type="button"
                      >
                        Clear
                      </a>
                    </h2>
                    <div
                            id="panelsStayOpen-collapseBrand"
                            class="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-collapseBrand"
                    >
                      <div class="accordion-body" >
                        <div class="form-check" v-for="brand in brandData"
                             :key="brand.id">
                          <label class="form-check-label">
                            {{ brand.brand }}  <span class="frm_count">({{brand.productsCount}})</span>
                            <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :checked="productBrandBool[brand.brand]"
                                    @click="selectBrandFilter(brand.brandId, brand.brand)"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Price Range
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div class="accordion-body">
                       <div class="range-value-sec">
                          <div class="range-min-val">
                            <input
                              type="text"
                              v-model="this.priceFrom"
                              @keyup="searchWithPriceMobile"
                              placeholder="QAR 1000"
                            />
                          </div>

                          <div class="range-txt-seperator">-</div>

                          <div class="range-max-val">
                            <input
                              type="text"
                              v-model="this.priceTo"
                              @keyup="searchWithPriceMobile"
                              placeholder="QAR 1000"
                            />
                          </div>
                        </div>

<!--                        <FilterTypeSlider-->
<!--                                v-model="range"-->
<!--                                @input="setSelectedVariants"  />-->

<!--                        <VueSimpleRangeSlider-->
<!--                                style="width: 300px"-->
<!--                                :min="1"-->
<!--                                :max="20000"-->
<!--                                :logarithmic="true"-->
<!--                                v-model="range"-->
<!--                                bar-color="#bebebe"-->
<!--                                active-bar-color="#6699ff"-->
<!--                                @click="setSelectedVariants"-->
<!--                        />-->



                      </div>
                    </div>
                  </div>

                  <div
                          v-for="fil in filterTypes.filter((val)=>val.filterValue!='')"
                          :key="fil.id"
                          class="accordion-item"
                  >
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#${fil.filterName}`"
                              aria-expanded="true"
                              :aria-controls="fil.filterName"
                      >
                        {{ fil.filterName }}

                      </button>
                      <a
                              :id="'filter-'+fil.filterId"
                              href="#"
                              style="font-size: 15px; display: none"
                              class="clear-filter filters"
                              v-on:click.prevent="clearFilters(fil.filterValue,fil.filterId)"
                              type="button"
                      >
                        Clear
                      </a>
                    </h2>
                    <div

                            :id="fil.filterName"
                            class="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div
                              v-if="fil.filterValue?.length > 0"
                              class="accordion-body"
                      >
                        <div class="form-check" v-for="j in fil.filterValue"
                             :key="j">
                          <label class="form-check-label">
                            {{ j.filterName }} <span class="frm_count">({{ j.productsCount }})</span>
                            <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :checked="filtersBool[j.filterName]"
                                    @click="selectFilters(j.filterName,fil.filterId, fil.filterValue)"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nav_close" v-on:click.prevent="closeNav()"></div>
            </div>
            <!--FILTER SIDE NAV ENDS-->
<!--            <div class="filter__item" v-for="item in info" :key="item.unique_id">-->
<!--              <h2>{{ item.display_name }}</h2>-->
<!--              <template v-if="item.type == 'slider'">-->
<!--                <FilterTypeSlider-->
<!--                        :item="item"-->
<!--                        v-model="range"-->
<!--                        v-on:input="setSelectedVariants"-->
<!--                />-->
<!--                <VueSimpleRangeSlider-->
<!--                        style="width: 300px"-->
<!--                        :min="item.slider_min_value"-->
<!--                        :max="item.slider_max_value"-->
<!--                        :logarithmic="true"-->
<!--                        v-model="range"-->
<!--                        v-on:input="setSelectedVariants"-->
<!--                />-->
<!--              </template>-->
<!--              &lt;!&ndash; <template v-else-if="item.type == 'list'">-->
<!--                <FilterTypeList-->
<!--                  :item="item"-->
<!--                  v-model="selected"-->
<!--                  v-on:input="setSelectedVariants"-->
<!--                />-->
<!--              </template> &ndash;&gt;-->
<!--            </div>-->
            <!--SORT SIDE NAV START-->
            <div
              id="sortSideNav"
              :class="openSortBool ? 'active' : ''"
              class="sidenav f_l_pdb"
            >
              <div class="nav_out">
                <a class="sub_holder" style="padding-bottom: 25px" href="#">
                  <span class="feather-icon"> </span>
                  <span class="back_txt"> Sort</span>
                </a>
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  v-on:click.prevent="openSort()"
                  >&times;</a
                >
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Sort By
                      </button>
                      <!-- <button class="clear-filter" type="button">Clear</button> -->
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div class="accordion-body">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="sortBy"
                            id="sortBy"
                            value="title-asc"
                            v-model="sortBy"
                            @click="sortData('title-asc', 'Title : A-Z')"
                          />
                          <label class="form-check-label" for="sortByID">
                            Title : A-Z
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="sortBy"
                            id="sortBy"
                            value="title-desc"
                            v-model="sortBy"
                            @click="sortData('title-desc', 'Title : Z-A')"
                          />
                          <label class="form-check-label" for="sortByID">
                            Title : Z-A
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="sortBy"
                            id="sortBy"
                            @click="
                              sortData('price-asc', 'PRICE : LOW TO High')
                            "
                            v-model="sortBy"
                            value="price-asc"
                          />
                          <label class="form-check-label" for="sortByID">
                            PRICE : LOW TO High
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="sortBy"
                            id="sortBy"
                            value="price-desc"
                            @click="
                              sortData('price-desc', 'PRICE : HIGH TO LOW')
                            "
                            v-model="sortBy"
                          />
                          <label class="form-check-label" for="sortByID">
                            PRICE : HIGH TO LOW
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nav_close" v-on:click.prevent="openSort()"></div>
            </div>

            <!--SORT SIDE NAV ENDS-->

            <!-- Product List -->
            <div class="product_list_view">
              <div class="row">
                <ProductCard
                  v-for="item in productData"
                  :key="item.id"
                  :productData="item"
                  removeClass="true"
                  class="col-xl-4 col-6"
                />

                <button class="btn_outline sho_btns_blk" @click="commentsToShow" id="showMore"> SHOW MORE</button>
               <!--  <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-4 text-center">
                    
                  </div>
                  <div class="col-md-4"></div>

                </div> -->




                <!--<p class="mt-3">Current Page: {{ page }}</p>-->

              </div>
            </div>
            <!-- Product List -->
          </div>
          <!-- End Prducts List -->
        </div>
      </div>
    </div>
  </section>

  <CartFooter />

</template>
<script>
import "../cart/css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/Products";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
import common from "@/apis/Common";
import wishListApi from "@/apis/WishList";
//import BreadCrumb from '../common/BreadCrumb';
import ProductCard from "@/components/common/ProductCard";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import  FilterTypeSlider from "@/components/products/FilterTypeSlider.vue";



export default ({
  setup() {
      //
  },
  name: "products-list",
  data() {
    return {
        perPage: 3,
        currentPage: 1,
      productData: [],
      sortBy: "title-asc",
      cartCount: 10,
      checkBool: [],
      loaderBool: [],
      common: common,
      openFilterBool: false,
      openSortBool: false,
      wishListBool: [],
      mainMenu: "",
      subMenu: "",
      childMenu: "",
      mainId: "",
      subId: "",
      subCatId: "",
      listSubId: "",
      listSubIdSearch: [],
      listsubCatIdArray: [],
      sortByLabel: "",
      childId: "",
        productCount: 0,


        page:1,
      filtersData: [],
      filtersBool: [],
      filterIdBool: [],
      filterIdList: [],
      filterIdDisplay: [],
      filtersList: [],
      filterDisplay: [],
        filterTypes:[],


        productBrandBool: [],
        productBandIdApi:[],
        productBrandApiList:[],
        productBrandDisplay:[],
        productBrandApiProductList:[],
        brandIdProduct:[],
        productBrandIdBool:[],


        productTypeData: [],
        productTypeBool: [],
      productTypeList: [],
      productTypeApiProductList: [],
      productTypeDisplay: [],


      priceFrom: 1,
      priceTo: 228700,
        priceMin: 0,
        priceMax: 0,
      fromPrice: false,
      searchStr: "",
      info: null,
      selected: [],
      range: [],
        priceData: [],
      selectedVariants: {},
    };
  },
  props: ["data"],
  components: {
    CartHeader,
    CartFooter,
    // BreadCrumb,
    ProductCard,
    FilterTypeSlider,
    VueSimpleRangeSlider
  },

    computed: {
        rows() {
            return this.productCount
        }
    },

  created() {
    document.title = this.$route.meta.title;
      let { id, mainMenu, subMenu, childMenu } = this.$route.params;
      localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
      if (mainMenu) {
          mainMenu = mainMenu.replaceAll("-", " ");
          this.mainMenu = mainMenu;
      }
      if (subMenu) {
          subMenu = subMenu.replaceAll("-", " ");
          this.subMenu = subMenu;
      }
      if (childMenu) {
          childMenu = childMenu.replaceAll("-", " ");
          this.childMenu = childMenu;
      }
      id = Base64.decode(id);
      id = id.split("@@");
      this.mainId = id[0];
      this.subId = id[1];
      this.childId = id[2];

      if (this.childMenu == '' && this.mainMenu != 'brands'){
          this.productTypeBool = [];
          this.productTypeDisplay = [];
          this.productTypeApiProductList = [];
          this.listsubCatIdArray = [];
      }
      else if (this.mainMenu == 'brands'){

          let brand = this.subMenu.toUpperCase()
          this.productBrandBool[brand] = !this.productBrandBool[brand];
          this.productBrandIdBool[this.mainId] = !this.productBrandIdBool[this.mainId];

          if (this.productBrandBool[brand]) {
              // productTypeDisplay
              this.productTypeDisplay.push({"id": this.mainId, "value": brand, "tag": "brand"});

              this.productBrandApiProductList[this.mainId] = brand;
              // this.productTypeList[brandId] = value;
          } else {
              delete this.productBrandApiProductList[this.mainId];
              let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === brand)
              this.productTypeDisplay.splice(localSessionIndex, 1)
              // this.productTypeList.splice(index, 1)

          }
          this.brandIdProduct.push(this.mainId)
      }
      else{
          this.productTypeBool[this.childId] = !this.productTypeBool[this.childId];
          if (this.productTypeBool[this.childId]) {
              this.productTypeDisplay.push({"id": this.childId, "value": this.childMenu, "tag": "type"});

              this.productTypeApiProductList[this.childId] = this.childMenu;
              this.productTypeList[this.childId] = this.childMenu;
          } else {
              delete this.productTypeApiProductList[this.childId];
              let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === this.childId)
              this.productTypeDisplay.splice(localSessionIndex, 1)
          }
          this.listsubCatIdArray.push(this.childId)
      }


      if (window.innerWidth > 992){
            this.callPriceProductApi();
            this.callProductApi();
        }else {
            this.callProductApiMobile();
        }




    this.wishListBool = this.$store.getters.getWishList;

    // let { id } = this.$route.params;
    // if(id){
    //   id = Base64.decode(id);
    //   id = id.split("@@");
    //   this.mainId = id[0];
    //   this.subId = id[1];
    //   this.childId = id[2];
    //   if (id[1]) {
    //     this.getProductTypes();
    //     this.getFilters();
    //   }
    // }
    // this.min = 0
    // this.max = 250
    // this.formatter = value => `￥${value}`

    // axios
    //         .get(
    //                 "https://raw.githubusercontent.com/cscart/apply-for-job/master/frontend/architect/files/filters.json"
    //         )
    //         .then((response) => {
    //           this.info = response.data;
    //           this.setDefaultVariants();
    //         });

  },
  mounted(){




  },
    updated(){

        if (this.productCount < 30 ){
            document.getElementById('showMore').style.display ="none";
        }
        else if (this.productCount == this.productData.length?this.productData.length : 0){
            document.getElementById('showMore').style.display ="none";
        }
        else{
            document.getElementById('showMore').style.display ="block";
        }
    },
  methods: {
    // sortFunc: function (){
    //   return this.productTypeDisplay.slice().sort(function(a, b){
    //     return (a.value > b.value) ? 1 : -1;
    //   });
    // },

      commentsToShow(){
          this.page += 1;


          if (this.searchStr == '' && this.mainMenu != 'brands'){
              let paramsData = Base64.decode(this.$route.params.id);
              paramsData = paramsData.split("@@");
              let id = paramsData[0];
              let subid = paramsData[1];
              let child = paramsData[2];
              // child = child ? child : "";
              let listSubCategoryId = [];
              let productBrand = [];
              // if (this.productTypeList?.length) {
              //   console.log(...Object.keys(this.productTypeList))
              //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
              // }
              if (this.productTypeApiProductList?.length) {
                  console.log(...Object.keys(this.productTypeApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
              }
              if (this.productBrandApiProductList?.length) {
                  console.log(...Object.keys(this.productBrandApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  productBrand = [...Object.keys(this.productBrandApiProductList)];
              }
              let filtersPost = Object.values(this.filtersList);


              let sortOptions = this.sortBy.split("-");
              let userId = localStorage.getItem("id");
              userId = Base64.decode(userId);

              let range = [];
              if (this.range[0]) {
                  range.push(this.range[0]);
              }
              if (this.range[1]) {
                  range.push(this.range[1]);
              }
              let payLoad = {
                  page:this.page,
                  categoryId: id,
                  brand: productBrand,
                  subCategoryId: subid,
                  listSubCategoryId: listSubCategoryId?.length
                      ? listSubCategoryId
                      : child
                          ? [child]
                          : [],
                  userId: userId,
                  sortBy: sortOptions[0],
                  sortValue: sortOptions[1],
                  filters: filtersPost,
                  price: range,
                  searchStr: this.searchStr,
              };
              Api.getProductList(payLoad)
                  .then((res) => {
                      console.log("Swaraj product Desktop", res.data);
                      if (res.data.success) {
                          console.log(res.data.data.products)
                          // this.productData = res.data.data.products;
                          this.productData = [...this.productData, ...res.data.data.products];
                          this.brandData = res.data.data.brandTypes;
                          this.productTypeData = res.data.data.productTypes;
                          this.filterTypes = res.data.data.filterTypes;
                          this.productCount = res.data.data.productsCount;
                          // this.priceMin = res.data.data.priceMin;
                          // this.priceMax = res.data.data.priceMax;
                          if (!this.fromPrice) {
                              if (this.openSortBool) {
                                  this.openSort();
                              }
                              // if (this.openFilterBool) {
                              //   this.openFilter();
                              // }
                          }
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  });

          }
          else
          {

              let id = "";
              let subid = "";
              // let child = [];
              // child = child ? child : "";
              let listSubCategoryId = [];
              let productBrand = [];
              // if (this.productTypeList?.length) {
              //   console.log(...Object.keys(this.productTypeList))
              //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
              // }
              if (this.productTypeApiProductList?.length) {
                  console.log(...Object.keys(this.productTypeApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
              }
              if (this.productBrandApiProductList?.length) {
                  console.log(...Object.keys(this.productBrandApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  productBrand = [...Object.keys(this.productBrandApiProductList)];
              }
              let filtersPost = Object.values(this.filtersList);


              let sortOptions = this.sortBy.split("-");
              let userId = localStorage.getItem("id");
              userId = Base64.decode(userId);

              let range = [];
              if (this.range[0]) {
                  range.push(this.range[0]);
              }
              if (this.range[1]) {
                  range.push(this.range[1]);
              }
              let payLoad = {
                  page: this.page,
                  categoryId: id,
                  brand: productBrand,
                  subCategoryId: subid,
                  listSubCategoryId: listSubCategoryId,
                  userId: userId,
                  sortBy: sortOptions[0],
                  sortValue: sortOptions[1],
                  filters: filtersPost,
                  price: range,
                  searchStr: this.searchStr,
              };
              Api.getProductList(payLoad)
                  .then((res) => {
                      console.log("Swaraj product Desktop", res.data);
                      if (res.data.success) {
                          console.log(res.data.data.products)
                          this.productData = [...this.productData, ...res.data.data.products];
                          this.brandData = res.data.data.brandTypes;
                          this.productTypeData = res.data.data.productTypes;
                          this.filterTypes = res.data.data.filterTypes;
                          this.productCount = res.data.data.productsCount;
                          // this.priceMin = res.data.data.priceMin;
                          // this.priceMax = res.data.data.priceMax;
                          if (!this.fromPrice) {
                              if (this.openSortBool) {
                                  this.openSort();
                              }
                              // if (this.openFilterBool) {
                              //   this.openFilter();
                              // }
                          }
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  });
          }

          if (this.productCount < 30 ){
              document.getElementById('showMore').style.display ="none";
          }else if (this.productCount == this.productData.length){
              document.getElementById('showMore').style.display ="none";
          }else{
              document.getElementById('showMore').style.display ="block";
          }



      },
    setDefaultVariants() {
      // this.selected = [];

        this.range = [
            this.priceMin,this.priceMax
        ];
        console.log(this.range);
    },
    log() {
      console.log(this.selectedVariants);
    },
    setSelectedVariants() {
      this.selectedVariants = {
        variants: this.selected,
        min: this.range[0],
        max: this.range[1],
      };
      this.searchWithPrice();
      // console.log(this.range[1]);
    },





    // Filter Clear Start

    clearFilters(clrFilters,id){
      // console.log(clrFilters)
      //
      // let payLoad = {
      //   subCategoryId: this.subId,
      //   filters:  [],
      //   listSubCategoryId: this.childId ? this.childId : "",
      // };
      // Api.getProductTypes(payLoad)
      //         .then((res) => {
      //           console.log("Rolessss", res.data);
      //           if (res.data.success) {
      //             this.productTypeData = res.data.data;
      //           }
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      // let payLoad1 = {
      //   subCategoryId: this.subId,
      //   listSubCategoryId: this.childId ? this.childId : "",
      //   filtersData: [],
      // };
      // Api.getFiltersNew(payLoad1)
      //         .then((res) => {
      //           if (res.data.success) {
      //             this.filtersData = res.data.data;
      //             this.getFiltersDataFormat();
      //           }
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });






      clrFilters?.forEach((val)=>{
        let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === val.filterName)
        if (localSessionIndex != -1){
          console.log(localSessionIndex)
          this.productTypeDisplay.splice(localSessionIndex,1)
        }

        //
      })
        clrFilters?.forEach((val)=>{
          delete this.filtersBool[val.filterName];
          delete this.filtersList[val.filterName];
        })





      document.getElementById("filter-"+id).setAttribute("style", "display:none;font-size:15px");
      // document.getElementById("filterM-"+id).setAttribute("style", "display:none;font-size:15px");
      this.callProductApi();
      // if (this.openFilterBool) {
      //   this.openFilter();
      // }
    },
    // Filter Clear End


    // Clear All Start
    clearAll() {

      this.productTypeBool = [];
      this.productTypeApiProductList = [];
      this.productTypeDisplay = [];
      this.filtersBool = [];
      this.filtersList = [];
      this.filterDisplay = [];
      this.listsubCatIdArray = [];
        this.brandIdProduct = [];
        this.productBrandBool = [];
        this.productBrandApiList = [];
        this.productBrandApiProductList = [];

        this.page = 1;
      this.callProductApi();
      for (var i = 0; i <= this.filterIdDisplay.length; i++){
        document.getElementById("filter-"+this.filterIdDisplay[i]).setAttribute("style", "display:none;font-size:15px");
      }

    },

    // Clear All End


    // Clear Product Types Start

    clearProductTypes(data) {
      this.productTypeBool = [];
      this.productTypeApiProductList = [];
      data?.forEach((val)=>{
        let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === val)
        this.productTypeDisplay.splice(localSessionIndex,1)
        console.log(localSessionIndex)
      });
      this.filtersBool = [];
      this.filtersList = [];
      this.filterDisplay = [];
      this.callProductApi();
    },
    // Clear Product Types End


      // Clear Brand Filter Starts
      clearBrands(data) {
          this.productBrandBool = [];
          this.productBrandApiList = [];
          data?.forEach((val)=>{
              let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === val)
              this.productTypeDisplay.splice(localSessionIndex,1)

          });
          this.brandIdProduct = [];
          this.productBrandApiProductList = [];
          this.productTypeList = [];
          this.callProductApi();
      },
      // Clear Brand Filter Ends


    // Product Api For Desktop
    callProductApi() {
      let { searchTerm } = this.$route.params;
      localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
      if (searchTerm && searchTerm!==undefined) {
        searchTerm = searchTerm.replaceAll("-", " ");
      this.searchStr = searchTerm;
          // this.getSearchProductsList();
          this.getProductList();
      }

      else {
        let { id, mainMenu, subMenu, childMenu } = this.$route.params;
        localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
        if (mainMenu) {
          mainMenu = mainMenu.replaceAll("-", " ");
          this.mainMenu = mainMenu;
        }
        if (subMenu) {
          subMenu = subMenu.replaceAll("-", " ");
          this.subMenu = subMenu;
        }
        if (childMenu) {
          childMenu = childMenu.replaceAll("-", " ");
          this.childMenu = childMenu;
        }

        id = Base64.decode(id);
        id = id.split("@@");
        this.mainId = id[0];
        this.subId = id[1];
        this.childId = id[2];
            if (id[1]) {
                this.getProductList();
            } else {
                this.getProductList();
            }



      }
    },
    // Product Api For Mobile
    callProductApiMobile() {
      let { searchTerm } = this.$route.params;
      localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
      if (searchTerm && searchTerm!==undefined) {
        searchTerm = searchTerm.replaceAll("-", " ");
      this.searchStr = searchTerm;
          // this.getSearchProductsList();
          this.getProductList();
      }
      else {
        let { id, mainMenu, subMenu, childMenu } = this.$route.params;
        localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
        if (mainMenu) {
          mainMenu = mainMenu.replaceAll("-", " ");
          this.mainMenu = mainMenu;
        }
        if (subMenu) {
          subMenu = subMenu.replaceAll("-", " ");
          this.subMenu = subMenu;
        }
        if (childMenu) {
          childMenu = childMenu.replaceAll("-", " ");
          this.childMenu = childMenu;
        }
        id = Base64.decode(id);
        id = id.split("@@");
        this.mainId = id[0];
        this.subId = id[1];
        this.childId = id[2];
        if (id[1]) {
          this.getProductListMobile();
        } else {

            this.getProductListMobile();

        }
      }
    },


    // Product Type Remove individual Start
    removeProductType(product, id, tag,value) {
      if (tag == "type") {
        this.productTypeBool[id] = !this.productTypeBool[id];
        if (this.productTypeBool[id]) {
          // this.productTypeList[id] = product;

          this.productTypeDisplay.push({"id": id, "value": product, "tag": "type"});
          this.productTypeApiProductList[id] = product;
        } else {
          delete this.productTypeApiProductList[id];
          // for (var i =0;i<=this.productTypeList.length;i++){
          //   if (this.productTypeList[i].id === id){
          //     this.productTypeList.splice(i, 1)
          //   }
          // }
          let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === product)
          this.productTypeDisplay.splice(localSessionIndex, 1)
          // delete this.productTypeList[id];
        }
      }

        if (tag == "brand") {


            this.productBrandBool[product] = !this.productBrandBool[product];
            this.productBrandIdBool[id] = !this.productBrandIdBool[id];

            if (this.productBrandBool[product]) {
                // productTypeDisplay
                this.productTypeDisplay.push({"id": id, "value": product, "tag": "brand"});

                this.productBrandApiProductList[id] = product;
                // this.productTypeList[brandId] = value;
            } else {
                delete this.productBrandApiProductList[id];
                let localSessionIndex = this.productTypeDisplay.findIndex(o => o.value === product)
                this.productTypeDisplay.splice(localSessionIndex, 1)
                // this.productTypeList.splice(index, 1)

            }






        }

      // this.productTypeDisplay = [];
      // for (var k=0;k<this.productTypeList.length;++k) {
      //   if (this.productTypeList[k] != "") {
      //     this.productTypeDisplay = this.productTypeList.map((idf, valuef) => {
      //       return { "id":valuef , "value":idf }
      //     })
      //
      //   }
      // }
      // this.productTypeDisplay = this.productTypeDisplay.filter(function( element ) {
      //   return element !== undefined;
      // });
      // this.productTypeDisplay = this.productTypeList
      // this.productTypeDisplay = [];
      // for (var k=0;k<this.productTypeList.length;++k) {
      //   if (this.productTypeList[k] != "") {
      //     this.productTypeDisplay = this.productTypeList.map((idf, valuef) => {
      //       return { "id":valuef , "value":idf }
      //     })
      //
      //   }
      // }
      // this.productTypeDisplay = this.productTypeDisplay.filter(function( element ) {
      //   return element !== undefined;
      // });
      if (tag == "filter"){
        // product = product.trim();
        this.filtersBool[product] = !this.filtersBool[product];
        this.filterIdBool[id] = !this.filterIdBool[id];


        // alert(this.filtersBool[filter])
        if (this.filtersBool[product]) {
          this.filtersList[product] = product;
          this.filterIdList[id] = id;
          this.productTypeDisplay.push({"id":id,"value":product,"data":value,"tag":"filter"});
          // this.filterIdDisplay.push(id)
          document.getElementById("filter-"+id).setAttribute("style", "display:block;font-size:15px");
          // document.getElementById("filterM-"+id).setAttribute("style", "display:block;font-size:15px");
        } else {
          delete this.filtersList[product];

          let localSessionIndex1 = this.productTypeDisplay.findIndex(o => o.value === product)
          this.productTypeDisplay.splice(localSessionIndex1, 1)
          // console.log(this.filtersList[filter])
          // for (let i =0; i<this.filterIdDisplay.length ; i++){
          //   var obj = this.filterIdDisplay[i];
          //   if (obj == id){
          //     this.filterIdDisplay.splice(1)
          //   }

          // obj.splice(index, 1)
          // }
          // this.filterIdDisplay.slice(index,1);
          this.callProductApi();
        }
        this.filterDisplay = Object.values(this.filtersList);
        this.filterIdDisplay = Object.values(this.filterIdList);
        // var a = filter
        // var b = this.filterDisplay;
        // let c = b.filter(x => a.includes(x));
        // console.log(b)
        // if (c.length > 0) {
        //
        //   document.getElementById("filter-"+id).setAttribute("style", "display:none;font-size:15px");
        //   //there is at least one equal value
        // }
        var list=[];
        value?.forEach((val)=>{
          list.push(val.filterName);
        })
        var check = list.filter(arr1Item => this.filterDisplay.includes(arr1Item));
        if (check.length == 0){
          document.getElementById("filter-"+id).setAttribute("style", "display:none;font-size:15px");
          // document.getElementById("filterM-"+id).setAttribute("style", "display:none;font-size:15px");
        }
      }

      this.callProductApi();

    },
    // Product Type Remove individual End

    // selectProductTypes(filter, value) {
    //   this.productTypeBool[filter] = !this.productTypeBool[filter];
    //   if (this.productTypeBool[filter]) {
    //     this.productTypeList[filter] = value;
    //   } else {
    //     delete this.productTypeList[filter];
    //   }
    //    this.productTypeDisplay = [];
    //   for (var k=0;k<this.productTypeList.length;++k) {
    //     if (this.productTypeList[k] != "") {
    //       this.productTypeDisplay = this.productTypeList.map((idf, valuef) => {
    //         return { "id":valuef , "value":idf }
    //       })
    //
    //     }
    //   }
    //   this.productTypeDisplay = this.productTypeDisplay.filter(function( element ) {
    //     return element !== undefined;
    //   });
    //   this.callProductApi();
    // },




      selectBrandFilter(brandId, value){
          if (this.$route.params.id != null) {
          let ids = this.$route.params;
          var id1 = Base64.decode(ids.id);
          ids = id1.split("@@");
          // Select Type for Menu list selection
              if (this.mainMenu != 'brands'){
          if (ids[1]) {
              this.productBrandBool[value] = !this.productBrandBool[value];
              this.productBrandIdBool[brandId] = !this.productBrandIdBool[brandId];
              if (this.productBrandBool[value]) {
                  // productTypeDisplay
                  this.productTypeDisplay.push({"id": brandId, "value": value, "tag": "brand"});

                  this.productBrandApiProductList[brandId] = value;
                  // this.productTypeList[brandId] = value;
              } else {
                  delete this.productBrandApiProductList[brandId];
                  let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === brandId)
                  this.productTypeDisplay.splice(localSessionIndex, 1)
                  // this.productTypeList.splice(index, 1)

              }
              this.brandIdProduct.push(brandId)

              this.callProductApi();

          }
          }else {
                  this.productBrandBool[value] = !this.productBrandBool[value];
                  this.productBrandIdBool[brandId] = !this.productBrandIdBool[brandId];
                  if (this.productBrandBool[value]) {
                      // productTypeDisplay
                      this.productTypeDisplay.push({"id": brandId, "value": value, "tag": "brand"});

                      this.productBrandApiProductList[brandId] = value;
                      // this.productTypeList[brandId] = value;
                  } else {
                      delete this.productBrandApiProductList[brandId];
                      let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === brandId)
                      this.productTypeDisplay.splice(localSessionIndex, 1)
                      // this.productTypeList.splice(index, 1)

                  }
                  this.brandIdProduct.push(brandId)

                  this.callProductApi();
              }
          }
          else {
              // Select Product Filter type by Search
              this.productBrandBool[value] = !this.productBrandBool[value];
              this.productBrandIdBool[brandId] = !this.productBrandIdBool[brandId];
              if (this.productBrandBool[value]) {
                  // productTypeDisplay
                  this.productTypeDisplay.push({"id": brandId, "value": value, "tag": "brand"});

                  this.productBrandApiProductList[brandId] = value;
                  // this.productTypeList[brandId] = value;
              } else {
                  delete this.productBrandApiProductList[brandId];
                  let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === brandId)
                  this.productTypeDisplay.splice(localSessionIndex, 1)
                  // this.productTypeList.splice(index, 1)

              }
              this.brandIdProduct.push(brandId)

              this.callProductApi();

          }
      },





    selectProductTypes(typeId,value) {
      if (this.$route.params.id != null) {
        console.log(this.$route.params.id)
        let ids = this.$route.params;
        var id1 = Base64.decode(ids.id);
        ids = id1.split("@@");
// Select Type for Menu list selection
        if (this.mainMenu != 'brands'){
            if (ids[1]) {
                this.productTypeBool[typeId] = !this.productTypeBool[typeId];
                if (this.productTypeBool[typeId]) {
                    this.productTypeDisplay.push({"id": typeId, "value": value, "tag": "type"});
                    this.productTypeApiProductList[typeId] = value;
                    this.productTypeList[typeId] = value;
                } else {
                    delete this.productTypeApiProductList[typeId];
                    let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === typeId)
                    this.productTypeDisplay.splice(localSessionIndex, 1)
                }
                this.listsubCatIdArray.push(typeId)

                this.callProductApi();

                this.page = 1;

            }
        }else{
            this.productTypeBool[typeId] = !this.productTypeBool[typeId];
            if (this.productTypeBool[typeId]) {
                this.productTypeDisplay.push({"id": typeId, "value": value, "tag": "type"});
                this.productTypeApiProductList[typeId] = value;
                this.productTypeList[typeId] = value;
            } else {
                delete this.productTypeApiProductList[typeId];
                let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === typeId)
                this.productTypeDisplay.splice(localSessionIndex, 1)
            }
            this.listsubCatIdArray.push(typeId)

            this.callProductApi();

            this.page = 1;
        }



      }
      else {
        // Select Product Filter type by Search

        // Select CheckBox for Product Type
        this.productTypeBool[typeId] = !this.productTypeBool[typeId];
        if (this.productTypeBool[typeId]) {
                this.productTypeDisplay.push({"id": typeId, "value": value, "tag": "type"});
                this.productTypeApiProductList[typeId] = value;
                this.productTypeList[typeId] = value;

        }
        else {
            delete this.productTypeApiProductList[typeId];
            let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === typeId)
            this.productTypeDisplay.splice(localSessionIndex, 1)
        }
          this.listsubCatIdArray.push(typeId)

          this.callProductApi();

      }

    },

    // slider(){
    //   alert()
    // },
    searchWithPrice() {
      this.fromPrice = true;
      this.callProductApi();
      this.fromPrice = false;
    },
    searchWithPriceMobile() {
      this.fromPrice = true;
      this.callProductApiMobile();
      this.fromPrice = false;
    },








    selectFilters(filter,id, value) {
      console.log(filter, "Filter")
      // filter = filter.trim();
      this.filtersBool[filter] = !this.filtersBool[filter];
      this.filterIdBool[id] = !this.filterIdBool[id];

      if (this.filtersBool[filter]) {
        this.filtersList[filter] = filter+","+id;
        console.log(this.filtersList)
        this.filterIdList[id] = id;
        this.productTypeDisplay.push({"id":id,"value":filter,"data":value,"tag":"filter"});

        document.getElementById("filter-"+id).setAttribute("style", "display:block;font-size:15px");
        // document.getElementById("filterM-"+id).setAttribute("style", "display:block;font-size:15px");
      } else {
        delete this.filtersList[filter];
        let localSessionIndex = this.productTypeDisplay.findIndex(o => o.id === filter)
        this.productTypeDisplay.splice(localSessionIndex, 1)

      }
      this.filterDisplay = Object.values(this.filtersList);
      this.filterIdDisplay = Object.values(this.filterIdList);

      let list=[];
     value?.forEach((val)=>{
        list.push(val.filterName);
      });

      let check = list.filter(arr1Item => this.filterDisplay.includes(arr1Item));
     if (check.length == 0){
       document.getElementById("filter-"+id).setAttribute("style", "display:none;font-size:15px");
       // document.getElementById("filterM-"+id).setAttribute("style", "display:none;font-size:15px");
     }


      this.callProductApi();
    },




    encryptIDs(menuId, subMenuId, childMenu = "") {
      let finalId = menuId;
      if (subMenuId) {
        finalId += "@@" + subMenuId;
      }
      if (childMenu) {
        finalId += "@@" + childMenu;
      }
      return Base64.encode(finalId);
    },
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, "");
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, "-");
        return slug;
      }
    },
    async openFilter() {
      this.openFilterBool = !this.openFilterBool;
    },
    async openSort() {
      this.openSortBool = !this.openSortBool;
    },
    async sortData(type, label) {
      this.sortByLabel = label;
      this.sortBy = type;
      let id = Base64.decode(this.$route.params.id);
      id = id.split("@@");
      this.mainId = id[0];
      this.subId = id[1];
      if (id[1]) {
        this.getProductList(type);
      } else {
        this.getProducts(type);
      }
    },
    getFiltersDataFormat() {
      console.log("this.filtersData",this.filtersData);
      for (var i in this.filtersData) {
        if (this.filtersData[i].filterValue != "") {
          this.filtersData[i].filterValue =
            this.filtersData[i].filterValue.split(",");
        }
      }
    },
    async getProductTypes() {
      let payLoad = {
        subCategoryId: this.subId,
        filters: this.filterDisplay ? this.filterDisplay : [],
        listSubCategoryId: this.childId ? this.childId : "",
      };
      Api.getProductTypes(payLoad)
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.productTypeData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getFilters() {
      let payLoad = {
        subCategoryId: this.subId ? this.subId : "",
        listSubCategoryId: this.childId ? this.childId : this.listsubCatIdArray,
        filtersData: this.filterDisplay ? this.filterDisplay : "",
      };
      Api.getFiltersNew(payLoad)
        .then((res) => {
          if (res.data.success) {
            this.filtersData = res.data.data;
            this.getFiltersDataFormat();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSearchProductsList(type) {
      if (type) {
        this.sortBy = type;
      }
      let sortOptions = this.sortBy.split("-");
      let filtersPost = Object.keys(this.filtersList);
      let price = [];
      if (this.priceFrom) {
        price.push(this.priceFrom);
      }
      if (this.priceTo) {
        price.push(this.priceTo);
      }

      if (this.range[0]) {
        price.push(this.range[0]);
      }
      if (this.range[1]) {
        price.push(this.range[1]);
      }

      /*let listSubCategoryId = [];
      if (this.productTypeList?.length) {
        listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
      }*/
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let payLoad = {
        searchStr: this.searchStr,
        userId: userId,
        listSubCategories: [],
        sortBy: sortOptions[0],
        sortValue: sortOptions[1],
        filters: filtersPost,
        price: this.range,
      };

      Api.getSearchProductsList(payLoad)
        .then((res) => {
          console.log("search Product list", res.data.data.products);
          if (res.data.success) {
            this.productData = res.data.data.products;


            this.productTypeData = res.data.data?.filters?.productTypes;
            this.filtersData = res.data.data?.filters?.filters;
            this.getFiltersDataFormat();


            if (!this.fromPrice) {
              if (this.openSortBool) {
                this.openSort();
              }
              // if (this.openFilterBool) {
              //   this.openFilter();
              // }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProducts(type) {
      if (type) {
        this.sortBy = type;
      }
      let sortOptions = this.sortBy.split("-");
      let id = Base64.decode(this.$route.params.id);
      let filtersPost = Object.keys(this.filtersList);

      let price = [];

      if (this.priceFrom) {
        price.push(this.priceFrom);
      }
      if (this.priceTo) {
        price.push(this.priceTo);
      }

      if (this.range[0]) {
        price.push(this.range[0]);
      }
      if (this.range[1]) {
        price.push(this.range[1]);
      }

      /*let listSubCategoryId = [];
      if (this.productTypeList?.length) {
        listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
      }*/

      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let payLoad = {
        listSubCategoryId: this.listSubId,
        userId: userId,
        collectionId: id,
        sortBy: sortOptions[0],
        sortValue: sortOptions[1],
        filters: filtersPost,
        price: this.range,
      };

      Api.getProducts(payLoad)
        .then((res) => {
          console.log("swaraj Products", res.data);
          if (res.data.success) {
            this.productData = res.data.data.products;


            this.productTypeData = res.data.data?.filters?.productTypes;
            this.filtersData = res.data.data?.filters?.filters;
            this.getFiltersDataFormat();
            if (!this.fromPrice) {
              if (this.openSortBool) {
                this.openSort();
              }
              // if (this.openFilterBool) {
              //   this.openFilter();
              // }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async addToCart(data) {
      this.loaderBool[data.id] = true;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { id, productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
        variantId: id,
        qty: 1,
      };
      Api.addToCart(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.sucess = "Product Added Successfully";
            this.cartCount += 1;
            this.loaderBool[data.id] = false;
            this.checkBool[data.id] = true;
            setTimeout(() => {
              this.checkBool[data.id] = false;
            }, 3000);
          }
        })
        .catch((err) => {
          this.loaderBool[data.id] = false;
          console.log(err);
        });
    },
    // Price Slider For Mobile
     getProductListMobile(type) {
        if (type) {
            this.sortBy = type;
        }
        if (this.searchStr == ''){
            let paramsData = Base64.decode(this.$route.params.id);
            paramsData = paramsData.split("@@");
            let id = paramsData[0];
            let subid = paramsData[1];
            let child = paramsData[2];
            // child = child ? child : "";
            let listSubCategoryId = [];
            let productBrand = [];
            // if (this.productTypeList?.length) {
            //   console.log(...Object.keys(this.productTypeList))
            //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
            // }
            if (this.productTypeApiProductList?.length) {
                console.log(...Object.keys(this.productTypeApiProductList))
                // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
            }
            if (this.productBrandApiProductList?.length) {
                console.log(...Object.keys(this.productBrandApiProductList))
                // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                productBrand = [...Object.keys(this.productBrandApiProductList)];
            }
            let filtersPost = Object.values(this.filtersList);


            let sortOptions = this.sortBy.split("-");
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);

            let price = [];
            if (this.priceFrom) {
                price.push(this.priceFrom);
            }
            if (this.priceTo) {
                price.push(this.priceTo);
            }
            let payLoad = {
                page: this.page,
                categoryId: id,
                brand: productBrand,
                subCategoryId: subid,
                listSubCategoryId: listSubCategoryId?.length
                    ? listSubCategoryId
                    : child
                        ? [child]
                        : [],
                userId: userId,
                sortBy: sortOptions[0],
                sortValue: sortOptions[1],
                filters: filtersPost,
                price: price,
                searchStr:  this.searchStr,
            };
            Api.getProductList(payLoad)
                .then((res) => {
                    console.log("Swaraj product Mobile", res.data);
                    if (res.data.success) {
                        console.log(res.data.data.products)
                        this.productData = res.data.data.products;
                        this.brandData = res.data.data.brandTypes;
                        this.productTypeData = res.data.data.productTypes;
                        this.filterTypes = res.data.data.filterTypes;
                        this.productCount = res.data.data.productsCount;
                        // this.priceMin = res.data.data.priceMin;
                        // this.priceMax = res.data.data.priceMax;
                        if (!this.fromPrice) {
                            if (this.openSortBool) {
                                this.openSort();
                            }
                            // if (this.openFilterBool) {
                            //   this.openFilter();
                            // }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            let id = "";
            let subid = "";
            // let child = [];
            // child = child ? child : "";
            let listSubCategoryId = [];
            let productBrand = [];
            // if (this.productTypeList?.length) {
            //   console.log(...Object.keys(this.productTypeList))
            //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
            // }
            if (this.productTypeApiProductList?.length) {
                console.log(...Object.keys(this.productTypeApiProductList))
                // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
            }
            if (this.productBrandApiProductList?.length) {
                console.log(...Object.keys(this.productBrandApiProductList))
                // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                productBrand = [...Object.keys(this.productBrandApiProductList)];
            }
            let filtersPost = Object.values(this.filtersList);


            let sortOptions = this.sortBy.split("-");
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);

            let price = [];
            if (this.priceFrom) {
                price.push(this.priceFrom);
            }
            if (this.priceTo) {
                price.push(this.priceTo);
            }
            let payLoad = {
                page: this.page,
                categoryId: id,
                brand: productBrand,
                subCategoryId: subid,
                listSubCategoryId: listSubCategoryId,
                userId: userId,
                sortBy: sortOptions[0],
                sortValue: sortOptions[1],
                filters: filtersPost,
                price: price,
                searchStr: this.searchStr,
            };
            Api.getProductList(payLoad)
                .then((res) => {
                    console.log("Swaraj product Mobile", res.data);
                    if (res.data.success) {
                        console.log(res.data.data.productsCount)
                        this.productData = res.data.data.products;
                        this.brandData = res.data.data.brandTypes;
                        this.productTypeData = res.data.data.productTypes;
                        this.filterTypes = res.data.data.filterTypes;
                        this.productCount = res.data.data.productsCount;
                        // this.priceMin = res.data.data.priceMin;
                        // this.priceMax = res.data.data.priceMax;
                        if (!this.fromPrice) {
                            if (this.openSortBool) {
                                this.openSort();
                            }
                            // if (this.openFilterBool) {
                            //   this.openFilter();
                            // }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    },
    // Price Slider For Desktop
    async getProductList(type) {
      if (type) {
        this.sortBy = type;
      }
      if (this.searchStr == '' && this.mainMenu != 'brands'){
          let paramsData = Base64.decode(this.$route.params.id);
          paramsData = paramsData.split("@@");
          let id = paramsData[0];
          let subid = paramsData[1];
          let child = paramsData[2];
          // child = child ? child : "";
          let listSubCategoryId = [];
          let productBrand = [];
          // if (this.productTypeList?.length) {
          //   console.log(...Object.keys(this.productTypeList))
          //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
          // }
          if (this.productTypeApiProductList?.length) {
              console.log(...Object.keys(this.productTypeApiProductList))
              // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
              listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
          }
          if (this.productBrandApiProductList?.length) {
              console.log(...Object.keys(this.productBrandApiProductList))
              // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
              productBrand = [...Object.keys(this.productBrandApiProductList)];
          }
          let filtersPost = Object.values(this.filtersList);


          let sortOptions = this.sortBy.split("-");
          let userId = localStorage.getItem("id");
          userId = Base64.decode(userId);


          let payLoad = {
              page:this.page,
              categoryId: id,
              brand: productBrand,
              subCategoryId: subid,
              listSubCategoryId: listSubCategoryId?.length
                  ? listSubCategoryId
                  : child
                      ? [child]
                      : [],
              userId: userId,
              sortBy: sortOptions[0],
              sortValue: sortOptions[1],
              filters: filtersPost,
              price: this.range,
              searchStr: this.searchStr,
          };
          Api.getProductList(payLoad)
              .then((res) => {
                  console.log("Swaraj product Desktop", res.data);
                  if (res.data.success) {
                      console.log(res.data.data.products)
                      this.productData = res.data.data.products;
                      // this.productData = [...this.productData, ...res.data.data.products];
                      this.brandData = res.data.data.brandTypes;
                      this.productTypeData = res.data.data.productTypes;
                      this.filterTypes = res.data.data.filterTypes;
                      this.productCount = res.data.data.productsCount;
                      // this.priceMin = res.data.data.priceMin;
                      // this.priceMax = res.data.data.priceMax;
                      // this.priceData = res.data.data.priceData;
                      if (!this.fromPrice) {
                          if (this.openSortBool) {
                              this.openSort();
                          }
                          // if (this.openFilterBool) {
                          //   this.openFilter();
                          // }
                      }
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
      }
      else
      {

          let id = "";
          let subid = "";
          // let child = [];
          // child = child ? child : "";
          let listSubCategoryId = [];
          let productBrand = [];
          // if (this.productTypeList?.length) {
          //   console.log(...Object.keys(this.productTypeList))
          //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
          // }
          if (this.productTypeApiProductList?.length) {
              console.log(...Object.keys(this.productTypeApiProductList))
              // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
              listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
          }
          if (this.productBrandApiProductList?.length) {
              console.log(...Object.keys(this.productBrandApiProductList))
              // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
              productBrand = [...Object.keys(this.productBrandApiProductList)];
          }
          let filtersPost = Object.values(this.filtersList);

          let sortOptions = this.sortBy.split("-");
          let userId = localStorage.getItem("id");
          userId = Base64.decode(userId);

          let range = [];
          if (this.range[0]) {
              range.push(this.range[0]);
          }
          if (this.range[1]) {
              range.push(this.range[1]);
          }

              let payLoad = {
                  page: this.page,
                  categoryId: id,
                  brand: productBrand,
                  subCategoryId: subid,
                  listSubCategoryId: listSubCategoryId,
                  userId: userId,
                  sortBy: sortOptions[0],
                  sortValue: sortOptions[1],
                  filters: filtersPost,
                  price: range,
                  searchStr: this.searchStr,
              };
              Api.getProductList(payLoad)
                  .then((res) => {
                      console.log("Swaraj product Desktop", res.data);
                      if (res.data.success) {
                          console.log(res.data.data.products)
                          this.productData = res.data.data.products;
                          this.brandData = res.data.data.brandTypes;
                          this.productTypeData = res.data.data.productTypes;
                          this.filterTypes = res.data.data.filterTypes;
                          this.productCount = res.data.data.productsCount;
                          // this.priceMin = res.data.data.priceMin;
                          // this.priceMax = res.data.data.priceMax;
                          if (!this.fromPrice) {
                              if (this.openSortBool) {
                                  this.openSort();
                              }
                              // if (this.openFilterBool) {
                              //   this.openFilter();
                              // }
                          }
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  });


      }


    },



      callPriceProductApi() {
          let { searchTerm } = this.$route.params;
          localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
          if (searchTerm && searchTerm!==undefined) {
              searchTerm = searchTerm.replaceAll("-", " ");
              this.searchStr = searchTerm;
              // this.getSearchProductsList();
              this.priceSlider();
          }

          else {
              let { id, mainMenu, subMenu, childMenu } = this.$route.params;
              localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
              if (mainMenu) {
                  mainMenu = mainMenu.replaceAll("-", " ");
                  this.mainMenu = mainMenu;
              }
              if (subMenu) {
                  subMenu = subMenu.replaceAll("-", " ");
                  this.subMenu = subMenu;
              }
              if (childMenu) {
                  childMenu = childMenu.replaceAll("-", " ");
                  this.childMenu = childMenu;
              }
              id = Base64.decode(id);
              id = id.split("@@");
              this.mainId = id[0];
              this.subId = id[1];
              this.childId = id[2];
              if (id[1]) {
                  this.priceSlider();
              } else {
                  this.priceSlider();
              }

          }
      },

      async priceSlider(type) {
          if (type) {
              this.sortBy = type;
          }
          if (this.searchStr == ''  && this.mainMenu != 'brands'){
              let paramsData = Base64.decode(this.$route.params.id);
              paramsData = paramsData.split("@@");
              let id = paramsData[0];
              let subid = paramsData[1];
              let child = paramsData[2];
              // child = child ? child : "";
              let listSubCategoryId = [];
              let productBrand = [];
              // if (this.productTypeList?.length) {
              //   console.log(...Object.keys(this.productTypeList))
              //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
              // }
              if (this.productTypeApiProductList?.length) {
                  console.log(...Object.keys(this.productTypeApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
              }
              if (this.productBrandApiProductList?.length) {
                  console.log(...Object.keys(this.productBrandApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  productBrand = [...Object.keys(this.productBrandApiProductList)];
              }
              let filtersPost = Object.values(this.filtersList);


              let sortOptions = this.sortBy.split("-");
              let userId = localStorage.getItem("id");
              userId = Base64.decode(userId);


              let payLoad = {
                  page:this.page,
                  categoryId: id,
                  brand: productBrand,
                  subCategoryId: subid,
                  listSubCategoryId: listSubCategoryId?.length
                      ? listSubCategoryId
                      : child
                          ? [child]
                          : [],
                  userId: userId,
                  sortBy: sortOptions[0],
                  sortValue: sortOptions[1],
                  filters: filtersPost,
                  price: this.range,
                  searchStr: this.searchStr,
              };
              Api.getProductList(payLoad)
                  .then((res) => {
                      console.log("Swaraj product Desktop", res.data);
                      if (res.data.success) {
                          console.log(res.data.data.products)
                          this.productData = res.data.data.products;
                          // this.productData = [...this.productData, ...res.data.data.products];
                          this.brandData = res.data.data.brandTypes;
                          this.productTypeData = res.data.data.productTypes;
                          this.filterTypes = res.data.data.filterTypes;
                          this.productCount = res.data.data.productsCount;
                          this.priceMin = res.data.data.priceMin;
                          this.priceMax = res.data.data.priceMax;
                          this.priceData = res.data.data.priceData;
                          this.setDefaultVariants();
                          if (!this.fromPrice) {
                              if (this.openSortBool) {
                                  this.openSort();
                              }
                              // if (this.openFilterBool) {
                              //   this.openFilter();
                              // }
                          }
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  });

          }else
          {

              let id = "";
              let subid = "";
              // let child = [];
              // child = child ? child : "";
              let listSubCategoryId = [];
              let productBrand = [];
              // if (this.productTypeList?.length) {
              //   console.log(...Object.keys(this.productTypeList))
              //   listSubCategoryId = [...child, ...Object.keys(this.productTypeList)];
              // }
              if (this.productTypeApiProductList?.length) {
                  console.log(...Object.keys(this.productTypeApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  listSubCategoryId = [...Object.keys(this.productTypeApiProductList)];
              }
              if (this.productBrandApiProductList?.length) {
                  console.log(...Object.keys(this.productBrandApiProductList))
                  // listSubCategoryId = [...child, ...Object.keys(this.productTypeApiProductList)];
                  productBrand = [...Object.keys(this.productBrandApiProductList)];
              }
              let filtersPost = Object.values(this.filtersList);


              let sortOptions = this.sortBy.split("-");
              let userId = localStorage.getItem("id");
              userId = Base64.decode(userId);


              let payLoad = {
                  page: this.page,
                  categoryId: id,
                  brand: productBrand,
                  subCategoryId: subid,
                  listSubCategoryId: listSubCategoryId,
                  userId: userId,
                  sortBy: sortOptions[0],
                  sortValue: sortOptions[1],
                  filters: filtersPost,
                  price: this.range,
                  searchStr: this.searchStr,
              };
              Api.getProductList(payLoad)
                  .then((res) => {
                      console.log("Swaraj product Desktop", res.data);
                      if (res.data.success) {
                          console.log(res.data.data.products)
                          this.productData = res.data.data.products;
                          this.brandData = res.data.data.brandTypes;
                          this.productTypeData = res.data.data.productTypes;
                          this.filterTypes = res.data.data.filterTypes;
                          this.productCount = res.data.data.productsCount;
                          this.priceMin = res.data.data.priceMin;
                          this.priceMax = res.data.data.priceMax;
                          this.priceData = res.data.data.priceData;
                          this.setDefaultVariants();
                          if (!this.fromPrice) {
                              if (this.openSortBool) {
                                  this.openSort();
                              }
                              // if (this.openFilterBool) {
                              //   this.openFilter();
                              // }
                          }
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  });
          }


      },



    productDetails(id) {
      router.push({
        name: "ProductDetails",
        params: { id: id },
      });
    },
    async addToWishList(data) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
      };
      wishListApi
        .addtoWishList(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.$store.dispatch("addWishList", productId);
            this.wishListBool[productId] = true;
          }
        })
        .catch((err) => {
          this.wishListBool[productId] = false;
          console.log(err);
        });
    },
  },

});
</script>
