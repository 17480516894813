<template>
<div :class="{'col-xl-3 col-md-4 col-6':!removeClass}" >
                  <div class="prod_l_cnt">
                    <div class="prod_d_itm">
                      <figure>
                        <router-link :to="`/product/${sanitizeTitle(productData.title)}/${productData.id}`" >
                          <img class="img-fluid" :src="productData?.variants[0]?.images[0]?.imageUrl" alt="" />
                        </router-link>

                      </figure>

                      <div class="prdt-onr-name">{{productData.brand}}</div>

                      <div class="row">
                        <div class="col-xl-6">
                          <div class="prdt-name">{{ productData.title }}</div>
                          <div class="prdt-price" v-if="this.priceBool == 0 || this.priceBool == null">


                              <div v-if="productData.variants[0].originalPrice == productData.variants[0].price">
                                  {{ common.formatNumber(productData.variants[0].price) }}
                              </div>
                              <div v-else>
                                <div class="or_price">{{ common.formatNumber(productData.variants[0].price) }}</div>
                                  <del class="dis_price"> {{ common.formatNumber(productData.variants[0].originalPrice) }}</del>
                         
                              </div>



                          </div>
                        </div>
                        <div class="col-xl-6">
                        </div>
                      </div>
                    </div>
                    <div class="add-wishlist-icon">


<!--                      {{productData.variants[0]?.productId == wishlistDefaultData.productId}}-->
<!--                      {{ this.wishlistDefaultData == 0 }}-->
<!--                      {{wishListBool[productData.variants[0]?.productId]}}-->

                      <button v-if="!wishListBool[productData.variants[0]?.productId] && this.projectDefault == null"
                              id="ad-remove-wish-list" @click.prevent="ItemSetId()">
                        <img class="img-fluid" src="../../assets/image/SVG/heart.svg" data-bs-toggle="modal" data-bs-target="#wishlistModel" :data-id="productData.variants[0]?.productId">
                      </button>

                      <button v-else-if="!wishListBool[productData.variants[0]?.productId]" @click="addToWishList(productData)"
                              id="ad-remove-wish-list">
                        <img class="img-fluid" src="../../assets/image/SVG/heart.svg">
                      </button>



<!--                      <button v-else @click="addToWishList(productData)"-->
<!--                              id="ad-remove-wish-list">-->
<!--                        <img class="img-fluid" src="../../assets/image/SVG/heart.svg">-->
<!--                      </button>-->

                      <button v-if="wishListBool[productData.variants[0]?.productId]" id="ad-remove-wish-list">
                        <img v-on:click.prevent="deleteWishlist(productData.variants[0])" class="img-fluid" src="../../assets/image/SVG/heart-fill.svg">
                      </button>


<!--                      <button v-if="wishListBool[productData.variants[0]?.productId]" id="ad-remove-wish-list">-->
<!--                        <img  class="img-fluid" src="../../assets/image/SVG/heart-fill.svg">-->
<!--                      </button>-->


                    </div>

                    <div class="ordr_btn_sec ">
                      <button :disabled="disableButton(loaderBool[productData.id],checkBool[productData.id],productData?.variants[0]?.stock<=0 && productData.preOrder == 0)" :class="{ 'disable-btn': loaderBool[productData.id] }"
                        v-on:click.prevent="addToCart(productData)" class="b_w_ust btn_default">
                        <span v-if="checkBool[productData.id]"><img src="../../assets/image/check-mark-white.svg" alt="" />
                        </span>
                        <div v-if="!checkBool[productData.id] && loaderBool[productData.id]">
                          <img  src="../../assets/image/loader.gif"  style="width: 22px"/>
                        </div>
                        <span v-if="!checkBool[productData.id] && !loaderBool[productData.id]">
                        {{(productData?.variants[0]?.stock<=0) ? (productData.preOrder == 1) ? 'Pre Order' : 'Out of Stock' : 'Add To Cart' }}
                        </span>
<!--                        && productData.preOrder == 1-->
                      </button>
                    </div>
                  </div>
                </div>



</template>

<script>
import common from "@/apis/Common";

import Api from "@/apis/Products";
//import CartApi from "@/apis/Cart";
import { Base64 } from "js-base64";
import wishListApi from "@/apis/WishList";
// import Project from "../../apis/Project";
export default {
  name: "product-card",
  props: ["productData", "classItems", "removeClass"],
  data() {
    return {
      common: common,
      checkBool: [],
      loaderBool: [],
      wishListBool: [],
      priceBool: "",
      projectBool: "",
      default : [],
      projectDefault : [],
      wishListData : [],
      wishlistDefaultData : [],
      client_id : ""
    };
  },
  created() {
    // this.wishListBool = this.$store.getters.getWishList;

    this.projectBool = localStorage.getItem("project");
      // this.getWishList();
  },
  mounted() {
    this.priceBool = localStorage.getItem("priceBool");
    this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));
    // this.getWishList();

  },
  computed: {

    disableButton() {
      return (bool1,bool2,bool3) => {
        if(bool1 || bool2 || bool3){
          return true;
        }else{
          return false;

        }
      };
    },
  },
  methods: {
      ItemSetId: function(){
          var element = event.target;
          var id = element.getAttribute('data-id');
          localStorage.setItem("productId", id)

      },
    async deleteWishlist(item) {
      console.log("itemsss",item);
      const { productId } = item;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      wishListApi.deleteWishlist({ userId: userId, productId: productId, clientProjectId: this.projectDefault.id})
        .then((res) => {
          if (res.data.success) {
            this.getWishList();
            this.$store.dispatch('addWishList', productId);
            this.wishListBool[productId] = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getWishList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      wishListApi.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.wishListData = res.data.data;
              if (this.projectDefault != null) {
                  this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
              }else {
                  this.wishlistDefaultData = []
              }
            let wishlist = [];
            this.wishlistDefaultData.forEach((val)=>{
              // console.log("swaraj", val)
              wishlist[val.productId] = true;
              // val.products.forEach((val1)=>{
              //
              // });
            });
            this.$store.dispatch('addTotalWishList', wishlist);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sanitizeTitle: function(title) {
      if(title){
      // Change to lower case
      let slug = title?.toLowerCase();
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      return slug;
      }
    },

    async addToWishList(data) {
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        const { productId } = data?.variants[0];


        let payLoad = {
          userId: userId,
          productId: productId,
          clientProjectId: this.projectDefault.id
        };
        wishListApi.addWishlistProductDetails(payLoad) //{ userId: userId, trash: 0 }
        // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                .then((res) => {
                  console.log("Rolessss", res.data);
                  if (res.data.success) {
                    this.$store.dispatch('addWishList', productId);
                    this.wishListBool[productId] = true;
                  }
                })
                .catch((err) => {
                  this.wishListBool[productId] = false;
                  console.log(err);
                });

    },
    async addToCart(data) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { id, productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
        variantId: id,
        qty: 1,
      };
      this.loaderBool[data.id] = true;
      Api.addToCart(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.sucess = "Product Added Successfully";
            this.cartCount += 1;
            this.loaderBool[data.id] = false;
            this.checkBool[data.id] = true;
            this.$store.dispatch('cartItemsCount', 1);
            setTimeout(() => {
              this.checkBool[data.id] = false;
              this.loaderBool[data.id] = false;
            }, 1000);
          }
        })
        .catch((err) => {
          this.loaderBool[data.id] = false;
          this.checkBool[data.id] = false;
          console.log(err);
          // if(err?.response?.data?.errors)
          // {
          //   this.$router.push(`/product/${this.sanitizeTitle(data.title)}/${productId}`)
          //
          // //   // alert(err?.response?.data?.errors);
          // //   // return;
          // }
        });
    },
  },
  setup() {},
};
</script>

