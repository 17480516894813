<template>
    <CartHeader :cartCountUpdate="cartCount" />
    <!-- Header End -->
    <section class="products_sec product_details_list">
        <div class="page-width">
            <!-- Breadcrumb Sec -->
            <section class="breadcrumb_sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/home">Home</router-link>
                        </li>
                        <!--<li v-if="mainMenu" class="breadcrumb-item">{{ mainMenu }}</li>-->
                        <li v-if="mainMenu" class="breadcrumb-item">
                            <router-link data-target="trending" class="nav_trig"
                                         :to="`/products-list/${sanitizeTitle(mainMenu)}/${encryptIDs(mainId)}`">{{ mainMenu }}</router-link></li>
                        <li
                                v-if="!childMenu"
                                class="breadcrumb-item active"
                                aria-current="page"
                        >
                            {{ subMenu }} {{this.searchStr}}
                        </li>
                    </ol>
                </nav>
            </section>

            <!-- Breadcrumb Sec -->

            <div class="row">
                <div class="col-lg-12">
                    <!-- Start Prducts List -->
                    <div class="product_wish_list">
                        <div id="scroll-element" ref="scrollDiv" :class="stickClass" class="row">
                            <div class="col-md">
                                <div class="page_ttl-sub">{{ subMenu }} ({{ itemsCount }})</div>
                            </div>
                            <div class="col-md text-end">
                                <div class="dropdown dd_default">
                                    <!-- Desktop View -->
                                    <div class=" d-none d-lg-block jump_to_arrow">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                aria-expanded="false">{{ (this.lisSubcat) ? this.lisSubcat : 'Jump to:' }}</button>
                                        <ul class="dropdown-menu">
                                            <li v-for="cat in productsData.filter((val => val.products?.length > 0))" :key="cat.id">
                                                <div class="dropdown-item" @click="goto(`${sanitizeTitle(cat.listSubMenu)}`)"
                                                     href="#">{{ cat.listSubMenu }}</div>
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- Mobile View -->
                                    <div class="d-lg-none d-lg-block mobile_ver_jump"   v-bind:class="{'jump-show': jumpClass }" @click="jumpTo">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                aria-expanded="false">{{ (this.lisSubcat) ? this.lisSubcat : 'Jump to:' }}</button>
                                        <ul class="dropdown-menu">
                                            <li v-for="cat in productsData.filter((val => val.products?.length > 0))" :key="cat.id">
                                                <div class="dropdown-item" @click="goto(`${sanitizeTitle(cat.listSubMenu)}`, cat.listSubMenu)"
                                                     href="#">{{ cat.listSubMenu }}</div>
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-for="cat in productsData" :key="cat.id">
                            <section v-if="cat.products?.length" class="fml_sec" :id="`${sanitizeTitle(cat.listSubMenu)}`" :ref="`${sanitizeTitle(cat.listSubMenu)}`">
                                <h4 v-if="cat.products?.length" class="s_a_head">{{ cat.listSubMenu }}
                                    <router-link class="s_a_headtop"
                                                 :to="`/products/${sanitizeTitle(mainMenu)}/${sanitizeTitle(cat.subCategoryName)}/${sanitizeTitle(cat.listSubMenu)}/${encryptIDs(categoryId, cat.subCategoryId, cat.listSubCategoryId)}`">
                                        Shop all ({{ cat.count }}) </router-link>
                                </h4>
                                <!-- Product Wish List -->
                                <div v-if="cat.products?.length" class="product_list_view">
                                    <div class="row">
                                        <ProductCard v-for="item in cat.products" :key="item.id" :productData="item"
                                                     class="col-xl-3 col-6" />
                                    </div>
                                </div>
                                <!-- Product List -->
                                <div v-if="cat.products?.length" class="text-center">
                                    <router-link class="btn_outline"
                                                 :to="`/products/${sanitizeTitle(mainMenu)}/${sanitizeTitle(cat.subCategoryName)}/${sanitizeTitle(cat.listSubMenu)}/${encryptIDs(categoryId, cat.subCategoryId, cat.listSubCategoryId)}`">
                                        SHOP all {{ cat.listSubMenu }} ({{ cat.count }})</router-link>
                                </div>
                            </section>
                        </div>



                    </div>
                    <!-- End Prducts List -->
                </div>
            </div>
        </div>
    </section>
    <CartFooter />
</template>
<script>
    import "./cart/css/cart.css";
    import CartHeader from "@/components/layouts/cart/CartHeader.vue";
    import CartFooter from "@/components/layouts/cart/CartFooter.vue";
    import Api from "@/apis/Products";
    import { Base64 } from "js-base64";
    import common from "@/apis/Common";
    import ProductCard from "@/components/common/ProductCard";
    import Api1 from "@/apis/Api";
    export default {
        name: "search-list",
        data() {
            return {
                productsData: [],
                common: common,
                categoryId: '',
                subCategoryId: 0,
                grpName: '',
                stickClass: '',
                lisSubcat: 'Jump to',
                jumpClass: false,
                baseURL: Api1.defaults.baseURL,
            };
        },
        components: {
            CartHeader,
            CartFooter,
            ProductCard
        },
        computed: {
            itemsCount: function () {
                let count = 0;
                if (this.productsData) {
                    for (var i in this.productsData) {
                        if (this.productsData[i]?.products)
                            count += this.productsData[i]?.count;
                    }
                }
                return count;
            },
        },
        created() {
            let { mainMenu, id, subMenu } = this.$route.params;
            localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
            if (mainMenu) {
                this.mainMenu = mainMenu.replaceAll("-", " ");
                //
                // mainMenu = mainMenu.replaceAll("-", " ");
                // this.mainMenu = mainMenu;
            }
            if (subMenu) {
                this.subMenu = subMenu.replaceAll("-", " ");
            }
            id = Base64.decode(id);
            id = id.split("@@");
            this.mainId = id[0];
            this.subId = id[1];

            this.getMainMenuProductsList();
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.onResize);
            })
        },
        unmounted() {
            window.removeEventListener('scroll', this.onResize);
        },
        methods: {
            // sortFunc: function (){
            //   return this.productsData.slice().sort(function(a, b){
            //     return (a.listSubMenu > b.listSubMenu) ? 1 : -1;
            //   });
            // },
            jumpTo(){

                this.jumpClass = !this.jumpClass;

            },
            goto(refName,value) {
                this.lisSubcat = value
                var element = this.$refs[refName];
                var top = element[0].offsetTop;
                window.scrollTo(0, top);
                this.jumpClass=true;

            },

            onResize() {
                if (window.innerWidth > 992){
                    if (window.top.scrollY >= 150) {
                        this.stickClass = 'sticky-class';
                    } else {
                        this.stickClass = '';
                    }
                }

            },
            groupName(name) {
                console.log(this.$route.path)
                this.grpName = name;
                //
            },
            groupName1() {
                this.$router.push(window.location.hash=this.lisSubcat);
                // document.getElementsByClassName("jump-select").addEventListener("click",window.location.hash = this.lisSubcat)

                // this.grpName = name;
            },
            encryptIDs(menuId, subMenuId, childMenu = "") {
                let finalId = menuId;
                if (subMenuId) {
                    finalId += "@@" + subMenuId;
                }
                if (childMenu) {
                    finalId += "@@" + childMenu;
                }
                return Base64.encode(finalId);
            },
            sanitizeTitle: function (title) {
                if (title) {
                    // Change to lower case
                    let slug = title?.toLowerCase();
                    // Trim the last whitespace
                    slug = slug.replace(/\s*$/g, "");
                    // Change whitespace to "-"
                    slug = slug.replace(/\s+/g, "-");
                    return slug;
                }
            },
            async getMainMenuProductsList() {
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                let payLoad = {
                    categoryId: this.mainId,
                    userId: userId,
                    subCategoryId: this.subId
                };

                Api.getMainMenuProductsList(payLoad)
                    .then((res) => {
                        console.log("search Product list", res.data.data);
                        if (res.data.success) {
                            this.productsData = res.data.data;

                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
        setup() { },
    };
</script>
