<template>
  <div class="document_verification">
    <BeforeLoginHeader />
    <div class="document_verification_body_part">
      <div class="inner_document_verification_body_part container">
        <form class="mt-5">
          <div class="document_verification_box p-4">
            <div class=" inner_document_verification_box mt-lg-4 mt-md-3">
              <img src="../../../assets/image/document_verification_icon.png" class="img-fluid" alt=""
                style="height:80px;">
              <h5 class="mt-lg-4 mt-2 txt_none">Thank you for submitting the required documents.</h5>
              <p class="txt_none"> We are currently reviewing them and will get back to
                you within 48 hours.
                <br /><br />
                Your patience is appreciated.
              </p>
            </div>
            <p class="mt-lg-4 mt-md-3 mt-4 txt_none">Having issues? <router-link to="/contact-us">Click here for
                support</router-link>
            </p>
            <div class="mt-4 mb-4">
              <a class="btn_default" href="#" @click="logout">Sign Out</a>
            </div>

          </div>
        </form>

      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import router from "@/routes/routes";
export default ({
  name: 'document-verification-msg',
  components: {
    LoginFooter,
    BeforeLoginHeader
  },
  setup() {

  },
  created() {
    document.title = this.$route.meta.title;
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('companyStatus');
    localStorage.removeItem('user-token');
    let profileStatus = localStorage.getItem("profileStatus");
    if (profileStatus == 0) {
      router.push({ name: "CompanyDetailsIndividual" });
    }
    else if (profileStatus == 1) {
      router.push({ name: "DocumentVerificationMsg" });
    }
    else if (profileStatus == 2) {
      router.push({ path: "/home" });
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      router.push({ name: 'Login' });
    },
  }
})
</script>
<style>
@import '../../../assets/css/document_verification_msg.css';
</style>
