<template>
  <div class="emali_verification">
    <BeforeLoginHeader />
    <div class="email_verification_body_part">
      <div class="inner_email_verification_body_part container">
        <form class="mt-lg-5 mt-md-4 mt-5">
          <div class="email_verification_box p-4">
            <div class=" inner_email_verification_box mt-lg-4 mt-md-3">
              <img src="../../../assets/image/mail-envelope-open.svg" class="image-fluid" alt="">
              <h5 class="mt-lg-4 mt-2 txt_none">Thank you for your interest!</h5>
              <p class="txt_none">We have sent an email to:</p>
              <p>{{ this.registerData.email }}</p>
            </div>
            <p class="mt-4 txt_none">
              Please check your inbox and click on the confirmation link to complete the signup process.
            </p>
            <p class="mt-lg-4 mt-3 txt_none">Didn’t receive email?</p>
            <div class="my-2">
              <button class="btn_default" @click.prevent="Resend" id="resendEmail">RESEND EMAIL</button>
            </div>
            <p class="mt-3 txt_none" id="countDown" style="display: none">After {{ countDown }} seconds you will be able
              to
              resend email again </p>
          </div>
        </form>

      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import User from "@/apis/User";

export default ({
  data() {
    return {
      countDown: 30,
      registerData: ""
    }
  },
  name: 'email-verification',
  components: {
    LoginFooter,
    BeforeLoginHeader
  },
  setup() {

  },
  created() {
    document.title = this.$route.meta.title;
    this.registerData = JSON.parse(localStorage.getItem("registerData"))
    // if (window.$cookies.get('registerUserId') == null){
    //   this.$router.push({name: "Error"})
    // }
  },
  mounted() {
    this.registerData = JSON.parse(localStorage.getItem("registerData"))
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
          document.getElementById("resendEmail").setAttribute('disabled', true);
          document.getElementById("countDown").style.display = "block";
          this.countZero()
        }, 1000)
      }
    },
    countZero() {
      if (this.countDown == 0) {
        document.getElementById("resendEmail").removeAttribute('disabled');
        document.getElementById("countDown").style.display = "none";
      }
    },

    Resend() {
      document.getElementById("resendEmail").setAttribute('disabled', true);
      // this.countDown = 30
      this.countDown = 30
      this.countDownTimer()

      User.emailVerification({
        email: this.registerData.email,
      }).then((response) => {
        console.log(response.data)

      })
        .catch((err) => {
          console.log(err);
        })
    }

  }
})
</script>
<style>
@import "../../../assets/css/email_verification.css";
</style>
