<template>
<!--    {{value[0]}}-->
    <div class="price_slider_block">
        <div class="price__wrap range-value-sec">
            <div class="range-min-val">
            <label for="minValue">


                <input
                        :id="minValue"
                        type="text"
                        ref="minValue"
                        :value="data[0]"
                        @input="updateValue($event.target.value)"
                        disabled
                        @change="minValue($event.target.value)"
                />
            </label>
        </div>
        <div class="range-txt-seperator">-</div>
        <div class="range-max-val">
            <label for="maxValue">
<!--                {{data[1]}}-->
                <input
                        :id="maxValue"
                        type="text"
                        ref="maxValue"
                        :value="data[1]"
                        @input="updateValue($event.target.value)"
                        disabled
                        @change="maxValue($event.target.value)"
                />
            </label>
            </div>
        </div>
    </div>
</template>




<script>
    export default {

        props: ["data","modelValue"],
        computed: {},
        methods: {
            updateValue() {

                this.$emit("input", [
                    this.$refs.minValue.value,
                    this.$refs.maxValue.value,
                ]);
                // console.log(event)
            },
            minValue(value) {
                // console.log(event.target.value)
                if (
                    value < this.data[0] ||
                    value > this.data[1]
                ) {
                    this.$refs.minValue.value = this.data[0];
                }

                this.updateValue();
            },
            maxValue(value) {
                if (
                    value < this.data[0] ||
                    value > this.data[1]
                ) {
                    this.$refs.maxValue.value = this.data[1];
                }
                this.updateValue();
            },
        },
    };
</script>

