import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    // getWishList(payload){
    //     return ApiToken.post("/userWishlist",payload);
    // },
    getWishList(payload){
        return ApiToken.post("/userWishlistNew",payload,  { headers: authHeader() });
    },
    wishlistByProject(payload){
        return ApiToken.post("/wishlistByProject",payload,  { headers: authHeader() });
    },
    deleteWishlist(payload){
        return ApiToken.post("/deleteWishlist",payload,  { headers: authHeader() });
    },
    addtoWishList(payLoad) {
        return ApiToken.post("/addWishlist", payLoad,  { headers: authHeader() })
    },
    addWishlistProductDetails(payLoad){
        return ApiToken.post("/addWishlistProductDetails", payLoad,  { headers: authHeader() })
    }
}