<template>
  <div class="col-lg-9 user-detail-view-sec">
    <h2 class="ac_pg_head mb-0">USER Details</h2>

    <div class="ac_det_cnt ac_addr pl-0">
      <span>rAMA GOPAL KASULA<br> 
            rAMAGOPALKASULA@GMAIL.COM
      </span>
      <p>M: (+91) 99 999 99 999</p>
      <p>MANAGER</p>
    </div>


    <h2 class="ac_head_bar">added Roles</h2>

    <div class="add-roles-sec ac_det_cnt">
      <!-- Address Row first -->
      <div class="row">
        <div class="col-lg-6">
          <h5>ADDRESS BOOK (View, Delete)</h5>
        </div>

        <div class="col-lg-6">
          <h5>Use Management (View, Edit, Delete)</h5>
        </div>
      </div>
      <!-- Address Row first -->

      <!-- Address Row second -->
      <div class="row">
        <div class="col-lg-6">
          <h5>Roles Manager (View, Delete)</h5>
        </div>

        <div class="col-lg-6">
          <h5>Orders</h5>
        </div>
      </div>
      <!-- Address Row second -->
    </div>

    <h2 class="ac_head_bar">Activities</h2>

    <div class="activity-sec">
      <div class="ac_det_cnt table-responsive">
        <table class="addr_bk">
          <thead class="bg-transparent">
            <th>dATE & tIME</th>
            <th>tASK</th>
          </thead>
          
          <tbody>
            <tr class="table_data_tr">
              <td><span class="date_sec">01 jULY 2022</span><br><span class="time_sec">10: 35 am</span></td>
              <td>ADDED ADDRESS ON ADDRESS bOOK<br>delete the ORDERS</td>
            </tr>

            <tr class="table_data_tr">
              <td><span class="date_sec">01 jULY 2022</span><br><span class="time_sec">10: 35 am</span></td>
              <td>ADDED ADDRESS ON ADDRESS bOOK<br>delete the ORDERS</td>
            </tr>

            <tr class="table_data_tr">
              <td><span class="date_sec">01 jULY 2022</span><br><span class="time_sec">10: 35 am</span></td>
              <td>ADDED ADDRESS ON ADDRESS bOOK<br>delete the ORDERS</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>
<script>
import "./css/edit_user_management.css";
export default {
  name: "view-user-managemnet",
  data() {
    return {
      selected: "",
      options: [
        { text: "Role 1", value: "Role 1", _id: "12" },
        { text: "Role 2", value: "Role 2", _id: "11" },
      ],
    };
  },
  methods: {
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
  },
  setup() {},
};
</script>