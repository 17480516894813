<template>
  <div id="home-new">
    <div class="login_main_div container-fluid-lg">
      <BeforeLoginHeader />
      <div class="body_part row">
        <div class="body_image col-xl-7 mt-md-0 p-0">
          <div class="body_images"></div>
        </div>
        <div class="body_form col-lg-5">
          <div class="inner_body_form login_form_inner_body">
            <div class="alert alert-danger" v-if="errors == 'Invalid logins'">
              INVALID LOGINS
            </div>
            <div class="alert alert-danger" v-else-if="errors != []">
              {{errors}}
            </div>
            <h2>LOGIN</h2>
           
            <form v-on:submit.prevent="Login">
              <div class="input_field mt-md-3 mt-3">
                <label>EMAIL ID*</label>
                <input type="text" min="6" v-on="
                  form.email ? { change: v$.form.email.$validate } : {}
                " v-model="form.email" />
              </div>
              <span v-if="v$.form.email.$error" class="error_msg_log error_msg">
                <span v-if="form.email == ''">Email id is required</span>
                <span v-else>{{ v$.form.email.$errors[0].$message }}</span>
              </span>

              <div class="input_field inpt_icn mt-3">
                <label>PASSWORD*</label>
                <input :type="passwordVisible == true ? 'text' : 'password'" v-model="form.password" />
                <div class="inp_fld_icn">
                  <img class="visibility_btn" @click.prevent="togglePasswordVisibility()" :src="imgSrc" />
                </div>
              </div>
              <span v-if="v$.form.password.$error" class="error_msg_log error_msg">
                <span v-if="form.password == ''">Password is required</span>
                <span v-else-if="
                  v$.form.password.$errors[0].$message == 'Value is required'
                ">Password cannot have spaces!</span>
                <span v-else>{{ v$.form.password.$errors[0].$message }}</span>
              </span>

              <p class="my-3 text-start">
                <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="openForgotPwd">
                  FORGOT PASSWORD?</a>
              </p>
              <div class="col-12 btn_section">
                <button :disabled="isLoader" :class="{ 'disable-btn': isLoader }" class="btn_default">
                  <div v-if="isLoader">
                    <img width="30" src="../../assets/image/loader.gif" />
                  </div>
                  <span v-if="!isLoader">SIGN IN</span>
                </button>
                <p class="mt-2">
                  DON'T HAVE AN ACCOUNT? <br />
                  <router-link to="/register">CREATE AN ACCOUNT</router-link>
                </p>
              </div>
              <div class="login_account_note_i_section">
                <p>Note:</p>
                <p class="txt_none">
                  That's Living B2B is solely dedicated to business customers, including real estate developers,
                  retailers, interior designers, and architects. We specialize in high-quality, stylish furniture for
                  residential and hospitality spaces to suit every taste. Our wide range includes modern contemporary
                  furnishings, luxury decor, and lighting.

                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  </div>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="forger_password_main_section">
            <div class="forgot_password_main_div">
              <button @click="closePopup" type="button" class="btn-close fgt_psw_btn" data-bs-dismiss="modal" id="close"
                aria-label="Close" ref="closePop"></button>
              <div class="forgot_password_form">
                <div class="alert alert-danger" v-if="forgetPassword.errors">
                  {{ forgetPassword.errors }}
                </div>
                <br />
                <h2 class="mb-4 text-start"><b>FORGOT PASSWORD</b></h2>

                <form v-on:submit.prevent="requestResetPassword">
                  <div class="input_field inpt_icn mt-3">
                    <label>EMAIL ID*</label>
                    <input id="emailForgetPassword" type="text" v-on="
                      forgetPassword.email
                        ? {
                          change: v$.forgetPassword.email.$validate,
                        }
                        : {}
                    " v-model="forgetPassword.email" />

                  </div>
                  <span v-if="v$.forgetPassword.email.$error && forgotClose" class="error_msg_log error_msg">
                    <span v-if="forgetPassword.email == ''">Email id is required</span>
                    <span v-else>{{
                      v$.forgetPassword.email.$errors[0].$message
                    }}</span>
                  </span>
                  <div style="" class="row forgot_password_section_btn">
                    <div class="col-6 text-start">
                      <button class="btn_default" :class="{ 'disable-btn': isLoaderFwd }"
                        :disabled="isDisable && isLoaderFwd" id="resendEmail">
                        <div v-if="isLoaderFwd">
                          <img width="30" src="../../assets/image/loader.gif" />
                        </div>
                        <span v-if="!isLoaderFwd">SEND RESET LINK</span>
                      </button>
                    </div>

                    <div class="ancle_tag col-6" data-bs-dismiss="modal">
                      <a @click="closePopup" style="cursor: pointer">BACK TO LOGIN</a>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import LoginFooter from "@/components/LoginFooter";
// import ForgetPassword from "@/components/user/auth/ForgotPassword";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import router from "@/routes/routes";
import User from "@/apis/User";
import { Base64 } from "js-base64";
import "../../assets/css/style.css";
import "../../assets/css/change_password.css";
import loggedIn from "@/components/middleware/auth";



export default {
  name: "User-login",

  data() {
    return {
      login_data: [],
      errors: "",
      form: {
        email: "",
        password: "",
      },
      forgetPassword: {
        email: "",
        errors: "",
      },
      has_error: false,
      passwordVisible: false,
      modalShow: false,
      isDisable: false,
      isLoader: false,
      forgotClose: true,
      isLoaderFwd: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        email: { required, email },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(18),
          containsPasswordRequirement: helpers.withMessage(
            () =>
              `The password requires an uppercase, lowercase, number and special character`,
            (value) =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])^\S*$/.test(
                value
              )
          ),
        },
      },
      forgetPassword: {
        email: { required, email },
      },
    };
  },

  created() {
    document.title = this.$route.meta.title;
    if (loggedIn()) {
      router.push({ path: "/home" });
    }
  },
  mounted() {
    //
  },
  methods: {
    async Login() {
      this.v$.form.$validate(); // checks all inputs
      if (!this.v$.form.$error) {
        this.isLoader = true;
        User.login({
          email: this.form.email,
          password: this.form.password,
        })
          .then((response) => {
            localStorage.setItem("user-token", response.data.data.token);
            localStorage.setItem(
              "id",
              Base64.encode(response.data.data.userId)
            );
            sessionStorage.setItem(
              "id",
              Base64.encode(response.data.data.userId)
            );
            this.form.email = "";
            this.form.password = "";
            const { profileStatus } = response.data.data;
            localStorage.setItem("profileStatus", profileStatus);
            if (profileStatus == 0) {
              router.push({ name: "CompanyDetailsIndividual" });
            } else if (profileStatus == 1) {
              router.push({ name: "DocumentVerificationMsg" });
            } else if (profileStatus == 2) {
              console.log(router.currentRoute.value.fullPath)
              if (router.currentRoute.value.fullPath == '/'){
                router.push({ path: "/home" });
              }else{
                var fullPath =  router.currentRoute.value.fullPath;
               var split = fullPath.split("=")
                router.push({ path: split[1] });
              }

              // router.push({ path: "/home" });
              // let userId = localStorage.getItem("id");
              // userId = Base64.decode(userId);
              // User.priceDisplay({ userId: userId })
              //         .then((res) => {
              //           if (res.data.success) {
              //             localStorage.setItem("priceBool", res.data.data.priceDisplay)
              //           }
              //         })
              //         .catch((err) => {
              //           console.log(err);
              //         });
            }
            this.isLoader = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoader = false;
            this.errors = err.response.data.errors;
            this.errors = err.response.data.error.email[0];
          });


      } else {
        console.log("Form failed validation");
      }
    },
    async requestResetPassword() {
      this.has_error = true;
      this.v$.forgetPassword.$validate(); // checks all inputs
      if (!this.v$.forgetPassword.$error) {
        this.isDisable = true;
        this.isLoaderFwd = true;
        const emailId = this.forgetPassword.email;
        let Email = { email: emailId };
        User.forgetPassword(Email)
          .then((result) => {
            this.has_error = true;
            this.response = result.data;
            this.isLoaderFwd = false;
            const elem = this.$refs.closePop;
            elem.click();
            window.$cookies.set("userId", result.data.data.id, 300);
            // localStorage.setItem("userIdCookies", result.data.data.id)
            router.push({ name: "ResetPasswordEmail", params: Email });

          })
          .catch((error) => {
            console.error(error);
            this.isDisable = false;
            this.isLoaderFwd = false;
            const errorMsg =
              error.response.data && error.response.data.error?.email[0];
            this.forgetPassword.errors = errorMsg
              ? errorMsg
              : error.response.data.errors;
            this.forgetPassword.email = "";
            this.v$.forgetPassword.$reset();
          });
      } else {
        console.log("Form failed validation");
      }
    },
    async togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      this.forgotClose = true;
    },
    async closePopup() {
      this.v$.forgetPassword.$reset();
      this.forgetPassword.email = "";
      this.forgotClose = false;
      this.forgetPassword.errors = "";
    },
    async openForgotPwd() {
      this.closePopup();
      this.forgotClose = true;
    },
  },
  computed: {
    imgSrc: function () {
      return this.passwordVisible === true
        ? require("../../assets/image/show_password_icon.png")
        : require("../../assets/image/hide_password_icon.png");
    },
  },
  components: {
    BeforeLoginHeader,
    LoginFooter,
    // ForgetPassword
  },
};
</script>

<style>
.border-color {
  border: 1px solid red !important;
}

.error_msg_log {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
