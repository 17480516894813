<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="products_sec">
    <div class="page-width">
      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->

      <div class="row row_sidebar">
        <!-- Start Shopping Cart -->
        <div class="col-lg-8 side_sticky2_cart">
          <!-- Checkout Page Title -->
          <div class="shpng_cart_ttl">
            <span>Checkout</span>
          </div>
          <!-- Checkout Page Title -->

          <!-- Checkout Address Sec -->
          <div class="checkout-address-sec">
            <!-- Adrs Title Sec -->
            <div class="shpng-adrs-ttl">
              <span>Shipping Address</span>
              <button class="btn_default" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Add new address
              </button>
            </div>
            <!-- Adrs Title Sec -->



            <!-- Default Selected Address Section -->
            <div v-if="shippingData == ''" class="default-selected-adrs-sec">
              <p>No address found.</p>
            </div>
            <div v-else class="default-selected-adrs-sec">
              <ul>
                <li>
                  <address v-html="displayAddress(deliveryAdd[0])"></address>
                  <button @click="showAddList" class="btn_default">Change Address</button>
                </li>
              </ul>
            </div>
            <!-- Default Selected Address Section -->

            <!-- Added Address List Section -->
            <div v-if="showAddrList" class="added-adrs-list-sec">
              <div class="row">
                <div class="col-sm-6" v-for="item in shippingData" :key="item.id">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="groupItem" id="groupItem"
                      @click="changeAdd(item, 'shipping')" />
                    <label class="form-check-label" :for="item.id">
                      <address v-html="displayAddress(item)"></address>
                    </label>
                    <div v-if="showShipping[item.id]" class="u_a_btn">
                      <button :disabled="loaderShipBool[item.id]" :class="{ 'disable-btn': loaderShipBool[item.id] }" @click="changeDefault(item, 'shipping')" class="btn_default">
                      <div v-if="loaderShipBool[item.id]">
                          <img width="22" src="../../assets/image/loader.gif" />
                        </div>
                      <span v-if="!loaderShipBool[item.id]">
                      Update</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Added Address List Section -->

            <!-- Billing and Shipping Address check Section -->
            <div class="sel-bil-shp-adrs-as-same product_filter_sec">
              <div class="form-check">
                <label class="form-check-label" for="flexCheckChecked">
                  My billing and Shipping address are the Same
                  <input class="form-check-input" type="checkbox" id="flexCheckChecked" :checked="!showBillingAdd"
                    @click="showBilling" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <!-- Billing and Shipping Address check Section -->
            <!-- Added Address List Section -->
            <div v-if="showBillingAdd" class="added-adrs-list-sec">
              <div class="shpng-adrs-ttl mb-3">
                <span>Billing Address</span>
              </div>
              <div class="row">
                <!-- Default Selected Billing Address Section -->
                <!-- <div v-if="billingAdd[0]" class="shpng-adrs-ttl">
                  <span>Billing Address</span>
                </div> -->
                <div v-if="billingData == ''" class="default-selected-adrs-sec">
                  <p>No address found.</p>
                </div>
                <div v-else class="default-selected-adrs-sec">
                  <ul>
                    <li>
                      <address v-html="displayAddress(billingAdd[0])"></address>
                    </li>
                  </ul>
                </div>
                <!-- Default Selected Billing Address Section -->
                <div class="col-sm-6" v-for="item in billingData" :key="item.id">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                      @click="changeAdd(item, 'billing')" />
                    <label class="form-check-label" for="flexRadioDefault1">
                      <address v-html="displayAddress(item)"></address>
                    </label>
                    <div v-if="showBillAddBool[item.id]" class="u_a_btn">
                      <button :disabled="loaderBillBool[item.id]" :class="{ 'disable-btn': loaderBillBool[item.id] }" @click="changeDefault(item, 'billing')" class="btn_default">
                      <div v-if="loaderBillBool[item.id]">
                          <img width="22" src="../../assets/image/loader.gif" />
                        </div>
                      <span v-if="!loaderBillBool[item.id]">
                      Update</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Added Address List Section -->
            <div class="cntnu-shpng-btn">
              <router-link to="/show-cart">
                <img class="img-fluid" src="../../assets/image/long-arrow-back.svg" />
                <span>Back to Cart</span>
              </router-link>
            </div>
          </div>
          <!-- Checkout Address Sec -->
        </div>
        <!-- End Shopping Cart -->

        <!-- Start Coupan Code -->
        <div class="col-lg-4 side_sticky_cart">
          <div class="order-summery-sec">
            <h4>Cart  summary</h4>
            <div class="ordr-prc-tx-dtls">
              <p>
                <span>Items ({{ itemsCount }})</span>
                <span>{{ common.formatNumber(cartData.cartTotal) }}</span>
              </p>

<!--              <p>-->
<!--                <span>Tax</span>-->
<!--                <span v-if="cartData.taxes">{{-->
<!--                common.formatNumber(cartData.taxes)-->
<!--                }}</span>-->
<!--                <span v-else>&#45;&#45;N/A&#45;&#45;</span>-->
<!--              </p>-->
            </div>

<!--            <div class="promo-code-sec">-->
<!--              <p>Promo code</p>-->
<!--              <div class="promo-cupn-sec">-->
<!--                <input type="text" v-model="couponCode" placeholder="Enter Your Code" />-->
<!--                <span v-if="couponError" class="error_msg_log error_msg">{{-->
<!--                couponError-->
<!--                }}</span>-->
<!--                <button :disabled="couponCode == ''" v-on:click.prevent="applyCoupon()">-->
<!--                  Apply-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div v-if="cartData.couponDiscount" class="applied-promo-code-sec">-->
<!--              <div class="promo-apply-sec">-->
<!--                <span>promo code</span>-->
<!--                <strong>{{ cartData.couponCode }}</strong>-->
<!--                <button v-on:click.prevent="removeCoupon(cartData.couponCode)">-->
<!--                  Remove Promocode-->
<!--                </button>-->
<!--              </div>-->

<!--              <div class="promo-disc-prc">-->
<!--                <span>- {{ common.formatNumber(cartData.couponDiscount) }}</span>-->
<!--              </div>-->
<!--            </div>-->

            <div class="ttl-cart-prc-sec cart_botm">
              <span>Total Cost</span>
              <span>{{ common.formatNumber(cartTotal) }}</span>
            </div>
            <div class="order-summery-sec saving_block" v-if="cartData.cartTotal != cartData.originalAmount">
            <p>Total Savings for this order <span class="price_sav">{{ common.formatNumber(amountSave) }}</span></p>
          </div>
            <p class="shp_note">Note: Shipping fee not included</p>
            <div class="ctn-shp-btn">
              <button :disabled="isLoader" :class="{ 'disable-btn': isLoader }" @click="orderConfirm">
                <div v-if="isLoader">
                  <img width="22" src="../../assets/image/loader.gif" />
                </div>
                <span v-if="!isLoader">Confirm Order</span>
              </button>
            </div>
          </div>
          

        </div>
        <!-- Add New Address Modal -->
        <div class="modal fade add-new-adrs-modal" id="exampleModal" tabindex="-1"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header border-0 pb-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        ref="closePop"></button>
              </div>

              <!-- Modal Body -->
              <div class="modal-body">
                <AddAddress :fromCheckout="fromCheckout" @addressAdded="addressAdded" :key="addressKey" />
              </div>
              <!-- Modal Body -->
              <!-- Modal Footer -->
            </div>
          </div>
        </div>

        <!-- Add New Address Modal -->
        <!-- End Coupan Code -->
      </div>
    </div>
  </section>
  <CartFooter />
</template>
<script>
import "./css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import AddAddress from "@/components/user/address-book/AddAddress";
import Api from "@/apis/Cart";
import addApi from "@/apis/AddressBook";
import { Base64 } from "js-base64";
import common from "@/apis/Common";
import router from "@/routes/routes";
import Master from "@/apis/Master";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "@vuelidate/validators";
import BreadCrumb from '@/components/common/BreadCrumb';
import User from "@/apis/User";
export default {
  name: "cart-checkout",
  data() {
    return {
      cartData: [],
      addressData: [],
      shippingData: [],
      billingData: [],
      billingAdd: [],
      deliveryAdd: [],
      common: common,
      showBillingAdd: false,
      fromCheckout: true,
      addressObj: {
        userId: 1,
        addressType: 1,
        mobile: "",
        firstName: "",
        lastName: "",
        email: "",
        state: '',
        city: "",
        streetAddressOne: "",
        streetAddressTwo: "",
        zipCode: "",
        countryId: "",
      },
      selected: "",
      countries: [],
      isLoader: false,
      isaddLoader: false,
      success: "",
      addressKey: 1,
      showAddrList: false,
      showShipping: [],
      loaderShipBool: [],
      loaderBillBool: [],
      showBillAddBool: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    AddAddress,
    BreadCrumb,
  },
  created() {
    document.title = this.$route.meta.title;
    this.getAddressList();
    this.getCartItems();
    this.getCountries();
  },
  computed: {
    cartTotal: function () {
      let total = this.cartData.cartTotal;
      if (this.cartData.couponDiscount) {
        total = total - this.cartData.couponDiscount;
      }
      return total;
    },
    amountSave: function () {
      let cartTotal = this.cartData.cartTotal;
      let originalPrice = this.cartData.originalAmount;
      if (this.cartData.cartTotal) {
        cartTotal = originalPrice - cartTotal;
      }
      return cartTotal;
    },
    itemsCount: function () {
      let count = 0;
      if (this.cartData.cartItems) {
        count = this.cartData.cartItems.length;
      }
      return count;
    },
    displayAddress() {
      return (address) => {
        let displayAddress = "";
        if (address) {
          if (address.streetAddressOne)
            displayAddress = address.firstName + " " + address.lastName;
          if (address.streetAddressOne)
            displayAddress += "<br>" + address.streetAddressOne;
          if (address.streetAddressTwo)
            displayAddress += "<br>" + address.streetAddressTwo;
          if (address.city) displayAddress += "<br>" + address.city;
          if (address.state) displayAddress += ", " + address.state;
          if (address.countryName)
            displayAddress += ", " + address.countryName;
          if (address.zipCode) displayAddress += "<br>" + address.zipCode;
        }
        return displayAddress;
      };
    },
  },
  methods: {
    addressAdded(value) {
      if (value) {
        this.getAddressList();
      }
      this.addressKey += 1;
      const elem = this.$refs.closePop;
      elem.click();
    },
    getCountries: function () {
      Master.country().then(
        function (response) {
          this.countries = response.data.data;
        }.bind(this)
      );
    },
    async changeAdd(item, addressType) {
      if (addressType == "shipping") {
        this.showShipping = [];
        this.shipAdd = item.id;
        this.showShipping[item.id]=true;
      }
      if (addressType == "billing" || !this.showBillingAdd) {
        this.showBillAddBool = [];
        this.billAdd = item.id;
        this.showBillAddBool[item.id]=true;
      }
    },
    async showBilling() {
      this.showBillingAdd = !this.showBillingAdd;
      if(this.showBillingAdd){
        if(this.billingAdd[0]?.id){
          this.billAdd = this.billingAdd[0].id;
        }else{
          this.billAdd = ''
        }
      }else{
        this.billAdd = this.shipAdd;
      }
    },
    async showAddList() {
      this.showAddrList = !this.showAddrList;
    },
    async orderConfirm() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      if(!this.shipAdd || !this.billAdd){
        alert("Please select shipping/billing address");
        return;
      }
      this.isLoader = true;
      User.getUserInfo({ userId: userId })
          .then((res) => {
            if (res.data.success) {
              Api.orderConfirm({
                userId: userId,
                shippingAddress: this.shipAdd,
                billingAddress: this.billAdd,
              })
                  .then((res) => {
                    if (res.data.success) {
                      this.isLoader = false;
                      this.cartData.orderID = res.data?.data?.orderId;
                      this.cartData.count = res.data?.data?.orderQty;
                      localStorage.setItem("cartData", JSON.stringify(this.cartData))
                      router.push({ name: "OrderSuccess" });
                    } else {
                      this.$router.push({
                        name: "OrderFailure",
                        params: { sippingId: this.shipAdd, billingId: this.billAdd },
                      });
                    }
                  })
                  .catch((err) => {
                    this.isLoader = false;
                    console.log(err);
                    this.$router.push({ name: "OrderFailure",
                      params: { sippingId: this.shipAdd, billingId: this.billAdd },
                    });
                  });
            }else if (res.data.status == "Token is Expired"){
              this.logout()
            }else if (res.data.status == "Token is Invalid"){
              this.logout()
            }
          })
          .catch((err) => {
            if (err.response.data.errors == "User profile not found..!"){
              this.logout();
            }
          });




    },
    logout() {
      localStorage.clear();
      router.push({ name: 'Login' });
    },
    async getAddressList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      addApi
        .getAddressList({ userId: userId, trash: 0 })
        .then((res) => {
          if (res.data.success) {
            this.addressData = res.data.data;
            this.deliveryAdd = this.addressData.filter(
              (val) => val.addressType == 1 && val.default == 1
            );
            this.shippingData = this.addressData.filter(
              (val) => val.addressType == 1
            );
            this.billingAdd = this.addressData.filter(
              (val) => val.addressType == 2 && val.default == 1
            );
            this.billingData = this.addressData.filter(
              (val) => val.addressType == 2
            );
            if (this.deliveryAdd[0]) {
              this.shipAdd = this.deliveryAdd[0].id;
              if (!this.showBillingAdd) {
                this.billAdd = this.deliveryAdd[0].id;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCartItems() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getCartItems({ userId: userId })
        .then((res) => {
          if (res.data.success) {
            this.cartData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async applyCoupon() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.applyCoupon({ userId: userId, couponCode: this.couponCode })
        .then((res) => {
          if (res.data.success) {
            this.getCartItems();
            setTimeout(() => {
              this.showdelMsg = false;
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async removeCoupon() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      Api.removeCoupon({ userId: userId, couponCode: this.couponCode })
        .then((res) => {
          if (res.data.success) {
            this.getCartItems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async changeDefault(item, type) {
      if(type=='shipping'){
        this.loaderShipBool = [];
        this.loaderShipBool[item.id] = true;
      }
      else{
        this.loaderBillBool = [];
        this.loaderBillBool[item.id] = true;
      }
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      addApi.defualtAddress({ userId: userId, id: item.id })
        .then((res) => {
          if (res.data.success) {
            this.getAddressList();
            if(type=='shipping'){
              this.loaderShipBool[item.id] = false;
            }else{
              this.loaderBillBool[item.id] = false;
            }
          }
        })
        .catch((err) => {
            if(type=='shipping'){
              this.loaderShipBool[item.id] = false;
            }else{
              this.loaderBillBool[item.id] = false;
            }
            console.log(err);
        });
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      addressObj: {
        firstName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
            () => `First name is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        lastName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsLastNameRequirement: helpers.withMessage(
            () => `Last name is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        email: { required, email },
        addressType: { required },
        streetAddressOne: { required },
        countryId: { required },
        city: {
          required,
          minLength: minLength(3),
          containsFirstNameRequirement: helpers.withMessage(
            () => `City is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
      },
    };
  },
};
</script>
