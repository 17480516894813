<template>
    <div class="col-lg-9">
        <div class="alert alert-success" v-if="success">{{ success }}</div>
        <div class="alert alert-danger" v-if="errorShow" id="error_msg">
            <ul>
                <li>{{ errors }}</li>
            </ul>
        </div>
        <h2 class="ac_pg_head">Add Project</h2>
        <h2 class="ac_head_bar">Project Information</h2>
        <form v-on:submit.prevent="addProject">
            <div class="ac_det_cnt">
                <div class="row">
                    <div class="col-md-6 margin-bottom20 margin-bottom20">
                        <div class="input_field">
                            <label>PROJECT NAME*</label>
                            <input type="text" v-model="client.projectName" v-on="
                                client.projectName
                                    ? { change: v$.client.projectName.$validate }
                                    : {}
                            " />
                        </div>
                        <span v-if="v$.client.projectName.$error" class="error_msg_log error_msg">
                            <span v-if="client.projectName == ''">Project Name is required</span>
                            <span v-else>{{ v$.client.projectName.$errors[0].$message }}</span>
                        </span>
                    </div>
                    <div class="col-lg-6">
                        <div class="dd_default ipt_fld  mb-3">
                            <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                                <label>COUNTRY*</label>
                                <!--                                <div class="dropdown dd_default">-->
                                <!--                                    <button class="btn btn-secondary dropdown-toggle" type="button"-->
                                <!--                                        data-bs-toggle="dropdown" aria-expanded="false">{{(client.countryId) ?-->
                                <!--                                        countryName : 'Select Country'}}</button>-->
                                <!--                                    <ul class="dropdown-menu">-->
                                <!--                                        <li v-for="country in countries" :key="country.id">-->
                                <!--                                            <a @click="changeMode(country)" class="dropdown-item">{{ country.name }}</a>-->
                                <!--                                        </li>-->

                                <!--                                    </ul>-->
                                <!--                                </div>-->
                                <select v-model="client.countryId" class="bg-white" @change="countrySelect(client.countryId)">
                                    <!--                                    <option value="">SELECT COUNTRY</option>-->
                                    <option v-for="country in countries" :key="country.id" :value="country.id" :text="country.name" ></option>
                                </select>

                            </div>
                            <span
                                    v-if="v$.client.countryId.$error"
                                    class="error_msg_log error_msg"
                            >
              <span v-if="client.countryId == ''">Country is required</span>
              <span v-else>{{ v$.client.countryId.$errors[0].$message }}</span>
            </span>
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>EMAIL ID</label>
                            <input type="text" v-model="client.email" v-on="
                                client.email
                                    ? { change: v$.client.email.$validate }
                                    : {}
                            " />
                        </div>
                        <span v-if="v$.client.email.$error" class="error_msg_log error_msg">
                            <span>{{ v$.client.email.$errors[0].$message }}</span>
                        </span>
                    </div>
<!--                    <div class="col-md-4 margin-bottom20">-->
<!--                        <div class="input_field">-->
<!--                            <label>MOBILE NUMBER*</label>-->
<!--                            <input type="text" v-model="client.mobile" v-on="-->
<!--                                client.mobile-->
<!--                                    ? { change: v$.client.mobile.$validate }-->
<!--                                    : {}-->
<!--                            " />-->
<!--                        </div>-->
<!--                        <span v-if="v$.client.mobile.$error" class="error_msg_log error_msg">-->
<!--                            <span v-if="client.mobile == ''">Mobile Number is required</span>-->
<!--                            <span v-else>{{ v$.client.mobile.$errors[0].$message }}</span>-->
<!--                        </span>-->
<!--                    </div>-->
                    <div class="col-md-6">
                        <div class="ipt_group">
                            <div class="dd_default ipt_fld">
                                <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                                    <label>CODE</label>
                                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"
                                                                             data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> {{(client.phoneCode)?'+'+phoneCode:'+00'}}</button>
                                        <ul class="dropdown-menu" style="">
                                            <li v-for="country in code" :key="country.phoneCode">
                                                <a @click="changePhoneCodeMode(country)" class="dropdown-item"><img :src="country.countryImage">  +{{country.phoneCode}}</a>
                                            </li>
                                        </ul>
                                    </div>

<!--                                    <select v-model="client.phoneCode" class="bg-white">-->
<!--                                          <option v-for="country in countries" :key="country.phoneCode" :value="country.phoneCode" >-->
<!--                                              <img :src="country.countryImage"/> {{'+'+country.phoneCode}}-->
<!--                                          </option>-->

<!--                                    </select>-->



                                </div>
                            </div>
                            <div class="input_field">
                                <label>MOBILE NUMBER</label>
                                <input type="text" v-model="client.mobile"
                                       v-on="client.mobile ? { change: v$.client.mobile.$validate }: {}"/>
                            </div>

                        </div>

                        <span
                                v-if="v$.client.mobile.$error"
                                class="error_msg_log error_msg"
                        >
              <span v-if="v$.client.mobile.$errors[0].$message">MOBILE NUMBER IS INVALID</span>
            </span>

                    </div>

                </div>
            </div>
            <h2 class="ac_head_bar">Address</h2>
            <div class="ac_det_cnt">
                <div class="row">
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STREET ADDRESS LINE 1</label>
                            <input type="text" v-model="client.streetAddressOne" />
                        </div>

                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STREET ADDRESS LINE 2</label>
                            <input type="text" v-model="client.streetAddressTwo" />
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>CITY</label>
                            <input type="text" v-model="client.city" />
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STATE/ PROVINCE</label>
                            <input type="text" v-model="client.state" />
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>ZIP / POSTALCODE</label>
                            <input type="text" v-model="client.zipCode" />
                        </div>
                    </div>

                </div>


            </div>
            <div>
                <h2 class="ac_head_bar">Logo</h2>
                <div class="ac_det_cnt">
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <div class="file_u_cnt">
                                <input type="file" id="reg_upload"  @change="onFileSelected($event)" hidden
                                       accept="image/png, image/jpeg, image/jpg" />
                                <span class="lable_sec" id="reg_file">{{ regFile }}</span>
                                <label for="reg_upload" class="reg_u_btn">Choose File</label>
                            </div>
                            <p class="below_input_msg">
                                Please upload the logo of the project. Upload a PNG, or JPG file.
                            </p>

                        </div>
                    </div>
<!--                    <div class="business_type_section mt-3">-->
<!--                        <div>-->
<!--                            <input name="isDefault" @change="onChangeDefault" :value="client.isDefault"-->
<!--                                :checked="client.isDefault == 1" id="b_type_freelance" type="checkbox" /><label-->
<!--                                class="ms-2" for="b_type_freelance">Make this project as default for Wishlist</label>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>



                <div class="ad_btns_cnt mb-4">
                    <button class="btn_outline" v-on:click.prevent="cancel()">
                        CANCEL
                    </button>
                    <button :disabled="isLoader" :class="{ 'disable-btn': isLoader }" class="btn_default">
                        <div v-if="isLoader">
                            <img width="30" src="../../../assets/image/loader.gif" />
                        </div>
                        <span v-if="!isLoader"> Add </span>
                    </button>

                </div>

            </div>
        </form>
    </div>
</template>
<script>
import router from "@/routes/routes";
import "../address-book/css/add_address.css";
import Master from "@/apis/Master";
import useVuelidate from "@vuelidate/core";
import Api from "@/apis/Project";
import { Base64 } from "js-base64";
// import User from "../../../apis/User";
import {
    email,
    numeric,
    helpers,
    maxLength,
    minLength,
    required,
} from "@vuelidate/validators";
// import vSelect from "vue-select";
export default {
    name: "add-client",
    props: ["fromCheckout"],
    data() {
        return {
            client: {
                userId: 1,
                mobile: "",
                projectName: "",
                email: "",
                state: '',
                city: "",
                streetAddressOne: "",
                streetAddressTwo: "",
                zipCode: "",
                countryId: "",
                phoneCode: "",
                isDefault: 0,
            },
            files: {
                file1: "",
            },
            selected: "",
            countries: [],
            code: [],
            isLoader: false,
            success: "",
            errors: "",
            errorShow: false,
            regFile: "No file chosen",
        };
    },
    components: {
        // vSelect
    },
    mounted() {
        // let userId = localStorage.getItem("id");
        // userId = Base64.decode(userId);
        // User.getUserInfo({ userId: userId })
        //     .then((res) => {
        //         if (res.data.success) {
        //             this.data = res.data.data;
        //             this.addressObj.firstName = res.data.data.firstName
        //             this.addressObj.lastName = res.data.data.lastName
        //             this.addressObj.email = res.data.data.userEmail
        //             this.addressObj.mobile = res.data.data.mobile
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    },
    methods: {

        async changeMode(mode) {
            this.countryName = mode.name;
            this.client.countryId = mode.id;
        },

        async changePhoneCodeMode(data){
            this.phoneCode = data.phoneCode;
            this.countryImage = data.countryImage;
            this.client.phoneCode = data.phoneCode;
        },
        async cancel() {
            router.push("/user/projects");
        },
        onChangeDefault() {
            if (this.client.isDefault == 1) {
                this.client.isDefault = 0;
            } else {
                this.client.isDefault = 1;
            }
        },
        async addProject() {
            this.errors = [];
            this.errorShow = false;
            let userId = localStorage.getItem("id");
            this.client.userId = Base64.decode(userId);
            // this.v$.$validate();
            this.v$.client.projectName.$validate()
            this.v$.client.countryId.$validate()

            if (!this.v$.client.projectName.$error && !this.v$.client.countryId.$error) {
                this.isLoader = true;
                // if no fail validation
                const fd = new FormData();
                fd.append("image", this.files.file1);
                fd.append("data", JSON.stringify(this.client));
                Api.addProject(fd,
                    {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        console.log(res);
                        this.success = res.data.message;
                        this.isLoader = false;
                        setTimeout(() => {
                            console.log('name', "Raja Tamil sdfsdfsf");
                            this.$router.push({ name: "ProjectClient" });
                        }, 3000);
                    })
                    .catch((err) => {
                        this.isLoader = false;
                        this.errors = err?.response?.data?.errors;
                        this.errorShow = true;
                    });
                console.log("Form successfully submitted.");
            } else {
                console.log("Form failed validation");
            }
        },
        getCountries: function () {
            Master.country().then(
                function (response) {
                    this.countries = response.data.data;
                }.bind(this)
            );
        },
        phoneCodeData: function () {
            Master.phoneCode().then(
                function (response) {
                    this.code = response.data.data;
                }.bind(this)
            );
        },
        onFileSelected(event) {
            this.regFile = event.target.files[0].name;
            console.log(event.target.files[0].name);
            this.files.file1 = event.target.files[0];

        },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            client: {
                projectName: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(100),
                    // containsFirstNameRequirement: helpers.withMessage(
                    //     () => `Project name is invalid`,
                    //     (value) => /^[A-Za-z\s.']+$/.test(value)
                    // ),
                },
                mobile: {

                    numeric
                },
                countryId: { required },
                email: {  email },
                city: {
                    minLength: minLength(3),
                    containsFirstNameRequirement: helpers.withMessage(
                        () => `City is invalid`,
                        (value) => /^[A-Za-z\s']+$/.test(value)
                    ),
                },
            },
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.getCountries();
        this.phoneCodeData();
        document.title = this.$route.meta.title;
    },
};
</script>
<style>
@import "../../../assets/css/company_details_business.css";
</style>
