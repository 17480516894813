<template>
  <div class="col-lg-9">
  <div class="alert alert-success" v-if="showdelMsg" id="error_msg">
        {{ delMsg }}
      </div>
    <h2 class="ac_pg_head">USER MANAGEMENT</h2>
    <div class="row">
      <div class="col-lg-8 order-lg-last">
        <button v-on:click.prevent="add_btn()" class="btn_default rl_add_btn">
          ADD New User
        </button>
      </div>
      <div class="col-lg-4">
        <div class="input-group inpt_bx mb-3">
          <input type="text" class="form-control" placeholder="Name/Mobile/Email" v-model="userSearch"
            v-on:keyup="searchUsers('keyup')" />
          <button class="b_w_ust btn_default" v-on:click="searchUsers('')" type="button">SEARCH</button>
        </div>
      </div>
    </div>

    <div class="ac_det_cnt">
      <table class="addr_bk">
        <thead>
          <th>SI.No</th>
          <th>Name</th>
          <th class="d-none d-xl-table-cell">Mobile</th>
          <th class="d-none d-xl-table-cell">Email</th>
          <th>Role</th>
          <!-- <th class="d-none d-xl-table-cell">Last Active</th> -->
          <th>Status</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-if="!(userData.length>0)">
            <td colspan="8" style="text-align:center;padding-top:10px">
              <img v-if="isLoader" width="30" src="../../../assets/image/loader.gif" />
              <span v-if="!isLoader">No Records Found</span>
            </td>
          </tr>
          <tr v-for="(data, index) in userData" :key="data.userId" class="table_data_tr">
            <td>{{ index + 1 }}</td>
            <td>{{ data.firstName + ' ' + data.lastName }}</td>
            <td class="responsive_none d-none d-xl-table-cell">{{ data.mobile }}</td>
            <td class="responsive_none d-none d-xl-table-cell">{{ data.email }}</td>
            <td>{{ data.roleName }}</td>
           <!-- <td class="responsive_none d-none d-xl-table-cell">{{ data.lastLogin }}</td> -->
            <td :class="{ status_active: data.status === 1 }" class="status_inactive">
              {{ (data.status==1)?"Active":"Inactive" }}
            </td>
            <td>
              <div class="add_a_icns">
                <router-link :to="`edit-user/${data.userId}`" class="edit_icn"><img src="../../../assets/image/edit_icon.svg" class="img-fluid" />
                  <span> Edit</span>
                </router-link>
                <a v-on:click.prevent="deleteUser(data.userId)" href="#" class="del_icn"><img src="../../../assets/image/delete_icon.svg" class="img-fluid" />
                  <span> Delete</span>
                </a>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import router from "@/routes/routes";
import "./css/user_management.css";
import Api from "@/apis/UserManagment";
import { Base64 } from "js-base64";
export default {
  name: "user-management",
  data() {
    return {
      userData: [],
      isLoader: true,
      originalData: [],
      userSearch: '',
      delMsg: "Deleted Successfully",
      showdelMsg: false,
    };
  },
  methods: {
    async searchUsers(type) {
      if ((this.userSearch && type != 'keyup') || (type == 'keyup' && this.userSearch.length > 2)) {
        let searchText = this.userSearch.toLowerCase();
        this.userData = this.originalData.filter((item) =>
        (item.firstName.toLowerCase().indexOf(searchText) > -1
          ||
                item.lastName.toLowerCase().indexOf(searchText) > -1
                ||
                item.roleName.toLowerCase().indexOf(searchText) > -1
                ||
          item.mobile.toLowerCase().indexOf(searchText) > -1
          ||
          item.email.toLowerCase().indexOf(searchText) > -1));
      } else {
        this.userData = this.originalData;
      }
    },
    async add_btn() {
      router.push("/user/add-user");
    },
    async viewAdd() {
      router.push("/user/view-user");
    },
    async getUsersList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getUsersList({ userId: userId, trash: 0 })
        .then((res) => {
          this.isLoader = false;
          if (res.data.success) {
            this.originalData = this.userData = res.data.data;
          }
        })
        .catch((err) => {
          this.isLoader = false;
          console.log(err);
        });
    },
    async deleteUser(userId) {
      let text = "Are you sure you want to delete?";
      if (confirm(text) == true) {
        Api.deleteUser({ userId: userId, trash:0})
          .then((res) => {
            if (res.data.success) {
              this.showdelMsg = true;
              this.getUsersList();
              setTimeout(() => {
                this.showdelMsg = false;
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  setup() { },
  created() {
    window.scrollTo(0, 0);
    this.getUsersList();
  },
};
</script>
<style>
.status_active {
  color: green !important;
}

.status_inactive {
  color: red;
}
</style>
