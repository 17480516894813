<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Privacy policy Sec -->

      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->
      <!-- Start page  -->
      <h2 class="standard-title heading_Strike">
        <span>THAT'S LIVING STORES</span>
      </h2>
      <div class=" row align-items-center">
        <div class="col-lg-6 ">
          <h5 class="st_header">That’s Living: Experience Furniture Shopping Like Never Before!</h5>
          <p class="abt_pslty">Spread across an area of 4,460 SQM in Tawar Mall and 5,820 SQM in Doha Festival City,
            Doha, our showrooms will let you witness an exclusive range of high-quality original and bespoke furniture
            units.
            <br>
            At That’s Living, we believe in building long-lasting relationships with our customers and making their
            shopping experience great. Our experienced and skilled team of designers is always there to discuss ideas
            about the project and understand the personal interests which need more attention. We make sure that:
          </p>
          <ul class="check_lst_icn ps-4">
            <li>
              We respect the customer’s point of view and their ideas.
            </li>
            <li>
              We let our customers know about the personal home design plans.
            </li>
            <li>
              We make modifications reflecting the modern living lifestyle.
            </li>
            <li>
              We plan every project as per the budget blueprint provided by the customer.
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div class="mt-lg-0 mt-5">
            <img alt="image"
              src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/our_stores_image.jpg?v=1651741851"
              class="img-fluid">
          </div>
        </div>
      </div>


      <!-- <h1>Doha</h1> -->

      <div class="row mt-4">
        <div>
          <div>
            <h2><stong>TAWAR MALL - DOHA QATAR</stong></h2>
            <hr class="mb-4">
            <div class="row">
              <div class="col-xl-4 col-md-6">
                <div class="st_card">
                  <img
                    src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Caracole_c66abb17-ef95-4ffc-9688-44a7bea564ef.jpg?v=1660654270"
                    alt="" class="img-fluid">
                  <h2>CARACOLE</h2>
                  <h4>Tawar Mall, 1st Floor، Al Markhiya St, Doha, Qatar</h4>

                  <span style="display:inline-flex">
                    <img alt="call logo"
                      src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                      class="img-fluid pe-2">
                    <a class="call" href="tel:+974 3080 3086" target="_blank" rel="noreferrer noopener">+974 3080
                      3086</a>
                  </span>

                  <a class="map_link" href="https://g.page/caracole-qatar?share" target="_blank"
                    rel="noreferrer noopener">Maps and Directions</a>


                  <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
                  <div class="shop_timeing">

                    <div class="row my-1">
                      <p class="col-5">Monday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Tuesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Wednesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Thursday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Friday</p>
                      <p class="col-7">3PM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Saturday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Sunday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>

                  </div>
                </div>
              </div>






              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div class="st_card">
                  <img
                    src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Eclipse_9467641d-2202-4a34-a5a2-7c8d56b87e71.jpg?v=1660654303"
                    alt="" class="img-fluid">
                  <h2>ECLIPSE LIGHTING</h2>
                  <h4>Tawar Mall, 1st Floor، Al Markhiya St, Doha, Qatar</h4>

                  <span style="display:inline-flex"><img alt="call logo"
                      src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                      class="img-fluid pe-2"> <a class="call" href="tel:+974 3111 3270" target="_blank"
                      rel="noreferrer noopener">+974 3111 3270</a></span>

                  <a class="map_link" href="https://g.page/eclipse-lighting-doha?share" target="_blank"
                    rel="noreferrer noopener">Maps and Directions</a>
                  <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
                  <div class="shop_timeing">

                    <div class="row my-1">
                      <p class="col-5">Monday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Tuesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Wednesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Thursday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Friday</p>
                      <p class="col-7">3PM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Saturday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Sunday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>

                  </div>
                </div>
              </div>






              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div class="st_card">
                  <img
                    src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Eichholtz_d45a933b-075b-475d-be65-2a86d230e79d.jpg?v=1660654963"
                    alt="" class="img-fluid">
                  <h2>EICHHOLTZ</h2>
                  <h4>Tawar Mall, 1st Floor, Al Markhiya St, Doha, Qatar</h4>

                  <span style="display:inline-flex"><img alt="call logo"
                      src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                      class="img-fluid pe-2"> <a class="call" href="tel:+974 3399 2030" target="_blank"
                      rel="noreferrer noopener">+974 3399 2030</a></span>

                  <a class="map_link" href="https://g.page/Eichholtz-Qatar?share" target="_blank"
                    rel="noreferrer noopener">Maps and Directions</a>
                  <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
                  <div class="shop_timeing">

                    <div class="row my-1">
                      <p class="col-5">Monday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Tuesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Wednesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Thursday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Friday</p>
                      <p class="col-7">3PM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Saturday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Sunday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>

                  </div>
                </div>
              </div>






              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div class="st_card">
                  <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/TL.jpg?v=1660654319" alt=""
                    class="img-fluid">
                  <h2>THAT'S LIVING</h2>
                  <h4>Tawar Mall, Al Markhiya St, Doha, Qatar</h4>

                  <span style="display:inline-flex"><img alt="call logo"
                      src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                      class="img-fluid pe-2"> <a class="call" href="tel:+974 7726 6267" target="_blank"
                      rel="noreferrer noopener">+974 7726 6267</a></span>

                  <a class="map_link" href="https://goo.gl/maps/4KY6YaWrSCqRyg8D7" target="_blank"
                    rel="noreferrer noopener">Maps and Directions</a>
                  <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
                  <div class="shop_timeing">

                    <div class="row my-1">
                      <p class="col-5">Monday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Tuesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Wednesday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Thursday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Friday</p>
                      <p class="col-7">3PM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Saturday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>


                    <div class="row my-1">
                      <p class="col-5">Sunday</p>
                      <p class="col-7">9AM - 10PM</p>
                    </div>

                  </div>
                </div>
              </div>




            </div>

          </div>






        </div>
      </div>
      <div>
        <h2><strong>DOHA FESTIVAL CITY</strong></h2>
        <hr class="mb-4">
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/ThatsLivingStoreFestivalCity.jpg?v=1649057422"
                alt="" class="img-fluid">
              <h2>THAT'S LIVING FURNITURE</h2>
              <h4>DOHA FESTIVAL CITY, Umm Salal Muhammed, Qatar</h4>
              <span style="display:inline-flex"><img alt="call logo"
                  src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                  class="img-fluid pe-2"> <a class="call" href="tel:+974 7726 6267" target="_blank"
                  rel="noreferrer noopener">+974 7726 6267</a></span>

              <a class="map_link" href="https://g.page/thats-living-doha?share" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>






          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Theodore_Alexander_DFC.jpg?v=1649150382" alt=""
                class="img-fluid">
              <h2>THEODORE ALEXANDER</h2>
              <h4>DOHA FESTIVAL CITY, Umm Salal Muhammed, Qatar</h4>

              <span style="display:inline-flex"><img alt="call logo"
                  src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                  class="img-fluid pe-2"> <a class="call" href="tel:+974 7726 6267" target="_blank"
                  rel="noreferrer noopener">+974 7726 6267</a></span>

              <a class="map_link" href="https://g.page/theodorealexanderqatar?share" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>






          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Maya_Paris_1.png?v=1655718265" alt=""
                class="img-fluid">
              <h2>MAYA PARIS</h2>
              <h4>DOHA FESTIVAL CITY, Umm Salal Muhammed, Qatar</h4>

              <span style="display:inline-flex"><img alt="call logo"
                  src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/telephone.svg?v=1649143511"
                  class="img-fluid pe-2"> <a class="call" href="tel:+974 7726 6267" target="_blank"
                  rel="noreferrer noopener">+974 7726 6267</a></span>

              <a class="map_link" href="https://goo.gl/maps/KTRw39tNzn4JiUcA9" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>






          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Ambiance_2.png?v=1655718278" alt=""
                class="img-fluid">
              <h2>AMBIANCE</h2>
              <h4>DOHA FESTIVAL CITY, Umm Salal Muhammed, Qatar</h4>

              <a class="map_link" href="https://goo.gl/maps/NQ6PoxVEjYLvn1iF6" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>






          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Bernhardt_2.png?v=1655718288" alt=""
                class="img-fluid">
              <h2>BERNHARDT</h2>
              <h4>DOHA FESTIVAL CITY, Umm Salal Muhammed, Qatar</h4>

              <a class="map_link" href="https://goo.gl/maps/3QsDAjQf9gg1sdbo8" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>






          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="st_card">
              <img src="//cdn.shopify.com/s/files/1/0569/3643/1803/files/Eclipse_showroom.jpg?v=1657788910" alt=""
                class="img-fluid">
              <h2>ECLIPSE</h2>
              <h4>DOHA FESTIVAL CITY, UMM SALAL MUHAMMED, QATAR</h4>

              <a class="map_link" href="https://goo.gl/maps/3QsDAjQf9gg1sdbo8" target="_blank"
                rel="noreferrer noopener">Maps and Directions</a>
              <p class="shop_timing_heading my-1"><b>OPENING HOURS</b></p>
              <div class="shop_timeing">

                <div class="row my-1">
                  <p class="col-5">Monday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Tuesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Wednesday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Thursday</p>
                  <p class="col-7">9AM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Friday</p>
                  <p class="col-7">1PM - 11PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Saturday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>


                <div class="row my-1">
                  <p class="col-5">Sunday</p>
                  <p class="col-7">9AM - 10PM</p>
                </div>

              </div>
            </div>
          </div>




        </div>

      </div>

      <!-- Close page  -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import BreadCrumb from '@/components/common/BreadCrumb';
export default {
  name: "our-stores",
  data() {
    return {
      cmsData: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    document.title = this.$route.meta.title;
    this.getCMSData();
  },
  methods: {
    async getCMSData() {
      Api.getCMSData({ page: "terms" })
        .then((res) => {
          if (res.data.success) {
            this.cmsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
