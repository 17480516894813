<template>
    <div class="user_profile_section_col1_responsive  moblie_ham_burger w-100 col-2" id="moblie_ham">
        <div style="background-color:#ffffff">
            <div class="top_meanu col-12">
                <div class="inner_top_meanu">                   
                    <div  class="top_meanu_ul_section_responsive py-0 col-12">
                        <ul class="ul_list">
                            <li><div class="p-0 main_list_section" @click.prevent="togglePasswordDropVisibility()">TRENDING<img class="bropdown_btn mt-1" :src="imgdropSrc" style="width:20px ;height:14px"/></div>
                                <ul :class="{list_display: dropdown == true}" class="sub_menus" style="display:none">
                                    <li>data 1</li>
                                    <li>data 2</li>
                                </ul>
                            </li>
                            <li>BRANDS</li>
                            <li>FURNITURE</li>
                            <li>LIGHTING</li>
                            <li>CARPETS</li>
                            <li>KITCHEN & DINING</li>
                            <li>OUTDOOR</li>
                            <li>DECOR & MIRRORS</li>
                        </ul>
                    </div>               
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import "../../assets/css/my_profile.css";
import "../../assets/css/top_menus.css";
export default({
    name:'hamburger-left-meanu',
    data(){
        return{
            drop:false,
            dropdown:false,
        }
    },
    methods:{
        async regdrop(){
            if (this.drop === false ) {
                this.drop = true
                console.log("what")
            } else if (this.drop === true) {
                this.drop = false
            } {
                console.log("nothing")
            }
        },
        async togglePasswordDropVisibility() {
            if (this.dropdown == false) {
                this.dropdown = true
            }else if (this.dropdown == true ) {
                this.dropdown = false
            }else{
                this.dropdown = false
            }
        },
    },
    computed: {
        imgdropSrc: function () {
        return this.dropdown === true ? require("../../assets/image/dropdown_b.png") : require("../../assets/image/dropdown_b.png")
        },
    },
    props: [
        'active',
        'submeanuactive'
    ],
    setup() {
        
    },
    method:{
        async regdrop(){
            alert("hello")
            if (this.drop === false) {
                this.drop = true
                console.log("what")
            } else if (this.drop === true) {
                this.drop = false
            } {
                console.log("nothing")
            }
        }
    }
})
</script>