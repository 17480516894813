<template>
    <div class="col-lg-9">
        <form v-on:submit.prevent="supportSubmit">
            <div class="row">
                <div class="col-md-5 support_texts">
                    <div class="support_text_section">
                        <div>
                            <h2 class="ac_pg_head">Support</h2>
                            <div class="contact-note-dtls-sec">
                                <p class="txt_none">
                                    Our experts will help you with your inquiries, queries, and issues. Please feel free to reach us at +974 77266267 or
                                    email us at contact@thatsliving.com.
                                </p>
                                <p>
                                    <strong>EMAIL US :</strong> CONTACT@THATSLIVING.COM
                                </p>

                                <p>
                                    <strong>CALL US </strong> +974 77266267
                                </p>

                                <p><span>Available from 9AM - 6PM (GMT+3)</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="alert alert-success" v-if="success">{{ success }}</div>
                    <div class="alert alert-danger" v-if="errorShow" id="error_msg">
                        <ul>
                            <li v-for="(val, key) in errors" :key="key">
                                {{ errors[key][0] }}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="ac_pg_head cnfrm_head">Write to us</h2>
                        <div class="contact-note-dtls-sec frm_d">
                            <p class="txt_none">
                                Send us your queries or concerns in detail, and we will get back to you shortly at your registered email address.
                            </p>
                        </div>
                        <!-- <p>{{this.date_time}}</p> -->

                         <div class="dd_default ipt_fld">
                        <div class="input_field " data-bs-toggle="dropdown" aria-expanded="false">
                <label>Support Type*</label>
                <div class="dropdown dd_default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">{{(payLoad.supportType)?supportName:'Select Support Type'}}</button>
                  <ul class="dropdown-menu">
                    <li v-for="sup in supportTypeList" :key="sup.id">
                      <a @click="changeMode(sup)" class="dropdown-item">{{sup.supportType}}</a>
                    </li>
                  
                  </ul>
                </div>
                </div>
                
              </div>
                        <span v-if="v$.payLoad.supportType.$error" class="error_msg_log error_msg">
                            <span>{{
                            v$.payLoad.supportType.$errors[0].$message.replace(
                            "Value",
                            "Support Type"
                            )
                            }}</span></span>

                        <div class="input_field mt-3">
                            <label>SUBJECT *</label>
                            <input type="text" v-model="payLoad.subject" v-on="
                              payLoad.subject ? { change: v$.payLoad.subject.$validate } : {}
                            " />
                        </div>
                        <span v-if="v$.payLoad.subject.$error" class="error_msg">
                            <span>{{
                            v$.payLoad.subject.$errors[0].$message.replace(
                            "Value",
                            "Subject"
                            )
                            }}</span>
                        </span>
                        <!-- <div class="input_field mt-2">
                            <label>RIGHT TIME TO CALL DATE AND TIME</label>
                            <input type="datetime-local" v-model="date_time" />
                        </div> -->
                        <div class="input_field mt-3">
                            <label>MESSAGE</label>
                            <textarea class="border-0" v-model="payLoad.message" v-on="
                              payLoad.message ? { change: v$.payLoad.message.$validate } : {}
                            " rows="6" cols="50">
              </textarea>
                        </div>
                        <span v-if="v$.payLoad.message.$error" class="error_msg">
                            <span v-if="payLoad.message == ''">Message is required</span>
                            <span v-else>{{
                            v$.payLoad.message.$errors[0].$message.replace(
                            "FIELD",
                            "Message"
                            )
                            }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="text-end mb-3 mt-3">
                <button :class="{ 'disable-btn': isLoader }" :disabled="isLoader" class="btn_default">
                    <div v-if="isLoader">
                        <img width="30" src="../../../assets/image/loader.gif" />
                    </div>
                    <span v-if="!isLoader">SUBMIT</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import "./css/supports.css";
import router from "@/routes/routes";
import Api from "@/apis/User";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { Base64 } from "js-base64";
export default {
    name: "customer-support",
    data() {
        return {
            supportTypeList: [],
            payLoad: {
                userId: "",
                supportType: "",
                subject: "",
                message: "",
            },
            success: "",
            errorShow: false,
            errors: "",
        };
    },
    methods: {
        async cancel() {
            router.push("/user/order-details/" + this.$route.params.id);
        },
         async changeMode(mode){
            this.supportName = mode.supportType;
            this.payLoad.supportType = mode.id;
        },
        async getSupportTypes() {
            Api.getSupportTypes({ trash: 0 })
                .then((res) => {
                    if (res.data.success) {
                        this.supportTypeList = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async clearInputs() {
            this.payLoad.supportType = "";
            this.payLoad.subject = "";
            this.payLoad.message = "";
        },
        async supportSubmit() {
            this.errors = [];
            this.errorShow = false;
            this.v$.$validate(); // checks all inputs
            if (!this.v$.$error) {
                // if no fail validation
                this.isLoader = true;
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                this.payLoad.userId = userId;
                Api.submitTicket(this.payLoad)
                    .then((res) => {
                        this.isLoader = false;
                        this.clearInputs();
                        this.v$.payLoad.$reset();
                        if (res.data.success) {
                            this.success = res.data.message;
                            setTimeout(() => {
                                this.success = '';
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.errors = err?.response?.data?.error;
                        this.errorShow = true;
                        this.isLoader = false;
                    });
            }
        },
    },
    created() {
        window.scrollTo(0, 0);
        this.getSupportTypes();
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            payLoad: {
                supportType: { required },
                subject: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(100),
                    containsFirstNameRequirement: helpers.withMessage(
                        () => `Subject is invalid`,
                        (value) => /^[A-Za-z\s']+$/.test(value)
                    ),
                },
                message: {
                    required,
                    minLength: minLength(15),
                },
            },
        };
    },
};
</script>
