import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    getAddressList(otp){
        return ApiToken.post("/addressList", otp,  { headers: authHeader() })
    },
    addAddress(payLoad) {
        return ApiToken.post("/addAddress", payLoad,  { headers: authHeader() })
    },
    updateAddress(payLoad) {
        return ApiToken.put("/updateAddress", payLoad,  { headers: authHeader() })
    },
    deleteAddress(payLoad) {
        return ApiToken.post("/deleteAddress", payLoad,  { headers: authHeader() })
    },
    defualtAddress(payLoad) {
        return ApiToken.put("/updateDefaultAddress", payLoad,  { headers: authHeader() })
    },
}