<template>
  <!--  Header Start -->
  <BeforeLoginHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Order Success Sec -->
      <div class="row">
        <div class="col-lg-12">
          <!-- Order Success -->
          <div class="order-success-sec pgnotfnd-sec">
            <figure>
              <img alt="" src="../assets/image/404-error-img.png" />
            </figure>
            <p>Sorry This page you were looking for doesn't exist</p>

            <!-- <i class="bi bi-x-circle"></i> -->
            <div class="bck-to-shpng-btns">
              <div class="go-to-my-ordr-btn">
                <a href="#" class="btn btn_default" v-on:click.prevent="goBack">Back</a>
              </div>
              <div class="go-to-my-ordr-btn">
                <router-link class="btn btn_default" to="/home">Home</router-link>
              </div>
            </div>
          </div>
          <!-- Order Success -->
        </div>
      </div>
      <!-- Order Success Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import router from "@/routes/routes";
export default {
  name: "header-login",
  data() {
    return {
      datainfos: [],
      cartbadge: "0",
    };
  },
  components: {
    BeforeLoginHeader,
    CartFooter,
  },
  props: ["search", "usericons"],
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    goBack(){
      router.go(-2);
    }
  },
  setup() {},
};
</script>
