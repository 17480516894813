<template>
  <div class="forger_password_main_section">
    <LoginHeader search="none" usericons="none"/>
    <div class="forgot_password_main_div">
      <div class="inner_forgot_password_main_div">
        <div class="forgot_password_section">
          <div class="pt-5">
              <p><b>Login Successful</b></p>
              <button class="mt-lg-4 mt-md-3 mt-sm-2" @click.prevent="logout">Logout</button>
          </div>
        </div>
      </div>
    </div>
    <LoginFooter/>
  </div>
</template>
<script>
import LoginHeader from "@/components/LoginHeader";
import LoginFooter from "@/components/LoginFooter";
import Dashboard from "./Dashboard.vue";
import router from "@/routes/routes";
export default ({
  name:"app",
  data(){
    return{
      email:'',
      has_error: false

    }

  },
  methods:{
    logout() {
      localStorage.removeItem('user-token')
      router.push({name: 'Login'})
    }
  },
  setup() {

  },
  components:{
    LoginHeader,
    LoginFooter,
    // eslint-disable-next-line vue/no-unused-components
    Dashboard
  },
})
</script>
<style>
.border_color{
  border: 1px solid red;
}
</style>
