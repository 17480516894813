<template>
  <div class="col-lg-9 col-12">
    <div>
      <h2 class="ac_pg_head">MY PROFILE</h2>
      <h2 class="ac_head_bar">Business</h2>
      <div class="ac_det_cnt">
        <table>
          <tr>
            <td>Business type:</td>
            <td class="tb_data">{{ data.businessType }}</td>
          </tr>
        </table>
      </div>
      <div v-if="data.businessType !== 'INDIVIDUAL'" class="row">
        <div class="col-md-6">
          <h2 class="ac_head_bar">company Information</h2>
          <div class="ac_det_cnt">
            <table>
              <tr>
                <td>Company Name: </td>
                <td class="tb_data">
                  {{ data.companyName ? data.companyName : "--" }}
                </td>
              </tr>
              <tr>
                <td>Mobile: </td>
                <td class="tb_data">{{ '+'+data.mobile ? '+'+data.mobile : "--" }}</td>
              </tr>
              <tr>
                <td>Phone:</td>
                <td class="tb_data">{{ data.phone ? data.phone : "--" }}</td>
              </tr>
              <tr>
                <td>Email: </td>
                <td class="tb_data">
                  {{ data.userEmail ? data.userEmail : "--" }}
                </td>
              </tr>
              <tr>
                <td>Website URL: </td>
                <td v-html="website" class="tb_data"></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="ac_head_bar">Primary Contact Details</h2>
          <div class="ac_det_cnt">
            <table>
              <tr>
                <td>Person Name: </td>
                <td class="tb_data">
                  {{ data.contactPerson ? data.contactPerson : "--" }}
                </td>
              </tr>
              <tr>
                <td>Mobile Number: </td>
                <td class="tb_data" v-if="data.contactMobile != ''">
                  {{ '+'+data.contactMobile }}
                </td>
                <td class="tb_data" v-else>
                  --
                </td>
              </tr>
              <tr>
                <td>Email: </td>
                <td class="tb_data">
                  {{ data.contactEmail ? data.contactEmail : "--" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="data.businessType !== 'INDIVIDUAL'" class="">
        <h2 class="ac_head_bar">Company Address</h2>
        <div class="ac_det_cnt">
          <table>
            <tr>
              <td v-html="address"></td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="data.businessType == 'INDIVIDUAL'" class="row">
        <div class="col-md-6">
          <h2 class="ac_head_bar">PERSONAL DETAILS</h2>
          <div class="ac_det_cnt">
            <table>
              <tr>
                <td>Name: </td>
                <td class="tb_data">
                  {{ data.firstName + ' ' + data.lastName }}
                </td>
              </tr>
              <tr>
                <td>Email: </td>
                <td class="tb_data">
                  {{ data.userEmail ? data.userEmail : "--" }}
                </td>
              </tr>
              <tr>
                <td>Mobile Number: </td>
                <td class="tb_data">
                  {{ '+'+data.mobile ? '+'+data.mobile : "--" }}
                </td>
              </tr>
              <tr>
                <td>Phone Number: </td>
                <td class="tb_data">
                  {{ data.phone ? data.phone : "--" }}
                </td>
              </tr>
              <tr>
                <td>Website URL: </td>
                <td class="tb_data">
                  {{ data.websiteUrl ? data.websiteUrl : "--" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="ac_head_bar">PERSONAL ADDRESS</h2>
          <div class="ac_det_cnt">
            <table>
              <tr>
                <td v-html="invAddress"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <h2 v-if="data.businessType == 'BUSINESS'" class="ac_head_bar">
        Files Uploaded
      </h2>
      <div v-if="data.businessType == 'BUSINESS'" class="ac_det_cnt">
        <div class="row">
          <div v-if="data.businessType == 'BUSINESS'" class="col-md-6">
            <div class="ac_docs_list">
              <img :src="imgRegSrc" class="img-fluid" />
              <p class="">Commercial Registration</p>
              <a v-if="data.registrationDoc" :href="data.registrationDoc" target="_blank">
                <img src="../../../assets/image/download_icon.svg" class="img-fluid ac_dd_btn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "@/apis/User";
import { Base64 } from "js-base64";
import "./css/my_profile.css";
import check from "../../../assets/image/check_radio.svg";
import uncheck from "../../../assets/image/Uncheck_radio.svg";
export default {
  name: "my-profile",
  data() {
    return {
      data: [],
    };
  },
  setup() { },
  created() {
    window.scrollTo(0, 0);
    document.title = this.$route.meta.title;
    this.getUserInfo();
  },
  mounted() {
    //
  },
  methods: {
    async getUserInfo() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      User.getUserInfo({ userId: userId })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async downLoadReg() {
      if (this.data.registrationDoc) {
        window.open(this.data.registrationDoc, '_blank');
      }
    },
    async downLoadAuth() {
      if (this.data.authorisationDoc) {
        window.open(this.data.authorisationDoc, "_blank");
      }
    },
    async downloadFile(url) {
      var FILE = window.URL.createObjectURL(new Blob([url]));
      var docUrl = document.createElement('x');
      docUrl.href = FILE;
      docUrl.setAttribute('download', 'sample.pdf');
      document.body.appendChild(docUrl);
      docUrl.click();
    },
  },
  computed: {
    address: function () {
      let address = "";
      if (this.data.address1) address = this.data.address1;
      if (this.data.address2) address += "<br> " + this.data.address2;
      if (this.data.city) address += "<br> " + this.data.city;
      if (this.data.state) address += ", " + this.data.state;
      if (this.data.countryName) address += ", " + this.data.countryName;
      if (this.data.poBox) address += ", " + this.data.poBox;
      return address;
    },
    invAddress: function () {
      let address = "";
      if (this.data.address1) address = this.data.address1;
      if (this.data.address2) address += "<br> " + this.data.address2;
      if (this.data.city) address += "<br> " + this.data.city;
      if (this.data.state) address += ", " + this.data.state;
      if (this.data.countryName) address += ", " + this.data.countryName;
      if (this.data.poBox) address += ", " + this.data.poBox;
      return address;
    },
    website: function () {
      let website = "--";
      if (this.data.websiteUrl) {
        website =
          "<a href='" +
          this.data.websiteUrl +
          "' target='_blank'>" +
          this.data.websiteUrl +
          "</a>";
      }
      return website;
    },
    imgRegSrc: function () {
      return this.data.registrationDoc ? check : uncheck;
    },
    imgAuthSrc: function () {
      return this.data.authorisationDoc ? check : uncheck;
    },
  },
};
</script>
