<template>
  <div class="col-lg-9">
  <div class="alert alert-success" v-if="showdelMsg" id="error_msg">
        {{ delMsg }}
      </div>
    <h2 class="ac_pg_head">ROLES MANAGEMENT</h2>
    <div class="row">
      <div class="col-lg-8 order-lg-last">
        <button v-on:click.prevent="addRole()" class="btn_default rl_add_btn">
          ADD New Role
        </button>
      </div>
      <div class="col-lg-4">
        <div class="input-group inpt_bx mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Search By Role"
            v-model="roleName"
            v-on:keyup="searchRole('keyup')"
          />
          <button class="b_w_ust  btn_default" v-on:click="searchRole('')" type="button">SEARCH</button>
        </div>
      </div>
    </div>

    <div class="ac_det_cnt px-0">
      <table class="addr_bk">
        <thead>
          <th class="d-none d-xl-table-cell">SI.No</th>
          <th>Role</th>
          <th>User Access</th>
          <th class="ab_icn_cnt"></th>
        </thead>
        <tbody>
        <tr v-if="!(roleData.length>0)"><td colspan="4" style="text-align:center;"><img v-if="isLoader" width="30" src="../../../assets/image/loader.gif" />
            <span v-if="!isLoader">No Records Found</span> </td></tr>
          <tr v-for="(item, index) in roleData" :key="item.roleId" class="table_data_tr">
            <td class="responsive_none d-none d-xl-table-cell">{{ index + 1 }}</td>

            <td>{{ item.roleName }}</td>
            <td>
            <span v-for="(j, index) in item.rolePages" :key="j.id" >
            {{ j.pageName }}<span v-if="item.rolePages[index+1]?.id>0">, </span>
            </span>
            </td>
            <td>
              <div class="add_a_icns">
                <a v-on:click.prevent="editRole(item.roleId)" href="#" class="edit_icn"
                  ><img
                    src="../../../assets/image/edit_icon.svg"
                    class="img-fluid"
                  />
                  <span> Edit</span>
                </a>
                <a
                  href="#"
                  v-on:click.prevent="deleteRole(item.roleId)"
                  class="del_icn"
                  ><img
                    src="../../../assets/image/delete_icon.svg"
                    class="img-fluid"
                  />
                  <span> Delete</span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import router from "@/routes/routes";
import "./css/role_management.css";
import Api from "@/apis/Roles";
import { Base64 } from "js-base64";

export default {
  name: "User-Roles",
  data() {
    return {
      roleData: [],
      originalData: [],
      delMsg: "Deleted Successfully",
      showdelMsg: false,
      roleName: '',
      isLoader: true,
    };
  },
  created() {
    window.scrollTo(0,0);
    this.getRolesList();
  },
  methods: {
    async searchRole(type){
        if((this.roleName && type!='keyup') || (type=='keyup' && this.roleName.length>2)){
          this.roleData = this.originalData.filter((item) => item.roleName.toLowerCase().indexOf(this.roleName.toLowerCase()) > -1);
        }else{
          this.roleData = this.originalData;
        }
    },
    async getRolesList() {
      this.isLoader = true;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getRolesList({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.originalData = this.roleData = res.data.data;
          }
          this.isLoader = false;
        })
        .catch((err) => {
          this.isLoader = false;
          console.log(err);
        });
    },
    async deleteRole(roleId) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let text = "Are you sure you want to delete?";
      if (confirm(text) == true) {
        //debugger; // eslint-disable-line no-debugger
        Api.deleteRole({ userId: userId, roleId: roleId })
          .then((res) => {
            if (res.data.success) {
              this.showdelMsg = true;
              this.getRolesList();
              setTimeout(() => {
                this.showdelMsg = false;
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async addRole(){
       router.push("/user/add-role")
    },
    async editRole(id){
       router.push({ name: 'EditRole', params: { id: id }})
    }
  },
  setup() {},
};
</script>
