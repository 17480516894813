import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    getProjectList(data){
        return ApiToken.post("/projectClients", data,  { headers: authHeader() })
    },
    addProject(payLoad) {
        return ApiToken.post("/addProjectClient", payLoad,  { headers: authHeader() })
    },
    updateProjectClient(payLoad) {
        return ApiToken.post("/updateProjectClient", payLoad,  { headers: authHeader() })
    },
    projectClientDelete(payLoad) {
        return ApiToken.post("/projectClientDelete", payLoad,  { headers: authHeader() })
    },
    defaultProjectDetails(payLoad) {
        return ApiToken.post("/defaultProjectDetails", payLoad,  { headers: authHeader() })
    },
    makeDefaultProject(payLoad) {
        return ApiToken.post("/makeDefaultProject", payLoad,  { headers: authHeader() })
    },
    projectImageDelete(payLoad) {
        return ApiToken.post("/projectImageDelete", payLoad,  { headers: authHeader() })
    },
    projectDetails(payLoad) {
        return ApiToken.post("/projectDetails", payLoad,  { headers: authHeader() })
    },
}