<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Privacy policy Sec -->

      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->
      <!-- Start page  -->
      <h2 class="standard-title heading_Strike">
        <span>About That's Living</span>
      </h2>
      <div class=" row align-items-center">
        <div class="col-lg-6">
          <div class="video_cnt" id="video_cnt">
            <iframe style="width:100%" height="400"
              src="https://www.youtube.com/embed/PKrQezD5zes?autoplay=1&amp;mute=1&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;color=white"
              title="YouTube video player" autoplay="1" frameborder="0" allowfullscreen=""></iframe>
          </div>
        </div>
        <div class="col-lg-6 ps-lg-5">
          <hr class="a_t_bar rt_bar">
          <p class="mb-4 abt_p1">That's Living is an international name in home fashion and a global leader in
            manufacturing and designing
            quality furniture partnered for growth. We enjoy a strong physical presence in over 28 flagship stores in
            Qatar, Russia, and Poland. Headquartered in Qatar, we are located in Doha Festival City with a 5,820 SQM
            showroom and Tawar Mall with a 4,460 SQM showroom. Our pieces have European elegance and American comforts,
            making the collection a beautiful blend of classic and contemporary edits.</p>
          <div class="center-text">
            <a class="btn_default bd_i_btn"
              href="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/That_s_Living_Profile_1.7.pdf?v=1651031992"
              target="_blank" rel="noreferrer noopener">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve">
                <g id="Layer_1">
                  <polygon
                    points="24,25 24,44.586 19.707,40.293 18.293,41.707 25,48.414 31.707,41.707 30.293,40.293 26,44.586 26,25  ">
                  </polygon>
                  <path
                    d="M40.996,17.2C40.999,17.133,41,17.066,41,17c0-3.09-2.349-5.643-5.354-5.965C34.286,5.172,29.095,1,23,1   c-7.168,0-13,5.832-13,13c0,0.384,0.02,0.775,0.06,1.18C4.902,16.106,1,20.669,1,26c0,6.065,4.935,11,11,11h8v-2h-8   c-4.963,0-9-4.038-9-9c0-4.651,3.631-8.588,8.267-8.962l1.091-0.088l-0.186-1.078C12.057,15.198,12,14.586,12,14   c0-6.065,4.935-11,11-11c5.393,0,9.95,3.862,10.836,9.182l0.145,0.871l0.882-0.036C34.925,13.015,34.986,13.008,35,13   c2.206,0,4,1.794,4,4c0,0.272-0.03,0.553-0.091,0.835l-0.235,1.096l1.115,0.109C43.9,19.442,47,22.864,47,27c0,4.411-3.589,8-8,8   h-9v2h9c5.514,0,10-4.486,10-10C49,22.175,45.624,18.128,40.996,17.2z">
                  </path>
                </g>
                <g></g>
              </svg>
              Company Profile
            </a>
          </div>
          <hr class="a_t_bar lt_bar">
        </div>
      </div>


      <section>
        <div class="row align-items-center">
          <div class="col-md-6 col-sm-12">
            <h4>Furniture That Reflects Your Personality</h4>
            <hr class="ttle_undr">
            <p class="abt_pslty">The idea of creating a piece of furniture at That's Living is to reflect your
              personality. More
              than a unit, it should define you and your personal space. With years of experience in making unique
              designs, we assure that your purchase at That's Living will have:
            </p>
            <ul class="ps-4 check_lst_icn">
              <li>Irresistible visuals</li>
              <li>Quality craftsmanship</li>
              <li>Durability</li>
              <li>Regular utilization</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <img alt="" src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/aboutus_img4.png?v=1650978658"
                class="col-6 img-fluid">
              <img alt="" src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/aboutus_img3.png?v=1650978658"
                class="col-6 img-fluid">
              <img alt="" src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/aboutus_img2.png?v=1650978658"
                class="col-6 img-fluid mt-4">
              <img alt="" src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/aboutus_img1.png?v=1650978658"
                class="col-6 img-fluid mt-4">
            </div>
          </div>
        </div>
      </section>


      <section>
        <div class="row align-items-center">
          <div class="col-md-6">
            <img alt="" src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/aboutus_pg.png?v=1650889268"
              class="img-fluid">
          </div>
          <div class="col-md-6">
            <p class="abt_pslty">From standalone pieces to complete sets, you can choose from a wide range of products.
              We have exclusive
              assortments of:</p>
            <ul class="ps-4 check_lst_icn">
              <li>Exquisite furniture for the living room, dining room, bedroom, and outdoor.</li>
              <li>Kitchen &amp; dining</li>
              <li>Home decor</li>
              <li>Lighting, mirrors, &amp; carpets</li>
            </ul>
            <router-link to="/contactus"><button class="btn_default mt-4">Contact Us</button></router-link>
          </div>
        </div>
      </section>


      <section>
        <h2 class="standard-title heading_Strike">
          <span>Visit us</span>
        </h2>
        <div class=" row align-items-center">
          <div class="col-lg-6">
            <img src="https://cdn.shopify.com/s/files/1/0569/3643/1803/files/TLQ_2684.jpg?v=1650890694"
              alt="store image" class="img-fluid">
          </div>
          <div class="col-lg-6 ps-lg-5">
            <h4 class="pe-4 vst_head">Visit Us And Experience Quality Furniture On Your Own!</h4>
            <hr class="ttle_undr">
            <p  class="abt_pslty">The idea of home furnishings has changed a lot in the last couple of years. While our digital platform,
              <a href="https://thatsliving.com/"> www.thatsliving.com</a>,
              is making lifestyles beautiful and relaxing, our physical presence in Doha Festival City and Tawar Mall
              showrooms lets you experience the unmatched quality on your own. The new range comes every now and then,
              and you can also consult our design experts for your predefined requirements. Visit us today for a
              delightful shopping experience.
            </p>
            <router-link to="/our-stores"><button class="btn_default mt-4 mb-2">Our Stores</button></router-link>
          </div>
        </div>
      </section>
      <!-- Close page  -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import BreadCrumb from '@/components/common/BreadCrumb';
export default {
  name: "about-us",
  data() {
    return {
      cmsData: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    document.title = this.$route.meta.title;
    this.getCMSData();
  },
  methods: {
    async getCMSData() {
      Api.getCMSData({ page: "terms" })
        .then((res) => {
          if (res.data.success) {
            this.cmsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
