<template>
  <div class="individual_company_details">
    <BeforeLoginHeader />
    <div class="company_detail_body_part">
      <div class="company_detail_body_part container-xl">
        <form
          class="mt-lg-5 mt-md-4 my-4"
          v-on:submit.prevent="individualCompany"
        >
          <p>{{ this.radio_value }}</p>
          <div class="business_type_section mb-md-3 mb-4">
            <h4>ACCOUNT TYPE</h4>
            <div>
              <input
                name="select"
                @change="onChange($event)"
                v-model="this.radio_value"
                checked
                value="INDIVIDUAL"
                id="b_type_freelance"
                type="radio"
              /><label class="ms-2" for="b_type_freelance">Individual</label>
              <input
                name="select"
                @change="onChange($event)"
                v-model="this.radio_value"
                value="BUSINESS"
                id="b_type_business"
                class="ms-4"
                type="radio"
              /><label class="ms-2" for="b_type_business">Business</label>
            </div>
          </div>
          <div
            class="alert alert-danger"
            v-if="error_mobile || error_website || error_phone || error_state"
            id="error_msg"
          >
            <ul>
              <li>{{ error_mobile }}</li>
              <li>{{ error_website }}</li>
            </ul>
          </div>
          <div class="alert alert-danger" v-if="errors_msg" id="error_msg1">
            {{ errors_msg }}
          </div>
          <div class="alert alert-success" v-if="success">{{ success }}</div>
          <h4 class="mt-lg-4 mt-md-3">PERSONAL DETAILS</h4>
          <div
            class="
              row
              company_details_row
              contact_persion_details_row
              company_details_row_input
              company_address_row
            "
          >
<!--            <div class="col-md-4 mb-3">-->
<!--              <label>MOBILE*</label>-->
<!--              <input-->
<!--                :class="{ border_color: iserror.cemail }"-->
<!--                v-on="-->
<!--                  individual.mobile-->
<!--                    ? { change: v$.individual.mobile.$validate }-->
<!--                    : {}-->
<!--                "-->
<!--                type="text"-->
<!--                v-model="individual.mobile"-->
<!--              />-->
<!--              <span-->
<!--                v-if="v$.individual.mobile.$error"-->
<!--                class="error_msg_log error_msg"-->
<!--              >-->
<!--                <span v-if="individual.mobile == ''"-->
<!--                  >Mobile number is required</span-->
<!--                >-->
<!--                <span v-else>{{-->
<!--                  v$.individual.mobile.$errors[0].$message-->
<!--                }}</span>-->
<!--              </span>-->
<!--            </div>-->
            <div class="col-md-4 col-sm-12 mb-3">
              <div class="ipt_group">
<!--                <div class="input_field">-->
<!--                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE*</label>-->
<!--                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"-->
<!--                                                             data-bs-toggle="dropdown" aria-expanded="false">{{(individual.phoneCode)?phoneCode:'00'}}</button>-->
<!--                      <ul class="dropdown-menu" style="">-->
<!--                        <li v-for="country in countries" :key="country.phoneCode">-->
<!--                          <a @click="changePhoneCodeMode(country)" class="dropdown-item">{{country.phoneCode}}</a>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
                 
<!--                  <select-->
<!--                          v-model="individual.phoneCode"-->
<!--                          v-on="-->
<!--                  individual.phoneCode-->
<!--                    ? { change: v$.individual.phoneCode.$validate }-->
<!--                    : {}-->
<!--                "-->
<!--                          class="bg-white mbl_ddn"-->
<!--                  >-->
<!--                    <option-->
<!--                            v-for="country in countries"-->
<!--                            :key="country.phoneCode"-->
<!--                            :value="country.phoneCode"-->
<!--                    >-->
<!--                      +{{ country.phoneCode }}-->
<!--                    </option>-->
                  <div class="dd_default ipt_fld indi_country">
                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                    <label>CODE*</label>
                      <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"
                                                               data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> {{(individual.phoneCode)?'+'+phoneCode:'+00'}}</button>
                          <ul class="dropdown-menu" style="">
                              <li v-for="code in phoneCodedate" :key="code.phoneCode">
                                  <a @click="changePhoneCodeMode(code)" class="dropdown-item"><img :src="code.countryImage">  +{{code.phoneCode}}</a>
                              </li>
                          </ul>
                      </div>
                      </div>
                      </div>


<!--                  </select>-->
<!--                </div>-->
                <div class="mbl_input">
                  <label>MOBILE NUMBER*</label>
                  <input type="text" v-model="individual.mobile" :class="{ border_color: iserror.cemail }"
                         v-on="individual.mobile ? { change: v$.individual.mobile.$validate }: {}"/>
                </div>

              </div>
              <span v-if="v$.individual.phoneCode.$error" class="error_msg_log error_msg">
              <span v-if="individual.phoneCode == ''">Phone Code is required</span>
              <span v-else>{{ v$.individual.phoneCode.$errors[0].$message }}</span>
            </span>
              <span
                      v-if="v$.individual.mobile.$error"
                      class="error_msg_log error_msg"
              >
              <span v-if="individual.mobile == ''">Mobile Number is required</span>
              <span v-else>{{ v$.individual.mobile.$errors[0].$message }}</span>
            </span>

            </div>




            <div class="col-md-4 mb-3">
              <label>PHONE</label>
              <input
                :class="{ border_color: iserror.cemail }"
                type="text"
                v-model="individual.phone"
              />
            </div>

            <div class="col-md-4 mb-4">
              <label>WEBSITE URL</label>
              <input
                :class="{ border_color: iserror.ctaxid }"
                v-on="
                individual.websiteUrl
                  ? { change: v$.individual.websiteUrl.$validate }
                  : {}
              "
                type="text"
                v-model="individual.websiteUrl"
              />
              <span v-if="v$.individual.websiteUrl.$error" class="error_msg_log error_msg">
<!--              <span v-if="v$.individual.websiteUrl.$error" class="error_msg_log error_msg">-->
                <span v-if="individual.websiteUrl != ''">{{
                v$.individual.websiteUrl.$errors[0].$message
                }}</span>
              </span>

            </div>
          </div>
          <h4 class="mt-lg-4 mt-md-3">PERSONAL ADDRESS</h4>
          <div
            class="
              row
              company_details_row company_address_row company_details_row_input
            "
          >
            <div class="col-md-4 col-sm-12 mb-3">
              <label>STREET ADDRESS LINE 1*</label>
              <input
                :class="{ border_color: iserror.caddress1 }"
                v-on="
                  individual.streetAddressOne
                    ? { change: v$.individual.streetAddressOne.$validate }
                    : {}
                "
                type="text"
                v-model="individual.streetAddressOne"
              />
              <span
                v-if="v$.individual.streetAddressOne.$error"
                class="error_msg_log error_msg"
              >
                <span v-if="individual.streetAddressOne == ''"
                  >Street address line 1 is required</span
                >
                <span v-else>{{
                  v$.individual.streetAddressOne.$errors[0].$message
                }}</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mb-3">
              <label>STREET ADDRESS LINE 2</label>
              <input
                :class="{ border_color: iserror.caddress2 }"
                type="text"
                v-model="individual.streetAddressTwo"
              />
            </div>
            <div class="col-md-4 col-sm-12 mb-3">
              <label>COUNTRY*</label>
              <select
                v-model="individual.country"
                v-on="
                  individual.country
                    ? { change: v$.individual.country.$validate }
                    : {}
                "
                class="bg-white"
              >
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <span
                v-if="v$.individual.country.$error"
                class="error_msg_log error_msg"
              >
                <span v-if="individual.country == ''">Country is required</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-2 mt-md-2 mb-3">
              <label>STATE / PROVINCE</label>
              <input
                :class="{ border_color: iserror.cstate }"
                type="text"
                v-model="individual.state"
              />
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-2 mt-md-2 mb-3">
              <label>CITY*</label>
              <input
                :class="{ border_color: iserror.ccity }"
                v-on="
                  individual.city ? { change: v$.individual.city.$validate } : {}
                "
                type="text"
                v-model="individual.city"
              />
              <span
                v-if="v$.individual.city.$error"
                class="error_msg_log error_msg"
              >
                <span v-if="individual.city == ''">City is required</span>
                <span v-else>{{ v$.individual.city.$errors[0].$message }}</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-2 mt-md-2 mb-3">
              <label>PO BOX</label>
              <input
                :class="{ border_color: iserror.ccountry }"
                type="text"
                v-model="individual.poBox"
              />
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-2 mt-md-2 mb-3">
              <label>PROOF OF IDENTITY*</label>
              <input
                :class="{ border_color: iserror.ccountry }"
                v-on="
                  individual.proofOfIdentiy
                    ? { change: v$.individual.proofOfIdentiy.$validate }
                    : {}
                "
                type="text"
                v-model="individual.proofOfIdentiy"
              />
              <p class="below_input_msg mt-1">
                Please enter your Govt ID details here.
              </p>
              <span
                v-if="v$.individual.proofOfIdentiy.$error"
                class="error_msg_log error_msg"
              >
                <span v-if="individual.proofOfIdentiy == ''"
                  >Proof of identity is required</span
                >
              </span>
            </div>
          </div>
          <div class="ad_btns_cnt">
            <button @click="cancel" class="btn_outline">CANCEL</button
          >
            <button
              :disabled="isLoader"
              :class="{ 'disable-btn': isLoader }"
              class="btn_default"
            >
            <div v-if="isLoader">
              <img width="30" src="../../../assets/image/loader.gif" />
            </div>
              <span  v-if="!isLoader">SUBMIT DETAILS</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import LoginFooter from "@/components/LoginFooter";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import Master from "@/apis/Master";
import router from "@/routes/routes";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import User from "@/apis/User";
import { Base64 } from "js-base64";
export default {
  name: "Individual-company-details",
  data() {
    return {
      interval: null,
      individual: {
        userId: '',
        mobile: "",
        phone: "",
        websiteUrl: "",
        streetAddressOne: "",
        streetAddressTwo: "",
        state: "",
        city: "",
        poBox: "",
        country: "",
        phoneCode: "",
        proofOfIdentiy: "",
      },
      error_mobile: "",
      error_website: "",
      error_state: "",
      error_streetAddressOne: "",
      errors_msg: "",
      success: "",
      countries: [],
        phoneCodedate: [],
      radio_value: "",
      iserror: {
        companyname: false,
        cmobile: false,
        cphone: false,
        cemail: false,
        cweb: false,
        ctaxid: false,
        caddress1: false,
        caddress2: false,
        ccity: false,
        cstate: false,
        ccountry: false,
        czip: false,
      },
      isLoader: false,
    };
  },
  components: {
    LoginFooter,
    BeforeLoginHeader,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      individual: {
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile number is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        websiteUrl: {
          required: false,
          containsFirstNameRequirement: helpers.withMessage(
                  () => `Website URL is invalid`,
                  (value) =>
                          /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g.test(
                                  value
                          )
          ), },
        streetAddressOne: { required },
        country: { required },
        phoneCode: { required },
        city: {
          required,
          minLength: minLength(3),
          containsFirstNameRequirement: helpers.withMessage(
            () => `City is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        proofOfIdentiy: { required },
      },
    };
  },
  methods: {
    async cancel() {
      localStorage.clear();
      router.push({ name: 'Login' });
    },

    async changePhoneCodeMode(data){
      this.phoneCode = data.phoneCode;
        this.countryImage = data.countryImage;
      this.individual.phoneCode = data.phoneCode;
    },
    async individualCompany() {
      this.v$.individual.mobile.$validate();
      this.v$.individual.phoneCode.$validate();
      this.v$.individual.streetAddressOne.$validate();
      this.v$.individual.country.$validate();
      this.v$.individual.city.$validate();
      this.v$.individual.proofOfIdentiy.$validate();
      if (!this.v$.individual.phoneCode.$error && !this.v$.individual.mobile.$error && !this.v$.individual.streetAddressOne.$error && !this.v$.individual.country.$error && !this.v$.individual.city.$error && !this.v$.individual.proofOfIdentiy.$error) {
        this.isLoader = true;
        // if no fail validation
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        this.individual.userId = userId,
        User.companyIndividual(
                {
                  userId: this.individual.userId,
                  mobile: this.individual.mobile,
                  phoneCode: this.individual.phoneCode,
                  phone: this.individual.phone,
                  websiteUrl: this.individual.websiteUrl.toLowerCase(),
                  streetAddressOne:this.individual.streetAddressOne,
                  streetAddressTwo: this.individual.streetAddressTwo,
                  state: this.individual.state,
                  city: this.individual.city,
                  poBox: this.individual.poBox,
                  country: this.individual.country,
                  proofOfIdentiy: this.individual.proofOfIdentiy,
                }

        )
          .then((res) => {
            debugger; //eslint-disable-line
            console.log(res);
            this.isLoader = false;
            localStorage.setItem("profileStatus",1);
            sessionStorage.setItem("profileStatus",1);
            this.success = res.data.message;
            setTimeout(() => {
              this.isLoader = true;
              this.$router.push({ name: "DocumentVerificationMsg" });
            }, 3000);
            sessionStorage.setItem("companyStatus", 1);
            localStorage.setItem("companyStatus", 1);
          })
          .catch((err) => {
            this.isLoader = false;
            this.errors_msg = err?.response?.data?.errors;
            if(err?.response?.data?.error?.websiteUrl)
              this.error_website = err?.response?.data?.error?.websiteUrl[0];
            if(err?.response?.data?.error?.phone)
              this.error_mobile = err?.response?.data?.error?.phone[0];
            if(err?.response?.data?.error?.state)
              this.error_mobile = err?.response?.data?.error?.state[0];
          });
        console.log("Form successfully submitted.");
      } else {
        console.log("Form failed validation");
      }
    },
    onChange(event) {
      var data = event.target.value;
      if (data === "INDIVIDUAL") {
        router.push({ name: "CompanyDetailsIndividual" });
      } else {
        router.push({ name: "CompanyDetailsBusiness" });
      }
      console.log(data);
    },
    getCountries: function () {
      Master.country().then(
        function (response) {
          this.countries = response.data.data;
        }.bind(this)
      );
    },
      phoneCodeData: function () {
          Master.phoneCode().then(
              function (response) {
                  this.phoneCodedate = response.data.data;
              }.bind(this)
          );
      },
  },
  created() {
    document.title = this.$route.meta.title;
    this.getCountries();
    this.phoneCodeData();
  },
};
</script>
<style>
@import "../../../assets/css/company_details_business.css";
.border_color {
  border: 1px solid red;
}
</style>