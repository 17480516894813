<template>
    <div class="user_profile_drop_down  w-100">
        <table class="w-100">
            <tr>
                <td ><router-link to="/user/profile"> <button :class="{'btn_background' : active == 'myprofile'}" class="ps-4 responsive_menu_btn">My Profile</button></router-link> </td>
            </tr>
            <tr>
                <td><router-link to="/user/address-book"><button :class="{'btn_background' : active == 'addressbook'}" class="ps-4 responsive_menu_btn">Address Book</button></router-link></td>
            </tr>
            <tr>
                <td><router-link to="/user/orders"> <button :class="{'btn_background' : active == 'order'}" class="ps-4 responsive_menu_btn">Orders</button> </router-link></td>
            </tr>
            <tr>
                <td>
                    <button :class="{'btn_background' : active === 'userrole'}" class="responsive_menu_btn" v-on:click.prevent="regdrop()" >
                        <span> User Roles</span><span class="dropdown_img"></span>
                    </button>
                </td>
            </tr>
            <tr :class="{'v_hiden' : drop == false}"  class="hover_visible">
                <td><router-link to="/user/roles"> <button :class="{'sub_meanu_active' : submeanuactive === 'roleMg'}" class="ps-4 responsive_menu_btn sub_menu_responsive">Roles Management</button> </router-link></td>
            </tr>
            <tr :class="{'v_hiden' : drop == false}" class="hover_visible">
                <td><router-link to="/user/user-management"> <button :class="{'sub_meanu_active' : submeanuactive === 'userMg'}" class="ps-4 responsive_menu_btn sub_menu_responsive">User Management</button> </router-link></td>
            </tr>
            <tr>
                <td><router-link to="/user/change-password"> <button :class="{'btn_background' : active == 'changepassword'}" class="ps-4 responsive_menu_btn">Change Password</button></router-link> </td>
            </tr>
            <tr>
                <td><router-link to="/user/support"> <button :class="{'btn_background' : active == 'support'}" class="ps-4 responsive_menu_btn">Support</button></router-link> </td>
            </tr>
            <tr>
                <td><button @click="logout" :class="{'btn_background' : active == 'signout'}" class="ps-4 responsive_menu_btn">Sign Out</button></td>
            </tr>
        </table>
    </div>
</template>
<script>
import "./css/user_profile_header.css";
import router from "@/routes/routes";
export default({
    name:'user-profile-drop-down',
    data(){
        return{
            drop:false,
        }
    },
    created(){
        console.log("hello")
        if(this.submeanuactive === "roleMg" || this.submeanuactive === "userMg"){
            this.drop = true
        }
        console.log(this.submeanuactive)
        console.log(this.drop)
    },
    methods:{
        logout() {
            localStorage.clear();
            router.push({name: 'Login'});
        },
        async regdrop(){
            if (this.drop === false ) {
                this.drop = true
                console.log("what")
            } else if (this.drop === true) {
                this.drop = false
            } {
                console.log("nothing")
            }
        }
    },
    props: [
        'active',
        'submeanuactive'
    ],
    setup() {
        
    },
    method:{
        async regdrop(){
            alert("hello")
            if (this.drop === false) {
                this.drop = true
                console.log("what")
            } else if (this.drop === true) {
                this.drop = false
            } {
                console.log("nothing")
            }
        }
    }
})
</script>
