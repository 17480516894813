<template>
  <CartHeader />
  <section class="contact_sec">
    <div class="page-width">
      <!-- Order Success Sec -->
      <div class="row">
        <div class="col-lg-12">
          <!-- Order Success -->
          <div class="order-success-sec">
            <i class="bi bi-check-circle"></i>
            <h6>Thank You!</h6>
            <p>Your order has been successfully placed!</p>
            <p>Order Date : <strong>{{currentDate}}</strong></p>
            <p>Order ID : {{order.orderID ? order.orderID : order.orderId }}</p>
            <p>Order Qty : {{ order.count ? order.count : order.orderQty}}</p>
            <!-- <i class="bi bi-x-circle"></i> -->
            <div class="bck-to-shpng-btns">
              <div class="go-to-my-ordr-btn">
                <router-link to="/user/orders/" class="btn btn_default" @click="removeCartData">My Orders</router-link>
              </div>
              <div class="go-to-my-ordr-btn">
                <router-link to="/home" class="btn btn_outline" @click="removeCartData">Home</router-link>
              </div>
            </div>
          </div>
          <!-- Order Success -->
        </div>
      </div>
      <!-- Order Success Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import moment from 'moment'
export default {
  name: "order-success",
  props: ["cartData"],
  data() {
    return {
      order : "",
      currentDate: moment(new Date()).format('DD-MM-YYYY'),
    };
  },
  components: {
    CartHeader,
    CartFooter,
  },
  async mounted() {
    console.log("Cart Dataaa", this.cartData);
  },
  created() {
    document.title = this.$route.meta.title;
    this.order = JSON.parse(localStorage.getItem("cartData"))
  },
  setup() {},
  computed: {
    itemsCount: function () {
      let data = JSON.parse(localStorage.getItem("cartData"))
      let count = 0;
      if (data.cartItems) {
        count = data.count;
      }
      return count;
    },
  },
  methods:{
    removeCartData(){
      localStorage.removeItem("cartData")
    }
  }
};
</script>
