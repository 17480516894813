<template>
  <section class="breadcrumb_sec">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/home">Home</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">
              {{currentTitle}}
            </li>
          </ol>
        </nav>
      </section>
</template>

<script>
export default {
  name: "bread-crumb",
  data() {
    return {
      previousUrl: '',
      currentTitle: '',
    };
  },
  created() {
    this.previousUrl = window?.previousUrl;
    this.previousTitle = window?.previousTitle?.replace('B2B - ', '');
    this.currentTitle = this.$route.meta.title.replace('B2B - ', '');
  },
  methods: {
  },
  setup() {},
};
</script>

