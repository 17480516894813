<template>
    <div class="unauthorized_access">
        <BeforeLoginHeader/>
        <div class="account_activation_body_part"  style="padding-top: 80px">
            <div class="inner_account_activation_body_part container">
                <form class="mt-lg-5 mt-md-4 mt-5">
                    <div class="account_activation_box p-4">
                        <div class=" inner_account_activation_box mt-lg-4 mt-md-3">
                            <img src="../../../assets/image/cross1.png" class="image-fluid" alt="">
                            <h5 class="mt-lg-4 mt-md-2 mt-3">Unauthorized Access!</h5>
                        </div>
                    </div>
                </form>
                <p class="mt-lg-4 mt-md-3 mt-4">
                <router-link to="/">RETURN TO LOGIN</router-link>
                </p>
            </div>
        </div>
        <LoginFooter/>
    </div>
</template>

<script>
    import BeforeLoginHeader from "@/components/BeforeLoginHeader";
    import LoginFooter from "@/components/LoginFooter";


    export default ({
        name:'account-verification',
        data() {
            return {
                //
            };
        },
        components:{
            LoginFooter,
            BeforeLoginHeader
        },
        mounted(){
            //
        },
        created() {
            document.title = this.$route.meta.title;
        },
        computed(){
        }
    })
</script>
<style>
    @import "../../../assets/css/account_activation.css";
</style>
