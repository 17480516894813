<template>
  <div class="account_activation">
    <BeforeLoginHeader />
    <div class="account_activation_body_part">
      <div class="inner_account_activation_body_part container">
        <form class="mt-lg-5 mt-md-4 mt-5">
          <div class="account_activation_box p-4">
            <div class=" inner_account_activation_box mt-lg-4 mt-md-3">
              <img src="../../../assets/image/right.gif" class="image-fluid" alt="">
              <h5 class="mt-lg-4 mt-md-2 mt-3 txt_none">Email Verified</h5>
            </div>
            <p class="mt-4 txt_none">
              Congratulations, you are now registered with us! You will be redirected to the login page shortly.
            </p>
          </div>
        </form>

      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
// import User from "../../../apis/User";
// import { Base64 } from "js-base64";


export default ({
  name: 'account-verification',
  data() {
    return {
      url_data: null,
    };
  },
  components: {
    LoginFooter,
    BeforeLoginHeader
  },
  mounted() {
    this.url_data = this.$route.params.id;
    sessionStorage.setItem('id', this.url_data);
  },
  created() {
    document.title = this.$route.meta.title;
    setTimeout(() => this.$router.push({ name: 'Login' }), 5000);


    // if (window.$cookies.get('registerUserId') == null){
    //   this.$router.push({name: "Error"})
    //   localStorage.removeItem("profileStatus")
    // }else{
    //   setTimeout(() => this.$router.push({ name: 'Login' }), 5000);
    // }
    // setTimeout(() => this.$router.push({ name: 'CompanyDetailsIndividual', params: { id: Base64.decode(this.url_data) } }), 5000);



  },
  computed() {
  }
})
</script>
<style>
@import "../../../assets/css/account_activation.css";
</style>
