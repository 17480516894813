<template>
    <div class="col-md-9 col-12 user_order_t_section_col2 py-md-0">
        <div class="alert alert-success" v-if="success">{{ success }}</div>
        <div class="coupon-card-sec">
            <h2 class="ac_pg_head">Price Setting</h2>
            <div class="coupon-card-lst">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="cpn_itm">
                            <form v-on:submit.prevent="priceSetting">
                            <div>
                                <h2 class="ac_head_bar">PRICE DISPLAY ON WEB</h2>
                                <div class="mt-3">
                                    <input
                                            name="select"
                                            @change="onChange($event)"
                                            value="0"
                                            :checked="this.setting.display == 0"
                                            id="yes"
                                            type="radio"
                                            class="ms-3"
                                    /><label class="ms-2" for="yes">Yes</label>
                                    <input
                                            name="select"
                                            @change="onChange($event)"
                                            value="1"
                                            id="no"
                                            class="ms-4"
                                            :checked="this.setting.display == 1"
                                            type="radio"
                                    /><label class="ms-2" for="no"
                                >No</label
                                >
                                </div>
                                <div class="ad_btns_cnt">
                                    <button
                                            :disabled="isLoader"
                                            :class="{ 'disable-btn': isLoader }"
                                            class="btn_default"
                                    >
                                        <div v-if="isLoader">
                                            <img width="30" src="../../../assets/image/loader.gif" />
                                        </div>
                                        <span  v-if="!isLoader"> Submit </span>
                                    </button>

                                </div>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import "./css/priceSetting.css";
    import "./css/user_address.css";
    import { Base64 } from "js-base64";
    import User from "../../../apis/User";
    export default {
        name: "price-setting",
        data() {
            return {
                setting: {
                    display:"0"
                },
                success: "",
                globalReadOnlyProperty: "",
                isLoader: false,
            };
        },
        created() {
            //
        },
        mounted() {
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);
            User.priceDisplay({ userId: userId })
                .then((res) => {
                    if (res.data.success) {
                        this.data = res.data.data;
                        this.setting.display = res.data.data.priceDisplay
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        methods: {
            onChange(event) {
                this.setting.display = event.target.value;
            },
            async priceSetting() {
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                User.priceDisplaySetup({
                        userId: userId,
                        priceDisplay:this.setting.display
                    })
                    .then((res) => {
                        console.log(res);
                        this.isLoader = false;
                        this.success = res.data.message;
                        localStorage.setItem("priceBool", this.setting.display)
                    })
                    .catch((err) => {
                        this.isLoader = false;
                        console.log(err);
                    });
            },
        },
        setup() { },
    };
</script>