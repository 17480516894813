import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    getCartItems(payLoad){
        return ApiToken.post("/cartItems", payLoad,  { headers: authHeader() })
    },
    cartOutOfStockProducts(payLoad){
        return ApiToken.post("/cartOutOfStockProducts", payLoad,  { headers: authHeader() })
    },
    confirmOrderWithStock(payLoad){
        return ApiToken.post("/confirmOrderWithStock", payLoad,  { headers: authHeader() })
    },
    cartUpdate(payLoad){
        return ApiToken.put("/cartQtyUpdate", payLoad,  { headers: authHeader() })
    },
    applyCoupon(payLoad){
        return ApiToken.post("/applyCoupon", payLoad,  { headers: authHeader() })
    },
    removeCoupon(payLoad) {
        return ApiToken.post("/removeCoupon", payLoad,  { headers: authHeader() })
    },
    deleteCart(payLoad) {
        return ApiToken.post("/deleteCart", payLoad,  { headers: authHeader() })
    },
    orderConfirm(payLoad){
        return ApiToken.post("/confirmOrder", payLoad,  { headers: authHeader() })
    },
    searchProducts(payLoad){
        return ApiToken.post("/searchProduct", payLoad,  { headers: authHeader() })
    },

}