<template>
    <div class="emali_verification">
        <BeforeLoginHeader />
        <div class="email_verification_body_part">
            <div class="inner_email_verification_body_part container">
                <form class="mt-lg-5 mt-md-4 mt-5">
                    <div class="email_verification_box p-4">
                        <div class=" inner_email_verification_box mt-lg-4 mt-md-3">
                            <img src="../../assets/image/mail-envelope-open.svg" class="image-fluid" alt="">
                            <h5 class="mt-lg-4 mt-md-2 mt-2 txt_none">Reset your password</h5>
                            <p class="txt_none">A password reset link has been sent to</p>
                            <p>{{ emailId }}</p>
                        </div>
                        <p class="mt-4 txt_none">
                            Please click on the link to secure your
                            account. If you haven’t received the email, please check your junk mail or spam folder.

                        </p>
                        <p class="mt-lg-4 mt-3 txt_none">Didn’t receive email?</p>
                        <div class="my-2">
                            <button class="main_btn btn_default" :class="{ 'disable-btn': isDisable }"
                                @click.prevent="ResendPassword" :disabled="isDisable" id="resendEmail">
                                <div v-if="isLoader"><img width="30" src="../../assets/image/loader.gif" /></div>
                                <span v-if="!isLoader">RESEND EMAIL</span>
                            </button>
                        </div>
                        <p class="mt-3 txt_none" id="countDown" v-if="showCountdown">After {{ countDown }} second you
                            will be able to resend
                            email again </p>
                        <p></p>
                    </div>
                </form>

            </div>
        </div>
        <LoginFooter />
    </div>
</template>

<script>
import LoginFooter from "@/components/LoginFooter";
import User from "@/apis/User";
import BeforeLoginHeader from "@/components/BeforeLoginHeader.vue";

export default ({
    props: ["email"],
    data() {
        return {
            countDown: 30,
            isDisable: false,
            showCountdown: false,
            isLoader: false,
            emailId: '',
        }
    },
    created() {
        document.title = this.$route.meta.title;
    },
    name: 'email-verification',
    components: {
        LoginFooter,
        BeforeLoginHeader
    },
    setup() {

    },
    async mounted() {
        this.emailId = this.email.email;
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                    this.isDisable = true;
                    this.showCountdown = true;
                    this.countZero()
                }, 1000)
            }
        },
        countZero() {
            if (this.countDown == 0) {
                this.isDisable = false;
                this.showCountdown = false;
            }
        },
        ResendPassword() {
            this.isLoader = true;
            this.isDisable = true;
            this.countDown = 30
            this.countDownTimer()
            User.resetPasswordEmail({
                email: this.emailId
            }).then((response) => {
                this.isLoader = false;
                console.log(response.data)
            }).catch((err) => {
                this.isLoader = false;
                console.log(err);
            })
        }

    }
})
</script>

<style>
@import "../../assets/css/email_verification.css";
</style>
