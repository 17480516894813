<template>
  <div class="col-lg-9 ">
    <div class="order_detls_section">
      <div class="invoice-dwnld-btn">
        <a :href="`${baseURL}orderCopyDownload/${orderData.id}`" target="_blank" class="edit_icn">
          <button>
            <span>Download Order Copy</span>
            <img src="/img/download_icon.1220d955.svg" class="img-fluid ac_dd_btn" />
          </button>
        </a>
      </div>

      <h2 class="ac_head_bar">Orders Details</h2>
      <div class="ac_det_cnt">

        <div class="order-dtl-lst">
          <li>
            <span>Order id :</span> <span><strong>{{ orderData.orderNum }}</strong></span>
          </li>
          <li>
            <span>Order Qty :</span> <span><strong>{{ orderData.qty }}</strong></span>
          </li>
          <li>
            <span>Order Date :</span> <span><strong>{{ orderData.orderDate }}</strong></span>
          </li>
          <li>
            <span>Order total amount :</span>
            <span><strong>{{ common.formatNumber(orderData.total) }}</strong></span>
          </li>
          <li>
            <span>Order Status:</span>

            <span class="grn-clr" v-if="this.orderData.orderStatus == 1"><strong>Confirmed</strong></span>
            <span class="yellow-clr" v-if="this.orderData.orderStatus == 2"><strong>Partial Delivered</strong></span>
            <span class="oran-clr" v-if="this.orderData.orderStatus == 3"><strong>Delivered</strong></span>
            <span class="red-clr" v-if="this.orderData.orderStatus == 4"><strong>Cancelled</strong></span>
<!--            <span class="grn-clr">{{ orderStatus(this.orderData.orderStatus) }}</span>-->
          </li>
          <li v-if="(orderData.originalAmount - orderData.total)">
            <span>Total savings :</span>
            <span><strong>{{ common.formatNumber(orderData.originalAmount - orderData.total ) }}</strong></span>
          </li>
          <li v-if="this.orderData.orderStatus == 4 && this.orderData.cancelReason  != null">
            <span>Cancel Reason:</span>
            <span>{{ this.orderData.cancelReason }}</span>
          </li>

        </div>

      </div>

      <!-- Products List Sec -->
      <div class="prodts-ordr-lst">
        <h2 class="ac_head_bar">List of products</h2>

<!--        <div class="ordr-cancel-rqst">-->
<!--          <a href="#">Cancel Request</a>-->
<!--        </div>-->
        <table class="list_orders_desktop">
          <thead>
            <tr>
              <th>Product</th>
              <th>Product Details</th>
              <th>Qty</th>
              <th>Net Price</th>
              <th>Gross Price</th>
<!--              <th>Delivery Status / ID</th>-->
<!--              <th>Reason</th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in orderData.products" :key="item.id">
              <td>
                <img :alt="item.title" :src="item.imageUrl" />
              </td>
              <td>
                <div>
                  <span class="prdt-onr-name">{{ item.brand }}</span>
                  <span>{{ item.title }}</span><span>SKU : {{ item.sku }}</span>

<!--                  item.cartStatus-->

                  <span class="grn-clr" v-if="item.cartStatus == 1">Confirmed</span>
                  <span class="yellow-clr" v-if="item.cartStatus == 2">Partial Delivered</span>
                  <span class="oran-clr" v-if="item.cartStatus == 3">Delivered</span>
                  <span class="red-clr" v-if="item.cartStatus == 4">Cancelled</span>
                  <p v-if="item.cancelReason"><b>Reason</b> : {{ item.cancelReason }}</p>
                </div>
              </td>
              <td>{{ item.qty }}</td>
              <td>{{ common.formatNumber(item.unitPrice) }}
                <div v-if="item.originalPrice != item.unitPrice">
                  <del class="dis_price cart_dis_price"> {{ common.formatNumber(item.originalPrice) }} </del>
                </div>
              </td>
              <td>{{ common.formatNumber(item.total) }}</td>
<!--              <td>SHIPPING / ABC123456 &#45;&#45; need</td>-->
<!--              <td>-->
<!--                <p v-if="item.cancelReason">{{ item.cancelReason }}</p>-->
<!--                <p v-else>NA</p>-->
<!--              </td>-->
            </tr>
          </tbody>

          <!-- <tfoot>
            <tr>
              <td colspan="2" style="text-align:left" class="td-hlgt-bg">
                <strong>Total Quantity: {{ orderData.qty }}</strong>
              </td>
              <td colspan="2" style="text-align:left" class="td-hlgt-bg">
                <strong>Total Price:
                  {{ common.formatNumber(orderData.total) }}</strong>
              </td>
              <td style="text-align:left" class="td-hlgt-bg">
                <strong>Total paid:
                  {{ common.formatNumber(orderData.totalPaidAmount) }}</strong>
              </td>
              <td class="td-hlgt-bg">
                <strong>Balance:
                  {{ common.formatNumber(calculateBal) }}</strong>
              </td>
            </tr>
          </tfoot> -->
        </table>
        <div class="list_orders_mobile">
          <div class="list_orders_main" v-for="item in orderData.products" :key="item.id">
            <div class="list_left_order">
              <h4>Product</h4>
              <div class="list_img" >
                <img :alt="item.title" :src="item.imageUrl" />
              </div>
            </div>
            <div class="list_right_order">
              <h4>Product Details</h4>
              <div class="list_right_status" >
                <span class="prdt-onr-name">{{ item.brand }}</span>
                  <span class="list_head">{{ item.title }}</span>
                  <span class="list_head2"><strong>SKU :</strong> {{ item.sku }}</span>
                 
                </div>
                <p class="list_quan"><strong>QTY:</strong> <span class="list_data">{{ item.qty }}</span></p>
                <p class="list_quan2 dis_listprice"><strong>NET PRICE:</strong>
                  <span class="list_data">{{ common.formatNumber(item.unitPrice) }}</span>
                  <span class="list_dis" v-if="item.originalPrice != item.unitPrice">
                    <del class="dis_price">{{ common.formatNumber(item.originalPrice) }} </del>
                  </span>
                </p>
                <p class="list_quan2"><strong>GROSS PRICE:</strong> <span class="list_data">{{ common.formatNumber(item.total) }}</span></p>
                <div class="confrim_sta">
                  <span class="grn-clr list_head3" v-if="item.cartStatus == 1">Confirmed</span>
                  <span class="yellow-clr list_head3" v-if="item.cartStatus == 2">Partial Delivered</span>
                  <span class="oran-clr list_head3" v-if="item.cartStatus == 3">Delivered</span>
                  <span class="red-clr list_head3" v-if="item.cartStatus == 4">Cancelled</span>
                  <p v-if="item.cancelReason" class="list_head4"><b>Reason</b> : {{ item.cancelReason }}</p>
                </div>
            </div>
        </div>
        </div>
        <div class="td-hlgt-bg">
          <div class="row">
            <div class="col-lg-1 col-6">
              Qty: <strong> {{ (orderData.qty  - orderData.cancelledQty) }}</strong>
            </div>
            <div class="col-lg-2 col-6">
              Price:
              <strong>{{ common.formatNumber(orderData.total) }}</strong>
            </div>
            <div class="col-lg-4 col-6" v-if="orderData.cancelledTotalAmount != 0">
              Cancelled Amount:
              <strong>{{ common.formatNumber(orderData.cancelledTotalAmount) }}</strong>
            </div>
            <div class="col-lg-2 col-6">
              Paid:
              <strong v-if="orderData.totalPaidAmount"> {{ common.formatNumber(orderData.totalPaidAmount) }}</strong>
              <strong v-else>QAR 0 </strong>
            </div>
            <div class="col-lg-3 col-6">
              Balance:
              <strong v-if="calculateBal"> {{ common.formatNumber(calculateBal) }}</strong>
              <strong v-else> QAR 0 </strong>
            </div>
          </div>
        </div>
    
      </div>
      <!-- Products List Sec -->

      <!-- Delivary Details Sec -->
      <div class="order-delivery-dtls-sec">
        <h2 class="ac_head_bar">Delivery Details</h2>
        <div class="ac_det_cnt">
          <div class="row">
            <div class="col-md-6">
              <div class="ac_addr">
                <div class="mb-1">
                  <strong>Shipping Address</strong>
                </div>
                <div>{{ this.orderData.shippingFirstName + ' ' + this.orderData.shippingLastName }}</div>
                <div v-html="displayShippingAdd"></div>
                <div v-if="this.orderData.shippingMobile">M: +{{ this.orderData.shippingMobile }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="ac_addr bill_addr_sec">
                <div class="mb-1">
                  <strong>Billing Address</strong>
                </div>
                <div>{{ this.orderData.billingFirstName + ' ' + this.orderData.billingLastName }}</div>
                <div v-html="displayBillingAdd"></div>
                <div v-if="this.orderData.billingMobile">M: +{{ this.orderData.billingMobile }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="ac_det_cnt">
          <div class="order-delvr-table-sec">
            <table class="addr_bk">
              <thead>
                <th>
                  <span class="vs-desk">Delivery ID</span><span class="vs-mobl">Delivery ID & by</span>
                </th>
                <th>Delivered by</th>
                <th>Delivery Date & Time</th>
                <th>Collected by details</th>
                <th>View Invoice</th>
              </thead>

              <tbody>
                <tr v-for="item in orderData.deliveryDetails" :key="item.id">
                  <td>
                    <span>{{ item.deliveryRefId }}</span>
                    <span class="vs-mobl">{{ item.firstName + ' ' + item.lastName }}</span>
                  </td>
                  <td>{{ item.firstName + ' ' + item.lastName }}</td>
                  <td>{{ item.deliveredOn }}</td>
                  <td>
                    <div>
                      <span>{{ item.collectedPersonName }}</span><span>{{ item.collectedPersonMobile }}</span>
                    </div>
                  </td>
                  <td>
                    <a v-if="item.deliveryReceipt" :href="item.deliveryReceipt" target="_blank">
                      <img src="/img/download_icon.1220d955.svg" class="img-fluid ac_dd_btn" />
                    </a>
                    <span v-else>--</span>
                  </td>
                </tr>
                <tr v-if="!(orderData?.deliveryDetails?.length > 0)">
                  <td colspan="5" style="text-align:center;">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <!-- Delivary Details Sec -->

      <!-- Payment Details Sec -->
      <div class="ordr-payment-dtls-sec">
        <h2 class="ac_head_bar">Payment Details</h2>

        <div class="ordr-cancel-rqst" v-if="this.orderData.orderStatus !== 4">
          <router-link :to="`/user/order-payment/${orderData.id}`">Add Payment</router-link>
        </div>
        <div class="ac_det_cnt">
          <table class="addr_bk">
            <thead>
              <th>
                <span class="vs-desk">Payment ID</span><span class="vs-mobl">Payment ID Date & Time</span>
              </th>
              <th>Payment Date & Time</th>
              <th>
                <span class="vs-desk">Payment Mode</span>
                <span class="vs-mobl">Payment Mode, Amount & Status</span>
              </th>
              <th>Amount</th>
              <th>
                <span class="vs-desk">Payment Status</span>
                <span class="vs-mobl">View Receipt</span>
              </th>
              <th>View Receipt</th>
            </thead>

            <tbody>
              <tr v-for="item in orderData.paymentDetails" :key="item.id">
                <td>
                  <span>{{ item.paymentRefId }}</span>
                  <span class="vs-mobl">{{ item.paidOn }}</span>
                </td>
                <td>{{ item.paidOn }}</td>
                <td>
                  <span>{{ item.paymentMode }}</span>
                  <span class="vs-mobl">
                    <div>
                      <span>{{ common.formatNumber(item.paidAmount) }}</span>
                      <span>{{ paymentStatus(item.paymentStatus) }}</span>
                    </div>
                  </span>
                </td>
                <td>
                  <div>
                    <span>{{ common.formatNumber(item.paidAmount) }}</span>
                  </div>
                </td>
                <td>
                  <span  class="vs-desk">{{ paymentStatus(item.paymentStatus) }} <br/><span v-if="item.paymentStatus == 2">Reason: {{item.rejectedReason}}</span></span>

                  <span class="vs-mobl"><a v-if="item.paymentReceipt" :href="item.paymentReceipt" target="_blank">
                      <img src="/img/download_icon.1220d955.svg" class="img-fluid ac_dd_btn" /></a>
                    <span v-else> -- </span>
                  </span>
                </td>
                <td>
                  <a v-if="item.paymentReceipt" :href="item.paymentReceipt" target="_blank">
                    <img src="/img/download_icon.1220d955.svg" class="img-fluid ac_dd_btn" />
                  </a>
                  <span v-else> -- </span>
                </td>
              </tr>
              <tr v-if="!(orderData?.paymentDetails?.length > 0)">
                <td colspan="6" style="text-align:center;">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Payment Details Sec -->
    </div>
  </div>
</template>
<script>
import "./css/orders.css";
import Api from "@/apis/Orders";
import Api1 from "../../../apis/Api";
import { Base64 } from "js-base64";
//import { Base64 } from "js-base64";
import common from "@/apis/Common";
export default {
  name: "order-details",
  data() {
    return {
      orderData: [],
      common: common,

      baseURL: Api1.defaults.baseURL,
    };
  },
  created() {
    window.scrollTo(0, 0);
    document.title = this.$route.meta.title;
    this.getOrderDetails();
  },
  computed: {
    paymentStatus() {
      return (payment) => {
        if (payment == 1) {
          return 'Confirmed';
        } else if (payment == 2) {
          return 'Rejected';
        } else {
          return 'Pending confirmation';
        }
      };
    },
    calculateBal() {
      return this.orderData.total -  this.orderData.cancelledTotalAmount - this.orderData.totalPaidAmount;
    },
    displayShippingAdd: function () {
      let displayAddress = "";
      if (this.orderData.shippingAddress)
        displayAddress += this.orderData.shippingAddress;
      if (this.orderData.streetAddressTwo)
        displayAddress += "<br> " + this.orderData.streetAddressTwo;
      if (this.orderData.shippingCity) displayAddress += "<br> " + this.orderData.shippingCity;
      if (this.orderData.shippingState) displayAddress += ", " + this.orderData.shippingState;
      if (this.orderData.shippingCountryName) displayAddress += ", " + this.orderData.shippingCountryName;
      if (this.orderData.shippingZipCode) displayAddress += ", " + this.orderData.shippingZipCode;
      return displayAddress;
    },
    displayBillingAdd: function () {
      let displayAddress = "";
      if (this.orderData.billingAddress)
        displayAddress += this.orderData.billingAddress;
      if (this.orderData.billingCity) displayAddress += "<br> " + this.orderData.billingCity;
      if (this.orderData.billingState) displayAddress += ", " + this.orderData.billingState;
      if (this.orderData.billingCountryName) displayAddress += ", " + this.orderData.billingCountryName;
      if (this.orderData.billingZipCode) displayAddress += ", " + this.orderData.billingZipCode;
      return displayAddress;
    },
    orderStatus() {
      return (status) => {
        let text = '';
        if (status == 1) {
          text = 'Confirmed';
        }
        if (status == 2) {
          text = 'Partial Delivered';
        }
        if (status == 3) {
          text = 'Delivered';
        }
        if (status == 4) {
         text = 'Cancelled';
        }
        return text;
      };
    }
  },
  methods: {
    async getOrderDetails() {
      let id = this.$route.params.id;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getOrderDetails({ orderId: id,userId: userId })
        .then((res) => {
          if (res.data.success) {
            this.orderData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$router.push('/user/orders');
        });
    },
  },
  setup() { },
};
</script>
