<template>
  <nav class="nav_bar">
    <div class="nav_left">
      <div class="bred_crumbs" v-on:click.prevent="openNav">
        <svg class="mobile-icon" viewBox="-1 -5 25 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke-width="1" transform="translate(0.500000, 0.466667)">
            <polygon points="0 1 0 0 21.5333333 0 21.5333333 1"></polygon>
            <polygon points="0 7.53333333 0 6.53333333 21.5333333 6.53333333 21.5333333 7.53333333"></polygon>
            <polygon points="0 14.0666667 0 13.0666667 21.5333333 13.0666667 21.5333333 14.0666667"></polygon>
          </g>
        </svg>
      </div>
      <div class="src_cnt">
        <div class="search_bar">
          <input ref="searchTextRef" placeholder="Search" aria-label="Search"  v-model="searchTerm" v-on:keyup="searchProducts()" class="s_inpt">
          <i class="s_h_icn">
            <img class="img-fluid" src="../../../assets/image/8666693_search_icon.png">
          </i>
          <i v-if="searchTerm" ref="searchRef" class="s_h_icn sh_clse" v-on:click.prevent="closeSearch()">
            Clear
          </i>
        </div>
        <!-- <div v-if="searchData.length>0" class="srch_data">
          <ul>
            <li v-for="item in searchData" :key="item.id">
              <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.id}`">
                <img class="img-fluid" src="https://b2b-staging.lab2100.com/uploads/products/407620220912094244.jpg">
                <span>{{item.title}}</span>

              </router-link>
            </li>
          </ul>
        </div> -->

<!--        <div v-if="searchData?.products?.length == 0" class="srch_data">-->
<!--          <div class="sr_head">-->
<!--            No records found-->
<!--          </div>-->

<!--        </div>-->
        <div v-if="searchData?.products?.length>0 || searchData?.categories?.length>0" class="srch_data">
          <div class="da_cnt">
            <div class="sr_prod">
              <div class="sr_head">
                Product Matches
              </div>
              <ul>
                <li v-for="item in searchData.products" :key="item.id">
                  <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.id}`">
                    <div class="l_blk">
                      <img class="img-fluid"
                        :src="item.imageUrl">
                    </div>
                    <div class="r_blk">
                      <div class="r_title">{{item.title}}</div>
                      <div class="r_title_brand">{{item.brand}}</div>
                      <div class="r_price brand_txt">

<!--                        {{ common.formatNumber(item.price) }}-->

                        <div class="prdt-price" v-if="this.priceBool == 0 || this.priceBool == null">


                          <div v-if="item.originalPrice == item.price" class="org_price">
                            {{ common.formatNumber(item.price) }}
                          </div>
                          <div v-else>
                            <div class="org_price">{{ common.formatNumber(item.price) }}</div>
                            <del  style="font-size: 10px"> {{ common.formatNumber(item.originalPrice) }}</del>
                           
                          </div>



                        </div>


                      </div>
                    </div>
                  </router-link>

                </li>
              </ul>
            </div>
            <div class="sr_col">
              <div class="sr_head h_dark">
                Suggestions
              </div>
              <ul>
                <li v-for="cat in searchData?.categories" :key="cat.id">
                  <router-link data-target="trending" class="nav_trig"
                :to="`/products-list/${sanitizeTitle(cat.menuName)}/${encryptIDs(cat.menuId)}`">
                    <div class="c_title">{{cat?.menuName}}</div>
                  </router-link>
                </li>
                <li v-for="sub in searchData?.subCategories" :key="sub.id">
                  <router-link
                  :to="`/products-list/${sanitizeTitle(sub.categoryName)}/${sanitizeTitle(sub.subMenuName)}/${encryptIDs(sub.menuId,sub.subMenuId)}`">
                    <div class="c_title">{{sub.subMenuName}}</div>
                  </router-link>
                </li>
                <li v-for="listSub in searchData?.listSubCategories" :key="listSub.id">
                  <router-link
                    :to="`/products/${sanitizeTitle(listSub.categoryName)}/${sanitizeTitle(listSub?.subCategoryName)}/${sanitizeTitle(listSub.listSubMenuName)}/${encryptIDs(listSub.menuId,listSub.subMenuId,listSub.listSubMenuId)}`">
                    <div class="c_title">{{listSub.listSubMenuName}}</div>
                  </router-link>
                </li>
              </ul>
              <div v-if="searchData?.collections?.length>0" class="sr_head h_dark">
                Collections
              </div>
              <ul v-if="searchData?.collections?.length>0">
                <li v-for="collection in searchData?.collections" :key="collection.collectionId">
                  <router-link :to="`/products/${sanitizeTitle(collection.collectionName)}/${encryptIDs(collection.collectionId)}`">
                    <div class="c_title">{{collection.collectionName}}</div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

         <div class="v_all">
         <router-link :to="`/products/search/${sanitizeTitle(searchTerm)}`">
            View all results for <b>{{searchTerm}}</b>
            </router-link>
          </div>
        </div>


        <div v-else-if="searchData?.products?.length == 0" class="srch_data">
          <div class="da_cnt">
            <div class="sr_prod">
              <div class="sr_head">
                Product Matches
              </div>
              <ul>
                <li>
                  No Results Matched
                </li>
              </ul>
            </div>
            <div class="sr_col">
              <div class="sr_head h_dark">
                Suggestions
              </div>
              <ul>
                <li>
                  No Suggestions found
                </li>
              </ul>
            </div>
          </div>

          <div class="v_all">
            <b>No Results found</b>
          </div>
        </div>







      </div>
    </div>
    <div class="nav_center">
      <div class="logo_cnt">
        <router-link to="/home"><img class="img-fluid" src="../../../assets/image/logo-top.png" /></router-link>
      </div>
    </div>
    <div class="nav_right">
      <ul>
        <li class="nav_head_wishlist">
          <i class="nav_option">
            <router-link to="/wishlist"><img class="img-fluid" src="../../../assets/image/SVG/heart.svg" />
              <span v-if="$store.getters.getWishListCount">{{$store.getters.getWishListCount}}</span>
            </router-link>
          </i>
        </li>
        <li>
          <i class="nav_option u_p_drpdwn">
            <img @click="showProfile" class="img-fluid" src="../../../assets/image/SVG/user.svg" />

            <ul v-if="showProfileMenu" class="u_p_dcnt">
              <li>
                <router-link to="/user/profile">My profile</router-link>
              </li>
              <li class="d-lg-none">
                <router-link to="/wishlist">Wishlist</router-link>
              </li>
<!--              <li>-->
<!--                <router-link to="/user/my-coupon">My Coupons</router-link>-->
<!--              </li>-->
              <li>
                <router-link to="/user/address-book">Address Book</router-link>
              </li>
              <li>
                <router-link to="/user/orders">My Orders</router-link>
              </li>
<!--              <li class="h_child" ref="userRole" data-bs-toggle="collapse" href=".collapseExample" role="button" aria-expanded="false"-->
<!--                aria-controls="collapseExample">-->
<!--                User Roles-->
<!--                <i ref="userRoleArrow" class="">-->
<!--                  <img class="hb_img" ref="userRoleArrowImg1" src="../../../assets/image/dropdown_b.png">-->
<!--                  <img class="hw_img" ref="userRoleArrowImg2" src="../../../assets/image/dropdown_w.png">-->
<!--                </i>-->

<!--              </li>-->
<!--              <li class="collapse hc_item collapseExample" id="">-->
<!--                <router-link to="/user/roles">Role Management</router-link>-->
<!--              </li>-->
<!--              <li class="collapse hc_item collapseExample">-->
<!--                <router-link to="/user/user-management">User management</router-link>-->
<!--              </li>-->
              <li>
                <router-link to="/user/price-setting">Price Setting</router-link>
              </li>
              <li>
                <router-link to="/user/projects">Projects</router-link>
              </li>
              <li>
                <router-link to="/user/change-password">Change Password</router-link>
              </li>
              <li>
                <router-link to="/user/support">Support</router-link>
              </li>
              <li><a href="#" @click="logout">Sign Out</a></li>
            </ul>
          </i>
        </li>
        <li>
          <i class="nav_option">
            <router-link to="/show-cart"><img class="img-fluid" src="../../../assets/image/SVG/cart.svg" /><span
                v-if="cartCount">{{cartCount}}</span></router-link>
          </i>
        </li>
      </ul>
    </div>
  </nav>
  <div id="mySidenav" :class="showNav ? 'active' : ''" class="sidenav">
    <div class="nav_out">
      <a class="sub_holder" href="#">
        Menu
      </a>
      <a href="javascript:void(0)" class="closebtn" v-on:click.prevent="closeNav()">&times;</a>
      <!-- <ul id="current"></ul> -->
      <ul id="main" class="menu_1">
        <li class="mnl-1" v-for="menu in menuData" :key="menu.menuId">
          <div class="mi_cnt">
            <router-link data-target="trending" class="nav_trig"
                :to="`/products-list/${sanitizeTitle(menu.menuName)}/${encryptIDs(menu.menuId)}`">{{ menu.menuName }}</router-link>
            <i @click="menu.childShow=!menu.childShow;" v-if="menu.subList.length>0" class="mi_icn">
              <img class="img-fluid" src="../../../assets/image/downarrow.png" :class="(menu.childShow)?'active':''">
            </i>
          </div>
          <ul v-if="menu.childShow" class="sub_menu" id="furniture">
            <li class="mnl-2" v-for="sub in menu.subList" :key="sub.subMenuId">
              <div class="mi_cnt">
                <router-link
                  :to="`/products-list/${sanitizeTitle(menu.menuName)}/${sanitizeTitle(sub.subMenuName)}/${encryptIDs(menu.menuId,sub.subMenuId)}`">
                  {{
                  sub.subMenuName }}</router-link>
                <i v-if="sub.listSubMenu.length>0" class="mi_icn">
                  <img class="img-fluid" :class="(sub.childShow)?'active':''" @click="sub.childShow=!sub.childShow;"
                    src="../../../assets/image/downarrow.png">
                </i>
              </div>
              <ul v-if="sub.childShow" class="sub_menu" id="living-room-furniture">
                <li class="mnl-3" v-for="list in sub.listSubMenu" :key="list.listSubMenuId">
                  <router-link
                    :to="`/products/${sanitizeTitle(menu.menuName)}/${sanitizeTitle(sub.subMenuName)}/${sanitizeTitle(list.listSubMenu)}/${encryptIDs(menu.menuId,sub.subMenuId,list.listSubMenuId)}`">
                    {{
                    list.listSubMenu }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="nav_close" v-on:click.prevent="closeNav()"></div>
  </div>
  <div id="mnu_head" class="mnu_cnt">
    <ul class="mnu_lst">
      <li v-for="menu in menuData" :key="menu.id">
        <router-link data-target="trending" class="nav_trig"
                :to="`/products-list/${sanitizeTitle(menu.menuName)}/${encryptIDs(menu.menuId)}`">
        {{ menu.menuName }}</router-link>
        <div class="mnd_cnt" v-if="menu?.subList?.length>0">
        <div class="mnd_data_cnt">
        <div class="row">
          <div v-for="sub in menu.subList" :key="sub.id" class="col-xl-3 col-md-6">
            <div class="mnd_head">
              <router-link
                :to="`/products-list/${sanitizeTitle(menu.menuName)}/${sanitizeTitle(sub.subMenuName)}/${encryptIDs(menu.menuId,sub.subMenuId)}`">
                {{
                sub.subMenuName }}</router-link>
            </div>
            <ul>
              <li v-for="list in sub.listSubMenu" :key="list.id">
                <router-link
                  :to="`/products/${sanitizeTitle(menu.menuName)}/${sanitizeTitle(sub.subMenuName)}/${sanitizeTitle(list.listSubMenu)}/${encryptIDs(menu.menuId,sub.subMenuId,list.listSubMenuId)}`">
                  {{list.listSubMenu }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
      </li>
    </ul>
    
  </div>
</template>
<script>
import Api from "@/apis/HomePage";
import CartApi from "@/apis/Cart";
import "../../cart/css/cart.css";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
import wishListApi from "@/apis/WishList";
import common from "@/apis/Common";
import User from "../../../apis/User";
export default {
  name: "cart-header",
  props: ["cartCountUpdate","wishListCountUpdate"],
  data() {
    return {
      menuData: [],
      showNav: false,
      cartData: [],
      cartCount: '',
      wishListCount: 0,
      showProfileMenu: false,
      wishListData: [],
      searchData: [],
      priceBool: '',
      wishListBool: [],
      searchTerm: '',
      megaMenuData: [],
      showMegaMenu: false,
      eventFromProfile: true,
      common: common,
    };
  },
  created() {
    document.title = this.$route.meta.title;
    this.megaMenu();
    this.getCartItems();
    this.getWishList();
    this.wishListBool = this.$store.getters.getWishList;
    let userId = localStorage.getItem("id");
    userId = Base64.decode(userId);
    User.priceDisplay({ userId: userId })
            .then((res) => {
              if (res.data.success) {
                this.priceBool = res.data.data.priceDisplay;
                localStorage.setItem("priceBool", res.data.data.priceDisplay)
              }
            })
            .catch((err) => {
              console.log(err);
            });
    this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));


    User.getUserInfo({ userId: userId })
        .then((res) => {
          if (res.data.success) {
            console.log("Hi User")
          }else if (res.data.status == "Token is Expired"){
            this.logout()
          }else if (res.data.status == "Token is Invalid"){
            this.logout()
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors == "User profile not found..!"){
            console.log(err)
            this.logout()
          }
        });

  },
  watch: {
    cartCountUpdate: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.getCartItems();
      }
    },
    '$store.state.cartCount': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCartItems();
      }
    },

  },
  mounted() {
    document.addEventListener('click', this.closeMegaMenu);
    document.addEventListener('click', this.closeProfile);
    document.addEventListener('click', this.closeSearchOnClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMegaMenu);
    document.removeEventListener('click', this.closeProfile);
    document.removeEventListener('click', this.closeSearchOnClick);
  },
  methods: {
    encryptIDs(menuId, subMenuId, childMenu = '') {
      let finalId = menuId;
      if (subMenuId) {
        finalId += '@@' + subMenuId
      }
      if (childMenu) {
        finalId += '@@' + childMenu
      }
      return Base64.encode(finalId);
    },
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, '');
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    },
    closeMegaMenu(e) {
      if (!this.$el.contains(e.target)) {
        this.showMegaMenu = false;
       // this.megaMenuData = [];
      }
    },
    async openMegaMenu(menu) {
      this.showMegaMenu = true;
      this.megaMenuData = menu;
    },
    async getWishList() {
      if (this.wishListCount <= 0) {
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        wishListApi.getWishList({ userId: userId })
          .then((res) => {
            if (res.data.success) {
              this.wishListData = res.data.data;
              if (this.projectDefault != null) {
                this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
                let wishlist = [];
                this.wishlistDefaultData.forEach((val)=>{
                  // console.log("swaraj", val)
                  wishlist[val.productId] = true;
                  // val.products.forEach((val1)=>{
                  //
                  // });
                });
                this.$store.dispatch('addTotalWishList', wishlist);
                this.wishListCount = Object.values(wishlist)?.length;
                console.log("Swaraj wishlist Count", this.wishListCount)
              }
              // else {
              //   this.wishlistDefaultData = []
              //   let wishlist = [];
              //   this.wishlistDefaultData.forEach((val)=>{
              //     // console.log("swaraj", val)
              //     wishlist[val.productId] = true;
              //     // val.products.forEach((val1)=>{
              //     //
              //     // });
              //   });
              //   this.$store.dispatch('addTotalWishList', wishlist);
              //   this.wishListCount = Object.values(wishlist)?.length;
              //   console.log("Swaraj wishlist Count", this.wishListCount)
              // }
              else {
                let count = 0;
                this.wishListData.forEach((val)=>{
                  count += val.products.length
                });

                this.$store.dispatch('addTotalWishListCount', count);
                this.wishListCount = count;
              }


            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showProfile() {
      this.showProfileMenu = !this.showProfileMenu;
      this.eventFromProfile = false;
    },
    closeProfile(e) {
      const elem = this.$refs.userRole;
      const elem2 = this.$refs.userRoleArrow;
      const elem3 = this.$refs.userRoleArrowImg1;
      const elem4 = this.$refs.userRoleArrowImg2;
      if(elem != e.target && elem2 != e.target && elem3 != e.target && elem4 != e.target){
        if (!this.$el.contains(e.target) && this.eventFromProfile) {
          this.showProfileMenu = false;
        }
        this.eventFromProfile = true;
      }
    },
    closeSearchOnClick(e) {
      const elem = this.$refs.searchRef;
      const elem2 = this.$refs.searchTextRef;
      if(elem != e.target && elem2 != e.target){
        this.closeSearch();
      }
    },
    navigateToProductList(id, subId, childId, title) {
      this.$store.dispatch('addCollectionName', title);
      let param = { id: id, subid: subId };
      let pathName = "Products3";
      if (childId) {
        param.child = childId;
        pathName = "Products4";
      }
      router.push({
        name: pathName,
        params: param,
      });
    },
    getCartItems() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      CartApi.getCartItems({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.cartData = res.data.data;
            this.cartCount = this.cartData?.cartItems?.length;
            console.log("cart Countttt", this.cartCount)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeNav() {
      this.showNav = false;
    },
    openNav() {
      this.showNav = true;
    },
    megaMenu() {
      let menuData = this.$store.getters.getMenus;
      if (menuData) {
        this.menuData = menuData;
        this.menuMap();
      }
      else {
        Api.megaMenu()
          .then((res) => {
            if (res.data.success) {
              this.menuData = res.data.data;
              this.$store.dispatch('addMenus', this.menuData);
              this.menuMap();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    menuMap() {
      for (var i in this.menuData) {
        this.menuData[i].childShow = false;
        //listSubMenu
        for (var j in this.menuData[i].subList) {
          this.menuData[i].subList[j].childShow = false;
          //for (var k in this.menuData[i].subList[j].listSubMenu) {
          //}
        }
      }
    },
    logout() {
      localStorage.clear();
      router.push({ name: 'Login' });
    },
    searchProducts() {
      if ((this.searchTerm && this.searchTerm.length > 2)) {
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        let payload = { searchStr: this.searchTerm, userId : userId };
        CartApi.searchProducts(payload) //{ userId: userId, trash: 0 }
          .then((res) => {
            if (res.data.success) {
              this.searchData = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else {
        this.searchData = [];
      }
    },
    closeSearch() {
      this.searchData = [];
      this.searchTerm = '';
    },
  },
  setup() { },
};
</script>
