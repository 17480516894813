<template>
    <div class="col-lg-9">
        <div>
            <div class="alert alert-success" v-if="showdelMsg" id="error_msg">
                {{ delMsg }}
            </div>
            <div class="d-flex mt-3 align-items-center">
                <h2 class="ac_pg_head mb-0">Projects</h2>
                <div class="ad_add_btn">
                    <button class="btn_default" v-on:click.prevent="addProject()">
                        Add New Project
                    </button>
                </div>
            </div>

            <div class="ac_det_cnt px-0 project_page_mb project_desk_version">
                <table class="addr_bk">
                    <thead>
                        <th>Project</th>

                        <th class="d-none d-xl-table-cell">Email</th>
                        <th class="d-none d-xl-table-cell">Mobile</th>
                        <th class="s_a_cnt">Street Address</th>
<!--                        <th class="d-none d-xl-table-cell">Default</th>-->
                        <th class="ab_icn_cnt"></th>
                    </thead>
                    <tbody>
                        <tr v-if="!(data.length > 0)">
                            <td colspan="7" style="text-align:center;">
                                <img v-if="isLoader" width="30" src="../../../assets/image/loader.gif" />
                                <span v-if="!isLoader">No Records Found</span>
                            </td>
                        </tr>
                        <tr v-for="item in data" :key="item.id">
                            <td class="project_text">

                                <div v-if="item.clientLogo" class="col-8 project_namelist">
                                    <div class="product_main_img">
                                        <img :src="item.clientLogo" class="img-fluid" />
                                    </div>
                                </div>
                                {{ item.projectClientName }}

                            </td>
                            <!--                        <td>{{ item.projectClientName  }}</td>-->
                            <td class="responsive_none d-none d-xl-table-cell project_text1">{{ item.email }}</td>
                            <td class="responsive_none d-none d-xl-table-cell project_text2"><span
                                    v-if="item.mobile.replace(/\s/g, '') != ''">+</span> {{ item.mobile }}</td>
                            <td class="project_text3">
                                <div v-html="displayAddress(item)"></div>
                            </td>
<!--                            <td class="responsive_none d-none d-xl-table-cell project_text4">-->
<!--                                <span v-if="item.isDefault == 1">-->
<!--                                    Yes-->
<!--                                </span>-->
<!--                                <span v-else>-->
<!--                                    No-->
<!--                                </span>-->

<!--                            </td>-->

                            <!--                        <td class="responsive_none d-none d-lg-table-cell">{{ (item.addressType==1)?'Shipping':'Billing' }}</td>-->
                            <td class="project_text5">
                                <div class="add_a_icns">
                                    <a v-on:click.prevent="editProject(item)" href="#" class="edit_icn"><img
                                            src="../../../assets/image/edit_icon.svg" class="img-fluid" />
                                        <span> Edit</span>
                                    </a>
                                    <a href="#" v-on:click.prevent="deleteClient(item.id)" class="del_icn"><img
                                            src="../../../assets/image/delete_icon.svg" class="img-fluid" />
                                        <span> Delete</span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="project_mobileversion">
                <div class="ifnot_img">
                    <p v-if="!(data.length > 0)">
                        <span style="text-align:center;">
                            <img v-if="isLoader" width="30" src="../../../assets/image/loader.gif" />
                            <span v-if="!isLoader">No Records Found</span>
                        </span>
                    </p>
                </div>
                <div class="pro_img" v-for="item in data" :key="item.id">
                    <div class="pro_left" v-if="item.clientLogo != ''">
                        <div v-if="item.clientLogo" class="project_namelist pro_img_box">
                            <img :src="item.clientLogo" class="img-fluid" />

                        </div>
                    </div>
                    <div class="pro_right">
                        <div class="pro_head1"> Project Details:</div>
                        <div class="pro_head"> <span>{{ item.projectClientName }}</span></div>
                        <div class="pro_email">{{ item.email }}</div>
                        <div class="pro_number"><span v-if="item.mobile.replace(/\s/g, '') != ''">+</span> {{ item.mobile }}
                        </div>
<!--                        <div class="pro_default"> <span class="default_pro">Default:</span>-->
<!--                            <span v-if="item.isDefault == 1">-->
<!--                                Yes-->
<!--                            </span>-->
<!--                            <span v-else>-->
<!--                                No-->
<!--                            </span>-->
<!--                        </div>-->
                    </div>
                    <div class="pro_address">
                        <p class="pro_headtext">Address:</p>
                        <span class="add_span" v-html="displayAddress(item)"></span>
                    </div>

                    <div class="pro_icons">
                        <div class="add_a_icns">
                            <a v-on:click.prevent="editProject(item)" href="#" class="edit_icn ">
                         <!--        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 500 500"
                                    style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                    <g id="Layer_18" transform="translate(-0.994 -1)">
                                        <path id="Path_205"
                                            d="M83.5,458.6c-7.1,0-12.8-6.3-12.8-14.1c0-0.5,0-1.1,0.1-1.6L82.3,327c0.3-3.2,1.6-6.2,3.7-8.5L329.9,48.1   c17.8-19.8,46.6-19.8,64.4-0.2c0,0.1,0.1,0.1,0.1,0.2l47.1,51.6c17.8,19.7,17.9,51.7,0.1,71.4c0,0.1-0.1,0.1-0.1,0.2L197.1,441.6   c-2.1,2.3-4.8,3.8-7.7,4.1L84.9,458.6H83.5z M107.3,334.9l-9.3,93.5l84.3-10.3l240.7-267c7.8-8.7,7.8-22.8,0-31.5l-46.4-51.5   c-7.9-8.6-20.5-8.6-28.4,0L107.3,334.9z" />
                                        <path id="Path_206"
                                            d="M397.1,213.8c-3.4,0-6.6-1.5-8.9-4.1l-93-103.1c-5-5.7-4.9-14.8,0.3-20.3c5.1-5.5,13.3-5.4,18.3,0.3   l92.5,102.5c5,6,4.7,15.3-0.7,20.8C403.1,212.3,400.2,213.7,397.1,213.8L397.1,213.8z" />
                                        <polygon id="Rectangle_756"
                                            points="178.9,318.5 295,189.7 313.1,209.8 197,338.6  " />
                                    </g>
                                </svg> -->
                                <span> Edit</span>
                            </a>
                            <a href="#" v-on:click.prevent="deleteClient(item.id)" class="del_icn">


                                <span> Delete</span>
                            </a>
                        </div>
                    </div>

                </div>

            </div>

        </div>
</div>
</template>
<script>
//import router from "@/routes/routes";
import "./css/user_address.css";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
import Api from "../../../apis/Project";
import wishListApi from "@/apis/WishList";

export default {
    name: "User-address",
    data() {
        return {
            data: [],
            wishListData: [],
            item: "",
            delMsg: "Deleted Successfully",
            showdelMsg: false,
            isLoader: true,
        };
    },
    created() {
        window.scrollTo(0, 0);
        document.title = this.$route.meta.title;
        this.getProjectList();
        this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));
    },

    computed: {
        displayAddress() {
            return (address) => {
                let displayAddress = "";
                if (address) {
                    if (address.streetAddressOne)
                        displayAddress += address.streetAddressOne;
                    if (address.streetAddressTwo)
                        displayAddress += "<br>" + address.streetAddressTwo;
                    if (address.city) displayAddress += "<br>" + address.city;
                    if (address.state) displayAddress += ", " + address.state;
                    if (address.countryName && address.city == '') {
                        displayAddress += address.countryName;
                    }else{
                        displayAddress +=  ", " + address.countryName;
                    }
                    if (address.zipCode) displayAddress += ", " + address.zipCode;
                }
                return displayAddress;
            };
        },
    },
    methods: {

        async getProjectList() {
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);
            Api.getProjectList({ userId: userId })
                .then((res) => {

                    if (res.data.success) {
                        this.data = res.data.data;
                    }
                    this.isLoader = false
                })
                .catch((err) => {
                    this.isLoader = false
                    console.log(err);
                });
        },
        // async getWishList() {
        //     if (this.wishListCount <= 0) {
        //         let userId = localStorage.getItem("id");
        //         userId = Base64.decode(userId);
        //         wishListApi.getWishList({ userId: userId })
        //             .then((res) => {
        //                 if (res.data.success) {
        //                     this.wishListData = res.data.data;
        //                     if (this.projectDefault != null) {
        //                         this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
        //                     }else {
        //                         this.wishlistDefaultData = []
        //                     }
        //
        //                     let wishlist = [];
        //                     this.wishlistDefaultData.forEach((val)=>{
        //                         // console.log("swaraj", val)
        //                         wishlist[val.productId] = true;
        //                         // val.products.forEach((val1)=>{
        //                         //
        //                         // });
        //                     });
        //                     this.$store.dispatch('addTotalWishList', wishlist);
        //                     this.wishListCount = Object.values(wishlist)?.length;
        //                     console.log("Swaraj wishlist Count", this.wishListCount)
        //                 }
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }
        // },
        async deleteClient(id) {
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);
            let text = "Are you sure you want to delete?";
            if (confirm(text) == true) {
                Api.projectClientDelete({ userId: userId, id: id })
                    .then((res) => {
                        if (res.data.success) {
                            this.showdelMsg = true;
                            this.getProjectList();
                            // this.getWishList();

                            setTimeout(() => {
                                this.showdelMsg = false;
                            }, 2000);

                        }
                        wishListApi.getWishList({ userId: userId })
                            .then((res) => {
                                if (res.data.success) {
                                    this.wishListData = res.data.data;
                                    if (this.projectDefault != null) {
                                        this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
                                    } else {
                                        this.wishlistDefaultData = []
                                    }

                                    let wishlist = [];
                                    this.wishlistDefaultData.forEach((val) => {
                                        // console.log("swaraj", val)
                                        wishlist[val.productId] = true;
                                        // val.products.forEach((val1)=>{
                                        //
                                        // });
                                    });
                                    this.$store.dispatch('addTotalWishList', wishlist);
                                    this.wishListCount = Object.values(wishlist)?.length;
                                    console.log("Swaraj wishlist Count", this.wishListCount)
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        async addProject() {
            router.push("/user/add-project")
        },
        async editProject(item) {
            console.log(item)
            localStorage.setItem("projectId", item.id)
            router.push({ name: 'EditProjectClient' })
        }
    },
    setup() { },
};
</script>
