<template>
  <div class="col-md-9 col-12 user_order_t_section_col2 py-md-0 py-5">
            <div style="text-align: left">
              <div class="row user_orders_heading mb-md-3 mb-2">
                <div class="role_management_t_heading">
                  <div class="row">
                    <div class="col-lg-8">
                      <h2 class="ac_pg_head">Cancel order</h2>
                    </div>
                    <div class="col-lg-4">
                      <div class="input-group inpt_bx mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search By Role"
                        />
                        <button class="btn btn_default" type="button">SEARCH</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-md-4">
                <h2 class="ac_head_bar"><stong>List of products</stong></h2>
                
                <div class="cancel-order-lst">
                 <!-- Cancel Order List --> 
                <div class="activity-sec">
                <table class="addr_bk">
                  <tr class="border-0">
                    <td class="py-0">
                      <div class="product_filter_sec">
                          <div class="form-check">
                            <label class="form-check-label" for="flexCheck3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheck3">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                      </div>
                    </td>
                    <td>Select All</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="bg-transparent border-0">
                    <th class="py-0"></th>
                    <th class="py-0">Product</th>
                    <th class="py-0">Product Details</th>
                    <th class="py-0">Qty</th>
                    <th class="py-0 d-none text-center d-lg-table-cell">Net Price</th>
                    <th class="py-0 text-center">Gross Price</th>
                  </tr>
                  <tbody>
                    <tr class="border-0">
                      <td>
                        <div class="product_filter_sec">
                          <div class="form-check">
                            <label class="form-check-label" for="flexCheck3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheck3">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td><img src="https://cdn.shopify.com/s/files/1/0569/3643/1803/products/USUV-4001543-KG-01.jpg?v=1649412466" alt=""/></td>
                      <td>
                          <span class="d-block">POP YOUR COLLAR</span>
                          <span class="d-block">SKU : CLA-020-125</span>
                      </td>
                      <td>3</td>
                      <td class="responsive_none text-center d-none d-xl-table-cell">QAR 6,450.00</td>
                      <td class="text-center">QAR 58.000.00</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="product_filter_sec">
                          <div class="form-check">
                            <label class="form-check-label" for="flexCheck3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheck3">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td><img src="https://cdn.shopify.com/s/files/1/0569/3643/1803/products/USUV-4001543-KG-01.jpg?v=1649412466" alt=""/></td>
                      <td>
                          <span class="d-block">POP YOUR COLLAR</span>
                          <span class="d-block">SKU : CLA-020-125</span>
                      </td>
                      <td>3</td>
                      <td class="responsive_none text-center d-none d-xl-table-cell">QAR 6,450.00</td>
                      <td class="text-center">QAR 58.000.00</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="product_filter_sec">
                          <div class="form-check">
                            <label class="form-check-label" for="flexCheck3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheck3">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td><img src="https://cdn.shopify.com/s/files/1/0569/3643/1803/products/USUV-4001543-KG-01.jpg?v=1649412466" alt=""/></td>
                      <td>
                          <span class="d-block">POP YOUR COLLAR</span>
                          <span class="d-block">SKU : CLA-020-125</span>
                      </td>
                      <td>3</td>
                      <td class="responsive_none text-center d-none d-xl-table-cell">QAR 6,450.00</td>
                      <td class="text-center">QAR 58.000.00</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="product_filter_sec">
                          <div class="form-check">
                            <label class="form-check-label" for="flexCheck3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheck3">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td><img src="https://cdn.shopify.com/s/files/1/0569/3643/1803/products/USUV-4001543-KG-01.jpg?v=1649412466" alt=""/></td>
                      <td>
                          <span class="d-block">POP YOUR COLLAR</span>
                          <span class="d-block">SKU : CLA-020-125</span>
                      </td>
                      <td>3</td>
                      <td class="responsive_none text-center d-none d-xl-table-cell">QAR 6,450.00</td>
                      <td class="text-center">QAR 58.000.00</td>
                    </tr>

                    
                    
                  </tbody>
                </table>
                
                <div class="ad_btns_cnt mb-5">
                  <button @click="cancel" class="btn_outline">CANCEL</button>
                  <button
                    :disabled="isLoader"
                    :class="{ 'disable-btn': isLoader }"
                    class="btn_default"
                  >
                  <div v-if="isLoader">
                    <img width="30" src="../../../assets/image/loader.gif" />
                  </div>
                  <span v-if="!isLoader">Update</span>
                  </button>
                </div>

              </div>
              <!-- Cancel Order List -->
              </div>

              </div>
            </div>
          </div>
</template>
<script>
import "./css/orders.css";

export default {
  name: "user-order-cancel",
  data() {
    return {
      count: 34,
    };
  },
  setup() {},
};
</script>