<template>
    <div class="otp_verification">
        <BeforeLoginHeader/>
        <div class="otp_verification_main_div">
            <div class="inner_otp_verification_main_div">
                <div class="otp_verification_section">

                    <div class="pt-5 otp_verification_inner_section">
                      <div v-if="errorRequired" class="alert alert-danger">
                        {{errorRequired}}
                      </div>
                      <div v-if="errorOtp" class="alert alert-danger">
                        {{errorOtp}}
                      </div>
                        <h2 class="mb-4"><b>VERIFY OTP</b></h2>
                        <p>A 6-digit OTP has been sent to your registered mobile number</p>
                      <form v-on:submit.prevent="verifyOtp">
                        <div id="otp" class=" d-flex flex-row justify-content-center mt-2">
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="first" v-model="otp.first" maxlength="1"  @input="next" @keyup.delete="triggerDBKey" />
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="second" v-model="otp.second" maxlength="1"  @input="next" @keyup.delete="triggerDBKey"/>
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="third" v-model="otp.third" maxlength="1"  @input="next" @keyup.delete="triggerDBKey"/>
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="fourth" v-model="otp.fourth" maxlength="1"  @input="next" @keyup.delete="triggerDBKey"/>
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="fifth" v-model="otp.fifth" maxlength="1"  @input="next" @keyup.delete="triggerDBKey"/>
                            <input class="m-2 inputs text-center form-control rounded" type="text" id="sixth" v-model="otp.sixth" maxlength="1"  @input="next" @keyup.delete="triggerDBKey"/>
                        </div>
                        <button class=" mt-md-3 mt-sm-2">SUBMIT</button>
<!--                        <p class="mt-3">RESEND OTP IN {{ countDown }} SEC </p>-->
                      </form>
                    </div>
                </div>
            </div>
        </div>
        <LoginFooter/>
    </div>
</template>

<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import User from "@/apis/User";
import router from "@/routes/routes";
import {Base64} from "js-base64";

export default({
  data() {
    return {
      otp:{
        first:'',
        second:'',
        third:'',
        fourth:'',
        fifth:'',
        sixth:'',
      },
      errorRequired: '',
      errorOtp: '',
      // countDown : 30
    }
  },
    name:'otp-verification',
    components:{
        BeforeLoginHeader,
        LoginFooter,
    },
  methods:{
    next(e) {
      if (e.target?.nextSibling) {
        e.target.nextSibling.focus()
      }
    },

    triggerDBKey(e) {
      if (e.target.value === '') {
        e.target.previousSibling.focus()
      }
    },


    // countDownTimer() {
    //   if(this.countDown > 0) {
    //     setTimeout(() => {
    //       this.countDown -= 1
    //       this.countDownTimer()
    //     }, 1000)
    //   }
    // },
    async verifyOtp() {
      // this.countDown = 30;
      // this.countDownTimer();
      User.otp(
          {
            userId:  Base64.decode(sessionStorage.getItem('id')),
            otp: this.otp.first + this.otp.second + this.otp.third + this.otp.fourth + this.otp.fifth + this.otp.sixth
          }).then((res) => {
        console.log(res.data.message)
        this.success = res.data.message;
        router.push({name:'DocumentVerificationMsg'});
      }).catch((err) => {
        console.log(err)
        this.otp.first = ""
        this.otp.second = ""
        this.otp.third = ""
        this.otp.fourth = ""
        this.otp.fifth = ""
        this.otp.sixth = ""
        this.errorRequired = err.response.data.errors;
        this.errorOtp = err.response.data.error.otp[0];
      })
    }
  },
   mounted() {
    setTimeout( () => sessionStorage.removeItem('companyStatus'), 120000);
    setTimeout( () => this.$router.push({ name: 'CompanyDetailsIndividual'}), 120000);
  },
  created() {
    document.title = this.$route.meta.title;
    if (sessionStorage.getItem('id') == null && sessionStorage.getItem('companyStatus') == null){
      router.push({name: "Login"})
    }else if (sessionStorage.getItem('id') != null && sessionStorage.getItem('companyStatus') == null){
      router.push({name: "CompanyDetailsIndividual"})
    }
    // this.countDownTimer();
  }

})
</script>
<style>
    @import '../../../assets/css/otp_verification.css';
</style>

