<template>
  <CartHeader :cartCountUpdate="cartCount" />

  <!-- Home Slider -->
  <div class="h_ba_slider">
    <splide v-if="windowWidth >= 768" :options="options">
      <splide-slide v-for="slide in slidesData" :key="slide.id">
        <a :href="slide.url">
          <div>
            <img class="img-fluid" :src="slide.image" :alt="slide.title" />
          </div>
        </a>
      </splide-slide>
    </splide>
    <splide v-if="windowWidth > 0 && windowWidth < 768" :options="options">
      <splide-slide v-for="(slide, index) in slidesData" :key="index">
        <a :href="slide.url">
          <div>
            <img class="img-fluid" :src="slide.imageMobile" />
          </div>
        </a>
      </splide-slide>
    </splide>
  </div>
  <!-- Home Slider -->
  <div class="page-width pt-0">
    <!-- SHOP By Room -->
    <div class="s_br_sec mb-4">
      <div class="sec-ttl-head">
        <span>Shop By Collection </span>
      </div>
      <splide :options="options2" :breakpoints="breakpoints">
        <splide-slide v-for="shop in shopbyRoomData" :key="shop.id">
          <div>
            <router-link
              :to="`/products/${sanitizeTitle(shop.title)}/${encryptIDs(shop.categoryId, shop.subCategoryId)}`">
              <img class="img-fluid" :src="shop.image" :alt="shop.title" />
              <h6>{{ shop.title }} </h6>
            </router-link>
          </div>
        </splide-slide>
      </splide>
    </div>
    <!-- SHOP By Room -->

    <!-- Best Selling Sec -->

    <div class="best-sellings-sec">
      <div class="best-sel-sec" v-for="collection in collectionList" :key="collection.id">
        <img v-if="collection.id != newArrivalsId.id" :src="collection.image" :alt="collection.name"
          class="img-fluid" />
        <div class="bsc_cnt" v-if="collection.id != newArrivalsId.id">
          <b>{{ collection.name }}</b><br />
          Top <br />Selling <br />products<br />
          <router-link :to="`/products/${sanitizeTitle(collection.name)}/${encryptIDs(collection.id)}`" class="btn_bg">Shop Now
          </router-link>
        </div>
      </div>

      <div class="best-sel-sec">
        <router-link to="/contactus" class="best_sel_anchor">
          <img src="../../assets/image/products/custom-collection.jpg" class="img-fluid ac_img" />
          <div class="bsc_cnt">
            <b>need</b><br />
            custom<br />
            Creative<br />
            Products?<br />
            <span class="btn_bg">Contact us</span>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Best Selling Sec -->

    <!-- New Arrivals Sec -->

    <div class="new-arrivals-sec product_wish_list">
      <div class="sec-ttl-head">
        <span>New Arrivals</span>
      </div>

      <div class="product_list_view s_br_sec">
        <splide :options="optionsNewArrivals" :breakpoints="breakpoints">
          <!--         <div v-if="isLoader">-->
          <!--           <img width="30" src="../../assets/image/loader.gif" />-->
          <!--         </div>-->
          <splide-slide class="" v-for="item in newArrivals" :key="item.id">
            <ProductCard :productData="item" removeClass="true" />
          </splide-slide>
        </splide>

        <div class="nas_btn text-end">
          <router-link class="btn btn-outline-dark"
            :to="`/products/${sanitizeTitle(newArrivalsId.name)}/${encryptIDs(newArrivalsId.id)}`">
            View all</router-link>
        </div>
      </div>
    </div>

    <!-- New Arrivals Sec -->

    <!-- Idea's and Inspirations Sec -->

<!--         <div class="ideas-inspr-sec mb-5">
          
          <div class="sec-ttl-head">
            <span>Idea's And Inspiration</span>
          </div>
       <div class="hii_gal">
            <div class="row">
              <div class="col-lg-4">
                <div class="hii_cnt">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModalidea"><img class="img-fluid" alt="" src="https://aura-apps.com/shoppable/engine/images/detailed/37/14196-02_AMANDA_SOFA_COLLECTION_01__1__copy.jpg" /></a>
                  <div class="hi_key_cnt" style="left:20%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">1</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://b2b-staging.lab2100.com/uploads/products/coffeetable.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                  <div class="hi_key_cnt" style="left:60%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">2</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://aura-apps.com/shoppable/engine/images/detailed/36/19271-01_TRISTAN_SOFA_COLLECTION_01__1__copy__1_.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="hii_cnt">
                  <img class="img-fluid" alt="" src="https://aura-apps.com/shoppable/engine/images/detailed/36/19271-01_TRISTAN_SOFA_COLLECTION_01__1__copy__1_.jpg" />
                  <div class="hi_key_cnt" style="left:20%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">1</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://b2b-staging.lab2100.com/uploads/products/coffeetable.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="hii_cnt">
                  <img class="img-fluid" alt="" src="https://aura-apps.com/shoppable/engine/images/detailed/37/14196-02_AMANDA_SOFA_COLLECTION_01__1__copy.jpg" />
                  <div class="hi_key_cnt" style="left:20%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">1</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://b2b-staging.lab2100.com/uploads/products/coffeetable.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                  <div class="hi_key_cnt" style="left:60%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">2</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://aura-apps.com/shoppable/engine/images/detailed/36/19271-01_TRISTAN_SOFA_COLLECTION_01__1__copy__1_.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade modal-lg how-to-rdm-coupn-modal" id="exampleModalidea" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered idea_popupblock">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
          
          <div class="hii_cnt hii_cnt_modal hii_gal">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModalidea"><img class="img-fluid hii_cnt_modal_img" alt="" src="https://aura-apps.com/shoppable/engine/images/detailed/37/14196-02_AMANDA_SOFA_COLLECTION_01__1__copy.jpg" /></a>
                  <div class="hii_cnt_modal_right">
                  <div class="hi_key_cnt" style="left:20%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">1</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://b2b-staging.lab2100.com/uploads/products/coffeetable.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                  <div class="hi_key_cnt" style="left:60%; top:50%">
                    <div class="hi_key_itm">
                      <span class="pop_num">2</span>
                      <div class="hi_itm">
                        <img class="img-fluid"
                          src="https://aura-apps.com/shoppable/engine/images/detailed/36/19271-01_TRISTAN_SOFA_COLLECTION_01__1__copy__1_.jpg">
                        <span>BALMORAL CHEST OF DRAWERS</span>
                      </div>
                    </div>
                  </div>
                </div>
                
            </div>
            <div class="list_modal_right">
            <div class="list_modal">
              <div class="image_modal">
                <span class="pop_num">1</span>
                <img class="img-fluid"
                          src="https://b2b-staging.lab2100.com/uploads/products/coffeetable.jpg">
                        
              </div>
              <div class="content_modal">
                <h5 class="ideas_head">BALMORAL CHEST OF DRAWERS</h5>
                <div class="public-hotspot-info__first-line">
                   <span class="public-hotspot-info__price">QAR 8,700.00</span>
                </div>
           <div class="dd_default ipt_fld ideas_btn">
             <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                <label>311W x 100D x 89.5H CM</label>
                <div class="dropdown dd_default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" ></button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item">311W x 100D x 89.5H CM</a>
                    </li>
                    <li>
                      <a class="dropdown-item">322W x 100D x 89.5H CM</a>
                    </li>
                  
                  </ul>
                </div>
                
              </div>
            
          </div>
          
                <div class="public-hotspot-wrapper-actions-add-to-cart">
                <button class="btn_default"><span>Add to cart</span></button>
              
        </div>
        <div class="public-hotspot-wrapper-actions-details">
          <button class="btn_outline">Details</button>
        </div>
              </div>
            </div>
            <div class="list_modal">
              <div class="image_modal">
                <span class="pop_num">2</span>
                <img class="img-fluid"
                          src="https://aura-apps.com/shoppable/engine/images/detailed/36/19271-01_TRISTAN_SOFA_COLLECTION_01__1__copy__1_.jpg">
                        
              </div>
              <div class="content_modal">
                <h5 class="ideas_head">BALMORAL CHEST OF DRAWERS</h5>
                <div class="public-hotspot-info__first-line">
                   <span class="public-hotspot-info__price">QAR 8,700.00</span>
                </div>
                <div class="dd_default ipt_fld ideas_btn">
             <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                <label>311W x 100D x 89.5H CM</label>
                <div class="dropdown dd_default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" ></button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item">311W x 100D x 89.5H CM</a>
                    </li>
                    <li>
                      <a class="dropdown-item">322W x 100D x 89.5H CM</a>
                    </li>
                  
                  </ul>
                </div>
                
              </div>
            
          </div>
                <div class="public-hotspot-wrapper-actions-add-to-cart">
                <button class="btn_default"><span>Add to cart</span></button>
              
        </div>
        <div class="public-hotspot-wrapper-actions-details">
          <button class="btn_outline">Details</button>
        </div>
              </div>
            </div>
       
          </div>
        </div>
        <div class="modal-footer border-0 pb-4">
          <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>  -->
    <!-- Idea's and Inspirations Sec -->

    <!-- Blogs -->

    <div class="blogs-sec">
      <div class="sec-ttl-head">
        <span>Blogs</span>
      </div>

      <!-- Blog posts -->
      <div class="blog-post-view-sec">
        <div class="row">
          <div v-for="blog in blogsData" :key="blog.id" class="col-xl-3 col-md-6">
            <div class="blg_cnt">
              <img class="img-fluid" :src="blog?.displayImg && blog?.displayImg">
              <div class="blg_data">
                <h2 v-html="blog.title.rendered"></h2>
                <!--                <p>A beautiful Refresh !</p>-->
                <a :href="blog.link" target="_blank">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="nas_btn text-end">
          <a href="https://blog.thatsliving.com/" target="_blank" class="btn btn-outline-dark">View all</a>
        </div>
      </div>
      <!-- Blog posts -->
    </div>

    <!-- Blogs -->

    <!-- Shop By Style -->

    <div class="shop-by-style-sec">
      <div class="shop-by-sec-bg">
        <div class="shop-by-sec-cnt">
          <p><b>Shop</b> <br />By <br />Style</p>
        </div>

        <div class="shop-by-sec-img">
          <ul>
            <li v-for="item in shopbyByStyle" :key="item.id">
              <router-link :to="`/products/${sanitizeTitle(item.name)}/${encryptIDs(item.id)}`">
                <img class="img-fluid" alt="" :src="item.image" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Shop By Style -->
  </div>
  <CartFooter />
</template>
<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import "../cart/css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import CartApi from "@/apis/Products";
import wishListApi from "@/apis/WishList";
import common from "@/apis/Common";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import ProductCard from "@/components/common/ProductCard";
import User from "../../apis/User";
// import User from "../../apis/User";
import Project from "../../apis/Project";
export default {
  name: "cart-list",
  data() {
    return {
      newArrivalsId: { id: 6, name: "New Arrivals" },
      common: common,
      slidesData: [],
      cartCount: 10,
      checkBool: [],
      loaderBool: [],
      shopbyRoomData: [],
      newArrivals: [],
      collectionList: [],
      wishListBool: [],
      windowWidth: '600',
      // carousel settings
      options: {
        rewind: true,
        width: '100%',
        gap: '1rem',
        lazyLoad: 'nearby',
      },
      options2: {
        width: '100%',
        perPage: 5,
        rewind: true,
        gap: '0.2rem',
        lazyLoad: 'nearby',
        breakpoints: {
          // 700px and up
          560: {
            perPage: 2,
            snapAlign: "center",
          },
          992: {
            perPage: 3,
            snapAlign: "center",
          }
        },
      },
      optionsNewArrivals: {
        width: '100%',
        perPage: 4,
        rewind: true,
        gap: '0.2rem',
        lazyLoad: 'nearby',
        breakpoints: {
          // 700px and up
          560: {
            perPage: 2,
            snapAlign: "center",
          },
          992: {
            perPage: 3,
            snapAlign: "center",
          }
        },
      },
      blogsData: [],
      shopbyByStyle: [],
      isLoader: true,
    };
  },
  components: {
    CartHeader,
    CartFooter,
    Splide,
    SplideSlide,
    ProductCard
  },
  created() {
    this.windowWidth = window.innerWidth;
    document.title = this.$route.meta.title;

    let profileStatus = localStorage.getItem("profileStatus");
    if (profileStatus == 0) {
      router.push({ name: "CompanyDetailsIndividual" });
    } else if (profileStatus == 1) {
      router.push({ name: "DocumentVerificationMsg" });
    } else if (profileStatus == 2) {
      router.push({ path: "/home" });
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      User.priceDisplay({ userId: userId })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("priceBool", res.data.data.priceDisplay)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.getSliders();
    this.getShopByRoomsList();
    this.getCollectionList();
    this.getNewArrivals();
    this.getBlogs();
    this.getShopByStyle();
    this.wishListBool = this.$store.getters.getWishList;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    let userId = localStorage.getItem("id");
    userId = Base64.decode(userId);
    Project.defaultProjectDetails({ userId: userId }).then((res) => {
      // console.log("Default_Project", res.data);
      if (res.data.success) {
        this.projectDefault =  res.data.data;
        localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
        this.$store.dispatch('checkDefaultProject', this.projectDefault);
      }
    }).catch((err) => {
      console.log("abcd",err);
    });

  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    encryptIDs(menuId, subMenuId, childMenu = '') {
      let finalId = menuId;
      if (subMenuId) {
        finalId += '@@' + subMenuId
      }
      if (childMenu) {
        finalId += '@@' + childMenu
      }
      return Base64.encode(finalId);
    },
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, '');
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async addToWishList(data) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId
      };
      wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.$store.dispatch('addWishList', productId);
            this.wishListBool[productId] = true;
          }
        })
        .catch((err) => {
          this.wishListBool[productId] = false;
          console.log(err);
        });
    },
    async addToCart(data) {
      this.loaderBool[data.id] = true;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { id, productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
        variantId: id,
        qty: 1,
      };
      CartApi.addToCart(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.sucess = "Product Added Successfully";
            this.cartCount += 1;
            this.loaderBool[data.id] = false;
            this.checkBool[data.id] = true;
            setTimeout(() => {
              this.checkBool[data.id] = false;
            }, 3000);
          }
        })
        .catch((err) => {
          this.loaderBool[data.id] = false;
          console.log(err);
        });
    },
    async getSliders() {
      Api.getSliders()
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.slidesData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getShopByRoomsList() {
      Api.getShopByRoomsList()
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.shopbyRoomData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCollectionList() {
      Api.getCollectionList()
        .then((res) => {
          console.log("Collection List", res.data);
          this.isLoader = false
          if (res.data.success) {
            this.collectionList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoader = false
        });
    },
    async getNewArrivals() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getNewArrivals({ userId: userId, collectionId: this.newArrivalsId.id, sortBy: "title", sortValue: "ASC", filters: [], price: [] })
        .then((res) => {
          console.log("New Arrivals", res.data);
          if (res.data.success) {
            this.newArrivals = res.data.data.products;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getBlogs() {
      Api.getBlogs()
        .then((res) => {
          if (res.data) {
            this.blogsData = res.data.splice(0, 4);
            this.blogsData.forEach((val, index) => {
              if (val['_links']['wp:featuredmedia'][0]?.href) {
                Api.getBlogsData(val['_links']['wp:featuredmedia'][0]?.href).then((res2) => {
                  this.blogsData[index]['displayImg'] = res2.data.guid.rendered;
                }).catch((err) => {
                  console.log(err);
                });
              }
            })
          }
          console.log("Blogssss data", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    navigateToProducts(collection) {
      let id = collection.id;
      let name = (collection.title) ? collection.title : collection.name
      this.$store.dispatch('addCollectionName', name);
      router.push({
        name: "Products",
        params: { id: id },
      });
    },
    productDetails(id) {
      router.push({
        name: "ProductDetails",
        params: { id: id },
      });
    },
    async getShopByStyle() {
      Api.getShopByStyle()
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.shopbyByStyle = res.data?.data?.splice(0, 3);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};

</script>



