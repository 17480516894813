<template>
    <div class="top_most_header">
        <div class="inner_top_most_header">
            <div class="row">
                <div class="col-6 support_section">
                    <h6>CALL SUPPORT : +91 9123456789</h6>
                </div>
                <div class="col-6 ul_section p-0">
                    <ul>
                        <li><h6>ENQUIRE</h6></li>
                        <li><h6>STORES</h6></li>
                        <li><h6  v-on:click.prevent="account_click()">MY ACCOUNT</h6></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from "@/routes/routes";
export default ({
    name:"top-most-header",
    methods:{
        async account_click(){
            router.push("/user/profile")
        }
    },
    setup() {
        
    },
})
</script>
<style scoped>
    .top_most_header{
        font-family: Avenir-black;
        border-bottom: 1px solid #e3e3e3;
    }
    .inner_top_most_header{
        padding: 0.5rem 5rem 0rem;;
    }
    .support_section{
        text-align: start;
    }
    .inner_top_most_header h6{
        margin: 0px;
    }
    .ul_section{
        text-align: end;
    }
    .ul_section ul{
        text-decoration: none;
        display: inline-flex;
        list-style: none;
    }
    .ul_section ul li{
        margin-right: 20px;
    }
    
</style>
