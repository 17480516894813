<template>
    <div id="header-login">
        <TopHeader/>
        <div class="header_section row col-12" >
            <div class=" col-xl-12 col-lg-12 col-md-12 header_seacrch" >
                <div class="hamburger_menus">
                    <nav class="navbar">
                        <div class="navbar-container container">
                            <input type="checkbox" name="" id="" unchecked/>
                            <div class="hamburger-lines">
                                <span class="line line1"></span>
                                <span class="line line2"></span>
                                <span class="line line3"></span>
                            </div>
                            <ul class="menu-items" style="background-color:#ffffff!important">
                                <HamburgerMenu/>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="search col-4 my-xl-3 my-md-2" :style="{display: search}">
                    <img src="../assets/image/8666693_search_icon.png" class="image-fluid"/><input type="text"/>
                </div>
                <button class="logo_btn"><router-link to="/my-profile"><img src="../assets/image/logo_thatsliving_800x-removebg-preview.png" class="img-fluid "></router-link></button>
                <div class="header_icon col-sm-2 col-xl-2 col-lg-2 " :style="{display: usericons}">
                    <div class="users_div" >
                        <div class="user_icon py-2 "><router-link to="" class="btn p-0" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-heart"></i> <img src="../assets/image/user_profile.svg"></router-link> 
                          <div class="dropdown-menu dropdown_menu_body p-0" onclick="event.stopPropagation()" aria-labelledby="triggerId">
                            <UserProfileHeader/>
                          </div>
                        </div>
                        <div class="addtocart_icon py-2 mx-md-3 mx-2"><i class="fa-solid fa-cart-shopping"><span class="badgecart bg-dark rounded-circle ">{{this.cartbadge}}</span></i><img src="../assets/image/add_to_cart.png"></div>
                        <div class="wishlist_icon py-2 "><i class="fa-solid fa-heart"></i><span class="badge bg-dark rounded-circle ">4</span><img src="../assets/image/wishlist.png">
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import HamburgerMenu from "@/components/layouts/HamburgerMenu.vue";
import TopHeader from "@/components/layouts/TopHeader.vue";
// import LeftMenusUsersProfile from "@/components/layouts/LeftMenu";
import UserProfileHeader from "@/components/layouts/UserProfileHeader";
export default{
    name: 'header-login',
    data(){
        return{
            datainfos:[],
            cartbadge:'0'
        }
    },
    components:{
        HamburgerMenu,
        TopHeader,
        // LeftMenusUsersProfile,
        UserProfileHeader

    },
    props: [
        'search',
        'usericons'
    ],
    setup() {

    },
}
</script>

<style scoped>

    @import '../assets/css/style.css';
    @media (max-width: 768px) {
      .hamburger_menus input[type="checkbox"],
      .hamburger-lines {
        display: block;
      }

      .hamburger_menus .navbar-container {
        display: block;
        position: relative;
        height: 53px;
      }

      .hamburger_menus input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
      }

      .hamburger_menus .hamburger-lines {
        display: block;
        height: 18px;
        width: 27px;
        position: absolute;
        top: 24px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .hamburger_menus .hamburger-lines .line {
        display: block;
        height: 2px;
        width: 90%;
        border-radius: 10px;
        background: #000000;
      }

      .hamburger_menus .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
      }

      .hamburger_menus .hamburger-lines .line2 {
        transition: transform 0.1s ease-in-out;
      }

      .hamburger_menus .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
      }

    .menu-items {
        background-color: #ffffff!important;
        padding-top: 0px!important;
        background: none!important;
        height: 100vh;
        width: 287px!important;
        /* max-width: 300px; */
        transform: translate(-150%);
        display: flex;
        margin-top: 60px!important;
        flex-direction: column;
        margin-left: -49px;
        padding-left: 0px!important;
        transition: transform 0.5s ease-in-out;
        overflow: scroll;
      }

      .menu-items li {
        margin-bottom: 1.8rem;
        font-size: 1rem;
        font-weight: 500;
      }

      .hamburger_menus button {
        width: fit-content;
      }

      .hamburger_menus .logo {
        position: absolute;
        top: 12px;
        right: 15px;
        font-size: 1.7rem;
      }

      .hamburger_menus input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
      }

      .hamburger_menus input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
      }

      .hamburger_menus input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
      }

      .hamburger_menus input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
      }
      .dropdown_menu_body{
        transform: translate3d(0px, 42.5px, 0px)!important;
        right: -13px!important;
        min-width: 12rem;
      }
    }
    
    @media (max-width: 500px) {
    .hamburger_menus input[type="checkbox"]:checked ~ .logo {
        display: none;
      }
      .dropdown_menu_body{
        transform: translate3d(0px, 42.5px, 0px)!important;
        right: -13px!important;
        min-width: 12rem;
      }
    }
</style>
