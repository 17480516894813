<template>
  <div class="reg_page">
    <BeforeLoginHeader />
    <div class="body_part body_part_reg row" style="margin: auto">
      <div class="create_acc_1 col-lg-6">
        <div class="banner bground_banner_left">
          <div class="create_account_note_section">
            <div class="create_account_note_i_section">
              <p>Note:</p>
              <p class="txt_none">
                That's Living B2B is solely dedicated to business customers, including real estate developers,
                retailers, interior designers, and architects. We specialize in high-quality, stylish furniture for
                residential and hospitality spaces to suit every taste. Our wide range includes modern contemporary
                furnishings, luxury decor, and lighting.
              </p>
            </div>
          </div>
          <div class="create_account_pre_section mt-4">
            <p style="text-transform: uppercase; font-weight: bold">Pre-requirments:</p>
            <p>Documents required to complete the registration process:</p>
            <ol>
              <li>Commercial Residence Copy (CR Copy)</li>
              <li>
                The authority to open an account with That’s Living
              </li>
              <li>
                A letterhead with sign and stamp
              </li>
            </ol>
            <p>
              Please download a copy of the
              <a target="_blank" href="/uploads/docs/Letter_Of_Authorization.pdf">
                sample letterhead here</a>
            </p>
          </div>
        </div>
      </div>
      <div class="body_form create_account_form create_acc_2 col-lg-6">
        <div class="inner_body_form">
          <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
          <h2>CREATE ACCOUNT</h2>
          <form v-on:submit.prevent="register">
            <div class="input_field mt-3">
              <label>FIRST NAME*</label>
              <input type="text" v-on="
                reg_form.first_name
                  ? { change: v$.reg_form.first_name.$validate }
                  : {}
              " v-model="reg_form.first_name" />
            </div>
            <span v-if="v$.reg_form.first_name.$error" class="error_msg">
              <span v-if="reg_form.first_name == ''">First name is required</span>
              <span v-else>{{
                v$.reg_form.first_name.$errors[0].$message
              }}</span>
            </span>
            <div class="input_field mt-3">
              <label>LAST NAME*</label>
              <input type="text" v-on="
                reg_form.last_name
                  ? { change: v$.reg_form.last_name.$validate }
                  : {}
              " v-model="reg_form.last_name" />
            </div>
            <span v-if="v$.reg_form.last_name.$error" class="error_msg">
              <span v-if="reg_form.last_name == ''">Last name is required</span>
              <span v-else>{{
                v$.reg_form.last_name.$errors[0].$message
              }}</span>
            </span>
            <div class="input_field mt-3">
              <label>EMAIL ID*</label>
              <input type="text" v-on="
                reg_form.email ? { change: v$.reg_form.email.$validate } : {}
              " v-model="reg_form.email" />
            </div>
            <span v-if="v$.reg_form.email.$error" class="error_msg">
              <span v-if="reg_form.email == ''" class="error_msg">Email id is required</span>
              <span v-else class="error_msg">{{
                v$.reg_form.email.$errors[0].$message
              }}</span>
            </span>
            <div class="mt-3">
              <div class="input_field inpt_icn">
                <label>PASSWORD*</label>
                <input v-on="
                  reg_form.password
                    ? { change: v$.reg_form.password.$validate }
                    : {}
                " :type="passwordVisible == true ? 'text' : 'password'" v-model="reg_form.password" />

                <div class="inp_fld_icn">
                  <img class="visibility_btn" @click.prevent="togglePasswordVisibility()" :src="imgSrc" />
                </div>
              </div>
              <span v-if="v$.reg_form.password.$error" class="error_msg mt-0">
                <span v-if="reg_form.password == ''">Password is required</span>
                <span v-else-if="
                  v$.reg_form.password.$errors[0].$message ==
                  'Value is required'
                ">Password cannot have spaces!</span>
                <span v-else>{{
                  v$.reg_form.password.$errors[0].$message
                }}</span>
              </span>
            </div>
            <div class="row mt-3">
              <div class="col">
                <button :class="{ 'disable-btn': isLoader }" :disabled="isLoader" class="btn_default">
                  <div v-if="isLoader">
                    <img width="22" src="../../assets/image/loader.gif" />
                  </div>
                  <span v-if="!isLoader">CREATE</span>
                </button>
              </div>
              <div class="col-md-6 ancle_tag">
                <router-link to="/">RETURN TO LOGIN</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <LoginFooter />
  </div>
</template>

<script>
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import router from "@/routes/routes";
import Register from "./Register.vue";
import User from "@/apis/User";

export default {
  name: "app",
  data() {
    return {
      errors: "",
      reg_form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      passwordVisible: false,
      isLoader: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      reg_form: {
        first_name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
            () => `First name is invalid, special charecters not allowed`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        last_name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100),
          containsLastNameRequirement: helpers.withMessage(
            () => `Last name is invalid, special charecters not allowed`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        email: { required, email },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(18),
          containsPasswordRequirement: helpers.withMessage(
            () =>
              `The password requires an uppercase, lowercase, number and special character`,
            (value) =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]+)^\S*$/.test(
                value
              )
          ),
        },
      },
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    async register() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        this.isLoader = true;
        User.register({
          firstName: this.reg_form.first_name,
          lastName: this.reg_form.last_name,
          email: this.reg_form.email,
          password: this.reg_form.password,
        })
          .then((res) => {
            this.isLoader = false;
            console.log(res.data.data.id);
            // window.$cookies.set("registerUserId", res.data.data.id, 900);
            var data = {email: res.data.data.email, id: res.data.data.id}
            localStorage.setItem("registerData" , JSON.stringify(data))
            router.push({
              name: "EmailVerification",
              params: { email: res.data.data.email, id: res.data.data.id },
            });
          })
          .catch((err) => {
            console.log(err);
            this.errors = err.response.data.errors;
            this.isLoader = false;
          });
        console.log("Form successfully submitted.");
      } else {
        console.log("Form failed validation");
      }
    },
    async togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  computed: {
    imgSrc: function () {
      return this.passwordVisible === true
        ? require("../../assets/image/show_password_icon.png")
        : require("../../assets/image/hide_password_icon.png");
    },
  },
  components: {
    BeforeLoginHeader,
    LoginFooter,
    // eslint-disable-next-line vue/no-unused-components
    Register,
  },
};
</script>

<style>
.border-color {
  border: 1px solid red !important;
}

.error_msg1 {
  display: none;
}

.error_msg {
  color: red !important;
  text-align: left !important;
  font-size: 12px !important;
  display: block;
  margin-bottom: 5px;
}

.inner_input_field {
  display: flex;
  flex-direction: column;
}

.input_field .visibility_btn {
  width: 76%;
  position: unset !important;
  display: inline-block;
  color: #080a1c;
  margin-top: 0.7rem;
  right: 7.5%;
}

.disabl-btn {
  color: black !important;
  background-color: whitesmoke !important;
}
</style>
