<template>
  <div class="col-lg-9">
    <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
    <div class="alert alert-success" v-if="success">{{ success }}</div>
    <form v-on:submit.prevent="updateRole">
      <h2 class="ac_pg_head">UPDATE ROLE</h2>
      <div class="row">
        <div class="col-lg-6">
          <div class="input_field">
            <label>ROLE TITLE</label>
            <input
              type="text"
              v-model="roleObj.name"
              v-on="roleObj.name ? { keyup: v$.roleObj.name.$validate } : {}"
            />
          </div>
          <span v-if="v$.roleObj.name.$error" class="error_msg_log error_msg">
            <span>{{ v$.roleObj.name.$errors[0].$message }}</span>
          </span>
        </div>
      </div>
      <div class="row mt-4 align-items-center">
        <div class="col">
          <h2 class="ac_pg_head mb-0">ROLE ACCESS</h2>
        </div>
        <div class="col text-end">
          <label for="select_all_ipt" class="btn_default">
            <input
              id="select_all_ipt"
              type="checkbox"
              @click="selectAll"
              v-model="allSelected"
            />
            Select All
          </label>
        </div>
      
      </div>

      <div class="ac_det_cnt">
        <div class="row">
          <div
            class="col-md-6"
            v-for="(page, index) in pagesData"
            :key="page.sectionId"
          >
            <label class="ac_head_bar rl_access">
              <input
                type="checkbox"
                :checked="page.checked"
                class="form-check-input"
                @click="selectSubAll(page.sectionName)"
              />
              {{ page.sectionName }}
            </label>
            <div class="ac_det_cnt">
              <div
                class="form-check"
                v-for="(list, index2) in page.pages"
                :key="list.pageId"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="addr_view"
                  @click="pushData(index, index2)"
                  :checked="pagesData[index].pages[index2].checked"
                />
                <label class="form-check-label">
                  {{ list.page }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="ad_btns_cnt">
          <button @click="cancel" class="btn_outline">CANCEL</button>
          <button
            :disabled="isLoader"
            :class="{ 'disable-btn': isLoader }"
            class="btn_default"
          >
          <div v-if="isLoader">
            <img width="30" src="../../../assets/image/loader.gif" />
          </div>
           <span v-if="!isLoader">Update</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import router from "@/routes/routes";
import "./css/edit_role_management.css";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import Api from "@/apis/Roles";
import { Base64 } from "js-base64";
export default {
  name: "edit-role",
  data() {
    return {
      selected: [],
      pagesData: [],
      roleObj: { name: "", userId: 1, pages: [] },
      errors: "",
      success: "",
      isLoader: false,
      allSelected: false,
      permIds: [],
      editData: [],
      userId: "",
      roleId: this.$route.params.id,
    };
  },
  methods: {
    getPages: function () {
      Api.getPagesList().then(
        function (response) {
          this.pagesData = response.data.data;
          for (var i in this.pagesData) {
            this.pagesData[i].checked = false;
            for (var j in this.pagesData[i].pages) {
              this.pagesData[i].pages[j].checked = false;
            }
          }
        }.bind(this)
      );
    },
    getPageData: function () {
      let postObj = { userId: this.userId, roleId: this.roleId };
      Api.getPageData(postObj).then(
        function (response) {
          this.editData = response.data.data;
          let data = [];
          this.editData.rolePages.forEach((item) => {
            data.push(item.pageId);
          });
          this.roleObj.name = this.editData.roleName;
          for (var i in this.pagesData) {
            this.pagesData[i].checked = false;
            let allValues = 0;
            for (var j in this.pagesData[i].pages) {
              if (data.includes(this.pagesData[i].pages[j].pageId)) {
                allValues += 1;
                this.pagesData[i].pages[j].checked = true;
                let pageId = this.pagesData[i].pages[j].pageId;
                this.permIds[pageId] = pageId;
              } else {
                this.pagesData[i].pages[j].checked = false;
              }
            }
            if (allValues>0) {
              this.pagesData[i].checked = true;
            }
          }
        }.bind(this)
      );
    },
    pushData: function (index, index2) {
      this.pagesData[index].pages[index2].checked =
        !this.pagesData[index].pages[index2].checked;
      const pageId = this.pagesData[index].pages[index2].pageId;
      if (this.pagesData[index].pages[index2].checked) {
        this.permIds[pageId] = pageId;
      } else {
        delete this.permIds[pageId];
      }
      let parentUnceck = false;
      for (var j in this.pagesData[index].pages) {
              if(this.pagesData[index].pages[j].checked){
                parentUnceck = true;
              }
            }
      this.pagesData[index].checked = parentUnceck;
    },
    async cancel() {
      router.push("/user/roles");
    },
    async updateRole() {
      this.roleObj.userId = this.userId;
      this.roleObj.roleId = this.roleId;
      this.v$.$validate(); // checks all inputs
      this.roleObj.pages = Object.values(this.permIds);
      if (this.roleObj.pages.length > 0) {
        if (!this.v$.$error) {
          this.isLoader = true;
          // if no fail validation
          Api.updateRole(this.roleObj)
            .then((res) => {
              console.log(res);
              this.success = res.data.message;
              this.isLoader = false;
              setTimeout(() => {
                this.$router.push({ name: "Roles" });
              }, 3000);
            })
            .catch((err) => {
              this.isLoader = false;
              if (err.response.data.error?.name[0]) {
                this.errors = err.response.data.error?.name[0];
              } else {
                this.errors = err.response.data.errors;
              }
              setTimeout(() => {
                this.errors = "";
              }, 5000);
            });
          console.log("Form successfully submitted.");
        } else {
          console.log("Form failed validation");
        }
      } else {
        alert("Please select atleast one permission");
      }
    },
    selectAll: function () {
      this.userIds = [];
      this.allSelected = !this.allSelected;
      for (var i in this.pagesData) {
        this.pagesData[i].checked = this.allSelected;
        for (var j in this.pagesData[i].pages) {
          this.pagesData[i].pages[j].checked = this.allSelected;
          const pageId = this.pagesData[i].pages[j].pageId;
          if (this.pagesData[i].pages[j].checked) {
            this.permIds[pageId] = pageId;
          } else {
            delete this.permIds[pageId];
          }
        }
      }
    },
    selectSubAll: function (sectionName) {
      this.userIds = [];
      for (var i in this.pagesData) {
        if (this.pagesData[i].sectionName === sectionName) {
          this.pagesData[i].checked = !this.pagesData[i].checked;
          for (var j in this.pagesData[i].pages) {
            this.pagesData[i].pages[j].checked = this.pagesData[i].checked;
            const pageId = this.pagesData[i].pages[j].pageId;
            if (this.pagesData[i].pages[j].checked) {
              this.permIds[pageId] = pageId;
            } else {
              delete this.permIds[pageId];
            }
          }
        }
      }
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      roleObj: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsroleTitleRequirement: helpers.withMessage(
            () => `Role name is invalid`,
            (value) => /^[A-Za-z0-9.\s']+$/.test(value)
          ),
        },
      },
    };
  },
  created() {
    if (!this.$route.params.id) {
      router.push("/user/roles");
    }
    document.title = this.$route.meta.title;
    let userId = localStorage.getItem("id");
    this.userId = Base64.decode(userId);
    this.getPages();
    this.getPageData();
  },
};
</script>
