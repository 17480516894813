import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    getProducts(payload){
        return ApiToken.post("/collectionProducts",payload,  { headers: authHeader() });
    },
    // getProductList(payload){
    //     return ApiToken.post("/products",payload,  { headers: authHeader() });
    // },
    getProductList(payload){
        return ApiToken.post("/productsNew",payload,  { headers: authHeader() });
    },

    addToCart(payload){
        return ApiToken.post("/addToCart",payload,  { headers: authHeader() });
    },
    getProductDetails(payload){
        return ApiToken.post("/productDetails",payload,  { headers: authHeader() });
    },
    addtoWishList(payLoad){
        return ApiToken.post("/addWishlist", payLoad,  { headers: authHeader() })
    },
    getRecentProducts(payLoad){
        return ApiToken.post("/recentProducts", payLoad,  { headers: authHeader() })
    },
    getProductTypes(payLoad){
        return ApiToken.post("/filterListSubMenus", payLoad,  { headers: authHeader() })
    },
    getFiltersNew(payLoad){
        return ApiToken.post("/listSubMenuFiltersNew", payLoad,  { headers: authHeader() })
    },
    // getProductTypes(payLoad){
    //     return ApiToken.post("/listSubMenus", payLoad)
    // },
    getFilters(payLoad){
        return ApiToken.post("/listSubMenuFilters", payLoad,  { headers: authHeader() })
    },
    getSearchProductsList(payLoad){
        return ApiToken.post("/searchViewAll", payLoad,  { headers: authHeader() })
    },
    getMainMenuProductsList(payLoad){
        return ApiToken.post("/menuProducts", payLoad,  { headers: authHeader() })
    },
    notifyItem(payLoad){
        return ApiToken.post("/notifyProduct", payLoad,  { headers: authHeader() })
    }

}