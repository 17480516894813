<template>
    <!--  Header Start -->
    <BeforeLoginHeader />
    <!-- Header End -->

    <section class="contact_sec">
        <div class="page-width">
            <!-- Privacy policy Sec -->

            <!-- Breadcrumb Sec -->
            <br/>
            <!-- Breadcrumb Sec -->
            <div class="prvc-plc-cnt-sec container" v-html="cmsData.content">

            </div>

            <!-- Cookies policy Sec -->
        </div>
    </section>
    <LoginFooter />
</template>
<script>
    import Api from "@/apis/HomePage";
    import BeforeLoginHeader from "@/components/BeforeLoginHeader";
    import LoginFooter from "@/components/LoginFooter";
    export default {
        name: "terms-and-conditions",
        data() {
            return {
                cmsData: [],
            };
        },
        components: {
            BeforeLoginHeader,
            LoginFooter,
        },
        created() {
            document.title = this.$route.meta.title;
            this.getCMSData();
        },
        methods: {
            async getCMSData() {
                Api.getCMSData({ page: "terms" })
                    .then((res) => {
                        if (res.data.success) {
                            this.cmsData = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
        setup() { },
    };
</script>
