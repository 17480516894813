import ApiToken from "./ApiToken";

export default {
    getUsersList(payload) {
        return ApiToken.post("/usersList", payload)
    },
    addUser(payload) {
        return ApiToken.post("/addUser", payload)
    },
    getUserDetails(payload) {
        return ApiToken.post("/userDetails", payload)
    },
    updateUser(payload) {
        return ApiToken.post("/updateUser", payload)
    },
    deleteUser(payLoad) {
        return ApiToken.post("/deleteUser", payLoad)
    }
}