<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #080A1C;
  margin-top: 60px;
}
@font-face {
  font-family: "Avenir-black";
  src: url("./assets/fonts/Avenir\ LT\ Std\ 55\ Roman.otf ");
}
body {
  font-family: Avenir-black;
}
h1,
h2 {
  font-size: 24px;
  margin: 0px;
}
p,
a,
ol,
ul {
  margin: 0px;
}
.main_btn {
  font-size: 16px !important;
  padding: 8px !important;
  background-color: #000000;
  color: #ffffff;
}
.main_btn:hover {
  background-color: #ffffff;
  color: #000000;
}
@media screen and (max-width: 1366px) {
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 996px) {
  h1,
  h2 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 576px) {
  body {
    font-size: 12px;
  }
  h1,
  h2 {
    font-size: 16px !important;
  }
}
</style>
