import Api from "./Api"
import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";

export default {
    register(reg_form) {
        return Api.post("/register", reg_form)
    },
    emailVerification(data) {
        return Api.post("/resendEmail", data,  { headers: authHeader() })
    },
    login(form) {
        return Api.post("/login", form)
    },
    forgetPassword(data){
        return Api.post("/forgotPassword", data)
    },
    resetPasswordEmail(data){
        return Api.post("/forgotPassword", data)
    },
    resetPassword(form) {
        return Api.post("/resetPassword", form)
    },
    companyIndividual(individual) {
        return ApiToken.post("/individualDetails", individual,  { headers: authHeader() })
    },
    companyBusinessDetails(company_details) {
        return ApiToken.post("/businessDetails", company_details,  { headers: authHeader() })
    },
    otp(otp){
        return ApiToken.post("/verifyMobile", otp)
    },
    postSupport(payLoad) {
        return Api.post("/contactSupport", payLoad)
    },
    getUserInfo(payLoad) {
        return ApiToken.post("/userProfile/",payLoad,  { headers: authHeader() })
    },
    changePassword(payLoad) {
        return ApiToken.put("/changePassword", payLoad,  { headers: authHeader() })
    },
    getMyCoupons(payLoad) {
        return ApiToken.post("/couponsList", payLoad)
    },
    getSupportTypes(payLoad) {
        return ApiToken.get("/supportTypes", payLoad)
    },
    submitTicket(payLoad) {
        return ApiToken.post("/supporRequest", payLoad)
    },
    priceDisplaySetup(payLoad) {
        return ApiToken.put("/priceDisplaySetup", payLoad,  { headers: authHeader() })
    },
    priceDisplay(payLoad) {
        return ApiToken.post("/priceDisplay", payLoad,  { headers: authHeader() })
    },
    getUserDiscountPercentage(payLoad) {
        return ApiToken.post("/getUserDiscountPercentage", payLoad,  { headers: authHeader() })
    },
}