<template>
  <div class="col-lg-9">
  <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
    <div class="alert alert-success" v-if="success">{{ success }}</div>
    <h2 class="ac_pg_head mb-0">ADD USER</h2>
        <form v-on:submit.prevent="addUser">
    <div class="ac_det_cnt">
      <div class="">
        <div class="row">
          <div class="col-md-6 mt-3">
            <div class="input_field">
              <label>FIRST NAME*</label>
              <input
                type="text"
                v-model="userObj.firstName"
                v-on="
                  userObj.firstName
                    ? { keyup: v$.userObj.firstName.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.userObj.firstName.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.firstName == ''">First name is required</span>
              <span v-else>{{ v$.userObj.firstName.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 mt-3">
            <div class="input_field">
              <label>LAST NAME*</label>
              <input
                type="text"
                v-model="userObj.lastName"
                v-on="
                  userObj.lastName
                    ? { keyup: v$.userObj.lastName.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.userObj.lastName.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.lastName == ''">Last name is required</span>
              <span v-else>{{ v$.userObj.lastName.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 mt-3">
            <div class="input_field">
              <label>EMAIL ID*</label>
              <input
                type="text"
                v-model="userObj.email"
                v-on="
                  userObj.email
                    ? { keyup: v$.userObj.email.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.userObj.email.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.email == ''">Email Id is required</span>
              <span v-else>{{ v$.userObj.email.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 mt-3">
            <div class="input_field">
              <label>MOBILE NUMBER*</label>
              <input
                type="text"
                v-model="userObj.mobile"
                v-on="
                  userObj.mobile
                    ? { keyup: v$.userObj.mobile.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.userObj.mobile.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.mobile == ''">MOBILE NUMBER is required</span>
              <span v-else>{{ v$.userObj.mobile.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 mt-3">
            <div class="dd_default ipt_fld">
            <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">
                <label>ROLE*</label>
                <div class="dropdown dd_default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">{{(userObj.userRole)?roleName:'Select Role'}}</button>
                  <ul class="dropdown-menu">
                    <li v-for="role in rolesList" :key="role.roleId">
                      <a @click="changeRole(role)" class="dropdown-item">{{role.roleName}}</a>
                    </li>
                  
                  </ul>
                </div>
                
              </div>
            <span
              v-if="v$.userObj.userRole.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.userRole == ''">Role is required</span>
              <span v-else>{{ v$.userObj.userRole.$errors[0].$message }}</span>
            </span>
            </div>
          </div>

          <div class="col-md-6 mt-3">
            <div class="dd_default ipt_fld">
            <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">
                <label>Status*</label>
                <div class="dropdown dd_default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">{{(userObj.status)?statusName:'Select Status'}}</button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click="changeStatus('Active', 1)" class="dropdown-item">Active</a>
                    </li>
                    <li>
                      <a @click="changeStatus('In Active', 2)" class="dropdown-item">In Active</a>
                    </li>
                  
                  </ul>
                </div>
                
              </div>
            <span
              v-if="v$.userObj.status.$error"
              class="error_msg_log error_msg"
            >
              <span v-if="userObj.status == ''">Status is required</span>
              <span v-else>{{ v$.userObj.status.$errors[0].$message }}</span>
            </span>
            </div>
          </div>

        </div>
        <div class="ad_btns_cnt">
          <button @click="cancel" class="btn_outline">CANCEL</button
          >
          <button
              :disabled="isLoader"
              :class="{ 'disable-btn': isLoader }"
              class="btn_default"
            >
             <div v-if="isLoader">
              <img width="30" src="../../../assets/image/loader.gif" />
            </div>
             <span  v-if="!isLoader"> Add User</span>
            </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>
<script>
import "./css/edit_user_management.css";
import RoleApi from "@/apis/Roles";
import { Base64 } from "js-base64";
import useVuelidate from "@vuelidate/core";
import Api from "@/apis/UserManagment";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "@vuelidate/validators";
import router from "@/routes/routes";
export default {
  name: "add-user",
  data() {
    return {
      roleSelected: "",
      rolesList: [],
      errors: "",
      success: "",
      isLoader: false,
      userObj: {
        userId: 1,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        userRole: "",
        password: "Test@123",
        status: "",
      },
      roleName: '',
      statusName: '',
    };
  },
  created() {
    window.scrollTo(0,0);
    document.title = this.$route.meta.title;
    this.getRolesList();
  },
  methods: {
    async cancel() {
      router.push("/user/user-management");
    },
    async changeRole(role){
      this.roleName = role.roleName;
      this.userObj.userRole = role.roleId;
    },
    async changeStatus(status, id){
      this.statusName = status;
      this.userObj.status = id;
    },
    async addUser() {
      let userId = localStorage.getItem("id");
      this.userObj.userId = Base64.decode(userId);
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.isLoader = true;
        // if no fail validation
        Api.addUser(this.userObj)
          .then((res) => {
            console.log(res);
            this.success = res.data.message;
            this.isLoader = false;
            setTimeout(() => {
              this.$router.push({ name: "UserManagement" });
            }, 3000);
          })
          .catch((err) => {
            this.isLoader = false;
              if(err.response.data.error){
                if(err?.response?.data?.error && err?.response?.data?.error?.name){
                  this.errors = err?.response?.data?.error?.name[0];
                }
                if(err?.response?.data?.error && err?.response?.data?.error?.mobile){
                  this.errors += err?.response?.data?.error?.mobile[0];
                }
              }else{
                this.errors = err.response.data.errors;
              }

              setTimeout(() => {
                this.errors = "";
              }, 5000);
          });
        console.log("Form successfully submitted.");
      } else {
        console.log("Form failed validation");
      }
    },
    async getRolesList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      RoleApi.getRolesList({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.rolesList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      userObj: {
        firstName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
              () => `First name is invalid`,
              (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        lastName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsLastNameRequirement: helpers.withMessage(
              () => `Last name is invalid`,
              (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        email: { required, email },
        userRole: { required },
        status: { required },
      },
    };
  },
};
</script>