import { createStore } from 'vuex'

export default createStore({
    state: {
        collectionName: '',
        defaultProject: [],
        menus: '',
        wishList: [],
        wishListCount: 0,
        cartCount: 0,
      },
    mutations: {
        UPDATE_COLLECTION(state, payload) {
            state.collectionName = payload
        },
        ADD_MENUS(state, payload) {
            state.menus = payload
        },
        ADD_WISHLIST(state, payload) {
            state.wishList[payload] = true;
            state.wishListCount = Object.values(state.wishList)?.length;
        },
        ADD_TOTAL_WISHLIST(state, payload) {
            state.wishList = payload;
            state.wishListCount = Object.values(payload)?.length;
        },
        ADD_WISHLIST_COUNT(state, payload){
            state.wishListCount = payload
        },
        ADD_CART_ITEMS_COUNT(state, payload) {
            state.cartCount += payload;
        },
        CHECK_DEFAULT_PROJECT(state, payload) {
            state.defaultProject = payload
        },
    },
    actions: {
        addCollectionName(context,payload){
            context.commit('UPDATE_COLLECTION', payload)
        },
        addMenus(context,payload){
            context.commit('ADD_MENUS', payload)
        },
        addWishList(context,payload){
            context.commit('ADD_WISHLIST', payload)
        },
        addTotalWishList(context,payload){
            context.commit('ADD_TOTAL_WISHLIST', payload)
        },
        addTotalWishListCount(context,payload){
            context.commit('ADD_WISHLIST_COUNT', payload)
        },
        cartItemsCount(context,payload){
            context.commit('ADD_CART_ITEMS_COUNT', payload)
        },
        checkDefaultProject(context,payload){
            context.commit('CHECK_DEFAULT_PROJECT', payload)
        },
    
    },
    getters: {
        getCollectionName: function (state){
            return state.collectionName;
        },
        getMenus: function (state){
            return state.menus;
        },
        getWishList: function (state){
            return state.wishList;
        },
        getWishListCart: function (state){
            return state.wishList;
        },
        getWishListCount: function (state){
            return state.wishListCount;
        },
        getCartCount: function (state){
            return state.cartCount;
        },
        getDefaultProject: function (state){
            return state.defaultProject;
        },
    }
})