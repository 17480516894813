<template>
  <div class="user_profile">
    <CartHeader />
    <div class="user_profile_body">
      <div class="user_profile_section container-fluid">
        <div class="row mt-lg-5 mt-md-5 w-100 mx-auto">
          <LeftMenusUsersProfile active="myprofile" />
          <router-view></router-view>
        </div>
      </div>
    </div>
    <CartFooter />
  </div>
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader";
import CartFooter from "@/components/layouts/cart/CartFooter";
import LeftMenusUsersProfile from "@/components/layouts/LeftMenu";
import router from "@/routes/routes";
import "./css/common.css";
export default {
  name: "my-profile",
  data() {
    return {
      data: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    LeftMenusUsersProfile,
  },
  setup() {},
  created() {
    document.title = this.$route.meta.title;
    let profileStatus = localStorage.getItem("profileStatus");
    if (profileStatus == 0) {
      router.push({ name: "CompanyDetailsIndividual" });
    } else if (profileStatus == 1) {
      router.push({ name: "DocumentVerificationMsg" });
    }
  },
};
</script>
