<template>
  <div class="user_profile_section_col1 col-2">
    <table>
      <tr>
        <td>
          <router-link to="/user/profile">
            <button
              :class="{ btn_background: menuName == 'profile' }"
              class="ps-4"
            >
              My Profile
            </button></router-link
          >
        </td>
      </tr>
<!--      <tr>-->
<!--        <td>-->
<!--          <router-link to="/user/my-coupon"-->
<!--            ><button-->
<!--              :class="{ btn_background: menuName == 'MyCoupons' }"-->
<!--              class="ps-4"-->
<!--            >-->
<!--              My Coupons-->
<!--            </button></router-link-->
<!--          >-->
<!--        </td>-->
<!--      </tr>-->
      <tr>
        <td>
          <router-link to="/user/address-book"
            ><button
              :class="{ btn_background: menuName == 'AddressBook' }"
              class="ps-4"
            >
              Address Book
            </button></router-link
          >
        </td>
      </tr>
      <tr>
        <td>
          <router-link to="/user/orders">
            <button :class="{ btn_background: menuName == 'Orders' || menuName == 'OrderPayment' }" class="ps-4">
             My Orders
            </button>
          </router-link>
        </td>
      </tr>
<!--      <tr>-->
<!--        <td>-->
<!--          <button-->
<!--            :class="{ btn_background: userMenu,'active': userMenu }" id="dropdown"-->
<!--            v-on:click.prevent="regdrop()"-->
<!--          >-->
<!--            <span> User Roles</span><span class="dropdown_img"></span>-->
<!--          </button>-->
<!--        </td>-->
<!--      </tr>-->
<!--      <tr :class="{ v_hiden: (drop == false && !userMenu) }" class="hover_visible">-->
<!--        <td>-->
<!--          <router-link to="/user/roles">-->
<!--            <button-->
<!--              :class="{ sub_meanu_active: menuName === 'Roles' }"-->
<!--              class="ps-4"-->
<!--            >-->
<!--              Roles Management-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </td>-->
<!--      </tr>-->
<!--      <tr :class="{ v_hiden: (drop == false && !userMenu) }" class="hover_visible">-->
<!--        <td>-->
<!--          <router-link to="/user/user-management">-->
<!--            <button-->
<!--              :class="{ sub_meanu_active: menuName === 'UserManagement' }"-->
<!--              class="ps-4"-->
<!--            >-->
<!--              User Management-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </td>-->
<!--      </tr>-->
      <tr>
        <td>
          <router-link to="/user/price-setting">
            <button
                    :class="{ btn_background: menuName == 'PriceSetting' }"
                    class="ps-4"
            >
              Price Setting
            </button></router-link
          >
        </td>
      </tr>
      <tr>
        <td>
          <router-link to="/user/projects">
            <button
                    :class="{ btn_background: menuName == 'ProjectClient' }"
                    class="ps-4"
            >
              Projects
            </button></router-link
          >
        </td>
      </tr>
      <tr>
        <td>
          <router-link to="/user/change-password">
            <button
              :class="{ btn_background: menuName == 'ChangePassword' }"
              class="ps-4"
            >
              Change Password
            </button></router-link
          >
        </td>
      </tr>
      <tr>
        <td>
          <router-link to="/user/support">
            <button
              :class="{ btn_background: menuName == 'Support' }"
              class="ps-4"
            >
              Support
            </button></router-link
          >
        </td>
      </tr>
      <tr>
        <td>
            <button
              :class="{ btn_background: menuName == 'Signout' }"
              class="ps-4"
              @click="logout"
            >
              Sign Out
            </button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import router from "@/routes/routes";
export default {
  name: "user-profile-left-meanu",
  data() {
    return {
      drop: false,
      menuName: '',
      userMenu: false,
    };
  },
  watch:{
    $route (to){
      this.menuName = to.name;
      if(this.menuName === 'Roles' || this.menuName === 'UserManagement'){
        this.userMenu = true;
      }
      else{
          this.userMenu = false;
      }
      console.log("this.menuName", this.menuName)
    }
  },
  created() {
    if(router.currentRoute.value.name){
      this.menuName = router.currentRoute.value.name;
      if(this.menuName === 'Roles' || this.menuName === 'UserManagement'){
        this.userMenu = true;
      }
      else{
          this.userMenu = false;
      }
    }
    if (this.submeanuactive === "roleMg" || this.submeanuactive === "userMg") {
      this.drop = true;
    }
    console.log(this.submeanuactive);
    console.log(this.drop);
  },
  methods: {
    async regdrop() {

      if (this.drop == false){
        // alert(this.menuName)
        document.getElementById("dropdown").classList.add('btn_background');
        document.getElementById("dropdown").classList.add('active');
      }
      else if (this.menuName === 'Roles' || this.menuName === 'UserManagement'){
        document.getElementById("dropdown").classList.add('btn_background');
        document.getElementById("dropdown").classList.add('active');
      }
      else {
        document.getElementById("dropdown").classList.remove('btn_background');
        document.getElementById("dropdown").classList.remove('active');
      }

      this.drop = !this.drop;
    },
    logout() {
      localStorage.clear();
      router.push({name: 'Login'});
    }
  },
  props: ["active", "submeanuactive"],
  setup() {},
  method: {
    async regdrop() {
      alert("hello");
      if (this.drop === false) {
        this.drop = true;
        console.log("what");
      } else if (this.drop === true) {
        this.drop = false;
      }
      {
        console.log("nothing");
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/my_profile.css";
.btn_background {
  background-color: #080A1C;
  color: white;
}
.visible {
  display: block;
}
.v_hiden {
  display: none;
}
.user_profile_section_col1 table .hover_visible button:hover {
  background-color: #ffffff !important;
  color: #080a1c !important;
  border-left: 4px solid #080a1c !important;
}
.user_profile_section_col1 table .hover_visible button:focus {
  background-color: #ffffff !important;
  color: #080a1c !important;
  border-left: 4px solid #080a1c !important;
}
.sub_meanu_active {
  border-left: 4px solid #080a1c;
  background-color: #fff;
  color: #080a1c;
}
</style>
