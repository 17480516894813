<template>
  <div class="col-md-9 col-12 user_order_t_section_col2 py-md-0">
    <div class="coupon-card-sec">
      <h2 class="ac_pg_head">My Coupons</h2>
      <div class="coupon-card-lst">
        <div class="row">
          <div v-for="item in couponsData" :key="item.id" class="col-lg-4 col-md-6">
            <div class="cpn_itm">
              <h5>{{item.name}}</h5>
<!--              <p>Get up to 30% offer on your first order above 200 QAR and above</p>-->
              <p>Get up to  <span v-if="item.couponType == 1">QAR {{item.worth}}</span> <span v-else>{{item.worth}}%</span>  off</p>
              <div class="coupon-display-sec">
                <div class="input-group inpt_bx">
                  <input type="text" class="form-control" :placeholder="item.code" v-on:focus="$event.target.select()" :ref="`myinput+${item.id}`" readonly :value="item.code">
                  <button class="btn btn_default b_w_ust" type="button"
                   @click="copy(item)">
                  <span v-if="checkBool[item.id]"><img src="../../../assets/image/check-mark-white.svg" alt="" />
                          </span>
                  <span v-if="!checkBool[item.id]">copy</span></button>
                </div>

              </div>
<!--              <p class="cpn_expry">this code will expire <b>{{formatDate(item.validTo)}}</b></p>-->
              <p class="cpn_itm" >expire on
                <b class="cpn_expry">&nbsp; {{ item.validTo }} </b>
              </p>
              <p v-if="getNow() < item.validTo ">Active</p>
              <p v-else>Expired</p>
<!--              <div class="coupon-redem-btn"><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">How to-->
<!--                  redeem</a></div>-->
            </div>
          </div>
          <div v-if="!(couponsData.length>0)">
            <p style="text-align:center; margin-top: 100px">No coupons available</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- How to redeem Modal  -->
  <div class="modal fade modal-lg how-to-rdm-coupn-modal" id="exampleModal1" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5 class="modal-title text-center" id="staticBackdropLabel">How to redeem</h5>
          <p>Hero can be anyone. Even a man knowing something as simple and reassuring as putting a coat around a young
            boy shoulders to let him know the world hadn’t ended.You fight like a younger man, there’s nothing held
            back. It’s admirable, but mistaken. When their enemies were at the gates the Romans would suspend democracy
            and appoint one man to protect the city.</p>
          <ul>
            <li>PPC Management Services</li>
            <li>Enterprise PPC Management Services</li>
            <li>Programmatic Advertising Services</li>
            <li>Competitor Geofencing Services</li>
            <li>Addressable Geofencing Services</li>
            <li>Employment & Recruiting Marketing</li>
          </ul>
        </div>
        <div class="modal-footer border-0 pb-4">
          <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <!-- How to redeem Modal  -->
</template>
<script>
import "./css/coupons.css";
import Api from "@/apis/User";
import {Base64} from "js-base64";
import moment from 'moment'
export default {
  name: "user-coupons",
  data() {
    return {
      couponsData: [],
      checkBool: [],
      validTo: "",
    };
  },
  created() {
    window.scrollTo(0,0);
    document.title = this.$route.meta.title;
    this.getMyCoupons();
  },
  methods: {
    getNow() {
      return moment(new Date()).format('DD-MM-YYYY HH:MM A')

    },
    copy(item) {
        let text = item.code
        this.checkBool[item.id] = true;
        var selected = false;
        var el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        if (document.getSelection().rangeCount > 0) {
            selected = document.getSelection().getRangeAt(0)
        }
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
        setTimeout(() => {
              this.checkBool[item.id] = false;
            }, 1000);
    },
    formatDate(value){
        if (value) {
          return value.split(" ")[0];
          //return moment(String(value)).format('DD/MM/YYYY')
        }
    },
    async getMyCoupons() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getMyCoupons({
        userId:userId
      })
        .then((res) => {
          if (res.data.success) {
            this.couponsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>