<template>
  <!--  Header Start -->
  <CartHeader :cartCountUpdate="cartCount" />
  <!-- Header End -->
  <section class="products_sec">
    <div class="page-width">
      <!-- Breadcrumb Sec -->
      <section v-if="mainMenu" class="breadcrumb_sec">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home">Home</router-link>
            </li>
            <li v-if="mainMenu" class="breadcrumb-item">
              <router-link data-target="trending" class="nav_trig"
                :to="`/products-list/${sanitizeTitle(mainMenu)}/${encryptIDs(mainId)}`">{{ mainMenu }}</router-link>
            </li>
            <li v-if="subMenu" class="breadcrumb-item">
              <router-link :to="`/products/${sanitizeTitle(mainMenu)}/${sanitizeTitle(subMenu)}/${encryptIDs(mainId, subId)}`">{{ subMenu }}</router-link>
            </li>
            <li v-if="childMenu" class="breadcrumb-item" aria-current="page">
              <router-link
                :to="`/products/${sanitizeTitle(mainMenu)}/${sanitizeTitle(subMenu)}/${sanitizeTitle(childMenu)}/${encryptIDs(mainId, subId, childId)}`">
                {{ childMenu }}
              </router-link>
            </li>
            <li v-if="title" class="breadcrumb-item active">{{ title }}</li>
          </ol>
        </nav>
      </section>
      <section v-else class="breadcrumb_sec">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home">Home</router-link>
            </li>
            <li class="breadcrumb-item" aria-current="page"  v-if="previousUrl != '/home' && breadCrumb != null">
              <router-link :to="previousUrl">{{previousTitle ? previousTitle : breadCrumb.searchTerm}}</router-link>
            </li>

            <li class="breadcrumb-item active" aria-current="page">
              {{ title }}
            </li>
          </ol>
        </nav>
      </section>
      <!-- Breadcrumb Sec -->
      <!-- Product Details -->
      <div class="prod_det_cnt">
        <div class="row">
          <div class="col-lg-6 prod_d_col">
            <div class="outer">
              <ProductZoomer :base-images="images" :base-zoomer-options="zoomerOptions" :key="zoomKey" />
              <div class="big_img_cnt dsk_cnt">
                <div v-if="productData.glbObject" class="btn_actions_cnt">
                  <button class="btn_outline dimen_btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <span>View in 3D
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.017"
                        height="22.465" viewBox="0 0 20.017 22.465">
                        <defs>
                          <clipPath id="a">
                            <rect width="20.017" height="22.465" fill="none" />
                          </clipPath>
                        </defs>
                        <g transform="translate(0 0)" clip-path="url(#a)">
                          <path
                            d="M10.057,0a.265.265,0,0,1,.129.034L19.978,5.5a.265.265,0,0,1,.136.231V16.748a.265.265,0,0,1-.134.231l-9.792,5.549a.265.265,0,0,1-.261,0L.134,16.979A.265.265,0,0,1,0,16.748V5.732A.265.265,0,0,1,.136,5.5L9.928.034A.265.265,0,0,1,10.057,0Zm9.527,5.888L10.057.569.53,5.888V16.594l9.527,5.4,9.527-5.4Z"
                            transform="translate(-0.049 -0.049)" />
                          <path
                            d="M10.057,13.279a.265.265,0,0,1-.131-.034L.134,7.7A.265.265,0,0,1,.4,7.234l9.662,5.475,9.661-5.475a.265.265,0,0,1,.261.461l-9.792,5.549A.265.265,0,0,1,10.057,13.279Z"
                            transform="translate(-0.049 -1.373)" />
                          <path
                            d="M0,10.873a.265.265,0,0,1-.265-.265V0A.265.265,0,0,1,0-.265.265.265,0,0,1,.265,0V10.608A.265.265,0,0,1,0,10.873Z"
                            transform="translate(10.008 11.64)" />
                        </g>
                      </svg>
                    </span>
                  </button>
                  <!-- View In 3D Model-->
                  <div class="modal view-in-3d-modal fade" id="exampleModal" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header border-0">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <model-viewer :src="productData.glbObject" crossOrigin="anonymous2"
                            requestHeader="{ 'Access-Control-Allow-Origin': '*'}" ar
                            ar-modes="wall webxr scene-viewer quick-look" camera-controls poster="poster.webp"
                            shadow-intensity="1" with-credentials=true>
                          </model-viewer>
                        </div>
                        <div class="modal-footer border-0 justify-content-center" v-if="productData.glbZip">
                          <a :href="productData.glbZip" class="btn btn-dark" target="_blank">Download 3d model</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- View In 3D Model-->
                <a :href="`${baseURL}productSheetDownload/${productData.id}`" target="_blank">
                  <button class="btn_outline dimen_btn">
                    <span>Product Sheet
                      <svg xmlns="http://www.w3.org/2000/svg" width="21.16" height="21" viewBox="0 0 21.16 21">
                        <g transform="translate(0.08)">
                          <rect width="21" height="21" fill="none" />
                          <path
                            d="M17.841,18.417H14.02a.5.5,0,0,1,0-1h3.821a2.819,2.819,0,0,0,.014-5.637h-.038a.5.5,0,0,1-.5-.561A4.6,4.6,0,0,0,8.444,9.055a.5.5,0,0,1-.823.18,2.023,2.023,0,0,0-3.455,1.429c0,.009,0,.018,0,.027s0,.04,0,.061a.5.5,0,0,1-.366.5,3.139,3.139,0,0,0,.837,6.166h3.5a.5.5,0,0,1,0,1h-3.5a4.139,4.139,0,0,1-1.465-8.011,3.025,3.025,0,0,1,4.6-2.315A5.6,5.6,0,0,1,18.36,10.649c0,.054,0,.109,0,.165a3.819,3.819,0,0,1-.516,7.6Z"
                            transform="translate(-0.58 -3.217)" />
                          <path
                            d="M22.1,43.807l-2.454-2.454a.5.5,0,0,1,.707-.707L22.1,42.393l1.746-1.746a.5.5,0,0,1,.707.707Z"
                            transform="translate(-11.6 -23.78)" />
                          <path d="M0,8.761a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.261A.5.5,0,0,1,0,8.761Z"
                            transform="translate(10.5 10.92)" />
                        </g>
                      </svg>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 prod_d_col">
            <div class="prod_title">{{ productData.title }}</div>
            <div class="prod_price" v-if="this.priceBool == 0 || this.priceBool == null">
              <div v-if="productData?.variants[0]?.orginalPrice == productData?.variants[0]?.price">
                {{ common.formatNumber(productData?.variants[0]?.price) }}
              </div>
              <div v-else>
               
                <div>{{ common.formatNumber(productData?.variants[0]?.price) }}</div>
                <del class="dis_price produ_dis_price"> {{ common.formatNumber(productData?.variants[0]?.orginalPrice) }}</del>
              </div>
            </div>
            <div class="prod_price_arb" v-if="this.priceBool == 0 || this.priceBool == null">
              <span style="float: left">ر.ق &nbsp;</span>
              <span class="translate">{{ productData?.variants[0]?.qarPrice }}</span>
            </div>
            <div class="prod_wish_cnt">
<!--              <div class="product_filter_sec">-->
<!--{{!wishListBool[productData.variants[0]?.productId]}}-->
              <div class="prod_w_itm" >

                <button v-if="!wishListBool[productData?.variants[0]?.productId] && projectDefault == null" data-bs-toggle="modal" data-bs-target="#wishlistModel" class="addwishlisticon"  @click="ItemSetId"  :data-id="productData.variants[0]?.productId">
                  <img class="wish_icn" src="../../assets/image/SVG/heart.svg" />
                </button>

                <button v-else-if="!wishListBool[productData?.variants[0]?.productId]" @click="addToWishList(productData)" class="addwishlisticon">
                  <img class="wish_icn" src="../../assets/image/SVG/heart.svg" />
                </button>

                <button v-if="wishListBool[productData?.variants[0]?.productId]" class="addwishlisticon">
                  <img v-on:click.prevent="deleteWishlist(productData.variants[0])" class="wish_icn"
                    src="../../assets/image/SVG/heart-fill.svg" />
                </button>

                <span v-if="!wishListBool[productData?.variants[0]?.productId] && projectDefault == null" data-bs-toggle="modal" data-bs-target="#wishlistModel" @click="ItemSetId"  :data-id="productData.variants[0]?.productId">Add
                  to Wishlist</span>

                <span v-else-if="!wishListBool[productData?.variants[0]?.productId]" @click="addToWishList(productData)">Add to Wishlist </span>

                <span v-if="wishListBool[productData?.variants[0]?.productId]" v-on:click.prevent="deleteWishlist(productData.variants[0])">Remove from Wishlist</span>


              </div>

              <!-- <div class="prod_w_itm">
                <img class="wish_icn" src="../../assets/image/SVG/compare_icn.svg" />
                <span>Add to Compare</span>
              </div> -->
            </div>
            <div class="product_filter_sec" v-if="productData.variants[0]?.stock <= 0 && productData.preOrder != 1">
              <div class="form-check">
                <label class="form-check-label text-none" for="flexCheck3">
                  NOTIFY ME ONCE ITEM IS BACK IN STOCK
                  <!--                  Notify me once item is back in stock -->
                  <input class="form-check-input" type="checkbox" value="" @change.prevent="NotifyItem(productData?.id,productData.variants[0]?.id)" ref="theNotifyCheck" id="flexCheck3">
                  <span class="checkmark"></span>
                  <div v-if="message" style="text-transform: uppercase !important; font-size: 12px" class="text-success">{{ message }}</div>
                </label>
              </div>
            </div>

            <div class="cart_row d-flex align-items-end"  v-if="productData.variants[0]?.stock > 0 && productData.preOrder == 0 || productData.preOrder == 1">
              <div class="prod_qty">
                <label for="Quantity" class="qty_sel_label">Quantity</label>
                <div class="sp-quantity">
                  <div class="sp-minus btn_qty" v-on:click.prevent="incQuantity(-1)">
                    -
                  </div>
                  <div class="sp-input">
                    <input type="text" class="quntity-input" v-model="productQty" />
                  </div>
                  <div class="sp-plus btn_qty" v-on:click.prevent="incQuantity(1)">
                    +
                  </div>
                </div>
              </div>
              <div class="cart_btn_cnt">
                <button :disabled="loaderBool[productData.id]" :class="{ 'disable-btn': loaderBool[productData.id] }"
                  v-on:click.prevent="addToCart(productData)" class="btn_default">
                  <span v-if="checkBool[productData.id]"><img src="../../assets/image/check-mark-white.svg" alt="" />
                  </span>
                  <div v-if="loaderBool[productData.id]">
                    <img width="22" src="../../assets/image/loader.gif" />
                  </div>
                  <span v-if="
  !checkBool[productData.id] && !loaderBool[productData.id]
">
                    {{(productData?.variants[0]?.stock<=0) ? (productData.preOrder == 1) ? 'Pre Order' : 'Out of Stock' : 'Add To Cart' }}
<!--                    {{ (productData?.variants[0]?.stock <= 0) ? "Pre Order" : "Add To Cart" }} -->
                  </span>
                </button>
              </div>
            </div>
            <p style="color:red;" v-if="stockMsgBool">{{ stockMsg }}</p>
            <p v-if="productData?.preOrder == 1 && productData?.variants[0]?.stock <= 0" class="tran_note mb-2">
              This product is estimate to be back in stock soon. Pre-order today, and our customer support team will be in touch with you shortly.
            </p>

            <!--            <p class="tran_note">-->
            <!--              Customers outside of Qatar please contact THAT'S LIVING-->
            <!--              <router-link to="/contactus">Customer care</router-link>-->
            <!--            </p>-->
            <div class="big_img_cnt mbl_cnt">
              <div v-if="productData.glbObject" class="btn_actions_cnt">
                <button class="btn_outline dimen_btn" data-bs-toggle="modal" data-bs-target="#mobileModel">
                  <span>View in 3D
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.017"
                      height="22.465" viewBox="0 0 20.017 22.465">
                      <defs>
                        <clipPath id="a">
                          <rect width="20.017" height="22.465" fill="none" />
                        </clipPath>
                      </defs>
                      <g transform="translate(0 0)" clip-path="url(#a)">
                        <path
                          d="M10.057,0a.265.265,0,0,1,.129.034L19.978,5.5a.265.265,0,0,1,.136.231V16.748a.265.265,0,0,1-.134.231l-9.792,5.549a.265.265,0,0,1-.261,0L.134,16.979A.265.265,0,0,1,0,16.748V5.732A.265.265,0,0,1,.136,5.5L9.928.034A.265.265,0,0,1,10.057,0Zm9.527,5.888L10.057.569.53,5.888V16.594l9.527,5.4,9.527-5.4Z"
                          transform="translate(-0.049 -0.049)" />
                        <path
                          d="M10.057,13.279a.265.265,0,0,1-.131-.034L.134,7.7A.265.265,0,0,1,.4,7.234l9.662,5.475,9.661-5.475a.265.265,0,0,1,.261.461l-9.792,5.549A.265.265,0,0,1,10.057,13.279Z"
                          transform="translate(-0.049 -1.373)" />
                        <path
                          d="M0,10.873a.265.265,0,0,1-.265-.265V0A.265.265,0,0,1,0-.265.265.265,0,0,1,.265,0V10.608A.265.265,0,0,1,0,10.873Z"
                          transform="translate(10.008 11.64)" />
                      </g>
                    </svg>
                  </span>
                </button>
                <!-- View In 3D Model-->
                <div class="modal view-in-3d-modal fade" id="mobileModel" tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header border-0">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <!-- Use it like any other HTML element -->
                        <model-viewer :src="productData.glbObject" crossorigin="anonymous2" ar
                          ar-modes="wall webxr scene-viewer quick-look" camera-controls poster="poster.webp"
                          shadow-intensity="1">
                        </model-viewer>
                      </div>
                      <div class="modal-footer border-0 justify-content-center">
                        <a :href="productData.glbZip" class="btn btn-dark" target="_blank">Download 3d model</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- View In 3D Model-->
              <a :href="`productSheetDownload/${productData.id}`" target="_blank">
                <button class="btn_outline dimen_btn">
                  <span>Product Sheet
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.16" height="21" viewBox="0 0 21.16 21">
                      <g transform="translate(0.08)">
                        <rect width="21" height="21" fill="none" />
                        <path
                          d="M17.841,18.417H14.02a.5.5,0,0,1,0-1h3.821a2.819,2.819,0,0,0,.014-5.637h-.038a.5.5,0,0,1-.5-.561A4.6,4.6,0,0,0,8.444,9.055a.5.5,0,0,1-.823.18,2.023,2.023,0,0,0-3.455,1.429c0,.009,0,.018,0,.027s0,.04,0,.061a.5.5,0,0,1-.366.5,3.139,3.139,0,0,0,.837,6.166h3.5a.5.5,0,0,1,0,1h-3.5a4.139,4.139,0,0,1-1.465-8.011,3.025,3.025,0,0,1,4.6-2.315A5.6,5.6,0,0,1,18.36,10.649c0,.054,0,.109,0,.165a3.819,3.819,0,0,1-.516,7.6Z"
                          transform="translate(-0.58 -3.217)" />
                        <path
                          d="M22.1,43.807l-2.454-2.454a.5.5,0,0,1,.707-.707L22.1,42.393l1.746-1.746a.5.5,0,0,1,.707.707Z"
                          transform="translate(-11.6 -23.78)" />
                        <path d="M0,8.761a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.261A.5.5,0,0,1,0,8.761Z"
                          transform="translate(10.5 10.92)" />
                      </g>
                    </svg>
                  </span>
                </button>
              </a>
            </div>
            <div class="divider_hr"></div>
            <div class="prod_sub">

              <p v-if="productData.variants[0]?.stock > 0 && productData.preOrder == 0">
                <label>In Stock: </label>
                <span>{{productData.variants[0]?.stock}}</span>
              </p>

              <p v-else-if="productData.preOrder == 1">
                <label>Ready to ship: </label>
                <span>{{ productData.variants[0]?.stock > 0 ? productData.variants[0]?.stock : 0  }} (You can also order more)</span>
              </p>

              <p v-else-if="productData.variants[0]?.stock <= 0 && productData.preOrder == 0">
                <label>AVAILABILITY: </label>
                <span style="color: red">Out of Stock</span>
              </p>


<!--              <p>-->
<!--                <label>AVAILABILITY: </label>-->
<!--                <span v-if="productData.variants[0]?.stock > 0" class="text-success">-->
<!--                  <span v-if="productData.preOrder == 0">In Stock : {{productData.variants[0]?.stock}}</span>-->
<!--                  <span v-else-if="productData.preOrder == 1"> Ready to ship : {{ productData.variants[0]?.stock > 0 ? productData.variants[0]?.stock : 0  }} ( You can order more too)</span>-->

<!--                </span>-->
<!--                <span v-if="productData.variants[0]?.stock <= 0 && productData.preOrder != 1" style="color: red">Out of Stock</span>-->
<!--&lt;!&ndash;                <span v-if="productData.preOrder == 1 && productData.variants[0]?.stock <= 0" style="color: red">Pre Order</span>&ndash;&gt;-->
<!--                <span v-if="productData.preOrder == 1 && productData.variants[0]?.stock <= 0" style="color: red">Pre Order</span>-->
<!--              </p>-->






              <p><label>SKU: </label> {{ productData.variants[0]?.sku }}</p>
              <p><label>Vendor: </label> {{ productData.brand }}</p>
              <p>
                <label>Product Type: </label> {{ productData.listSubCategory }}
              </p>
              <p v-if="productData.variants[0]?.sets != null">
                <label>Sets: </label>
                {{ productData.variants[0]?.sets }}
              </p>
                <p v-else>
                    <label>Dimensions: </label>
                    {{ productData.variants[0]?.dimensions }}
                </p>
              <!--              <p>-->
              <!--                <label>Share</label>-->
              <!--                <span class="social-share">-->
              <!--                  <a href="#">-->
              <!--                    <img src="../../assets/image/SVG/twitter.svg" />-->
              <!--                  </a>-->
              <!--                  <a href="#">-->
              <!--                    <img src="../../assets/image/SVG/facebook.svg" />-->
              <!--                  </a>-->
              <!--                </span>-->
              <!--              </p>-->
            </div>

            <div class="shpng-delvr-policies">
              <router-link to="/terms-conditions">View shipping & Delivery Details</router-link>
            </div>
          </div>
        </div>
        <div class="det_sec acco_desk_ver">
          <div class="row g-0">
            <div class="col-lg-6 det_cnt desc_sec">
              <div class="det_head">Description</div>
              <div class="det_body">
                <p v-html="productData.productDescription"></p>
              </div>
            </div>
            <div class="col-lg-6 det_cnt spec_sec">

              <div class="det_body">
                <div class="prod_spec">
                  <div class="row">
                    <div  v-if="productData.variants[0]?.catBlock == null" class="col-xl-12">
                      <div class="det_head">Specifications</div>
                      <p v-if="!productData.specifications.length > 0">
                        No specifications
                      </p>
                      <p v-for="spec in productData.specifications" :key="spec.id">
                        <strong>{{ spec.specificationName }} :</strong> {{ spec.specificationValue }}
                      </p>
                    </div>
                    <div  v-else class="col-xl-8">
                      <div class="det_head">Specifications</div>
                      <p v-if="!productData.specifications.length > 0">
                        No specifications
                      </p>
                      <p v-for="spec in productData.specifications" :key="spec.id">
                        <strong>{{ spec.specificationName }} :</strong> {{ spec.specificationValue }}
                      </p>
                    </div>
                    <div class="col-xl-4" v-if="productData.variants[0]?.catBlock">
                      <div class="cb_cnt">
                        <div class="det_head">CAD Block <small>(CM)</small></div>
                        <img class="img-fluid"  data-bs-toggle="modal" data-bs-target="#exampleModal1"
                          :src="productData.variants[0]?.catBlock ">
                        <a class="d_btn" :href="productData.variants[0]?.catBlock" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                          Preview CAD block

                        </a>
                      </div>
                    </div>
                    <div class="modal view-in-3d-modal fade" id="exampleModal1" tabindex="-1"
                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header border-0">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                           <div  v-if="productData.variants[0]?.catBlock">
                             <img class="img-fluid" :src="productData.variants[0]?.catBlock">
                           </div>
                          </div>
                          <div class="modal-footer border-0 justify-content-center" v-if="productData.variants[0]?.CadBlockDwg">
<!--                            <a @click="downloadImage('https://i.imgur.com/lF1GKDt.jpg')" class="btn btn-dark" >Download CAD block</a>-->
                            <a :href="productData.variants[0]?.CadBlockDwg" class="btn btn-dark" >Download CAD block</a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="det_sec accordion acco_mobile_ver">
          <div class="row g-0">
            <div class="col-lg-6 det_cnt desc_sec">
              <div class="det_head accordion-header" id="specification-headingOne" data-bs-toggle="collapse"
                    data-bs-target="#specification-collapseOne"
                    aria-expanded="false"
                    aria-controls="specification-collapseOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#specification-collapseOne" aria-expanded="false" aria-controls="specification-collapseOne">Description  </button></div>
              <div class="det_body accordion-collapse collapse" id="specification-collapseOne"
                 
                  aria-labelledby="specificatio-headingOne">
                <p v-html="productData.productDescription"></p>
              </div>
            </div>
            <div class="col-lg-6 det_cnt spec_sec">

              <div class="det_body" >
                <div class="prod_spec">
                  <div class="row">
                    <div  class="col-xl-12">
                      <div class="det_head accordion-header" id="specification-headingTwo" data-bs-toggle="collapse"
                    data-bs-target="#specification-collapseTwo"
                    aria-expanded="false"
                    aria-controls="specification-collapseTwo">
                    <button class="accordion-button defaiult_icon" type="button" data-bs-toggle="collapse" data-bs-target="#specification-collapseTwo" aria-expanded="false" aria-controls="specification-collapseTwo">Specifications </button></div>
                    <div class="det_body accordion-collapse collapse" id="specification-collapseTwo"
                 
                 aria-labelledby="specificatio-headingTwo">
                      <p v-if="!productData.specifications.length > 0">
                        No specifications
                      </p>
                      <p v-for="spec in productData.specifications" :key="spec.id">
                        <strong>{{ spec.specificationName }} :</strong> {{ spec.specificationValue }}
                      </p>
                      </div>
                    </div>




                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-6 det_cnt spec_sec"  v-if="productData.variants[0]?.catBlock">

<div class="det_body">
  <div class="prod_spec">
    <div class="row">
      <div class="col-xl-8">
        <div class="det_head accordion-header" id="specification-headingThree" data-bs-toggle="collapse"
      data-bs-target="#specification-collapseThree"
      aria-expanded="false"
      aria-controls="specification-collapseTwo">
      <button class="accordion-button defaiult_icon" type="button" data-bs-toggle="collapse" data-bs-target="#specification-collapseThree" aria-expanded="false" aria-controls="specification-collapseThree">CAD Block <small>(CM)</small> </button></div>
      <div class="det_body accordion-collapse collapse" id="specification-collapseThree"
   
   aria-labelledby="specificatio-headingTwo">
   <div class="col-xl-4" v-if="productData.variants[0]?.catBlock">
        <div class="cb_cnt">
       
          <img class="img-fluid"  data-bs-toggle="modal" data-bs-target="#exampleModal3"
            :src="productData.variants[0]?.catBlock ">
          <a class="d_btn" :href="productData.variants[0]?.catBlock" data-bs-toggle="modal" data-bs-target="#exampleModal3">
            Preview CAD block

          </a>
        </div>
      </div>
      <div class="modal view-in-3d-modal fade" id="exampleModal3" tabindex="-1"
           aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
             <div  v-if="productData.variants[0]?.catBlock">
               <img class="img-fluid" :src="productData.variants[0]?.catBlock">
             </div>
            </div>
            <div class="modal-footer border-0 justify-content-center">
<!--                            <a @click="downloadImage('https://i.imgur.com/lF1GKDt.jpg')" class="btn btn-dark" >Download CAD block</a>-->
              <a @click="downloadImage(productData.variants[0]?.catBlock)" class="btn btn-dark" >Download CAD block</a>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
   

    </div>

  </div>
</div>
</div>
          </div>
        </div>
        <div class="nh_section">
          <div class="row">
            <div class="col">
              <h4><b>Need Help</b></h4>
            </div>
            <div class="col-lg-3">
              <a href="tel:+97477266267">
                <img class="img-fluid" src="../../assets/image/SVG/call.svg" />
                +974 7726 6267
              </a>
            </div>
            <div class="col-lg-3">
              <a href="mailto:contact@thatsliving.com">
                <img class="img-fluid" src="../../assets/image/SVG/email.svg" />
                contact@thatsliving.com
              </a>
            </div>
            <div class="col-lg-3">
              <router-link to="/contactus">
                <img class="img-fluid" src="../../assets/image/SVG/callback.svg" />
                Request Call Back
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Product Details -->

      <!-- Care Instructions -->
      <!--      <div class="det_sec">-->
      <!--        <div class="row g-0">-->
      <!--          <div class="col-lg-12 det_cnt desc_sec mb-5">-->
      <!--            <div class="det_head">Care instructions</div>-->
      <!--            <div class="det_body">-->
      <!--              <p>-->
      <!--                Dashing and distinctly modern, this architecturally inspired bed-->
      <!--                introduces an updated take on a classic Parsons style. Its-->
      <!--                dramatic headboard is upholstered in a light-toned performance-->
      <!--                fabric, richly contrasted by Dark Chocolate trim. An inlaid-->
      <!--                metal accent in Champagne Gold adds warmth and a sophisticated-->
      <!--                detail. Fully upholstered side rails and footboard are-->
      <!--                beautifully balanced on a Dark Chocolate plinth base.-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- Care Instructions -->

      <!-- Related Products -->
      <div class="product_wish_list mb-5">
        <div class="page_ttl-sub">RELATED PRODUCTS</div>
        <div class="product_list_view">
          <div class="row">
            <ProductCard v-for="item in recentProdData.relatedProducts" :key="item.id" :productData="item" />
          </div>
        </div>
      </div>
      <!-- Related Products -->
      <hr v-if="this.recentProdDataAll.recentViewedProducts[0] != null" />
      <!-- Recently Viewed Products -->
      <div class="product_wish_list my-5" v-if="recentProdDataAll.recentViewedProducts[0] != null">
        <div class="page_ttl-sub">Recently Viewed PRODUCTS</div>
        <div class="product_list_view">
          <div class="row">
            <ProductCard v-for="item in recentProdData.recentViewedProducts" :key="item.id" :productData="item" />
          </div>
        </div>
      </div>
      <!-- Recently Viewed Products -->
      <hr v-if="recentProdData.mostViewedProducts" />
      <!-- MOST VIEWED PRODUCTS -->
      <div class="product_wish_list my-5" v-if="recentProdData.mostViewedProducts">
        <div class="page_ttl-sub">MOST VIEWED PRODUCTS</div>
        <div class="product_list_view">
          <div class="row">
            <ProductCard v-for="item in recentProdData.mostViewedProducts" :key="item.id" :productData="item" />
          </div>
        </div>
      </div>
      <!-- MOST VIEWED PRODUCTS -->
    </div>
    <div class="modal view-in-3d-modal fade" id="wishlistModel" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" @click="closePopup" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closePop"></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">




                <div v-if="this.projects.length == 0">
                  <span style="margin-left: 30px">Please set anyone project as default</span>
                  <br/> <br/>
                  <div class="ad_btns_cnt">
                    <button @click="addProject"
                            class="btn_default">
                      <span>Create New Project</span>
                    </button>

                  </div>

                </div>
                <div v-else-if="this.projects.length > 0">


                  <div class="modal-head heade_whish">
                    <h5>Choose Your project to add to WishList </h5>
                  </div>

                  <div class="project_popup modal-body">
                    <div class="whish_check product_filter_sec">

                      <div class="form-check" v-for="(pro,index) in projects" :key="index">
                        <label class="form-check-label">
                          {{pro.projectClientName}}
                          <input class="form-check-input" type="checkbox" v-model="this.projectIds" :value="pro.id">
                          <span class="checkmark"></span>
                        </label>
                      </div>




                    </div>

                  </div>
                  <!--                                <div class="whist_checbox" v-if="projectIds.length <= 1">-->
                  <!--                                    <div class="product_filter_sec">-->
                  <!--                                        <div class="form-check">-->
                  <!--                                            <label class="form-check-label text-none" for="flexCheck3"> MAKE THIS PROJECT AS DEFAULT FOR WISHLIST-->
                  <!--                                                <input class="form-check-input" type="checkbox" id="flexCheck3" name="isDefault"-->
                  <!--                                                       @change="onChangeDefault"-->
                  <!--                                                       :value="client.isDefault"-->
                  <!--                                                       :checked="client.isDefault == 1">-->
                  <!--                                                <span class="checkmark"></span>-->
                  <!--                                            </label>-->
                  <!--                                        </div>-->
                  <!--                                    </div>-->
                  <!--                                </div>-->
                  <div class="ad_btns_cnt whist_btns">
                    <button class="btn_outline" @click="addProject">
                      <span> Create New Project</span>
                    </button>
                    <button
                        class="btn_default" @click="addToWishListModel">
                      <span> Add To Project</span>
                    </button>

                  </div>




                </div>

              </div>

            </div>






          </div>

        </div>
      </div>
    </div>
  </section>
  <CartFooter />
</template>
<script>
import "../cart/css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/Products";
import Api1 from "../../apis/Api";
import { Base64 } from "js-base64";
import common from "@/apis/Common";
import ProductCard from "@/components/common/ProductCard";
//import { VueAgile } from 'vue-agile';
import ProductZoomer from "../common/ProductZoomer";
import wishListApi from "@/apis/WishList";
import User from "../../apis/User";
import FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import Api2 from "@/apis/Project";
import Project from "@/apis/Project";
/*eslint-disable */
//import * as modelViewer from "../../assets/model-viewer.js";
/*eslint-disable */
export default {
  name: "product-details",
  data() {
    return {
      message: '',
      baseURL: Api1.defaults.baseURL,
      images: {
        thumbs: [],
        normal_size: [],
        large_size: [],
      },
      imgData: [],
      productData: [],
      recentProdData: [],
      recentProdDataAll: [],
      checkBool: [],
      loaderBool: [],
      productQty: 1,
      cartCount: 10,
      common: common,
      zoomerOptions: [],
      options: {
        pane: "pane", // three type of pane ['pane', 'container-round', 'container']
        hoverDelay: 300, // how long after the zoomer take effect
        namespace: "zoomer-left", // add a namespace for zoomer component, useful when on page have mutiple zoomer
        move_by_click: false, // move image by click thumb image or by mouseover
        scroll_items: 5, // thumbs for scroll
        choosed_thumb_border_color: "#bbdefb", // choosed thumb border color
        scroller_button_style: "line",
        scroller_position: "left",
        zoomer_pane_position: "right",
      },
      mobileOptions: {
        zoomFactor: 30,
        pane: "pane",
        hoverDelay: 300,
        namespace: "zoomer-bottom",
        move_by_click: true,
        scroll_items: 3,
        choosed_thumb_border_color: "#bbdefb",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
      },
      windowWidth: "600",
      zoomKey: 1,
      wishListBool: [],
      title: '',
      mainMenu: "",
      subMenu: "",
      childMenu: "",
      mainId: "",
      subId: "",
      priceBool: "",
      childId: "",
      projectDefault: [],
      projects: [],
      previousUrl: '',
      previousTitle: '',
      breadCrumb:"",
      client: {
        isDefault: 0,
      },
      projectIds: [],
      stockMsgBool: false,
      stockMsg: 'You have reached the maximum available quantity.'

    };
  },
  components: {
    CartHeader,
    CartFooter,
    saveAs,
    //agile: VueAgile,
    //ZoomOnHover
    ProductZoomer,
    ProductCard,
  },
  created() {
    // localStorage.setItem("breadCrumb", JSON.stringify(this.$route.params));
    // this.wishListBool = this.$store.getters.getWishList;
    this.projectBool = localStorage.getItem("project");
    this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));
    let breadCrumb = localStorage.getItem('breadCrumb');
    breadCrumb = JSON.parse(breadCrumb);
    this.breadCrumb = breadCrumb;
    let previousUrl = localStorage.getItem("previousUrl");

    if (previousUrl?.includes('products')) {

      let { id, categoryId, mainMenu, subMenu, childMenu } = breadCrumb;

      if (mainMenu) {
        mainMenu = mainMenu.replaceAll("-", " ");
        this.mainMenu = mainMenu;
      }
      if (subMenu) {
        subMenu = subMenu.replaceAll("-", " ");
        this.subMenu = subMenu;
      }
      if (childMenu) {
        childMenu = childMenu.replaceAll("-", " ");
        this.childMenu = childMenu;
      }
      if (id) {
        id = Base64.decode(id);
        id = id.split("@@");
        this.mainId = id[0];
        this.subId = id[1];
        this.childId = id[2];
      }
      if (categoryId) {
        this.mainId = Base64.decode(categoryId);
      }
      this.previousUrl = window?.previousUrl;
    } else {
      localStorage.removeItem("breadCrumb");
      this.previousUrl = window?.previousUrl;
      this.previousTitle = window?.previousTitle?.replace('B2B - ', '');
    }
    document.title = this.$route.meta.title;
    let { title } = this.$route.params;
    if (title) {
      title = title.replaceAll('-', ' ');
      this.title = title;
    }
    if (this.$route.params.id) {
      this.getProductDetails();
      this.getRecentProducts();
    } else {
      this.$router.push({ name: "NotFound" });
    }
    this.windowWidth = window.innerWidth;
    this.getZoomerSettings();
    this.getProject()

  },
  mounted() {

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    let userId = localStorage.getItem("id");
    userId = Base64.decode(userId);
    User.priceDisplay({ userId: userId })
      .then((res) => {
        if (res.data.success) {
          this.data = res.data.data;
          this.priceBool = res.data.data.priceDisplay
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async downloadImage(url) {
      FileSaver.saveAs(url, "cadBlock.jpg",{ autoBom: true })
    },
    NotifyItem(productId,variantId){
      if (this.$refs.theNotifyCheck.checked) {
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        let payLoad = {
          productId: productId,
          variantId: variantId,
          userId: userId,
        };
        Api.notifyItem(payLoad)
                .then((res) => {
          // console.log(res.data.message)
                  this.message = res.data.message;
                }).catch((err) => {
          console.log(err);
        });
      } else {
        this.message = "";
      }
    },


    getProject: function () {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api2.getProjectList({userId: userId})
          .then((res) => {
            this.projects = res.data.data;
            if(res.data.data.length == 0){
              localStorage.setItem("project","NO")
            }else {
              localStorage.setItem("project","YES")
            }

          })
          .catch((err) => {
            console.log(err);
          });
    },
    addProject(){
      window.location.href = '/user/add-project'
      // this.$router.push(`/user/projects`)
    },

    addToWishListModel(){
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let productId = localStorage.getItem('productId');


      let payLoad = {
        userId: userId,
        productId: productId,
        isDefault: this.client.isDefault,
        clientProjectIds: this.projectIds,
        deleteCart:0
      };
      wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
          // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
          .then((res) => {
            console.log("Rolex Swaraj Bhuyan", res.data);
            if (res.data.success) {
              this.getWishList()
              // this.$store.dispatch('addWishList', productId);
              // this.wishListBool[productId] = true;
              const elem = this.$refs.closePop;
              elem.click();
              // this.$store.dispatch('addWishList', productId);
              // this.wishListBool[productId] = true;
              this.cartCount += 1;
              this.getCartItems();
            }
          })
          .catch((err) => {
            this.wishListBool[productId] = false;
            console.log(err);
          });

      Project.defaultProjectDetails({ userId: userId  }).then((res) => {
        // console.log("Default_Project", res.data);
        if (res.data.success) {
          this.projectDefault =  res.data.data;
          localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
          this.$store.dispatch('checkDefaultProject', this.projectDefault);
        }
      }).catch((err) => {
        console.log("abcd",err);
      });



    },

    async closePopup() {
      this.client.projectId = "";
      this.projectIds = [];
      this.v$.client.projectId.$reset();
      this.client.isDefault = 0;
    },


    async deleteWishlist(item) {
      console.log("itemsss", item);
      const { productId } = item;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      wishListApi.deleteWishlist({ userId: userId, productId: productId, clientProjectId: this.projectDefault.id })
        .then((res) => {
          if (res.data.success) {
            this.getWishList();
            this.$store.dispatch('addWishList', productId);
            this.wishListBool[productId] = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getWishList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      wishListApi.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.wishListData = res.data.data;
            if (this.projectDefault != null) {
              this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
              let wishlist = [];
              this.wishlistDefaultData.forEach((val)=>{
                // console.log("swaraj", val)
                wishlist[val.productId] = true;
                // val.products.forEach((val1)=>{
                //
                // });
              });
              this.$store.dispatch('addTotalWishList', wishlist);
            }

            // else {
            //   this.wishlistDefaultData = []
            //   let wishlist = [];
            //   this.wishlistDefaultData.forEach((val)=>{
            //     // console.log("swaraj", val)
            //     wishlist[val.productId] = true;
            //     // val.products.forEach((val1)=>{
            //     //
            //     // });
            //   });
            //   this.$store.dispatch('addTotalWishList', wishlist);
            // }
            // let wishlist = [];
            // this.wishListData.forEach((val)=>{
            //   val.products.forEach((val1)=>{
            //     wishlist[val1.productId] = true;
            //   });
            // });
            // this.$store.dispatch('addTotalWishList', wishlist);
            else {
              let count = 0;
              this.wishListData.forEach((val)=>{
                count += val.products.length
              });

              this.$store.dispatch('addTotalWishListCount', count);
              this.wishListCount = count;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    encryptIDs(menuId, subMenuId, childMenu = "") {
      let finalId = menuId;
      if (subMenuId) {
        finalId += "@@" + subMenuId;
      }
      if (childMenu) {
        finalId += "@@" + childMenu;
      }
      return Base64.encode(finalId);
    },
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, "");
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, "-");
        return slug;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.getZoomerSettings();
      this.zoomKey += 1;
    },
    ItemSetId: function(){
      var element = event.target;
      var id = element.getAttribute('data-id');
      localStorage.setItem("productId", id)

    },
    async addToWishList(data) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
        clientProjectId: this.projectDefault.id
      };
      wishListApi
        .addWishlistProductDetails(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolex Swaraj", res.data);
          if (res.data.success) {
            this.$store.dispatch("addWishList", productId);
            this.wishListBool[productId] = true;
          }
        })
        .catch((err) => {
          this.wishListBool[productId] = false;
          console.log(err);
        });
    },
    async getZoomerSettings() {
      if (this.windowWidth > 0 && this.windowWidth < 600) {
        this.zoomerOptions = this.mobileOptions;
        this.renderProducts(4);
      } else {
        this.zoomerOptions = this.options;
        this.renderProducts(4);
      }
    },
    async incQuantity(val) {
      this.productQty = this.productQty + val;
      let stock = this.productData?.variants[0]?.stock;
      if (stock > 0 && this.productData?.preOrder != 1 && this.productQty > stock) {
        this.stockMsgBool = true;
        this.productQty -= 1
        return;
      } else {
        this.stockMsgBool = false;
      }
      if (this.productQty < 1) {
        this.productQty = 1;
      }
    },
    async renderProducts(index) {
      this.recentProdData = [];
      this.recentProdData.recentViewedProducts = this.recentProdDataAll?.recentViewedProducts?.slice(0, index);
      this.recentProdData.mostViewedProducts = this.recentProdDataAll?.mostViewedProducts?.slice(0, index);
      this.recentProdData.relatedProducts = this.recentProdDataAll?.relatedProducts?.slice(0, index);
    },
    async addToCart(data) {
      this.loaderBool[data.id] = true;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { id, productId } = data?.variants[0];
      let payLoad = {
        userId: userId,
        productId: productId,
        variantId: id,
        qty: this.productQty,
      };
      this.stockMsgBool = false;
      Api.addToCart(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.sucess = "Product Added Successfully";
            this.cartCount += 1;
            this.loaderBool[data.id] = false;
            this.checkBool[data.id] = true;
            setTimeout(() => {
              this.checkBool[data.id] = false;
            }, 3000);
          }
        })
        .catch((err) => {
          this.loaderBool[data.id] = false;
          console.log(err);
          if (err?.response?.data?.errors) {
            this.stockMsgBool = true;
            return;
          }
        });
    },
    async getProductDetails() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let payLoad = {
        productId: this.$route.params.id,
        userId: userId,
      };
      Api.getProductDetails(payLoad)
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.productData = res.data.data;
            this.productData.variants[0].images.forEach((items, index) => {
              if (items.imageUrl) {
                console.log("itemasss", items);
                this.imgData.push({ id: index, url: items.imageUrl });
                this.images.thumbs = this.imgData;
                this.images.normal_size = this.imgData;
                this.images.large_size = this.imgData;
              }
            });
            console.log("this.imgDatathis.imgData", this.imgData);
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch((err) => {
          console.log(err);
          //this.$router.push({ name: "NotFound" });
          window.location = '/notfound';
        });
    },
    async getRecentProducts() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let payLoad = {
        productId: this.$route.params.id,
        userId: userId,
      };
      Api.getRecentProducts(payLoad)
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.recentProdData = this.recentProdDataAll = res.data.data;
            if (this.windowWidth > 0 && this.windowWidth < 600) {
              this.renderProducts(2);
            } else {
              this.renderProducts(4);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://unpkg.com/@google/model-viewer@2.0.2/dist/model-viewer.js"
    );
    recaptchaScript.setAttribute("type", "module");
    document.head.appendChild(recaptchaScript);


  },


};
</script>

