<template>
  <div class="company_details_business">
    <BeforeLoginHeader />
    <div class="company_detail_body_part">
      <div class="company_detail_body_part container-xl">
        <form class="mt-lg-5 mt-md-4 my-4" v-on:submit.prevent="companyBusinessDetails" enctype="multipart/form-data">
          <p>{{ this.radio_value }}</p>
          <div class="business_type_section mb-md-3 mb-4">
            <h4>ACCOUNT TYPE</h4>
            <div>
              <input name="select" @change="onChange($event)" v-model="this.radio_value" value="FREELANCER"
                id="b_type_freelance" type="radio" /><label class="ms-2" for="b_type_freelance">Individual</label>
              <input name="select" @change="onChange($event)" v-model="this.radio_value" checked value="BUSINESS"
                id="b_type_business" class="ms-4" type="radio" /><label class="ms-2"
                for="b_type_business">Business</label>
            </div>
          </div>
          <div class="alert alert-danger" v-if="isError">
            <ul>
              <li v-if="error_companyName">{{ error_companyName }}</li>
              <li v-if="error_mobile">{{ error_mobile }}</li>
              <li v-if="error_website">{{ error_website }}</li>
            </ul>
          </div>
          <div class="alert alert-danger" v-if="error_msg">
            {{ error_msg }}
          </div>
          <div class="alert alert-success" v-if="success_msg">
            {{ success_msg }}
          </div>
          <h4>COMPANY DETAILS</h4>
          <div class="row company_details_row company_details_row_input company_address_row">
            <div class="col-md-4 mt-md-1 mt-2">
              <label>COMPANY NAME*</label>
              <input :class="{ border_color: iserror.companyname }" v-on="
                company_details.companyName
                  ? { change: v$.company_details.companyName.$validate }
                  : {}
              " type="text" v-model="company_details.companyName" />
              <span v-if="v$.company_details.companyName.$error" class="error_msg_log error_msg">
                <span v-if="company_details.companyName == ''">Company name is required</span>
                <span v-else>{{
                  v$.company_details.companyName.$errors[0].$message
                }}</span>
              </span>
            </div>
            <!--            <div class="col-md-4 mt-md-1 mt-3">-->
            <!--              <div class="ipt_group">-->
            <!--                <div class="dd_default ipt_fld">-->
            <!--                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE*</label>-->
            <!--                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"-->
            <!--                                                             data-bs-toggle="dropdown" aria-expanded="false">+00</button>-->
            <!--                      <ul class="dropdown-menu" style="">-->
            <!--                        <li><a class="dropdown-item">+974</a></li>-->
            <!--                        <li><a class="dropdown-item">+95</a></li>-->
            <!--                        <li><a class="dropdown-item">+91</a></li>-->
            <!--                        <li><a class="dropdown-item">+01</a></li>-->
            <!--                      </ul>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="input_field">-->
            <!--                  <label>MOBILE NUMBER*</label>-->
            <!--                  <input type="text" />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
<!--            <div class="col-md-4 mt-md-1 mt-3">-->
<!--              <label>MOBILE*</label>-->
<!--              <input :class="{ border_color: iserror.cmobile }" v-on="-->
<!--                company_details.mobile-->
<!--                  ? { change: v$.company_details.mobile.$validate }-->
<!--                  : {}-->
<!--              " type="text" v-model="company_details.mobile" />-->
<!--              <span v-if="v$.company_details.mobile.$error" class="error_msg_log error_msg">-->
<!--                <span v-if="company_details.mobile == ''">Mobile is required</span>-->
<!--                <span v-else>{{-->
<!--                  v$.company_details.mobile.$errors[0].$message-->
<!--                }}</span>-->
<!--              </span>-->
<!--            </div>-->


            <div class="col-md-4 mt-md-1 mt-3">
              <div class="ipt_group">
                <!--                <div class="input_field">-->
                <!--                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE*</label>-->
                <!--                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"-->
                <!--                                                             data-bs-toggle="dropdown" aria-expanded="false">{{(individual.phoneCode)?phoneCode:'00'}}</button>-->
                <!--                      <ul class="dropdown-menu" style="">-->
                <!--                        <li v-for="country in countries" :key="country.phoneCode">-->
                <!--                          <a @click="changePhoneCodeMode(country)" class="dropdown-item">{{country.phoneCode}}</a>-->
                <!--                        </li>-->
                <!--                      </ul>-->
                <!--                    </div>-->
                <!--                  </div>-->
                
<!--                <select-->
<!--                        v-model="company_details.phoneCode"-->
<!--                        v-on="-->
<!--                  company_details.phoneCode-->
<!--                    ? { change: v$.company_details.phoneCode.$validate }-->
<!--                    : {}-->
<!--                "-->
<!--                        class="bg-white mbl_ddn"-->
<!--                >-->
<!--                  <option-->
<!--                          v-for="country in countries"-->
<!--                          :key="country.phoneCode"-->
<!--                          :value="country.phoneCode"-->
<!--                  >-->
<!--                    +{{ country.phoneCode }}-->
<!--                  </option>-->
<!--                </select>-->
                <div class="dd_default ipt_fld indi_country">
                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                    <label>CODE*</label>
                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"
                                                             data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> {{(company_details.phoneCode)?'+'+phoneCode:'+00'}}</button>
                      <ul class="dropdown-menu" style="">
                        <li v-for="country in codeData" :key="country.phoneCode">
                          <a @click="changePhoneCodeMode(country)" class="dropdown-item"><img :src="country.countryImage">  +{{country.phoneCode}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--                </div>-->
                <div  class="mbl_input">
                  <label>MOBILE NUMBER*</label>
                  <input type="text" v-model="company_details.mobile" :class="{ border_color: iserror.cmobile }"
                         v-on="company_details.mobile ? { change: v$.company_details.mobile.$validate }: {}"/>
                </div>

              </div>
              <span v-if="v$.company_details.phoneCode.$error" class="error_msg_log error_msg">
                <span v-if="company_details.phoneCode == ''">Phone Code is required</span>
              </span>

              <span
                      v-if="v$.company_details.mobile.$error"
                      class="error_msg_log error_msg"
              >
              <span v-if="company_details.mobile == ''">Mobile Number is required</span>
              <span v-else>{{ v$.company_details.mobile.$errors[0].$message }}</span>
            </span>

            </div>


            <div class="col-md-4 mt-md-1 mt-3">
              <label>PHONE</label>
              <input :class="{ border_color: iserror.cphone }" type="text" v-model="company_details.phone" />
            </div>
            <div class="col-md-4 mt-lg-3 mt-md-3 mt-3">
              <label>WEBSITE URL</label>
              <input :class="{ border_color: iserror.cweb }" v-on="
                company_details.websiteUrl
                  ? { change: v$.company_details.websiteUrl.$validate }
                  : {}
              " type="text" v-model="company_details.websiteUrl" />
              <span v-if="v$.company_details.websiteUrl.$error" class="error_msg_log error_msg">
                <span v-if="company_details.websiteUrl != ''">{{
                  v$.company_details.websiteUrl.$errors[0].$message
                }}</span>
              </span>
            </div>
          </div>
          <h4 class="mt-lg-4 mt-md-3 mt-4">CONTACT PERSON DETAILS</h4>
          <div class="
              row
              company_details_row
              contact_persion_details_row
              company_details_row_input
              company_address_row
            ">
            <div class="col-md-4 mt-md-1 mt-2">
              <label>NAME</label>
              <input :class="{ border_color: iserror.cemail }" type="text" v-model="company_details.contactPerson" />
            </div>
            <div class="col-md-4 mt-md-1 mt-3">
              <label>EMAIL</label>
              <input :class="{ border_color: iserror.cemail }" v-on="
                company_details.contactEmail
                  ? {
                    change: v$.company_details.contactEmail.$validate,
                  }
                  : {}
              " type="text" v-model="company_details.contactEmail" />
              <span v-if="v$.company_details.contactEmail.$error" class="error_msg_log error_msg">
                <span>{{
                  v$.company_details.contactEmail.$errors[0].$message
                }}</span>
              </span>
            </div>

<!--            <div class="col-md-4 mt-md-1 mt-3">-->
<!--              <label>MOBILE</label>-->
<!--              <input :class="{ border_color: iserror.ctaxid }" type="text" v-model="company_details.contactMobile" />-->
<!--            </div>-->
            <div class="col-md-4 mt-md-1 mt-3">
              <div class="ipt_group">
                <!--                <div class="input_field">-->
                <!--                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE*</label>-->
                <!--                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"-->
                <!--                                                             data-bs-toggle="dropdown" aria-expanded="false">{{(individual.phoneCode)?phoneCode:'00'}}</button>-->
                <!--                      <ul class="dropdown-menu" style="">-->
                <!--                        <li v-for="country in countries" :key="country.phoneCode">-->
                <!--                          <a @click="changePhoneCodeMode(country)" class="dropdown-item">{{country.phoneCode}}</a>-->
                <!--                        </li>-->
                <!--                      </ul>-->
                <!--                    </div>-->
                <!--                  </div>-->
               
<!--                <select-->
<!--                        v-model="company_details.phoneCode"-->
<!--                        class="bg-white mbl_ddn"-->
<!--                        disabled-->
<!--                >-->
<!--                  <option-->
<!--                          v-for="country in countries"-->
<!--                          :key="country.phoneCode"-->
<!--                          :value="country.phoneCode"-->
<!--                  >-->
<!--                    +{{ country.phoneCode }}-->
<!--                  </option>-->
<!--                </select>-->
                <div class="dd_default ipt_fld indi_country">
                  <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                    <label>CODE</label>
                    <div class="dropdown dd_default">
                      <button class="btn btn-secondary dropdown-toggle" type="button" disabled
                                                             data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> {{(company_details.phoneCode)?'+'+phoneCode:'+00'}}</button>
<!--                      <ul class="dropdown-menu" style="">-->
<!--                        <li v-for="country in phoneCode" :key="country.phoneCode">-->
<!--                          <a @click="changePhoneCodeMode(country)" class="dropdown-item"><img :src="country.countryImage">  +{{country.phoneCode}}</a>-->
<!--                        </li>-->
<!--                      </ul>-->
                    </div>
                  </div>
                </div>
                <!--                </div>-->
                <div class="mbl_input">
                  <label>MOBILE</label>
                  <input :class="{ border_color: iserror.ctaxid }" type="text" v-model="company_details.contactMobile" />
                </div>

              </div>




            </div>
          </div>
          <h4 class="mt-lg-4 mt-md-3 mt-4">COMPANY ADDRESS</h4>
          <div class="
              row
              company_details_row company_address_row company_details_row_input
            ">
            <div class="col-md-4 col-sm-12 mt-md-1 mt-2">
              <label>STREET ADDRESS LINE 1*</label>
              <input :class="{ border_color: iserror.caddress1 }" v-on="
                company_details.streetAddressOne
                  ? { change: v$.company_details.streetAddressOne.$validate }
                  : {}
              " type="text" v-model="company_details.streetAddressOne" />
              <span v-if="v$.company_details.streetAddressOne.$error" class="error_msg_log error_msg">
                <span v-if="company_details.streetAddressOne == ''">Street address line 1 is required</span>
                <span v-else>{{
                  v$.company_details.streetAddressOne.$errors[0].$message
                }}</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mt-md-1 mt-3">
              <label>STREET ADDRESS LINE 2</label>
              <input :class="{ border_color: iserror.caddress2 }" type="text"
                v-model="company_details.streetAddressTwo" />
            </div>
            <div class="col-md-4 col-sm-12 mt-md-1 mt-3">
              <label>COUNTRY*</label>
              <select v-model="company_details.country" class="bg-white">
                <option v-for="country in countries" :key="country.id" :value="country.id" :text="country.name">
                </option>
              </select>
              <span v-if="v$.company_details.country.$error" class="error_msg_log error_msg">
                <span v-if="company_details.country == ''">Country is required</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-3 mt-md-3 mt-3">
              <label>STATE / PROVINCE</label>
              <input :class="{ border_color: iserror.cstate }" type="text" v-model="company_details.state" />
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-3 mt-md-3 mt-3">
              <label>CITY*</label>
              <input :class="{ border_color: iserror.ccity }" v-on="
                company_details.city
                  ? { change: v$.company_details.city.$validate }
                  : {}
              " type="text" v-model="company_details.city" />
              <span v-if="v$.company_details.city.$error" class="error_msg_log error_msg">
                <span v-if="company_details.city == ''">City is required</span>
                <span v-else>{{
                  v$.company_details.city.$errors[0].$message
                }}</span>
              </span>
            </div>
            <div class="col-md-4 col-sm-12 mt-lg-3 mt-md-3 mt-3">
              <label>PO BOX</label>
              <input :class="{ border_color: iserror.ccountry }" type="text" v-model="company_details.poBox" />
            </div>
          </div>

          <div class="
              row
              company_details_row
              commercial_registration_row
              mb-4
              mt-lg-4 mt-md-3 mt-3
            ">
            <div class="col-md-4 col-12">
              <h4 class="mt-lg-0 mt-lg-0">Commercial Registration*</h4>
              <!-- <div class="registration_document_input_field ps-2">
                <label for="files" id="lable_sec"
                  ><span
                    :class="{ hidden_texts: files.file1?.name }"
                    class="text_vilible"
                    ><img
                      src="../../../assets/image/download_icon.svg"
                      alt=""
                    />Registration Form.PDF</span
                  >
                  {{ files.file1?.name }}</label
                >
                <input
                  id="files"
                  class="input_btn"
                  @change="onFileSelected"
                  type="file"
                /><button>BROWSE</button>
              </div> -->
              <div class="file_u_cnt">
                <input type="file" id="reg_upload" @change="onFileSelected($event)" hidden accept="image/*, .pdf" />
                <span class="lable_sec" id="reg_file">{{ regFile }}</span>
                <label for="reg_upload" class="reg_u_btn">Choose File</label>
              </div>
              <p class="below_input_msg">
                Please upload a copy of the organization's commercial registration. Please upload a pdf, png, or jpg
                file.
              </p>
              <span v-if="v$.files.file1.$error" class="error_msg_log error_msg">
                <span v-if="files.file1 == ''">Commercial Registration is required</span>
              </span>
            </div>

            <div class="col-md-4 col-12 mt-md-0 mt-2">
              <h4 class="mt-lg-0 mt-lg-0">Letter of Authorisation
                <!-- <span style="font-size:12px;"> Sample <a href="https://b2b-staging.lab2100.com/uploads/docs/Letter_Of_Authorization.pdf" target="_blank"><img
              src="../../../assets/image/download_icon.svg"
                class="img-fluid ac_dd_btn"
              /></a></span> -->
              </h4>

              <!-- <div class="registration_document_input_field ps-2">
                <label for="filess" id="lable_sec"
                  ><span
                    :class="{ hidden_texts: files.file2?.name }"
                    class="text_vilible"
                    ><img
                      src="../../../assets/image/download_icon.svg"
                      alt=""
                    />Registration Form.PDF</span
                  >
                  {{ files.file2?.name }}</label
                >
                <input
                  id="filess"
                  class="input_btn"
                  @change="onFileSelectedn"
                  type="file"
                /><button>BROWSE</button>
              </div> -->
              <div class="file_u_cnt">
                <input type="file" id="auth_upload" @change="onFileSelectedn($event)" hidden accept="image/*, .pdf" />
                <span class="lable_sec" id="auth_file">{{ authFile }}</span>
                <label for="auth_upload" class="reg_u_btn">Choose File</label>
              </div>
              <p class="below_input_msg">
                Please upload a copy of the letter of authorization to create an account with That's Living. Download a
                sample from <a target="_blank"
                  href="/uploads/docs/Letter_Of_Authorization.pdf">
                  here</a>. Please upload a pdf, png, or jpg file.
              </p>
            </div>
          </div>
          <div class="ad_btns_cnt">
            <button @click="cancel" class="btn_outline">CANCEL</button>

            <button :disabled="isLoader" :class="{ 'disable-btn': isLoader }" class="btn_default">
              <div v-if="isLoader">
                <img width="30" src="../../../assets/image/loader.gif" />
              </div>
              <span v-if="!isLoader"> SUBMIT DETAILS </span>
            </button>

          </div>
        </form>
      </div>
    </div>
    <LoginFooter />
  </div>
</template>
<script>
import router from "@/routes/routes";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import Master from "@/apis/Master";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,

} from "@vuelidate/validators";
import User from "@/apis/User";
import { Base64 } from "js-base64";
export default {
  name: "company-details",
  data() {
    return {
      company_details: {
        userId: localStorage.getItem("id")
          ? Base64.decode(localStorage.getItem("id"))
          : "",
        companyName: "",
        mobile: "",
        phone: "",
        websiteUrl: "",

        contactPerson: "",
        contactEmail: "",
        contactMobile: "",
        streetAddressOne: "",
        streetAddressTwo: "",
        proofOfIdentiy: "testIdentity1234",
        city: "",
        state: "",
        country: "",
        phoneCode: "",
        poBox: "",
      },
      files: {
        file1: "",
        file2: "",
      },
      error_msg: "",
      error_mobile: "",
      error_website: "",
      error_companyName: "",
      error_streetAddressOne: "",
      success_msg: "",
      countries: [],
      codeData: [],
      u_file: "",
      selected: "",
      radio_value: "",
      iserror: {
        companyname: false,
        cmobile: false,
        cphone: false,
        cemail: false,
        cweb: false,
        ctaxid: false,
        caddress1: false,
        caddress2: false,
        ccity: false,
        cstate: false,
        ccountry: false,
        czip: false,
      },
      isLoader: false,
      isError: false,
      authFile: "No file chosen",
      regFile: "No file chosen",
    };
  },
  props: {
    value: File,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      company_details: {
        companyName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        contactEmail: { email },
        websiteUrl: {
          required: false,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Website URL is invalid`,
            (value) =>
              /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g.test(
                value
              )
          ),
        },
        streetAddressOne: { required },
        country: { required },
        phoneCode: { required },
        city: {
          required,
          minLength: minLength(3),
          containsFirstNameRequirement: helpers.withMessage(
            () => `City is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
      },
      files: {
        file1: { required },
      },
    };
  },

  created() {
    this.getCountries();
    this.phoneCodeData();
    // this.companyBusinessDetails();
    document.title = this.$route.meta.title;
  },
  methods: {
    async cancel() {
      localStorage.clear();
      router.push({ name: 'Login' });
    },
    async changePhoneCodeMode(data){
      this.phoneCode = data.phoneCode;
      this.countryImage = data.countryImage;
      this.company_details.phoneCode = data.phoneCode;
    },
    async companyBusinessDetails() {
      this.v$.company_details.companyName.$validate();
      this.v$.company_details.mobile.$validate();
      this.v$.company_details.streetAddressOne.$validate();
      this.v$.company_details.country.$validate();
      this.v$.company_details.city.$validate();
      this.v$.files.file1.$validate();
      this.v$.company_details.phoneCode.$validate();
      if (!this.v$.company_details.phoneCode.$error && !this.v$.company_details.companyName.$error && !this.v$.company_details.mobile.$error && !this.v$.company_details.streetAddressOne.$error && !this.v$.company_details.country.$error
        && !this.v$.company_details.city.$error && !this.v$.files.file1.$error) {
        // if no fail validation
        this.error_website = "";
        this.error_mobile = "";
        this.error_companyName = "";
        this.isLoader = true;
        const fd = new FormData();
        fd.append("file1", this.files.file1, this.files.file1.name);
        fd.append(
          "file2",
          JSON.stringify(this.files.file2, this.files.file2.name)
        );

        fd.append("data", JSON.stringify({
          "userId": localStorage.getItem("id") ? Base64.decode(localStorage.getItem("id")) : "",
          "companyName": this.company_details.companyName,
          "mobile": this.company_details.mobile,
          "phoneCode": this.company_details.phoneCode,
          "phone": this.company_details.phone,
          "websiteUrl": this.company_details.websiteUrl.toLowerCase(),
          "contactPerson": this.company_details.contactPerson,
          "contactEmail": this.company_details.contactEmail,
          "contactMobile": this.company_details.contactMobile,
          "streetAddressOne": this.company_details.streetAddressOne,
          "streetAddressTwo": this.company_details.streetAddressTwo,
          "proofOfIdentiy": this.company_details.proofOfIdentiy,
          "city": this.company_details.city,
          "state": this.company_details.state,
          "country": this.company_details.country,
          "poBox": this.company_details.poBox,
        }));
        User.companyBusinessDetails(fd)
          .then((res) => {
            this.isError = false;
            localStorage.setItem("profileStatus", 1);
            sessionStorage.setItem("profileStatus", 1);
            setTimeout(() => {
              this.isLoader = false;
              this.$router.push({ name: "DocumentVerificationMsg" });
            }, 3000);
            this.success_msg = res.data.message;
            sessionStorage.setItem("companyStatus", 1);
            localStorage.setItem("companyStatus", 1);
          })
          .catch((err) => {
            this.isLoader = false;
            this.isError = true;
            this.error_website = err.response.data.error?.websiteUrl
              ? err.response.data.error?.websiteUrl[0]
              : "";
            this.error_mobile = err.response.data.error?.phone
              ? err.response.data.error?.phone[0]
              : "";
            this.error_companyName = err.response.data.error?.companyName
              ? err.response.data.error?.companyName[0]
              : "";
          });
        console.log("Form successfully submitted.");
      } else {
        this.isError = false;
        console.log("Form failed validation");
      }
    },
    onFileSelected(event) {
      this.regFile = event.target.files[0].name;
      console.log(event.target.files[0].name);
      this.files.file1 = event.target.files[0];
    },
    onFileSelectedn(event) {
      this.authFile = event.target.files[0].name;
      this.files.file2 = event.target.files[0];
      console.log("h2" + event.target.files[0].name);
    },

    onChange(event) {
      var data = event.target.value;
      if (data === "FREELANCER") {
        router.push({ name: "CompanyDetailsIndividual" });
      } else {
        router.push({ name: "CompanyDetailsBusiness" });
      }
      console.log(data);
    },
    getCountries: function () {
      Master.country().then(
        function (response) {
          this.countries = response.data.data;
        }.bind(this)
      );
    },
    phoneCodeData: function () {
      Master.phoneCode().then(
              function (response) {
                this.codeData = response.data.data;
              }.bind(this)
      );
    },
  },

  components: {
    LoginFooter,
    BeforeLoginHeader,
  },
};
</script>
<style>
@import "../../../assets/css/company_details_business.css";

.border_color {
  border: 1px solid red;
}

.text_vilible {
  display: inline-block;
  /* display: none; */
}

.hidden_texts {
  /* display: block; */
  display: none;
}

.input_btn {
  visibility: hidden;
  width: 0%;
  margin-top: 0.5rem;
}
</style>
