<template>
  <div class="col-lg-9">
    <div>
      <h2 class="ac_pg_head">Address Book</h2>
      <div class="row">
        <div class="col-md-6">
          <h2 class="ac_head_bar">Default Shipping Address</h2>
          <div class="ac_det_cnt ac_addr">
            <span  v-if="this.deliveryAdd[0]" v-html="displayDeliveryAdd"></span>
            <p  v-if="this.deliveryAdd[0]">M: +{{this.deliveryAdd[0]?.mobile}}</p>
            <div v-if="!this.deliveryAdd[0]" style="text-align:center;padding-top:10px;">No defualt shipping address</div>
            <p class="mt-4"  v-if="data[0]">
              <router-link to="/user/make-default/1">Change Default shipping address</router-link>
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="ac_head_bar">Default Billing Address</h2>
          <div  class="ac_det_cnt ac_addr">
            <span v-if="this.billingAdd[0]" v-html="displayBillingAdd"></span>
            <p v-if="this.billingAdd[0]">M: +{{this.billingAdd[0]?.mobile}}</p>
            <div v-if="!this.billingAdd[0]" style="text-align:center;padding-top:10px;">No defualt billing address</div>
            <p class="mt-4" v-if="data[0]">
              <router-link to="/user/make-default/2">Change Default billing address</router-link>
            </p>
          </div>
        </div>
      </div>

      <div class="alert alert-success" v-if="showdelMsg" id="error_msg">
        {{ delMsg }}
      </div>
      <div class="d-flex mt-3 align-items-center">
        <h2 class="ac_pg_head mb-0">Saved Addresses</h2>
        <div class="ad_add_btn">
          <button class="btn_default" v-on:click.prevent="addAddress()">
            Add New Address
          </button>
        </div>
      </div>

      <div class="ac_det_cnt px-0">
        <table class="addr_bk">
          <thead>
            <th>Name</th>
            <th class="s_a_cnt">Street Address</th>
            <th class="d-none d-xl-table-cell">City</th>
            <th class="d-none d-xl-table-cell">State</th>
            <th class="d-none d-lg-table-cell">Country</th>
            <th class="d-none d-lg-table-cell">Pin</th>
            <th class="d-none d-lg-table-cell">Address Type</th>
            <th class="ab_icn_cnt"></th>
          </thead>
          <tbody>
          <tr v-if="!(data.length>0)">
              <td colspan="7" style="text-align:center;">
              <img v-if="isLoader" width="30" src="../../../assets/image/loader.gif" />
            <span v-if="!isLoader">No Records Found</span></td>
             </tr>
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.firstName + " " + item.lastName }}</td>
              <td>
                {{ item.streetAddressOne }}
              </td>
              <td class="responsive_none d-none d-xl-table-cell">{{ item.city }}</td>
              <td class="responsive_none d-none d-xl-table-cell">{{ item.state }}</td>
              <td class="responsive_none d-none d-lg-table-cell">{{ item.countryName }}</td>
              <td class="responsive_none d-none d-lg-table-cell">{{ item.zipCode }}</td>
              <td class="responsive_none d-none d-lg-table-cell">{{ (item.addressType==1)?'Shipping':'Billing' }}</td>
              <td>
                <div class="add_a_icns">
                  <a
                    v-on:click.prevent="editAddress(item)"
                    href="#"
                    class="edit_icn"
                    ><img
                      src="../../../assets/image/edit_icon.svg"
                      class="img-fluid"
                    />
                    <span> Edit</span>
                  </a>
                  <a
                    href="#"
                    v-on:click.prevent="deleteAddress(item.id)"
                    class="del_icn"
                    ><img
                      src="../../../assets/image/delete_icon.svg"
                      class="img-fluid"
                    />
                    <span> Delete</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
//import router from "@/routes/routes";
import "./css/user_address.css";
import Api from "@/apis/AddressBook";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
export default {
  name: "User-address",
  data() {
    return {
      data: [],
      item:"",
      billingAdd: [],
      deliveryAdd: [],
      delMsg: "Deleted Successfully",
      showdelMsg: false,
      isLoader: true,
    };
  },
  created() {
    window.scrollTo(0,0);
    document.title = this.$route.meta.title;
    this.getAddressList();
  },
  computed: {
    displayDeliveryAdd: function () {
      let address = this.deliveryAdd[0];
      let displayAddress = "";
      if (address) {
        if (address.streetAddressOne)
          displayAddress = address.firstName + " " + address.lastName;
        if (address.streetAddressOne)
          displayAddress += "<br> " + address.streetAddressOne;
        if (address.streetAddressTwo)
          displayAddress += "<br> " + address.streetAddressTwo;
        if (address.city) displayAddress += "<br> " + address.city;
        if (address.state) displayAddress += ", " + address.state;
        if (address.countryName) displayAddress += ", " + address.countryName;
        if (address.zipCode) displayAddress += ", " + address.zipCode;
      }
      return displayAddress;
    },
    displayBillingAdd: function () {
      let address = this.billingAdd[0];
      let displayAddress = "";
      if (address) {
        if (address.streetAddressOne)
          displayAddress = address.firstName + " " + address.lastName;
        if (address.streetAddressOne)
          displayAddress += "<br> " + address.streetAddressOne;
        if (address.streetAddressTwo)
          displayAddress += "<br> " + address.streetAddressTwo;
        if (address.city) displayAddress += "<br> " + address.city;
        if (address.state) displayAddress += ", " + address.state;
        if (address.countryName) displayAddress += ", " + address.countryName;
        if (address.zipCode) displayAddress += ", " + address.zipCode;
      }
      return displayAddress;
    },
  },
  methods: {
    async getAddressList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getAddressList({ userId: userId, trash: 0 })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
            this.deliveryAdd = this.data.filter((val) => val.addressType == 1 && val.default == 1);
            this.billingAdd = this.data.filter((val) => val.addressType == 2  && val.default == 1);
          }
          this.isLoader = false
        })
        .catch((err) => {
          this.isLoader = false
          console.log(err);
        });
    },
    async deleteAddress(id) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let text = "Are you sure you want to delete?";
      if (confirm(text) == true) {
        Api.deleteAddress({ userId: userId, id: id })
          .then((res) => {
            if (res.data.success) {
              this.showdelMsg = true;
              this.getAddressList();
              setTimeout(() => {
                this.showdelMsg = false;
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async addAddress(){
       router.push("/user/add-address")
    },
    async editAddress(item){
      localStorage.setItem("address",JSON.stringify(item))
      router.push({ name: 'EditAddress' })
    }
  },
  setup() {},
};
</script>
