<template>
  <footer>
    <div class="page-width">
      <div class="row">
        <div class="col-md-2 col-6 mobile_about">
          <h5>Company</h5>
          <ul class="ftr_cl_cnt">
            <li><router-link to="/about-us">About Us</router-link></li>
            <li><router-link to="/contactus">Contact Us</router-link></li>
            <li><router-link to="/faq">Faq's</router-link></li>
            <li><router-link to="/user/profile">My Account</router-link></li>
            <li>
              <router-link to="/terms-conditions">TERMS AND CONDITIONS</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">PRIVACY POLICY</router-link>
            </li>
            <li>
              <router-link to="/cookies-policy">COOKIES POLICY</router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-5 col-6 brand_footer">
          <h5>Our Brands</h5>
          <ul class="ftr_cl_cnt list_one" >
            <li v-for="brand in brands" :key="brand.id">
              <router-link :to="`/products/${sanitizeTitle('brands')}/${sanitizeTitle(brand?.name)}/${encryptIDs(brand?.id)}`">{{brand.name}}</router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-3 col-6 mobile_colle">
          <h5>Collections</h5>
          <ul class="ftr_cl_cnt">
            <li v-for="menu in menuData" :key="menu.menuId">
              <router-link data-target="trending" class="nav_trig"
                           :to="`/products-list/${sanitizeTitle(menu.menuName)}/${encryptIDs(menu.menuId)}`">{{ menu.menuName }}</router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-2 col-6 mobile_social">
          <h5>Social</h5>
          <ul class="ftr_cl_cnt fi_lst">
            <li><a target="_blank" href="https://www.instagram.com/thatsliving.qatar">
              <img class="img-fluid" src="../../../assets/svg-icons/instagram.svg"/>
            </a></li>
            <li><a target="_blank" href="https://www.linkedin.com/company/that-s-living-qa">
              <img class="img-fluid" src="../../../assets/svg-icons/linkedin.svg"/>
            </a></li>
            <li><a target="_blank" href="https://www.youtube.com/channel/UCtVflXeRopCeq1adqRM79AQ">
              <img class="img-fluid" src="../../../assets/svg-icons/youtube.svg"/>
            </a></li>
            <li><a target="_blank" href="https://vm.tiktok.com/ZSeMbuMnh/">
              <img class="img-fluid" src="../../../assets/svg-icons/ticktok.svg"/>
            </a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="copy-right-sec">Copy Rights @{{new Date().getFullYear()}} That's Living</div>
  </footer>
  <WishlistPopUp></WishlistPopUp>
<!--  <div class="modal view-in-3d-modal fade" id="exampleModal" tabindex="-1"-->
<!--       aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--    <div class="modal-dialog modal-dialog-centered">-->
<!--      <div class="modal-content">-->
<!--        <div class="modal-header border-0">-->
<!--          <button type="button" @click="closePopup" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closePop"></button>-->
<!--        </div>-->

<!--        <div class="modal-body">-->
<!--          <div class="row">-->
<!--            <div class="col-md-12">-->
<!--              <div-->
<!--                      class="alert alert-danger"-->
<!--                      v-if="errorShow"-->
<!--                      id="error_msg"-->
<!--              >-->
<!--                <ul>-->
<!--                  <li>{{errors }}</li>-->
<!--                </ul>-->
<!--              </div>-->

<!--              <div v-if="this.projects.length == 0">-->
<!--                <span style="margin-left: 30px">Please set anyone project as default</span>-->
<!--                <br/> <br/>-->
<!--                <div class="ad_btns_cnt">-->
<!--                  <button @click="addProject"-->
<!--                          class="btn_default">-->
<!--                    <span>Create New Project</span>-->
<!--                  </button>-->

<!--                </div>-->

<!--              </div>-->
<!--              <div v-else-if="this.projects.length > 0">-->


<!--                <div class="modal-head heade_whish">-->
<!--                  <h5>Choose Your project to add to WishList </h5>-->
<!--                </div>-->

<!--                <div class="project_popup modal-body">-->
<!--                  <div class="whish_check product_filter_sec">-->

<!--                    <div class="form-check" v-for="(pro,index) in projects" :key="index">-->
<!--                      <label class="form-check-label">-->
<!--                        {{pro.projectClientName}}-->
<!--                        <input class="form-check-input" type="checkbox" v-model="this.projectIds" :value="pro.id">-->
<!--                        <span class="checkmark"></span>-->
<!--                      </label>-->
<!--                    </div>-->




<!--                  </div>-->

<!--                </div>-->
<!--&lt;!&ndash;                <div class="whist_checbox" v-if="projectIds.length <= 1">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <div class="product_filter_sec">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <div class="form-check">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                      <label class="form-check-label text-none" for="flexCheck3"> MAKE THIS PROJECT AS DEFAULT FOR WISHLIST&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <input class="form-check-input" type="checkbox" id="flexCheck3" name="isDefault"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                @change="onChangeDefault"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                               :value="client.isDefault"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                :checked="client.isDefault == 1">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                                <span class="checkmark"></span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                              </label>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                            </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                          </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                        <div class="ad_btns_cnt whist_btns">-->
<!--                                          <button class="btn_outline" @click="addProject">-->
<!--                                            <span> Create New Project</span>-->
<!--                                          </button>-->
<!--                                          <button-->
<!--                                                  class="btn_default" @click="addToWishList">-->
<!--                                            <span> Add To Project</span>-->
<!--                                          </button>-->

<!--                                        </div>-->




<!--                         </div>-->

<!--                                    </div>-->

<!--                                  </div>-->






<!--                                </div>-->

<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
                        </template>
                        <script>
                          import "../../../components/user/address-book/css/add_address.css";
                          import WishlistPopUp from "@/components/layouts/cart/WishlistPopUp.vue";
                        import Api from "@/apis/HomePage";
                        import Api1 from "@/apis/Project";
                        import common from "@/apis/Common";
                        import {Base64} from "js-base64";
                          import wishListApi from "@/apis/WishList";
                          // import Master from "../../../apis/Master";
                          import useVuelidate from "@vuelidate/core";
                          import {
                            required,
                          } from "@vuelidate/validators";
                          import Project from "../../../apis/Project";
                        export default {
                          name: 'cart-footer',
                          props: ["productData"],
                          data() {
                            return {
                              cartbadge: '0',
                              compo: '0',
                              menuData: [],
                                brands: [],
                              projectIds: [],
                              megaMenuData: [],
                              projects: [],
                              projectsData: "",
                              projectClientName: "",
                              common: common,
                              client: {
                                isDefault: 0,
                              },
                              isLoader: false,
                              wishListBool: [],
                              errors: "",
                              default: "",
                              errorShow: false,
                              projectDefault :[]
                            }
                          },
                          components: {
                            WishlistPopUp
                          },
                          created() {
                            document.title = this.$route.meta.title;
                            this.megaMenu();
                            this.getProject()
                            this.defaultProject();
                            this.getBrand();
                            this.projectDefault = this.$store.getters.getDefaultProject;

                          },
                          mounted(){
                            this.projectsData = localStorage.getItem('projectBool')

                          },
                          methods: {
                            async defaultProject(){
                              let userId = localStorage.getItem("id");
                              userId = Base64.decode(userId);
                              Project.defaultProjectDetails({ userId: userId }).then((res) => {
                                // console.log("Default_Project", res.data);
                                if (res.data.success) {
                                  this.projectDefault =  res.data.data;
                                  localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
                                  this.$store.dispatch('checkDefaultProject', this.projectDefault);
                                }
                              }).catch((err) => {
                                console.log("abcd",err);
                              });
                            },
                            // async changeMode(mode){
                            //   this.projectClientName = mode.projectClientName;
                            //   this.client.projectId = mode.id;
                            // },
                            async closePopup() {
                              this.client.projectId = "";
                              this.projectIds = [];
                              this.errors = "";
                              this.errorShow = false;
                              this.v$.client.projectId.$reset();
                              this.client.isDefault = 0;
                            },

                            addProject(){
                              window.location.href = '/user/add-project'
                              // this.$router.push(`/user/projects`)
                            },
                            getDefProject(){
                              window.location.href = '/user/projects'
                            },

                            onChangeDefault() {
                              if(this.client.isDefault==1){
                                this.client.isDefault = 0;
                              }else{
                                this.client.isDefault = 1;


                              }
                            },

                            getProject: function () {
                              let userId = localStorage.getItem("id");
                              userId = Base64.decode(userId);
                              Api1.getProjectList({userId: userId})
                                      .then((res) => {
                                        this.projects = res.data.data;
                                        if(res.data.data.length == 0){
                                          localStorage.setItem("project","NO")
                                        }else {
                                          localStorage.setItem("project","YES")
                                        }

                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                            },

                              getBrand: function () {
                                  Api.getBrand(

                                  )
                                      .then((res) => {
                                          this.brands = res.data.data;


                                      })
                                      .catch((err) => {
                                          console.log(err);
                                      });
                              },

                            async addToWishList() {
                              alert()
                              let userId = localStorage.getItem("id");
                              userId = Base64.decode(userId);
                              let productId = localStorage.getItem('productId');


                              let payLoad = {
                                userId: userId,
                                productId: productId,
                                isDefault: this.client.isDefault,
                                clientProjectIds: this.projectIds,
                                deleteCart:0
                              };
                              wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                              // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                                      .then((res) => {
                                        console.log("Rolex", res.data);
                                        if (res.data.success) {
                                          // this.$store.dispatch('addWishList', productId);
                                          // this.wishListBool[productId] = true;
                                          const elem = this.$refs.closePop;
                                          elem.click();
                                        }
                                      })
                                      .catch((err) => {
                                        this.wishListBool[productId] = false;
                                        console.log(err);
                                      });
                              Project.defaultProjectDetails({ userId: userId }).then((res) => {
                                // console.log("Default_Project", res.data);
                                if (res.data.success) {
                                  this.projectDefault =  res.data.data;
                                  localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
                                  this.$store.dispatch('checkDefaultProject', this.projectDefault);
                                }
                              }).catch((err) => {
                                console.log("abcd",err);
                              });



                            },


                            // async addProjectWishlist(){
                            //   this.errorShow = false;
                            //   this.v$.$validate(); // checks all inputs
                            //   if (!this.v$.$error) {
                            //     this.isLoader = true;
                            //   let data = JSON.parse(localStorage.getItem("productData"));
                            //   let userId = localStorage.getItem("id");
                            //   userId = Base64.decode(userId);
                            //
                            //   const { productId } = data?.variants[0];
                            //   let payLoad = {
                            //     userId: userId,
                            //     productId: productId,
                            //     clientProjectId: this.client.projectId,
                            //   };
                            //   wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                            //           .then((res) => {
                            //             console.log("Rolessss", res.data);
                            //             if (this.client.isDefault == 1){
                            //               let payLoad1 = {
                            //                 userId: userId,
                            //                 id: this.client.projectId
                            //               };
                            //               Project.makeDefaultProject(payLoad1).then((res) => {
                            //                 console.log("Success Default", res.data);
                            //               })
                            //             }
                            //             this.isLoader = false;
                            //             const elem = this.$refs.closePop;
                            //             elem.click();
                            //
                            //             if (res.data.success) {
                            //               this.$store.dispatch('addWishList', productId);
                            //               this.wishListBool[productId] = true;
                            //             }
                            //           })
                            //           .catch((err) => {
                            //             this.wishListBool[productId] = false;
                            //             this.isLoader = false;
                            //             this.errors = err?.response?.data?.error?.clientProjectId[0];
                            //             this.errorShow = true;
                            //             console.log(err);
                            //           });
                            //     this.v$.client.projectId.$reset();
                            //   } else {
                            //     console.log("Form failed validation");
                            //   }
                            // },
                            encryptIDs(menuId) {
                              let finalId = menuId;
                              return Base64.encode(finalId);
                            },
                            sanitizeTitle: function (title) {
                              if (title) {
                                // Change to lower case
                                let slug = title?.toLowerCase();
                                // Trim the last whitespace
                                slug = slug.replace(/\s*$/g, '');
                                // Change whitespace to "-"
                                slug = slug.replace(/\s+/g, '-');
                                return slug;
                              }
                            },
                            megaMenu() {
                              let menuData = this.$store.getters.getMenus;
                              if (menuData) {
                                this.menuData = menuData;
                                this.menuMap();
                              }
                              else {
                                Api.megaMenu()
                                        .then((res) => {
                                          if (res.data.success) {
                                            this.menuData = res.data.data;
                                            this.$store.dispatch('addMenus', this.menuData);
                                            this.menuMap();
                                          }
                                        })
                                        .catch((err) => {
                                          console.log(err);
                                        });
                              }
                            },
                            menuMap() {
                              for (var i in this.menuData) {
                                this.menuData[i].childShow = false;
                                //listSubMenu
                                for (var j in this.menuData[i].subList) {
                                  this.menuData[i].subList[j].childShow = false;
                                  //for (var k in this.menuData[i].subList[j].listSubMenu) {
                                  //}
                                }
                              }
                            },


                          },
                          setup: () => ({ v$: useVuelidate() }),
                          validations() {
                            return {
                              client: {
                                projectId: { required },
                              },
                            };
                          },
                        }
                        </script>
