<template>
  <div id="home-new">
    <div class="login_main_div container-fluid-lg">
      <BeforeLoginHeader />
      <div class="body_part row">
        <div class="body_image col-xl-7 mt-md-0 p-0">
          <div class="body_images"></div>
        </div>
        <div class="body_form col-lg-5">
          <div class="inner_body_form login_form_inner_body">
            <div class="pass_itm_blk">
              <h2 class="mb-4" style="text-align: left">
                <b>RESET YOUR PASSWORD</b>
              </h2>
              <form id="form_reset" v-on:submit.prevent="resetPassword">
                <div class="mb-3">
                  <div class="input_field inpt_icn">
                    <label>New PASSWORD*</label>
                    <input :type="passwordVisible == true ? 'text' : 'password'" v-model="form.password"
                      ref="password" />

                    <div class="inp_fld_icn">
                      <img class="visibility_btn" @click.prevent="togglePasswordVisibility()" :src="imgSrc" />
                    </div>
                  </div>
                  <span id="password" v-if="v$.form.password.$error" class="error_msg mt-0">
                    <span v-if="form.password == ''">New Password is required</span>
                    <span v-else-if="
                      v$.form.password.$errors[0].$message == 'Value is required'
                    ">Password cannot have spaces!</span>
                    <span v-else>{{ v$.form.password.$errors[0].$message }}</span>
                  </span>
                </div>
                <div class="mb-3">
                  <div class="input_field inpt_icn">
                    <label>CONFIRM PASSWORD*</label>
                    <input :type="repasswordVisible == true ? 'text' : 'password'" v-on="
                      form.confirmPassword
                        ? { keyup: v$.form.confirmPassword.$validate }
                        : {}
                    " v-model="form.confirmPassword" />

                    <div class="inp_fld_icn">
                      <img class="visibility_btn" @click.prevent="toggleRePasswordVisibility()" :src="reimgSrc" />
                    </div>
                  </div>
                  <span v-if="v$.form.confirmPassword.$error" class="error_msg mt-0">
                    <span v-if="form.confirmPassword == ''">Confirm password is required</span>
                    <span v-else-if="v$.form.confirmPassword.$errors[0].$message" id="confirm_password">Password does
                      not match</span>
                  </span>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 text-start">
                    <button class="btn_default">RESET NOW</button>
                  </div>
                  <div class="col-6 text-end">
                    <router-link to="/">RETURN TO LOGIN</router-link>
                  </div>
                </div>

                <p class="mt-3" id="countDown" style="display: none">
                  After {{ countDown }} second You are now being redirected to the
                  Login page
                </p>
                <div class="alert alert-success mt-5 col-12" v-if="success">
                  {{ success }}
                </div>
                <br />
                <div class="alert alert-danger mt-5 col-12" v-if="errors">
                  {{ errors }}
                </div>
                <br />
                <div class="alert alert-danger mt-5 col-12" v-if="error_msg">
                  {{ error_msg }}
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  </div>
  <!-- <div class="">
    <BeforeLoginHeader />
    <div class="r_p_cnt">
      <div class="pass_itm_blk">
        <h2 class="mb-4" style="text-align: left">
          <b>RESET YOUR PASSWORD</b>
        </h2>
        <form id="form_reset" v-on:submit.prevent="resetPassword">
          <div class="mb-3">
            <div class="input_field inpt_icn">
              <label>PASSWORD*</label>
              <input
                :type="passwordVisible == true ? 'text' : 'password'"
                v-model="form.password"
                ref="password"
              />

              <div class="inp_fld_icn">
                <img
                  class="visibility_btn"
                  @click.prevent="togglePasswordVisibility()"
                  :src="imgSrc"
                />
              </div>
            </div>
            <span
              id="password"
              v-if="v$.form.password.$error"
              class="error_msg mt-0"
            >
              <span v-if="form.password == ''">Password is required</span>
              <span
                v-else-if="
                  v$.form.password.$errors[0].$message == 'Value is required'
                "
                >Password cannot have spaces!</span
              >
              <span v-else>{{ v$.form.password.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="mb-3">
            <div class="input_field inpt_icn">
              <label>CONFIRM PASSWORD*</label>
              <input
                :type="repasswordVisible == true ? 'text' : 'password'"
                v-on="
                  form.confirmPassword
                    ? { keyup: v$.form.confirmPassword.$validate }
                    : {}
                "
                v-model="form.confirmPassword"
              />

              <div class="inp_fld_icn">
                <img
                  class="visibility_btn"
                  @click.prevent="toggleRePasswordVisibility()"
                  :src="reimgSrc"
                />
              </div>
            </div>
            <span v-if="v$.form.confirmPassword.$error" class="error_msg mt-0">
              <span v-if="form.confirmPassword == ''"
                >Confirm password is required</span
              >
              <span
                v-else-if="v$.form.confirmPassword.$errors[0].$message"
                id="confirm_password"
                >Password does not match</span
              >
            </span>
          </div>
          <div class="row align-items-center">
            <div class="col-6 text-start">
              <button class="btn_default">RESET NOW</button>
            </div>
            <div class="col-6 text-end">
              <router-link to="/">RETURN TO LOGIN</router-link>
            </div>
          </div>

          <p class="mt-3" id="countDown" style="display: none">
            After {{ countDown }} second You are now being redirected to the
            Login page
          </p>
          <div class="alert alert-success mt-5 col-12" v-if="success">
            {{ success }}
          </div>
          <br />
          <div class="alert alert-danger mt-5 col-12" v-if="errors">
            {{ errors }}
          </div>
          <br />
          <div class="alert alert-danger mt-5 col-12" v-if="error_msg">
            {{ error_msg }}
          </div>
          <br />
        </form>
      </div>
    </div>
    <LoginFooter />
  </div> -->
</template>
<script>
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  minLength,
  required,
  sameAs,
} from "@vuelidate/validators";
import User from "@/apis/User";
import { Base64 } from "js-base64";

export default {
  name: "change-password",
  data() {
    return {
      url_data: null,
      errors: "",
      error_msg: "",
      success: "",
      hidden: false,
      form: {
        password: "",
        confirmPassword: "",
      },
      countDown: 10,
      passwordVisible: false,
      repasswordVisible: false,
      // computedCondition: ''
    };
  },

  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(18),
          containsPasswordRequirement: helpers.withMessage(
            () =>
              `The password requires an uppercase, lowercase, number and special character`,
            (value) =>
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])^\S*$/.test(
                value
              )
          ),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
      },
    };
  },
  mounted() {
    this.url_data = this.$route.params.id;
    if (Base64.decode(this.url_data)  != window.$cookies.get('userId')){
      this.$router.push({name: "Error"})
      // this.$router.push({name: "UnauthorizedAccess"})
    }
  },

  created() {
    document.title = this.$route.meta.title;
  },

  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
      document.getElementById("countDown").style.display = "block";
      // this.hidden = false;
    },
    async resetPassword() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        // this.countDownTimer()
        User.resetPassword({
          userId: Base64.decode(this.url_data),
          newPassword: this.form.password,
          confirmPassword: this.form.confirmPassword,
        })
          .then((res) => {
            console.log(res.data.message);
            this.success = res.data.message;

            this.countDownTimer();
            setTimeout(
              () =>
                this.$router.push({
                  name: "Login",
                  params: { id: Base64.decode(this.url_data) },
                }),
              10000
            );
            // this.$router.go(0)
          })
          .catch((err) => {
            console.log((this.error_msg = err.response.data.errors));
            this.errors = err.response.data.error.userId[0];
            this.error_msg = err.response.data.errors;
          });
      } else {
        console.log("Form failed validation");
      }
    },
    async togglePasswordVisibility() {
      if (this.passwordVisible == false) {
        this.passwordVisible = true;
      } else if (this.passwordVisible == true) {
        this.passwordVisible = false;
      } else {
        this.passwordVisible = false;
      }
    },
    async toggleRePasswordVisibility() {
      if (this.repasswordVisible == false) {
        this.repasswordVisible = true;
      } else if (this.repasswordVisible == true) {
        this.repasswordVisible = false;
      } else {
        this.repasswordVisible = false;
      }
    },
  },
  computed: {
    imgSrc: function () {
      return this.passwordVisible === true
        ? require("../../../assets/image/show_password_icon.png")
        : require("../../../assets/image/hide_password_icon.png");
    },
    reimgSrc: function () {
      return this.repasswordVisible === true
        ? require("../../../assets/image/show_password_icon.png")
        : require("../../../assets/image/hide_password_icon.png");
    },
  },
  components: {
    LoginFooter,
    BeforeLoginHeader,
  },
};
</script>

<style>
@import "../../../assets/css/reset_password.css";
</style>