<template>
<div></div>
    <!-- <div class="forger_password_main_section">
        <div class="forgot_password_main_div">
            <div class="inner_forgot_password_main_div">
                <div class="forgot_password_section">
                    <div class="forgot_password_form pt-5">
                      <div class="alert alert-danger" v-if="errors">{{ errors }}</div><br/>
                      <form autocomplete="off"  method="post">
                        <h2 class="mb-4"><b>FORGOT PASSWORD</b></h2><br/>
                        <div class="mt-md-3 mt-3">
                          <label>EMAIL ID*</label>
                          <input id="email" type="text" v-on="email ? { keyup: v$.email.$validate } : {}" v-model="email"/><br>
                          <span v-if="v$.email.$error" class="error_msg_log error_msg">
                            <span v-if="v$.email.required">Email id is required</span>
                            <span v-else>{{ v$.email.$errors[0].$message }}</span>
                          </span>
                        </div>
                        <div style="" class="row align-items-center mt-lg-4 mt-md-3 mt-2 forgot_password_section_btn">
                            <button class="col-lg-5  col-6" id="close" v-on:click.prevent="requestResetPassword()" aria-hidden="true" :data-bs-dismiss="this.has_error === true ? 'modal' : ''" >SEND RESET LINK</button>
                            <div class="ancle_tag col-xl-5 col-6 p-0" data-bs-dismiss="modal" ><router-link  to="/">CANCEL</router-link></div>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>
// <script>
// import router from "@/routes/routes";
// import User from "@/apis/User";
// import useVuelidate from "@vuelidate/core";
// import {email, required} from "@vuelidate/validators";
// export default ({
//     name:"forget_password",
//     data(){
//         return{
//             errors:'',
//             email:'',
//             has_error: false,     
//         }

//     },
//   setup: () => ({ v$: useVuelidate() }),
//   validations () {
//     return {
//       email: { required, email }
//     }
//   },
//   mounted() {
//     document.getElementById('close').click();
//   },
//   created() {
//     document.title = this.$route.meta.title;
//   },
//     methods:{
//       async requestResetPassword() {

//         this.has_error=true
//         this.v$.$validate() // checks all inputs
//         if (!this.v$.$error) {
//         User.forgetPassword(
//             {email: this.email}
//         ).then(result => {
//           this.has_error=true
//           this.response = result.data;
//            router.push({name: 'ResetPasswordEmail' , params: {email: this.email}})
//           console.log(result.data);
//         }, error => {
//           console.error(error);
//           this.errors = error.response.data.errors
          
//         })
//         }else {
//           console.log('Form failed validation')
//         }
//       },
//     },
//     components:{
//     },
// })
// </script>
<style>

</style>

