export default {
    formatNumber(number) {
        if(!number)
        return '';
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'QAR',
          minimumFractionDigits: 0
      }).format(number);
    }
}