<template>
  <div class="col-md-9 col-12 user_order_t_section_col2 py-md-0">
    <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
    <div class="alert alert-success" v-if="success">{{ success }}</div>
    <div style="text-align: left">
      <h2 class="ac_pg_head mb-0">ADD Payment</h2>
      <div class="ac_det_cnt">
        <form class="" v-on:submit.prevent="addPayment" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-6  mt-3">
              <div class="input_field">
                <label>Enter Payment Id*</label>
                <input type="text" v-model="payLoad.paymentRefId"
                  v-on="payLoad.paymentRefId ? { change: v$.payLoad.paymentRefId.$validate } : {}" />
              </div>
              <span v-if="v$.payLoad.paymentRefId.$error" class="error_msg_log error_msg">
                <span v-if="payLoad.paymentRefId == ''">Payment Id is required</span>
                <span v-else>{{ v$.payLoad.paymentRefId.$errors[0].$message }}</span>
              </span>
            </div>

            <div class="col-lg-6  mt-3">
              <div class="dd_default ipt_fld">
                <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false">
                  <label>Enter Payment Mode*</label>
                  <div class="dropdown dd_default">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">{{ (payLoad.paymentMode) ? paymentModeText : 'Choose Payment Mode'
                      }}</button>
                    <ul class="dropdown-menu">
                      <li v-for="mode in modeList" :key="mode.id">
                        <a @click="changeMode(mode)" class="dropdown-item">{{ mode.mode }}</a>
                      </li>

                    </ul>
                  </div>

                </div>
              </div>
              <span v-if="v$.payLoad.paymentMode.$error" class="error_msg_log error_msg">
                <span v-if="payLoad.paymentMode == ''">Payment Mode is required</span>
                <span v-else>{{ v$.payLoad.paymentMode.$errors[0].$message }}</span></span>
            </div>

            <div class="col-lg-6 mt-3">
              <div class="input_field">
                <label>Payment DATE AND TIME*</label>
                <input type="datetime-local" v-model="payLoad.paidOn"
                  v-on="payLoad.paidOn ? { change: v$.payLoad.paidOn.$validate } : {}" />
              </div>
              <span v-if="v$.payLoad.paidOn.$error" class="error_msg_log error_msg">
                <span v-if="payLoad.paidOn == ''">Payment Date and Time is required</span>
                <span v-else>{{ v$.payLoad.paidOn.$errors[0].$message }}</span></span>
            </div>

            <div class="col-lg-6 mt-3">
              <div class="input_field">
                <label>Enter Amount*</label>
                <input type="text" v-model="payLoad.paidAmount"
                  v-on="payLoad.paidAmount ? { change: v$.payLoad.paidAmount.$validate } : {}" />
              </div>
              <span v-if="v$.payLoad.paidAmount.$error" class="error_msg_log error_msg">
                <span v-if="payLoad.paidAmount == ''">Amount is required</span>
                <span v-else>{{ v$.payLoad.paidAmount.$errors[0].$message }}</span></span>
            </div>

            <div class="col-lg-6 mt-3">

              <div class="file_u_cnt">
                <input type="file" id="reg_upload" @change="onFileSelected($event)" hidden accept="image/*, .pdf"
                  v-on="files.file1 ? { change: v$.files.file1.$validate } : {}" />
                <span class="lable_sec" id="reg_file">{{ fileUpd }}</span>
                <label for="reg_upload" class="reg_u_btn">Choose File*</label>
              </div>
              <p class="below_input_msg">
                Please upload a copy of transaction receipt for reference. Upload format PDF, PNG or JPG file.
              </p>

              <span v-if="v$.files.file1.$error" class="error_msg_log error_msg">
                <span v-if="files.file1 == ''">Upload Receipt is required</span>
              </span>

            </div>

          </div>


          <div class="ad_btns_cnt mb-lg-3 mb-md-3 mb-3">
            <button @click="cancel" class="btn_outline">CANCEL</button>
            <button class="btn_default" :disabled="isLoader" :class="{ 'disable-btn': isLoader }">
              <div v-if="isLoader">
                <img width="30" src="../../../assets/image/loader.gif" />
              </div>
              <span v-if="!isLoader">Add</span>
            </button>
          </div>
          <div class="login_account_note_i_section">
            <p>Note:</p>
            <p class="txt_none">
              <ul>
            <li>1. Please ensure the payment is less than or equal to the balance amount.</li>
            <li>2. The payment will be reflected in your account only after the admin approves.</li>
            <li>3. If the payment is not received, the admin will reject the same and share reasons over the customer's email.</li>
          </ul>




            </p>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>
<script>
import "./css/orders.css";
import router from "@/routes/routes";
import Api from "@/apis/Orders";
//import { Base64 } from "js-base64";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { Base64 } from "js-base64";
export default {
  name: "user-order-payment",
  data() {
    return {
      modeList: [],
      orderData: [],
      paymentModeText: '',
      payLoad: {
        orderId: '',
        paymentMode: '',
        paidAmount: '',
        paidOn: '',
        paymentRefId: '',
        paymentStatus: 1,
      },
      files: {
        file1: ''
      },
      fileUpd: 'Upload Reciept',
      errors: '',
      success: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    document.title = this.$route.meta.title;
    this.getPaymentModes();
    this.getOrderDetails();
  },
  methods: {
    async cancel() {
      router.push("/user/order-details/" + this.$route.params.id);
    },
    async changeMode(mode) {
      this.paymentModeText = mode.mode;
      this.payLoad.paymentMode = mode.id;
    },
    async getPaymentModes() {
      Api.getPaymentModes({ trash: 0 })
        .then((res) => {
          if (res.data.success) {
            this.modeList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileSelected(event) {
      this.fileUpd = event.target.files[0].name;
      console.log(event.target.files[0].name);
      this.files.file1 = event.target.files[0];
    },
    async getOrderDetails() {
      let id = this.$route.params.id;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getOrderDetails({ orderId: id,userId: userId })
              .then((res) => {
                if (res.data.success) {
                  this.orderData = res.data.data;
                }
              })
              .catch((err) => {
                console.log(err);
              });
    },
    async addPayment() {

      var total_bal = (this.orderData.total - this.orderData.cancelledTotalAmount - this.orderData.totalPaidAmount)
      if (this.payLoad.paidAmount > total_bal) {
        alert("PAID AMOUNT IS GREATER THAN BALANCE AMOUNT")
      }
      else{
      this.payLoad.orderId = this.$route.params.id;
      this.v$.$validate(); // checks all inputs
      const fd = new FormData();
      fd.append("image", this.files.file1, this.files.file1.name);
      fd.append("data", JSON.stringify(this.payLoad));
      if (!this.v$.$error) {
        this.isLoader = true;
        // if no fail validation
        Api.addPayment(fd)
          .then((res) => {
            console.log(res);
            this.success = res.data.message;
            this.isLoader = false;
            setTimeout(() => {
              router.push("/user/order-details/" + this.$route.params.id);
            }, 3000);
          })
          .catch((err) => {
            this.isLoader = false;
            if (err.response.data.error) {
              this.errors = err.response.data.error.paymentMode[0];
            } else {
              this.errors = err.response.data.errors;
            }
            setTimeout(() => {
              this.errors = "";
            }, 5000);
          });
        console.log("Form successfully submitted.");
      } else {
        console.log("Form failed validation");
      }
      }


    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      payLoad: {
        paymentRefId: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
            () => `Payment Id is invalid`,
            (value) => /^[0-9A-Za-z\s']+$/.test(value)
          ),
        },
        paymentMode: { required },
        paidAmount: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100),
          containspaymentModeRequirement: helpers.withMessage(
            () => `Amount is invalid`,
            (value) => /^[0-9\s']+$/.test(value)
          ),
        },
        paidOn: { required },
      },
      files: {
        file1: { required },
      },
    };
  },
};
</script>