<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Privacy policy Sec -->

      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->
      <p class="prvc-plc-cnt-sec container" v-html="cmsData.content"></p>

      <!-- Cookies policy Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import BreadCrumb from '@/components/common/BreadCrumb';
export default {
  name: "cookies-policy",
  data() {
    return {
      cmsData: []
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb,
  },
  props: ["search", "usericons"],
  created() {
    document.title = this.$route.meta.title;
    this.getCMSData();
  },
  methods: {
    async getCMSData() {
      Api.getCMSData({ page: "cookie" })
        .then((res) => {
          if (res.data.success) {
            this.cmsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
<style>
  /*table {*/
  /*  border-collapse: collapse;*/
  /*  border-spacing: 0;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*  border: 1px double #b3b3b3;*/
  /*  text-transform: uppercase !important;*/
  /*}*/
  /* table th {*/
  /*  font-weight: 700;*/
  /*  background: #f3f3f3;*/
  /*}*/
  /*table td,table th {*/
  /*  min-width: 2em;*/
  /*  padding: 0.4em;*/
  /*  border: 1px solid #bfbfbf;*/
  /*}*/
  /*table th {*/
  /*  min-width: 2em;*/
  /*  padding: 0.4em;*/
  /*  border: 1px solid #bfbfbf;*/
  /*}*/
  /*p{*/
  /*  text-transform: uppercase !important;*/
  /*}*/
  /*ul{*/
  /*  text-transform: uppercase !important;*/
  /*}*/
</style>
