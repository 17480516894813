<template>
    <div class="modal view-in-3d-modal fade" id="wishlistModel" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" @click="closePopup" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closePop"></button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div
                                    class="alert alert-danger"
                                    v-if="errorShow"
                                    id="error_msg"
                            >
                                <ul>
                                    <li>{{errors }}</li>
                                </ul>
                            </div>


                            <!--              <div>-->
                            <!--              <h2 class="ac_pg_head">Select Project to add wishlist</h2>-->
                            <!--              <form v-on:submit.prevent="addProjectWishlist">-->

                            <!--              <div class="dd_default ipt_fld">-->
                            <!--                <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">-->
                            <!--                  <div class="dropdown dd_default">-->
                            <!--                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"-->
                            <!--                            aria-expanded="false">{{(client.projectId)?projectClientName:'Select Project'}}</button>-->
                            <!--                    <ul class="dropdown-menu">-->
                            <!--                      <li v-for="project in projects" :key="project.id">-->
                            <!--                        <a @click="changeMode(project)" class="dropdown-item">{{project.projectClientName}}</a>-->
                            <!--                      </li>-->

                            <!--                    </ul>-->
                            <!--                  </div>-->

                            <!--                </div>-->
                            <!--                <span-->
                            <!--                        v-if="v$.client.projectId.$error"-->
                            <!--                        class="error_msg_log error_msg"-->
                            <!--                >-->
                            <!--              <span v-if="client.projectId == ''">Project is required</span>-->
                            <!--&lt;!&ndash;              <span v-else>{{ v$.client.projectId.$error }}</span>&ndash;&gt;-->
                            <!--            </span>-->
                            <!--              </div>-->
                            <!--              <div class="business_type_section mb-md-3 mb-4">-->
                            <!--                <div>-->
                            <!--                  <input-->
                            <!--                          name="isDefault"-->
                            <!--                          @change="onChangeDefault"-->
                            <!--                          :value="client.isDefault"-->
                            <!--                          :checked="client.isDefault == 1"-->
                            <!--                          id="b_type_freelance"-->
                            <!--                          type="checkbox"-->
                            <!--                  /><label class="ms-2" for="b_type_freelance" style="font-size: 12px"-->
                            <!--                >You want to make this project as default for other wishlist items</label-->
                            <!--                >-->
                            <!--                </div>-->
                            <!--              </div>-->

                            <!--              <div class="ad_btns_cnt">-->
                            <!--                <span class="btn_outline" @click="closePopup" data-bs-dismiss="modal">-->
                            <!--                  CLOSE-->
                            <!--                </span>-->
                            <!--                <button-->
                            <!--                        :disabled="isLoader"-->
                            <!--                        :class="{ 'disable-btn': isLoader }"-->
                            <!--                        class="btn_default"-->
                            <!--                >-->
                            <!--                  <div v-if="isLoader">-->
                            <!--                    <img width="30" src="../../../assets/image/loader.gif" />-->
                            <!--                  </div>-->
                            <!--                  <span  v-if="!isLoader"> Add </span>-->
                            <!--                </button>-->

                            <!--              </div>-->

                            <!--              </form>-->
                            <!--              </div>-->
                            <div v-if="this.projects.length == 0">
                                <span style="margin-left: 30px">Please set anyone project as default</span>
                                <br/> <br/>
                                <div class="ad_btns_cnt">
                                    <button @click="addProject"
                                            class="btn_default">
                                        <span>Create New Project</span>
                                    </button>

                                </div>

                            </div>
                            <div v-else-if="this.projects.length > 0">


                                <div class="modal-head heade_whish">
                                    <h5>Choose Your project to add to WishList </h5>
                                </div>

                                <div class="project_popup modal-body">
                                    <div class="whish_check product_filter_sec">

                                        <div class="form-check" v-for="(pro,index) in projects" :key="index">
                                            <label class="form-check-label">
                                                {{pro.projectClientName}}
                                                <input class="form-check-input" type="checkbox" v-model="this.projectIds" :value="pro.id">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>




                                    </div>

                                </div>
<!--                                <div class="whist_checbox" v-if="projectIds.length <= 1">-->
<!--                                    <div class="product_filter_sec">-->
<!--                                        <div class="form-check">-->
<!--                                            <label class="form-check-label text-none" for="flexCheck3"> MAKE THIS PROJECT AS DEFAULT FOR WISHLIST-->
<!--                                                <input class="form-check-input" type="checkbox" id="flexCheck3" name="isDefault"-->
<!--                                                       @change="onChangeDefault"-->
<!--                                                       :value="client.isDefault"-->
<!--                                                       :checked="client.isDefault == 1">-->
<!--                                                <span class="checkmark"></span>-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="ad_btns_cnt whist_btns">
                                    <button class="btn_outline" @click="addProject">
                                        <span> Create New Project</span>
                                    </button>
                                    <button
                                            class="btn_default" @click="addToWishList">
                                        <span> Add To Project</span>
                                    </button>

                                </div>




                            </div>

                        </div>

                    </div>






                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import "../../../components/user/address-book/css/add_address.css";
    import Api1 from "@/apis/Project";
    import common from "@/apis/Common";
    import {Base64} from "js-base64";
    import wishListApi from "@/apis/WishList";
    import useVuelidate from "@vuelidate/core";
    import {
        required,
    } from "@vuelidate/validators";
    import Project from "../../../apis/Project";
    export default {
        name: 'cart-footer',
        props: ["productData"],
        data() {
            return {
                cartbadge: '0',
                compo: '0',
                menuData: [],
                projectIds: [],
                megaMenuData: [],
                projects: [],
                projectsData: "",
                projectClientName: "",
                common: common,
                client: {
                    isDefault: 0,
                },
                isLoader: false,
                wishListBool: [],
                errors: "",
                default: "",
                errorShow: false,
                projectDefault :[]
            }
        },
        created() {
            document.title = this.$route.meta.title;
            this.getProject()
            this.defaultProject();
            this.projectDefault = this.$store.getters.getDefaultProject;

        },
        mounted(){
            this.projectsData = localStorage.getItem('projectBool')

        },
        methods: {
            async defaultProject(){
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                Project.defaultProjectDetails({ userId: userId  }).then((res) => {
                    // console.log("Default_Project", res.data);
                    if (res.data.success) {
                        this.projectDefault =  res.data.data;
                        localStorage.setItem("defaultProject",JSON.stringify(res.data.data))

                        this.getProject()
                        this.$store.dispatch('checkDefaultProject', this.projectDefault);
                    }
                }).catch((err) => {
                    console.log("abcd",err);
                });
            },
            // async changeMode(mode){
            //   this.projectClientName = mode.projectClientName;
            //   this.client.projectId = mode.id;
            // },
            async closePopup() {
                this.client.projectId = "";
                this.projectIds = [];
                this.errors = "";
                this.errorShow = false;
                this.v$.client.projectId.$reset();
                this.client.isDefault = 0;
            },

            addProject(){
                window.location.href = '/user/add-project'
                // this.$router.push(`/user/projects`)
            },
            getDefProject(){
                window.location.href = '/user/projects'
            },

            onChangeDefault() {
                if(this.client.isDefault==1){
                    this.client.isDefault = 0;
                }else{
                    this.client.isDefault = 1;
                }
            },

            getProject: function () {
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                Api1.getProjectList({userId: userId})
                    .then((res) => {
                        this.projects = res.data.data;
                        if(res.data.data.length == 0){
                            localStorage.setItem("project","NO")
                        }else {
                            localStorage.setItem("project","YES")
                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            async addToWishList() {
                let userId = localStorage.getItem("id");
                userId = Base64.decode(userId);
                let productId = localStorage.getItem('productId');


                let payLoad = {
                    userId: userId,
                    productId: productId,
                    isDefault: this.client.isDefault,
                    clientProjectIds: this.projectIds,
                    deleteCart:0
                };
                wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
                    .then((res) => {
                        console.log("Rolex Swaraj Bhuyan", res.data);
                        if (res.data.success) {

                            // this.$store.dispatch('addWishList', productId);
                            // this.wishListBool[productId] = true;
                          this.getWishList()
                            const elem = this.$refs.closePop;
                            elem.click();
                        }
                    })
                    .catch((err) => {
                        this.wishListBool[productId] = false;
                        console.log(err);
                    });

                Project.defaultProjectDetails({ userId: userId  }).then((res) => {
                    // console.log("Default_Project", res.data);
                    if (res.data.success) {
                        this.projectDefault =  res.data.data;
                        localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
                        this.$store.dispatch('checkDefaultProject', this.projectDefault);
                    }
                }).catch((err) => {
                    console.log("abcd",err);
                });



            },
          async getWishList() {
            let userId = localStorage.getItem("id");
            userId = Base64.decode(userId);
            wishListApi.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
                .then((res) => {
                  if (res.data.success) {
                    this.wishListData = res.data.data;
                    if (this.projectDefault != null) {
                      this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
                      let wishlist = [];
                      this.wishlistDefaultData.forEach((val)=>{
                        // console.log("swaraj", val)
                        wishlist[val.productId] = true;
                        // val.products.forEach((val1)=>{
                        //
                        // });
                      });
                      this.$store.dispatch('addTotalWishList', wishlist);
                    }
                    // else {
                    //   this.wishlistDefaultData = []
                    //   let wishlist = [];
                    //   this.wishlistDefaultData.forEach((val)=>{
                    //     // console.log("swaraj", val)
                    //     wishlist[val.productId] = true;
                    //     // val.products.forEach((val1)=>{
                    //     //
                    //     // });
                    //   });
                    //   this.$store.dispatch('addTotalWishList', wishlist);
                    // }
                    else {
                      let count = 0;
                      this.wishListData.forEach((val)=>{
                        count += val.products.length
                      });

                      this.$store.dispatch('addTotalWishListCount', count);
                      this.wishListCount = count;
                    }



                  }
                })
                .catch((err) => {
                  console.log(err);
                });
          },





        },
        setup: () => ({ v$: useVuelidate() }),
        validations() {
            return {
                client: {
                    projectId: { required },
                },
            };
        },
    }
</script>