import axios from "axios";
import Api1 from "../apis/Api";
let ApiToken = axios.create({
    baseURL: Api1.defaults.baseURL,
});

ApiToken.interceptors.request.use( (config) => {
    //const authToken = localStorage.getItem('user-token');
    //config.headers['authentication-token'] = authToken ? authToken : '';
    //config.headers['content-type'] = 'application/json';
    //config.headers['accept'] = 'application/json';
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
// Add a response interceptor
ApiToken.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
export default ApiToken;