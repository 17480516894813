<template>
  <div class="user_contact">
    <BeforeLoginHeader />
    <form v-on:submit.prevent="supportSubmit">
      <div class="user_contact_body">
        <div class="row mt-lg-5 mt-md-5 mx-auto" style="width: 100%">
          <div
            class="
              user_contact_col_2
              customer_support_col_2
              col-md-9 col-12
              py-md-0 py-5
              w-100
            "
          >
            <div class="row">
              <div class="col-md-5 user_contact_texts c_order1">
                <div class="user_contact_text_section">
                  <!-- <h1 class="py-3">SUPPORT 24/7</h1> -->
                  <div class="my-4">
                    <p>
                      Our experts are available to help users with any questions
                      or concerns. Please feel free to reach out at +974
                      77266267 or email us at CONTACT@THATSLIVING.COM.
                    </p>
                  </div>
                  <div class="mt-auto">
                    <table>
                      <tr>
                        <td>
                          <p>
                            <b>EMAIL US :</b
                            ><span>CONTACT@THATSLIVING.COM</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><b>CALL US :</b><span>+974 77266267</span></p>
                        </td>
                      </tr>
                      <tr>
                        <td><p>Available from 9AM - 6PM (GMT+3)</p></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-7 c_order2">
                <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
                <div class="alert alert-success" v-if="successMsg">
                  {{ successMsg }}
                </div>
                <div class="">
                  <h2 class="text-dark">CONTACT US</h2>
                  <form>
                    <div class="input_field col-md-5 col-12 col-5 mt-md-2 mt-2">
                      <label>NAME*</label>
                      <input
                        type="text"
                        v-model="supportForm.name"
                        v-on="
                          supportForm.name
                            ? { keyup: v$.supportForm.name.$validate }
                            : {}
                        "
                      />
                    </div>
                    <div class="p20">
                      <span v-if="v$.supportForm.name.$error" class="error_msg">
                        <span v-if="supportForm.name == ''"
                          >Name is required</span
                        >
                        <span v-else>{{
                          v$.supportForm.name.$errors[0].$message.replace(
                            "FIELD",
                            "Name"
                          )
                        }}</span>
                      </span>
                    </div>
                    <div class="input_field col-md-5 col-12 col-5 mt-md-2 mt-2">
                      <label>PHONE NUMBER*</label>
                      <input
                        type="text"
                        v-model="supportForm.mobile"
                        v-on="
                          supportForm.mobile
                            ? { keyup: v$.supportForm.mobile.$validate }
                            : {}
                        "
                      />
                    </div>
                    <div class="p20">
                      <span
                        v-if="v$.supportForm.mobile.$error"
                        class="error_msg"
                      >
                        <span v-if="supportForm.mobile == ''"
                          >Phone number is required</span
                        >
                        <span v-else>{{
                          v$.supportForm.mobile.$errors[0].$message.replace(
                            "FIELD",
                            "Phone number"
                          )
                        }}</span>
                      </span>
                    </div>
                    <div class="input_field col-md-5 col-12 col-5 mt-md-2 mt-2">
                      <label>EMAIL*</label>
                      <input
                        type="text"
                        v-model="supportForm.email"
                        v-on="
                          supportForm.email
                            ? { keyup: v$.supportForm.email.$validate }
                            : {}
                        "
                      />
                    </div>
                    <div class="p20">
                      <span
                        v-if="v$.supportForm.email.$error"
                        class="error_msg"
                      >
                        <span v-if="supportForm.email == ''"
                          >Email is required</span
                        >
                        <span v-else>{{
                          v$.supportForm.email.$errors[0].$message.replace(
                            "FIELD",
                            "Email"
                          )
                        }}</span>
                      </span>
                    </div>
                    <div class="input_field col-md-5 col-12 col-5 mt-md-2 mt-2">
                      <label>MESSAGE*</label>
                      <textarea
                        v-model="supportForm.message"
                        rows="10"
                        cols="50"
                        v-on="
                          supportForm.message
                            ? { keyup: v$.supportForm.message.$validate }
                            : {}
                        "
                      >
                      </textarea>
                    </div>
                    <div class="p20">
                      <span
                        v-if="v$.supportForm.message.$error"
                        class="error_msg"
                      >
                        <span v-if="supportForm.message == ''"
                          >Message is required</span
                        >
                        <span v-else>{{
                          v$.supportForm.message.$errors[0].$message.replace(
                            "FIELD",
                            "Message"
                          )
                        }}</span>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="
                user_contact_btn_section
                mt-md-3 mt-2
                row
                justify-content-end
                mb-md-5 mb-3
              "
            >
              <div class="col-md-3">
                <button
                  :class="{ 'disable-btn': isLoader }"
                  :disabled="isLoader"
                  class="main_btn w-100"
                >
                  <div v-if="isLoader">
                    <img width="30" src="../../assets/image/loader.gif" />
                  </div>
                  <span v-if="!isLoader">SUBMIT</span>
                </button>
              </div>
            </div>
          </div>
          <div class="support_address">
            <div class="row justify-content-md-between">
              <div class="col-md-5">
                <div class="col-11 mx-auto">
                  <h2>TAWAR MALL(TM) SHOWROOM</h2>
                  <div class="col-7">
                    <p>
                      1st Floor, Tawar Mall, Al Markhiya St Doha, Qatar Sunday
                      to Thursday 9AM - 10PM Friday- 3PM - 11PM
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mt-md-0 mt-4">
                <div class="col-11 mx-auto">
                  <h2>DOHA FESTIVAL CITY(DFC) SHOWROOM</h2>
                  <div class="col-7">
                    <p>
                      1st Floor - VIP Area, Doha Festival City. Umm Salal
                      Muhammed,Doha, Qatar. Sunday to Thursday 9AM - 10PM
                      Friday- 3PM - 11PM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <LoginFooter />
  </div>
</template>
<script>
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BeforeLoginHeader from "@/components/BeforeLoginHeader";
import LoginFooter from "@/components/LoginFooter";
import User from "@/apis/User";
import "./css/user-support.css";
export default {
  name: "user-support",
  data() {
    return {
      errors: "",
      supportForm: {
        name: "",
        mobile: "",
        email: "",
        message: "",
      },
      isLoader: false,
      messagesOverride: {
        required: "You must fill the {attribute} field to continue",
        email: "The email must be a genuine email address.",
      },
      successMsg: "",
    };
  },
  components: {
    BeforeLoginHeader,
    LoginFooter,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      supportForm: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
            () => `Name is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Phone Number is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        email: { required, email },
        message: {
          required,
          minLength: minLength(15),
        },
      },
    };
  },
  methods: {
    async supportSubmit() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        this.isLoader = true;
        User.postSupport(this.supportForm)
          .then((res) => {
            this.isLoader = false;
            this.clearInputs();
            this.v$.supportForm.$reset();
            if (res.data.success) {
              this.successMsg = res.data.message;
            }
          })
          .catch((err) => {
            console.log(err);
            this.errors = err.response.data.errors;
            this.isLoader = false;
          });
      }
    },
    async clearInputs() {
      this.supportForm.name = "";
      this.supportForm.mobile = "";
      this.supportForm.email = "";
      this.supportForm.message = "";
    },
  },
};
</script>
