<template>
  <CartHeader />
  <section class="contact_sec  feedback_page">
    <div class="page-width">
      <div class="row">
        <div class="col-lg-12">



          <div class="out_of_stock_pro">
            <div class="out_head_body">
              <h3 class="out_head"><img src="../../../assets/image/out_img.svg" alt="logo"> Sorry for the Inconvenience</h3>
              <p>The following product(s) are out of stock. Please remove out of stock products or add to wishlist then place the order</p>
            </div>

            <div class="product_details_stock">
              <div class="details_sto_header">
                <h5 class="header_left">PRODUCT DETAILS </h5>
                <h5 class="header_right">QTY</h5>
              </div>

              <div class="out_of_stock_left">






                <div class="out_of_stock_left_body"  v-for="item in products.cartItems" :key="item.id">
                  <div class="stock_img">
                    <img :src="item?.images[0]?.imageUrl" :alt="item.title" >
                  </div>
                  <div class="stock_text product_filter_sec">
                    <div class="prdt-onr-name checkout_brand">{{item.brand}}</div>
                    <h6><router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`">{{ item.title }}</router-link></h6>
                    <p class="item_inf">{{ item.sku }}</p>
                    <span>
                      <p v-if="item.stock > 0" class="text-success item_inf">In Stock : {{ item.stock }}</p>
                      <p v-if="item.stock <= 0 && item.preOrder == 0" style="color: red"  class="item_inf">Out of Stock</p>
                      <p v-if="item.preOrder == 1 && item.stock <= 0" style="color: red"  class="item_inf">Pre Order</p>
                    </span>
<!--                    <p class="item_inf">{{ item.stock }}</p>-->

              
                    <div class="form-check" v-if="item.stock <= 0">
                      <label class="form-check-label" :for="`customercheck${item.id}`"> NOTIFY ME ONCE ITEM IS BACK IN STOCK
                        <input class="form-check-input" type="checkbox" :value="item.productId" v-on:change="notifyItem($event,item.productId,item.variantId)" v-model="checkbox" :id="`customercheck${item.id}`">
                        <span class="checkmark"></span>
                        <div v-if="message[item.productId]" style="text-transform: uppercase !important; font-size: 12px" class="text-success" >{{ message[item.productId] }}</div>
                      </label>
                    </div>
                  </div>
                  <div class="stock_right_body">{{item.qty}}</div>
                </div>



                <p style="font-size: 12px; color: red">{{errorMessage}}</p>




              </div>



            </div>
            <div class="feedback_buttons">
              <!--              <button class="reset_btn btn_outline" id="notDisabled" :disabled="isLoader" :class="{ 'disable-btn': isLoader }" @click="placeOrder">-->
              <!--                <div v-if="isLoader">-->
              <!--                  <img width="22" src="../../../assets/image/loader.gif" />-->
              <!--                </div>-->
              <!--                <span v-if="!isLoader">PLACE ORDER</span>-->
              <!--              </button>-->
              <!--              <button disabled="" id="disabled" class="reset_btn btn_default"><span>PLACE ORDER</span></button>-->
              <router-link to="/show-cart"><button  value="GO TO CART" class="submit_btn btn_default">GO TO CART</button></router-link>
            </div>

          </div>
          <br/>


        </div>
      </div>
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import "./css/style.css";
import Api from "@/apis/Cart";
import Product from "@/apis/Products";
import { Base64 } from "js-base64";
export default {
  name: "order-failure",
  data() {
    return {
      products: [],
      cartData: [],
      checkbox: [],
      errorMessage: "",
      isLoader: false,
      message:[]
    }
  },
  components: {
    CartHeader,
    CartFooter,
  },
  created() {
    document.title = this.$route.meta.title;

    let userId = localStorage.getItem("id");
    userId = Base64.decode(userId);
    Api.cartOutOfStockProducts({ userId: userId })
            .then((res) => {
              if (res.data.success) {
                this.products = res.data.data;
              }
            })
            .catch((err) => {
              console.log(err);
            });
    Api.getCartItems({ userId: userId })
            .then((res) => {
              if (res.data.success) {
                this.cartData = res.data.data;
              }
            })
            .catch((err) => {
              console.log(err);
            });

  },
  mounted(){
  },
  methods: {
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, '');
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    },
    notifyItem(e,productId,variantId){
      if (e.target.checked) {
        let userId = localStorage.getItem("id");
        userId = Base64.decode(userId);
        let payLoad = {
          productId: productId,
          variantId: variantId,
          userId: userId,
        };
        Product.notifyItem(payLoad)
                .then((res) => {
                  // console.log(res.data.message)
                  this.message[productId] = res.data.message;
                }).catch((err) => {
          console.log(err);
        });
      }else {
        this.message = []
      }
    },
    // placeOrder(){
    //
    //   let userId = localStorage.getItem("id");
    //   if(this.cartData.cartItems.length == this.products.cartItems.length){
    //     this.errorMessage = "All the products are out of stock";
    //
    //     document.getElementById("notDisabled").style.display = "none";
    //     document.getElementById("disabled").style.display = "inline";
    //     // this.isLoader = true
    //
    //     return;
    //   }else{
    //     this.isLoader = true;
    //     userId = Base64.decode(userId);
    //     let payLoad = {
    //       shippingAddress: this.$route.params.sippingId,
    //       billingAddress: this.$route.params.billingId,
    //       userId: userId,
    //     };
    //     Api.confirmOrderWithStock(payLoad)
    //             .then((res) => {
    //               console.log(res)
    //               router.push({
    //                 name: "OrderSuccess",
    //                 params: { orderID: res.data?.data?.orderId, orderQty: res.data?.data?.orderQty},
    //               });
    //               this.isLoader = false;
    //               localStorage.setItem("cartData", JSON.stringify(res.data.data))
    //
    //
    //             }).catch((err) => {
    //       console.log(err);
    //
    //     });
    //  }
    // }



  },
  setup() {},
};
</script>

