<template>
  <div class="col-lg-9">
    <h2 class="ac_pg_head">My Orders</h2>
    <div class="ac_det_cnt px-0">
      <table class="addr_bk user_orders_table">
        <thead>
          <th>Images</th>
          <th class="">Order Id</th>
          <th class="">Date</th>
          <th class="d-none d-sm-table-cell">Delivery Address</th>
          <th class="">Order Amount</th>
          <th class="">Order Status</th>
        </thead>
        <tbody>
          <tr v-for="item in orderList" :key="item.id">
            <td>
              <div class="order_images">
                <div class="row">
                  <div v-if="item?.products[0]?.imageUrl" class="col-6">
                    <img :src="item?.products[0]?.imageUrl" class="img-fluid" />
                  </div>
                  <div v-if="item?.products[1]?.imageUrl" class="col-6">
                    <img :src="item?.products[1]?.imageUrl" class="img-fluid" />
                  </div>
                  <div v-if="item?.products[2]?.imageUrl" class="col-6">
                    <img :src="item?.products[2]?.imageUrl" class="img-fluid" />
                  </div>

                    <div v-if="item?.products[3]?.imageUrl" class="col-6">
                       <p style="margin-top: 20px">+</p>
                    </div>
                </div>
              </div>
            </td>
            <td>
              <div class="order_details">
                <router-link :to="`/user/order-details/${item.id}`">{{item.orderNum}}</router-link>
<!--                <div class="order_date">-->
<!--                  <span class="dot_green me-1"> </span>-->
<!--                  <span>{{item.orderDate}}</span>-->
<!--                </div>-->
              </div>
            </td>
            <td>
              <div class="order_details">
<!--                <router-link :to="`/user/order-details/${item.id}`">{{item.orderNum}}</router-link>-->
                <div class="order_date">
<!--                  <span class="dot_green me-1"> </span>-->
                  <span>{{formatDate(item.orderDate)}}</span>
                </div>
              </div>
            </td>
            <!-- <td class="d-none d-sm-table-cell">
              <span>{{item.qty}}</span>
            </td> -->
            <td class="d-none d-sm-table-cell">
              <div v-html="displayAddress(item)"></div>
              <!-- <span>{{common.formatNumber(item.subTotal)}}</span> -->
            </td>

            <td class="">
              <span>{{common.formatNumber(item.total)}}</span>
<!--              <div class="add_a_icns">-->
<!--                <a :href="`${baseURL}orderCopyDownload/${item.id}`"-->
<!--                  target="_blank" class="edit_icn">-->
<!--                  <img src="../../../assets/image/1.svg" class="img-fluid">-->
<!--                  <span> Order Copy</span>-->
<!--                </a>-->
<!--              </div>-->
            </td>
              <td>
                  <span class="grn-clr" v-if="item.orderStatus == 1">Confirmed</span>
                  <span class="yellow-clr" v-if="item.orderStatus == 2">Partial Delivered</span>
                  <span class="oran-clr" v-if="item.orderStatus == 3">Delivered</span>
                  <span class="red-clr" v-if="item.orderStatus == 4">Cancelled</span>
                  <br/><span v-if="item.orderStatus == 4">
<!--                {{ item.cancelReason }}-->
              </span>
              </td>


          </tr>
          <tr v-if="!(orderList.length>0)">
            <td colspan="6" style="text-align:center;">Orders not found</td>
          </tr>

        </tbody>
      </table>
    </div>
    <!-- 
    <div style="text-align: left">

      <div class="user_orders_data_table mt-md-4">
        <div class="user_orders_table user_orders_tables mx-auto w-100">
          <table>
            <tr>
              <td>Images</td>
              <td class="px-2">Order Details</td>
              <td class="px-2">Ordered Quantity</td>
              <td class="px-2 responsive_none">Sub Total</td>
              <td class="px-md-2 pe-2">Total</td>
              <td class="px-md-2 pe-2">Action</td>
            </tr>
            <tr class="data_tr" v-for="item in orderList" :key="item.id">
              <td>
                <div class="order_images">
                  <div class="row mx-auto px-1 py-3">
                    <div v-if="item?.products[0]?.imageUrl" class="col-6 p-0">
                      <img :src="item?.products[0]?.imageUrl" class="img-fluid" />
                    </div>
                    <div v-if="item?.products[1]?.imageUrl" class="col-6 p-0">
                      <img :src="item?.products[1]?.imageUrl" class="img-fluid" />
                    </div>
                    <div v-if="item?.products[2]?.imageUrl" class="col-6 p-0">
                      <img :src="item?.products[2]?.imageUrl" class="img-fluid" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="order_details p-md-2 ps-1">
                  <div class="order_id">
                    <span>
                      <router-link :to="`/user/order-details/${item.id}`">{{item.orderNum}}</router-link>
                    </span>
                  </div>
                  <div class="order_date">
                    <span class="dot_green me-1"></span><span>{{item.orderDate}}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="order_by p-2">
                  <div class="order_by_name">
                    <span>{{item.qty}}</span>
                  </div>
                </div>
              </td>
              <td class="responsive_none">
                <div class="delivery_details p-2">
                  <div class="delivery_contact">
                    <span>{{common.formatNumber(item.subTotal)}}</span>
                  </div>
                </div>
              </td>
              <td class="responsive_none">
                <div class="delivery_details p-2">
                  <div class="delivery_contact">
                    <span>{{common.formatNumber(item.total)}}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="price_detail p-md-2">
                  <div>
                    <a href="#"><img src="../../../assets/image/1.svg" class="img-fluid" style="width: 8%" /><span>
                        Invoice</span></a>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="!(orderList.length>0)">
              <td colspan="6" style="text-align:center;">Orders not found</td>
            </tr>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import "./css/orders.css";
import Api from "@/apis/Orders";
import { Base64 } from "js-base64";
import common from "@/apis/Common";
export default {
  name: "user-orders",
  data() {
    return {
      // baseURL:'https://b2b-staging.lab2100.com/api/',
      orderList: [],
      common: common,
    };
  },
  created() {
    window.scrollTo(0,0);
    document.title = this.$route.meta.title;
    this.getOrdersList();
  },
  computed: {
    displayAddress() {
      return (address) => {
        let displayAddress = "";
        if (address) {
          if (address.shippingAddress)
            displayAddress = address.shippingFirstName + " " + address.shippingLastName;
          if (address.shippingAddress)
            displayAddress += "<br>" + address.shippingAddress;
          if (address.shippingAddressTwo)
            displayAddress += "<br>" + address.shippingAddressTwo;
          if (address.shippingCity) displayAddress += "<br>" + address.shippingCity;
          if (address.shippingState) displayAddress += ", " + address.shippingState;
            if (address.shippingCountryName) displayAddress += ", " + address.shippingCountryName;
          if (address.shippingZipCode) displayAddress += "<br>" + address.shippingZipCode;
        }
        return displayAddress;
      };
    },
  },
  methods: {

    async getOrdersList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getOrdersList({ userId: userId })
        .then((res) => {
          if (res.data.success) {
            this.orderList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
      formatDate(value){
          if (value) {
              return value.split(" ")[0];
              //return moment(String(value)).format('DD/MM/YYYY')
          }
      },
  },
  setup() { },
};
</script>