<template>
  <div class="col-lg-9">
  <div class="alert alert-success" v-if="success">{{ success }}</div>
    <h2 class="ac_pg_head">Edit Address</h2>
    <h2 class="ac_head_bar">Contact Information</h2>
    <form v-on:submit.prevent="updateAddress">
      <div class="ac_det_cnt">
        <div class="row">
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>FIRST NAME*</label>
              <input
                type="text"
                v-model="addressObj.firstName"
                v-on="
                  addressObj.firstName
                    ? { change: v$.addressObj.firstName.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.addressObj.firstName.$error"
              class="error_msg_log error_msg"
            >
              <span>{{ v$.addressObj.firstName.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>LAST NAME*</label>
              <input
                type="text"
                v-model="addressObj.lastName"
                v-on="
                  addressObj.lastName
                    ? { change: v$.addressObj.lastName.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.addressObj.lastName.$error"
              class="error_msg_log error_msg"
            >
              <span>{{ v$.addressObj.lastName.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>EMAIL ID*</label>
              <input
                type="text"
                v-model="addressObj.email"
                v-on="
                  addressObj.email
                    ? { change: v$.addressObj.email.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.addressObj.email.$error"
              class="error_msg_log error_msg"
            >
              <span>{{ v$.addressObj.email.$errors[0].$message }}</span>
            </span>
          </div>
<!--          <div class="col-md-6 margin-bottom20">-->
<!--            <div class="input_field">-->
<!--              <label>MOBILE NUMBER*</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                v-model="addressObj.mobile"-->
<!--                v-on="-->
<!--                  addressObj.mobile-->
<!--                    ? { change: v$.addressObj.mobile.$validate }-->
<!--                    : {}-->
<!--                "-->
<!--              />-->
<!--            </div>-->
<!--            <span-->
<!--              v-if="v$.addressObj.mobile.$error"-->
<!--              class="error_msg_log error_msg"-->
<!--            >-->
<!--              <span>{{ v$.addressObj.mobile.$errors[0].$message }}</span>-->
<!--            </span>-->
<!--          </div>-->
          <div class="col-md-6">
            <div class="ipt_group">
              <div class="dd_default ipt_fld">
                <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE*</label>
                  <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"
                                                           data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> +{{(addressObj.phoneCode)?addressObj.phoneCode:'00'}}</button>
                    <ul class="dropdown-menu" style="">
                      <li v-for="country in code" :key="country.phoneCode">
                        <a @click="changePhoneCodeMode(country)" class="dropdown-item"><img :src="country.countryImage"> +{{country.phoneCode}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="input_field">
                <label>MOBILE NUMBER*</label>
                <input type="text" v-model="addressObj.mobile"
                       v-on="addressObj.mobile ? { change: v$.addressObj.mobile.$validate }: {}"/>
              </div>

            </div>
            <span
                    v-if="v$.addressObj.mobile.$error"
                    class="error_msg_log error_msg"
            >
              <span v-if="addressObj.mobile == ''">Mobile Number is required</span>
              <span v-else>{{ v$.addressObj.mobile.$errors[0].$message }}</span>
            </span>
            <span v-if="v$.addressObj.phoneCode.$error" class="error_msg_log error_msg">
              <span v-if="addressObj.phoneCode == null">Phone Code is required</span>
              <span v-else>{{ v$.addressObj.phoneCode.$errors[0].$message }}</span>
            </span>
          </div>
        </div>
      </div>
      <h2 class="ac_head_bar">Address</h2>
      <div class="ac_det_cnt">
        <div class="row">
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>STREET ADDRESS LINE 1*</label>
              <input
                type="text"
                v-model="addressObj.streetAddressOne"
                v-on="
                  addressObj.streetAddressOne
                    ? { change: v$.addressObj.streetAddressOne.$validate }
                    : {}
                "
              />
            </div>
            <span
              v-if="v$.addressObj.streetAddressOne.$error"
              class="error_msg_log error_msg"
            >
              <span>{{
                v$.addressObj.streetAddressOne.$errors[0].$message
              }}</span>
            </span>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>STREET ADDRESS LINE 2</label>
              <input type="text" v-model="addressObj.streetAddressTwo" />
            </div>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>CITY*</label>
              <input
                type="text"
                v-model="addressObj.city"
                v-on="
                  addressObj.city ? { change: v$.addressObj.city.$validate } : {}
                "
              />
            </div>
            <span
              v-if="v$.addressObj.city.$error"
              class="error_msg_log error_msg"
            >
              <span>{{ v$.addressObj.city.$errors[0].$message }}</span>
            </span>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>STATE/ PROVINCE</label>
              <input type="text" v-model="addressObj.state" />
            </div>
          </div>
          <div class="col-md-6 margin-bottom20">
            <div class="input_field">
              <label>ZIP / POSTALCODE</label>
              <input type="text" v-model="addressObj.zipCode" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="dd_default ipt_fld">
            <!--<div class="input_field">
              <label>COUNTRY*</label>
              <select
                v-model="addressObj.countryId"
                v-on="
                  addressObj.countryId
                    ? { keyup: v$.addressObj.countryId.$validate }
                    : {}
                "
              >
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                  :text="country.name"
                ></option>
              </select>
            </div>-->
            <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">
                <label>COUNTRY*</label>
<!--                <div class="dropdown dd_default">-->
<!--                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"-->
<!--                  aria-expanded="false">{{(addressObj.countryId)?countryName:'Select Country'}}</button>-->
<!--                  <ul class="dropdown-menu">-->
<!--                    <li v-for="country in countries" :key="country.id">-->
<!--                      <a @click="changeMode(country)" class="dropdown-item">{{country.name}}</a>-->
<!--                    </li>-->

<!--                  </ul>-->
<!--                </div>-->

              <select v-model="addressObj.countryId" class="bg-white">
                <option v-for="country in countries" :key="country.id" :value="country.id" :text="country.name">
                </option>
              </select>

              </div>
            <span
              v-if="v$.addressObj.countryId.$error"
              class="error_msg_log error_msg"
            >
              <span>{{ v$.addressObj.countryId.$errors[0].$message }}</span>
            </span>
          </div>
          </div>
          <div class="business_type_section mb-md-3 mb-4">
            <h2 class="ac_pg_head">Address Type</h2>
            <div>
              <input
                name="select"
                @change="onChange($event)"
                value="1"
                :checked="addressObj.addressType == 1"
                id="b_type_freelance"
                type="radio"
              /><label class="ms-2" for="b_type_freelance"
                >Shipping Address</label
              >
              <input
                name="select"
                @change="onChange($event)"
                value="2"
                id="b_type_business"
                class="ms-4"
                :checked="addressObj.addressType == 2"
                type="radio"
              /><label class="ms-2" for="b_type_business"
                >Billing Address</label
              >
            </div>
          </div>
          <div class="business_type_section mb-md-3 mb-4">
            <div>
              <input
                name="isDefault"
                @change="onChangeDefault"
                :value="addressObj.isDefault"
                :checked="addressObj.isDefault == 1"
                id="b_type_freelance"
                type="checkbox"
              /><label class="ms-2" for="b_type_freelance"
                >Make this as default address</label
              >
            </div>
          </div>
          <div class="ad_btns_cnt">
            <button class="btn_outline" v-on:click.prevent="cancel()">
              CANCEL
            </button>
            <button
              :disabled="isLoader"
              :class="{ 'disable-btn': isLoader }"
              class="btn_default"
            >
              <div v-if="isLoader">
                <img width="30" src="../../../assets/image/loader.gif" />
              </div>
              <span  v-if="!isLoader">UPDATE</span>
            </button>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import router from "@/routes/routes";
import "./css/add_address.css";
import Master from "@/apis/Master";
import useVuelidate from "@vuelidate/core";
import Api from "@/apis/AddressBook";
import { Base64 } from "js-base64";

import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
} from "@vuelidate/validators";

export default {
  name: "edit-address",
  props: ["userData"],
  data() {
    return {
      addressObj: {
        id: "",
        userId: "",
        addressType: 1,
        mobile: "",
        firstName: "",
        lastName: "",
        email: "",
        state: "",
        city: "",
        streetAddressOne: "",
        streetAddressTwo: "",
        zipCode: "",
        countryId: "",
        phoneCode: "",
        isDefault: 0,
      },
      selected: "",
      countries: [],
      code: [],
      isLoader: false,
      success: "",
      countryName: '',
    };
  },
  methods: {
    onChange(event) {
      this.addressObj.addressType = event.target.value;
    },
    async changePhoneCodeMode(data){
      this.phoneCode = data.phoneCode;
      this.countryImage = data.countryImage;
      this.addressObj.phoneCode = data.phoneCode;
    },
    async changeMode(mode){
      this.countryName = mode.name;
      this.addressObj.countryId = mode.id;
    },
    onChangeDefault() {
      if(this.addressObj.isDefault==1){
        this.addressObj.isDefault = 0;
      }else{
        this.addressObj.isDefault = 1;
      }
    },
    async cancel() {
      router.push("/user/address-book");
    },
    async updateAddress() {
      let userId = localStorage.getItem("id");
      this.addressObj.userId = Base64.decode(userId);
      delete this.addressObj.default;
      delete this.addressObj.defaultApproved;
      delete this.addressObj.countryName;

      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if no fail validation
        this.isLoader = true;
        Api.updateAddress(this.addressObj)
          .then((res) => {
            console.log(res);
            this.isLoader = false;
            this.success = res.data.message;
            setTimeout(() => {
              this.$router.push({ name: "AddressBook" });
            }, 3000);
          })
          .catch((err) => {
            this.isLoader = false;
            console.log(err);
          });
        console.log("Form successfully submitted.");
      } else {
        console.log("Form failed validation");
      }
    },
    getCountries: function () {
      Master.country().then(
        function (response) {
          this.countries = response.data.data;
        }.bind(this)
      );
    },
    getPhoneCode: function () {
      Master.phoneCode().then(
              function (response) {
                this.code = response.data.data;
              }.bind(this)
      );
    },
  },
  async mounted() {
    this.addressObj = JSON.parse(localStorage.getItem("address"));
    console.log("this.userDatathis.userData", this.userData);
    this.addressObj.isDefault = JSON.parse(localStorage.getItem("address")).default;
    this.countryName = this.addressObj.countryName;
    var x = this.addressObj.mobile.split(' ');
    this.addressObj.mobile = x[1]
    // this.phoneCode = '+'+x[0]
    this.addressObj.phoneCode = x[0]

  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      addressObj: {
        firstName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsFirstNameRequirement: helpers.withMessage(
              () => `First name is invalid`,
              (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        lastName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          containsLastNameRequirement: helpers.withMessage(
              () => `Last name is invalid`,
              (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
        mobile: {
          required,
          containsFirstNameRequirement: helpers.withMessage(
            () => `Mobile is invalid`,
            (value) =>
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                value
              )
          ),
        },
        email: { required, email },
        addressType: { required },
        streetAddressOne: { required },
        countryId: { required },
        phoneCode: { required },
        city: {
          required,
          minLength: minLength(3),
          containsFirstNameRequirement: helpers.withMessage(
            () => `City is invalid`,
            (value) => /^[A-Za-z\s']+$/.test(value)
          ),
        },
      },

    };
  },
  created() {
    window.scrollTo(0,0);
    this.getCountries();
    this.getPhoneCode();
    document.title = this.$route.meta.title;
  },
};
</script>
