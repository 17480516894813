<template>
  <CartHeader :cartCountUpdate="cartCount" />
  <section class="products_sec">
    <div class="page-width">
      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->

      <div class="row row_sidebar">
        <!-- Start Shopping Cart -->
        <div class="col-lg-8 side_sticky2_cart" :class="(cartData?.cartItems?.length > 0)?'col-lg-8':'col-lg-12'">
          <div class="shpng_cart_ttl">
            <span>Shopping Cart</span>
            <span>{{ itemsCount }} Items</span>
          </div>

          <div class="product_cart_list desktop_ver_shopp">
            <div class="product-cart-ttl ">
              <div class="prdt-ttl-head-dtl">
                <figure></figure>
                <div class="prdt-ttl-txt">
                  <h5>Product Details</h5>
                </div>
              </div>

              <div class="prdt-qty-sec">
                <h5>Qty</h5>
              </div>

              <div class="prdt-prc-sec">
                <h5>Unit Price</h5>
              </div>

              <div class="prdt-prc-total-sec">
                <h5>Total</h5>
              </div>
            </div>
            <ul>
              <div v-if="!cartData?.cartItems?.length > 0" style="text-align: center">
                <div v-if="isLoader">
                  <img width="30" src="../../assets/image/loader.gif" />
                </div>
                <div v-else-if="!cartData?.cartItems?.length > 0">
                  <div style="padding:40px;">Cart is empty</div>
                  <div class="cntnu-shpng-btn">
                    <router-link to="/home">
                      <img class="img-fluid" src="../../assets/image/long-arrow-back.svg" />
                      <span>Continue Shopping</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- Products List -->
              <li v-for="item in cartData.cartItems" :key="item.id">

                <!-- Product Image and details -->
                <div class="prdt-ttl-head-dtl">
                  <figure>
                    <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`"> <img
                        :src="item?.images[0]?.imageUrl" :alt="item.title" /> </router-link>
                  </figure>
                  <div class="prdt-ttl-txt">
                    <div class="prdt-onr-name checkout_brand">{{item.brand}}</div>
                    <h4>
                      <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`">{{ item.title }}
                      </router-link>
                    </h4>
                    <h4>{{ item.sku }} &nbsp;</h4>
                    <h4>
                      <span v-if="item.stock > 0" class="text-success">In Stock</span>
                      <span v-if="item.stock <= 0 && item.preOrder == 0" style="color: red">Out of Stock</span>
                      <span v-if="item.preOrder == 1 && item.stock <= 0" style="color: red">Pre Order</span>
                    </h4>

                    <p v-if="item.preOrder == 1  && item.stock <= 0" style="font-size:10px; line-height: 1.4;margin-bottom: 5px">Product is backordered and will ship as soon as it is back in stock.</p>
                    <div class="add_a_icns">
                      <a href="#" class="edit_icn" v-if="!wishListBool[item.productId]  && this.projectDefault == null" data-bs-toggle="modal" data-bs-target="#wishlistModel" @click="ItemSetId(item.productId)">
                        <img src="../../assets/image/SVG/heart_icn.svg" class="img-fluid">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="edit_icn" v-else-if="!wishListBool[item.productId]" @click="addToWishList(item)">
                        <img src="../../assets/image/SVG/heart_icn.svg" class="img-fluid">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="edit_icn" v-if="wishListBool[item.productId]" @click="deleteWishlist(item)">
                        <img class="img-fluid" src="../../assets/image/SVG/heart-fill.svg">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="del_icn" v-on:click.prevent="deleteCart(item.id)">
                        <img src="../../assets/image/delete_icon.svg" class="img-fluid" />
                        <span> Delete</span>
                      </a>
                    </div>
                    <!-- <div class="add-remove-prdt-frm-cart">
                      <button v-on:click.prevent="deleteCart(item.id)">
                        Remove
                      </button>
                    </div> -->
                  </div>
                </div>
                <!-- Product Image and details -->

                <!-- Product QTY add and remove -->
                <div class="prdt-qty-sec">
<!--                  <div class="prdt-incrs-descr">-->
<!--                    <button v-on:click.prevent="cartUpdate(item, 0)">-->
<!--                      <i>-</i>-->
<!--                    </button>-->
<!--                   <span class="sp-input" v-on:change.prevent="cartInputUpdate(item,1)">-->
<!--                     <input type="text" class="quntity-input" v-bind:value="inputValue[item.id] = qtyArr[item.id]" max="100"/>-->
<!--                   </span>-->
<!--&lt;!&ndash;                    <span>{{qtyArr[item.id]}}</span>&ndash;&gt;-->
<!--                    <button v-on:click.prevent="cartUpdate(item, 1)">-->
<!--                      <i>+</i>-->
<!--                    </button>-->
<!--                  </div>-->


                  <div class="prod_qty prdt-incrs-descr">
                    <div class="sp-quantity">
                      <div class="sp-minus btn_qty" v-on:click.prevent="cartUpdate(item, 0)">
                        -
                      </div>
                      <div class="sp-input" v-on:change.prevent="cartInputUpdate(item,qtyArr[item.id])">
                        <input type="text" class="quntity-input" v-model="qtyArr[item.id]" maxlength="4"/>
                      </div>
                      <div class="sp-plus btn_qty" v-on:click.prevent="cartUpdate(item, 1)">
                        +
                      </div>
                    </div>
                  </div>
                  <div v-if="stockMsgBool && item.preOrder == 0  && this.qtyArr[item.id] == item.stock && item.id == this.cartId"  class="add_a_icns" style="color:red;">
                    {{stockMsg}}
                  </div>

                </div>
                <!-- Product QTY add and remove -->

                <!-- Each Product Price -->
                <div class="prdt-prc-sec">
                  <strong>{{ common.formatNumber(item.price) }}</strong>
                  <div v-if="item.originalPrice != item.price">
                    <del class="dis_price cart_dis_price"> {{ common.formatNumber(item.originalPrice) }} </del>
                  </div>
                 
                </div>
                <!-- Each Product Price -->

                <!-- Total Products Price -->
                <div class="prdt-prc-total-sec">
                  <strong>{{ common.formatNumber(item.total) }}</strong>
                </div>
                <!-- Total Products Price -->
              </li>
              <!-- Products List -->
            </ul>
          </div>
          <div class="mobile_ver_shopincart">
            <div class="mail_shop_block" v-for="item in cartData.cartItems" :key="item.id">
            <div v-if="!cartData?.cartItems?.length > 0" style="text-align: center">
                <div v-if="isLoader">
                  <img width="30" src="../../assets/image/loader.gif" />
                </div>
                <div v-else-if="!cartData?.cartItems?.length > 0">
                  <div style="padding:40px;">Cart is empty</div>
                  <div class="cntnu-shpng-btn">
                    <router-link to="/home">
                      <img class="img-fluid" src="../../assets/image/long-arrow-back.svg" />
                      <span>Continue Shopping</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="shoping_left">
                <figure>
                    <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`"> <img
                        :src="item?.images[0]?.imageUrl" :alt="item.title" /> </router-link>
                  </figure>
                  <div class="prod_qty prdt-incrs-descr">
                    <div class="sp-quantity">
                      <div class="sp-minus btn_qty" v-on:click.prevent="cartUpdate(item, 0)">
                        -
                      </div>
                      <div class="sp-input" v-on:change.prevent="cartInputUpdate(item,qtyArr[item.id])">
                        <input type="text" class="quntity-input" v-model="qtyArr[item.id]" maxlength="4"/>
                      </div>
                      <div class="sp-plus btn_qty" v-on:click.prevent="cartUpdate(item, 1)">
                        +
                      </div>
                    </div>
                  </div>
              </div>
              <div class="shoping_right">
                <div class="prdt-ttl-txt_shoping">
                  <div class="prdt-onr-name checkout_brand">{{item.brand}}</div>
                    <h4>
                      <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`">{{ item.title }}
                      </router-link>
                    </h4>
                    <h4>{{ item.sku }} &nbsp;</h4>
                    <h4>
                      <span v-if="item.stock > 0" class="text-success">In Stock</span>
                      <span v-if="item.stock <= 0 && item.preOrder == 0" style="color: red">Out of Stock</span>
                      <span v-if="item.preOrder == 1 && item.stock <= 0" style="color: red">Pre Order</span>
                    </h4>
                   
                <div class="prdt-prc-sec">
                    <span class="unit_shop">Unit Price</span><strong>{{ common.formatNumber(item.price) }}</strong>
                     <div v-if="item.originalPrice != item.price" class="discont_shp">
                     <del class="dis_price cart_dis_price"> {{ common.formatNumber(item.originalPrice) }} </del>
                  </div>
                 
                </div>
                <!-- Each Product Price -->

                <!-- Total Products Price -->
                <div class="prdt-prc-total-sec">
                  <span class="unit_shop">Total Price</span> <strong>{{ common.formatNumber(item.total) }}</strong>
                </div>
                    <p v-if="item.preOrder == 1 && item.stock <= 0" style="font-size:10px; line-height: 1.4;margin-bottom: 5px">Product is backordered and will ship as soon as it is back in stock.</p>
                    <div class="add_a_icns shipping_icons">
                      <a href="#" class="edit_icn" v-if="!wishListBool[item.productId]  && this.projectDefault == null" data-bs-toggle="modal" data-bs-target="#wishlistModel" @click="ItemSetId(item.productId)" >
                        <img src="../../assets/image/SVG/heart_icn.svg" class="img-fluid">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="edit_icn" v-else-if="!wishListBool[item.productId]" @click="addToWishList(item)">
                        <img src="../../assets/image/SVG/heart_icn.svg" class="img-fluid">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="edit_icn" v-if="wishListBool[item.productId]" @click="deleteWishlist(item)">
                        <img class="img-fluid" src="../../assets/image/SVG/heart-fill.svg">
                        <span> Wishlist</span>
                      </a>
                      <a href="#" class="del_icn" v-on:click.prevent="deleteCart(item.id)">
                        <img src="../../assets/image/delete_icon.svg" class="img-fluid" />
                        <span> Delete</span>
                      </a>
                    </div>
                    <div class="prdt-qty-sec">

       
                  <div v-if="stockMsgBool && item.preOrder == 0  && this.qtyArr[item.id] == item.stock  && item.id == this.cartId" class="add_a_icns" style="color:red;">
                    {{stockMsg}}
                  </div>

                </div>
                <!-- Product QTY add and remove -->

                <!-- Each Product Price -->
         
                  </div>
              </div>
            </div>
          </div>

      

        </div>
        <!-- End Shopping Cart -->


        <!-- Start Coupan Code -->
        <div v-if="cartData?.cartItems?.length > 0" class="col-lg-4 side_sticky_cart">
          <div class="order-summery-sec">
            <h4>Cart summary</h4>
            <div class="ordr-prc-tx-dtls">
              <p>
                <span>Items ({{ itemsCount }})</span>
                <span>{{ common.formatNumber(cartData.cartTotal) }}</span>
              </p>

<!--              <p>-->
<!--                <span>Tax</span>-->
<!--                <span v-if="cartData.taxes">{{ common.formatNumber(cartData.taxes) }}</span>-->
<!--                <span v-else>&#45;&#45;N/A&#45;&#45;</span>-->
<!--              </p>-->
            </div>

<!--            <div class="promo-code-sec">-->
<!--              <p>Promo code</p>-->
<!--              <div class="promo-cupn-sec">-->
<!--                <input type="text" v-model="couponCode" placeholder="Enter Your Code" />-->
<!--                <span v-if="couponError" class="error_msg_log error_msg">{{-->
<!--                couponError-->
<!--                }}</span>-->
<!--                <button :disabled="couponCode == ''" v-on:click.prevent="applyCoupon()">-->
<!--                  Apply-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div v-if="cartData.couponDiscount" class="applied-promo-code-sec">-->
<!--              <div class="promo-apply-sec">-->
<!--                <span>promo code</span>-->
<!--                <strong>{{ cartData.couponCode }}</strong>-->
<!--                <button v-on:click.prevent="removeCoupon(cartData.couponCode)">-->
<!--                  Remove Promocode-->
<!--                </button>-->
<!--              </div>-->

<!--              <div class="promo-disc-prc">-->
<!--                <span>- {{ common.formatNumber(cartData.couponDiscount) }}</span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="ttl-cart-prc-sec cart_botm">
              <span>Total Cost</span>
              <span>{{ common.formatNumber(cartTotal) }}</span>
            </div>
            <div class="order-summery-sec saving_block" v-if="cartData.cartTotal != cartData.originalAmount">
            <p>Total Savings for this order <span class="price_sav">{{common.formatNumber(amountSave)}}</span></p>
          </div>

            <p class="shp_note">Note: Shipping fee not included</p>
<!--            <div class="ctn-shp-btn" v-if="checkOutofStock == this.cartData?.cartItems?.length">-->

            <div class="ctn-shp-btn" v-if="checkOutofStock > 0 || checkOutofStock1 > 0">
              <router-link to="/cart-checkout"><button class="reset_btn btn_default" disabled>Checkout </button></router-link>
              <p class="shp_note error_msg"><b>Note:</b> Please remove out-of-stock products or add them to wishlist to continue placing the order.</p>
            </div>
            <div class="ctn-shp-btn" v-else>
              <router-link to="/cart-checkout"><button>Checkout </button></router-link>
            </div>
          </div>
         
        </div>
        <!-- End Coupan Code -->

        <!-- Continue Shopping Btn -->
       
        <!-- Continue Shopping Btn -->
      </div>
      <div class="clearfix"></div>
        <div v-if="cartData?.cartItems?.length > 0" class="sidebar_fix">
          <div class="cntnu-shpng-btn">
            <router-link to="/home">
              <i>
                <img class="img-fluid" src="../../assets/image/long-arrow-back.svg" />
              </i>
              <span>Continue Shopping</span>
            </router-link>
          </div>
        </div>
      <div class="modal view-in-3d-modal fade" id="wishlistModel" tabindex="-1"
           aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button type="button" @click="closePopup" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closePop"></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">



                  <!--              <div>-->
                  <!--              <h2 class="ac_pg_head">Select Project to add wishlist</h2>-->
                  <!--              <form v-on:submit.prevent="addProjectWishlist">-->

                  <!--              <div class="dd_default ipt_fld">-->
                  <!--                <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">-->
                  <!--                  <div class="dropdown dd_default">-->
                  <!--                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"-->
                  <!--                            aria-expanded="false">{{(client.projectId)?projectClientName:'Select Project'}}</button>-->
                  <!--                    <ul class="dropdown-menu">-->
                  <!--                      <li v-for="project in projects" :key="project.id">-->
                  <!--                        <a @click="changeMode(project)" class="dropdown-item">{{project.projectClientName}}</a>-->
                  <!--                      </li>-->

                  <!--                    </ul>-->
                  <!--                  </div>-->

                  <!--                </div>-->
                  <!--                <span-->
                  <!--                        v-if="v$.client.projectId.$error"-->
                  <!--                        class="error_msg_log error_msg"-->
                  <!--                >-->
                  <!--              <span v-if="client.projectId == ''">Project is required</span>-->
                  <!--&lt;!&ndash;              <span v-else>{{ v$.client.projectId.$error }}</span>&ndash;&gt;-->
                  <!--            </span>-->
                  <!--              </div>-->
                  <!--              <div class="business_type_section mb-md-3 mb-4">-->
                  <!--                <div>-->
                  <!--                  <input-->
                  <!--                          name="isDefault"-->
                  <!--                          @change="onChangeDefault"-->
                  <!--                          :value="client.isDefault"-->
                  <!--                          :checked="client.isDefault == 1"-->
                  <!--                          id="b_type_freelance"-->
                  <!--                          type="checkbox"-->
                  <!--                  /><label class="ms-2" for="b_type_freelance" style="font-size: 12px"-->
                  <!--                >You want to make this project as default for other wishlist items</label-->
                  <!--                >-->
                  <!--                </div>-->
                  <!--              </div>-->

                  <!--              <div class="ad_btns_cnt">-->
                  <!--                <span class="btn_outline" @click="closePopup" data-bs-dismiss="modal">-->
                  <!--                  CLOSE-->
                  <!--                </span>-->
                  <!--                <button-->
                  <!--                        :disabled="isLoader"-->
                  <!--                        :class="{ 'disable-btn': isLoader }"-->
                  <!--                        class="btn_default"-->
                  <!--                >-->
                  <!--                  <div v-if="isLoader">-->
                  <!--                    <img width="30" src="../../../assets/image/loader.gif" />-->
                  <!--                  </div>-->
                  <!--                  <span  v-if="!isLoader"> Add </span>-->
                  <!--                </button>-->

                  <!--              </div>-->

                  <!--              </form>-->
                  <!--              </div>-->
                  <div v-if="this.projects.length == 0">
                    <span style="margin-left: 30px">Please set anyone project as default</span>
                    <br/> <br/>
                    <div class="ad_btns_cnt">
                      <button @click="addProject"
                              class="btn_default">
                        <span>Create New Project</span>
                      </button>

                    </div>

                  </div>
                  <div v-else-if="this.projects.length > 0">


                    <div class="modal-head heade_whish">
                      <h5>Choose Your project to add to WishList </h5>
                    </div>

                    <div class="project_popup modal-body">
                      <div class="whish_check product_filter_sec">

                        <div class="form-check" v-for="(pro,index) in projects" :key="index">
                          <label class="form-check-label">
                            {{pro.projectClientName}}
                            <input class="form-check-input" type="checkbox" v-model="this.projectIds" :value="pro.id">
                            <span class="checkmark"></span>
                          </label>
                        </div>




                      </div>

                    </div>
                    <!--                                <div class="whist_checbox" v-if="projectIds.length <= 1">-->
                    <!--                                    <div class="product_filter_sec">-->
                    <!--                                        <div class="form-check">-->
                    <!--                                            <label class="form-check-label text-none" for="flexCheck3"> MAKE THIS PROJECT AS DEFAULT FOR WISHLIST-->
                    <!--                                                <input class="form-check-input" type="checkbox" id="flexCheck3" name="isDefault"-->
                    <!--                                                       @change="onChangeDefault"-->
                    <!--                                                       :value="client.isDefault"-->
                    <!--                                                       :checked="client.isDefault == 1">-->
                    <!--                                                <span class="checkmark"></span>-->
                    <!--                                            </label>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <div class="ad_btns_cnt whist_btns">
                      <button class="btn_outline" @click="addProject">
                        <span> Create New Project</span>
                      </button>
                      <button
                          class="btn_default" @click="addToWishListModel">
                        <span> Add To Project</span>
                      </button>

                    </div>




                  </div>

                </div>

              </div>






            </div>

          </div>
        </div>
      </div>
        <div class="clearfix"></div>


    </div>
  </section>
  <CartFooter />
</template>
<script>
import "./css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/Cart";
import { Base64 } from "js-base64";
import common from "@/apis/Common";
import BreadCrumb from '../common/BreadCrumb';
import wishListApi from "@/apis/WishList";
import Api1 from "@/apis/Project";
import Project from "@/apis/Project";
export default {
  name: "cart-list",
  data() {
    return {
      cartData: [],
      qtyArr: [],
      couponCode: "",
      cartCount: 10,
      couponError: "",
      projectDefault: "",
      cartId: "",
      isLoader: true,
      common: common,
      wishListBool: [],
      projects: [],
      stockMsgBool: false,
      client: {
        isDefault: 0,
      },
      projectIds: [],
      stockMsg: 'You have reached the maximum available quantity.'
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    // this.wishListBool = this.$store.getters.getWishList;
    this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));
    document.title = this.$route.meta.title;
    this.getCartItems();
    this.getProject();
  },
  computed: {
    cartTotal: function () {
      let total = this.cartData.cartTotal;
      if (this.cartData.couponDiscount) {
        total = total - this.cartData.couponDiscount;
      }
      return total;
    },
    amountSave: function () {
      let cartTotal = this.cartData.cartTotal;
      let originalPrice = this.cartData.originalAmount;
      if (this.cartData.cartTotal) {
        cartTotal = originalPrice - cartTotal;
      }
      return cartTotal;
    },
    itemsCount: function () {
      let count = 0;
      if (this.cartData.cartItems) {
        count = this.cartData.cartItems.length;
      }
      return count;
    },
    checkOutofStock: function () {
      let objectSize = 0;
      for (var i =0; i < this.cartData.cartItems.length; i++)
      {
        if (this.cartData.cartItems[i].stock <= 0 && this.cartData.cartItems[i].preOrder == 0) {
          objectSize++;
        }
      }
      return objectSize;
    },
    checkOutofStock1: function () {
      let objectSize = 0;
      for (var i =0; i < this.cartData.cartItems.length; i++)
      {
        if (this.cartData.cartItems[i].qty  > this.cartData.cartItems[i].stock && this.cartData.cartItems[i].preOrder == 0) {
          objectSize++;
        }
      }
      return objectSize;
    },
  },
  methods: {

    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, '');
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    },
    getProject: function () {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api1.getProjectList({userId: userId})
          .then((res) => {
            this.projects = res.data.data;
            if(res.data.data.length == 0){
              localStorage.setItem("project","NO")
            }else {
              localStorage.setItem("project","YES")
            }

          })
          .catch((err) => {
            console.log(err);
          });
    },
    async getCartItems() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getCartItems({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            this.isLoader = false;
            this.cartData = res.data.data;
            this.cartData.cartItems.forEach((val) => {
              console.log("vallll", val);
              this.qtyArr[val.id] = val.qty;
              console.log("vallll", this.qtyArr);
            })
            // this.wishListBool = this.$store.getters.getWishListCart;
            // console.log(this.$store.getters.getWishListCart,"Swa")
          }
        })
        .catch((err) => {
          this.isLoader = false;
          console.log(err);
        });
    },
    addProject(){
      window.location.href = '/user/add-project'
      // this.$router.push(`/user/projects`)
    },
    async cartUpdate(item, qtyUpdate) {
      const {id,stock} = item;
      console.log("itemitem", item);
      let cartId = id
      this.cartId = id;
      if(qtyUpdate > 0){
          this.qtyArr[cartId] += 1
          if(item.preOrder == 0 && stock > 0 && this.qtyArr[cartId] > stock){
            this.stockMsgBool = true;
            this.qtyArr[cartId] -= 1
            return; 
          }
      }else if(this.qtyArr[cartId]>1){
         this.qtyArr[cartId] -= 1 
      }
      this.stockMsgBool = false;
      Api.cartUpdate({ id: cartId, update_type: qtyUpdate, qtyVal:this.qtyArr[cartId] })
        .then((res) => {
          if (res.data.success) {
            this.getCartItems();
            setTimeout(() => {
              this.showdelMsg = false;
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async cartInputUpdate(item, qtyUpdate) {
      // alert(qtyUpdate)
      const {id,stock} = item;
      console.log("itemitem", item);
      let cartId = id
      if(qtyUpdate > 0){
        if(item.preOrder == 0 && stock > 0 && qtyUpdate > stock){
          this.stockMsgBool = true;
          this.qtyArr[cartId] = stock
          Api.cartUpdate({ id: cartId, update_type: 1, qtyVal: this.qtyArr[cartId]})
                  .then((res) => {
                    if (res.data.success) {
                      this.getCartItems();
                      setTimeout(() => {
                        this.showdelMsg = false;
                      }, 2000);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          return;
        }
      }else if(qtyUpdate>1){
        this.qtyArr[cartId] = stock
        Api.cartUpdate({ id: cartId, update_type: 1, qtyVal: this.qtyArr[cartId]})
                .then((res) => {
                  if (res.data.success) {
                    this.getCartItems();
                    setTimeout(() => {
                      this.showdelMsg = false;
                    }, 2000);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
      }
      this.stockMsgBool = false;
      Api.cartUpdate({ id: cartId, update_type: 1, qtyVal: this.qtyArr[cartId]})
              .then((res) => {
                if (res.data.success) {
                  this.getCartItems();
                  setTimeout(() => {
                    this.showdelMsg = false;
                  }, 2000);
                }
              })
              .catch((err) => {
                console.log(err);
              });
    },
    async applyCoupon() {
      this.couponError = "";
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      Api.applyCoupon({ userId: userId, couponCode: this.couponCode })
        .then((res) => {
          if (res.data.success) {
            this.getCartItems();
            setTimeout(() => {
              this.showdelMsg = false;
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.couponError = err?.response?.data?.errors;
        });
    },
    async closePopup() {
      this.client.projectId = "";
      this.projectIds = [];
      this.v$.client.projectId.$reset();
      this.client.isDefault = 0;
    },
    async removeCoupon(couponCode) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      Api.removeCoupon({ userId: userId, couponCode: couponCode })
        .then((res) => {
          if (res.data.success) {
            this.getCartItems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteCart(cartId) {
      Api.deleteCart({ id: cartId })
        .then((res) => {
          if (res.data.success) {
            this.cartCount += 1;
            this.getCartItems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ItemSetId(data){
      localStorage.setItem("productId", data)

    },
    async addToWishList(data) {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { productId } = data;
      let payLoad = {
        userId: userId,
        productId: productId,
        clientProjectId: this.projectDefault.id
      };
      wishListApi.addWishlistProductDetails(payLoad) //{ userId: userId, trash: 0 }
      // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          console.log("Rolessss", res.data);
          if (res.data.success) {
            // this.$store.dispatch('addWishList', productId);
            // this.wishListBool[productId] = true;
            this.cartCount += 1;
            this.getCartItems();
          }
        })
        .catch((err) => {
          this.wishListBool[productId] = false;
          console.log(err);
        });
    },
    onChangeDefault() {
      if(this.client.isDefault==1){
        this.client.isDefault = 0;
      }else{
        this.client.isDefault = 1;
      }
    },
    addToWishListModel(){
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let productId = localStorage.getItem('productId');


      let payLoad = {
        userId: userId,
        productId: productId,
        isDefault: this.client.isDefault,
        clientProjectIds: this.projectIds,
        deleteCart:1
      };
      wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
          // wishListApi.addtoWishList(payLoad) //{ userId: userId, trash: 0 }
          .then((res) => {
            console.log("Rolex Swaraj Bhuyan", res.data);
            if (res.data.success) {
              this.getWishList()
              // this.$store.dispatch('addWishList', productId);
              // this.wishListBool[productId] = true;
              const elem = this.$refs.closePop;
              elem.click();
              // this.$store.dispatch('addWishList', productId);
              // this.wishListBool[productId] = true;
              this.cartCount += 1;
              this.getCartItems();
            }
          })
          .catch((err) => {
            this.wishListBool[productId] = false;
            console.log(err);
          });

      Project.defaultProjectDetails({ userId: userId  }).then((res) => {
        // console.log("Default_Project", res.data);
        if (res.data.success) {
          this.projectDefault =  res.data.data;
          localStorage.setItem("defaultProject",JSON.stringify(res.data.data))
          this.$store.dispatch('checkDefaultProject', this.projectDefault);
        }
      }).catch((err) => {
        console.log("abcd",err);
      });



    },
    async getWishList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      wishListApi.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
              .then((res) => {
                if (res.data.success) {
                  this.wishListData = res.data.data;

                  // let wishlist = [];
                  // this.wishListData.forEach((val)=>{
                  //   val.products.forEach((val1)=>{
                  //     wishlist[val1.productId] = true;
                  //   });
                  // });
                  // this.$store.dispatch('addTotalWishList', wishlist);
                  if (this.projectDefault != null) {
                    this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId == this.projectDefault.id).products
                    let wishlist = [];
                    this.wishlistDefaultData.forEach((val)=>{
                      // console.log("swaraj", val)
                      wishlist[val.productId] = true;
                      // val.products.forEach((val1)=>{
                      //
                      // });
                    });
                    this.$store.dispatch('addTotalWishList', wishlist);
                  }
                  // else {
                  //   this.wishlistDefaultData = []
                  //   let wishlist = [];
                  //   this.wishlistDefaultData.forEach((val)=>{
                  //     // console.log("swaraj", val)
                  //     wishlist[val.productId] = true;
                  //     // val.products.forEach((val1)=>{
                  //     //
                  //     // });
                  //   });
                  //   this.$store.dispatch('addTotalWishList', wishlist);
                  // }
                else {
                    let count = 0;
                    this.wishListData.forEach((val)=>{
                      count += val.products.length
                    });

                    this.$store.dispatch('addTotalWishListCount', count);
                    this.wishListCount = count;
                  }


                }
              })
              .catch((err) => {
                console.log(err);
              });
    },
    // async getWishList() {
    //   let userId = localStorage.getItem("id");
    //   userId = Base64.decode(userId);
    //   wishListApi.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
    //           .then((res) => {
    //             if (res.data.success) {
    //               this.wishListData = res.data.data;
    //               let wishlist = [];
    //               // this.wishListData.forEach((val)=>{
    //               //   wishlist[val.productId] = true;
    //               // });
    //               this.wishListData.forEach((val)=>{
    //                 val.products.forEach((val1)=>{
    //                   wishlist[val1.productId] = true;
    //                 });
    //               });
    //               this.$store.dispatch('addTotalWishList', wishlist);
    //             }
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    // },
    async deleteWishlist(item) {
      console.log("itemsss",item);
      const { productId } = item;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      let payLoad = {
        userId: userId,
        productId: productId,
        clientProjectId: this.projectDefault.id
      };
      //debugger; // eslint-disable-line no-debugger
      wishListApi.deleteWishlist(payLoad)
              .then((res) => {
                if (res.data.success) {
                  this.getWishList();
                  this.$store.dispatch('addWishList', productId);
                  this.wishListBool[productId] = false;

                }
              })
              .catch((err) => {
                this.wishListBool[productId] = false;
                console.log(err);
              });
    },
  },
  setup() { },
};
</script>
