<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Privacy policy Sec -->

      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->
      <div class="prvc-plc-cnt-sec container" v-html="cmsData.content">

      </div>

      <!-- Cookies policy Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import BreadCrumb from '@/components/common/BreadCrumb';
export default {
  name: "terms-and-conditions",
  data() {
    return {
      cmsData: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    document.title = this.$route.meta.title;
    this.getCMSData();
  },
  methods: {
    async getCMSData() {
      Api.getCMSData({ page: "terms" })
        .then((res) => {
          if (res.data.success) {
            this.cmsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
