import ApiToken from "./ApiToken";
export default {
    getPagesList(payLoad){
        return ApiToken.get("/rolePages", payLoad)
    },
    getPageData(payLoad){
        return ApiToken.post("/roleDetails", payLoad)
    },
    getRolesList(payLoad){
        return ApiToken.post("/roles", payLoad)
    },
    addRole(payLoad) {
        return ApiToken.post("/addRole", payLoad)
    },
    updateRole(payLoad) {
        return ApiToken.put("/updateRole", payLoad)
    },
    deleteRole(payLoad) {
        return ApiToken.post("/deleteRole", payLoad)
    }
}