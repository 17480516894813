<template>
  <div class="footer-login">
    <div class="footer_part container-fluid" id="footer_part">
      <div class="row py-3">
        <p class="col-lg-6 footer_first_link text-white">
          COPYRIGHTS @ 2022 <a href="" class="foot_p_tag">THATSLIVING</a>
        </p>
        <div class="col-lg-6 right_text">
          <p>
            <router-link to="/contact-us"><a class="foot_p_tag" href="#">contact us</a></router-link>
            <span class="px-2" style="color: white">|</span>
            <router-link to="/terms&conditions"><a class="foot_p_tag" href="#">TERMS AND CONDITIONS</a></router-link>
            <span class="px-2" style="color: white">|</span>
            <router-link to="/cookies&policy"><a class="foot_p_tag" href="#">COOKIE POLICY</a></router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footer-login",
  setup() { },
};
</script>
<style>
@import "../assets/css/style.css";

#footer_part .foot_p_tag {
  font-size: 12px !important;
}
</style>
