<template>
  <!--  Header Start -->
  <CartHeader />
  <!-- Header End -->

  <section class="contact_sec">
    <div class="page-width">
      <!-- Privacy policy Sec -->

      <!-- Breadcrumb Sec -->
      <BreadCrumb />
      <!-- Breadcrumb Sec -->
      <div class="reading-width faqs">

        <h3 class="faq text-center">FREQUENTLY ASKED QUESTIONS</h3>
        <div class="">

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_1" role="button" aria-expanded="false"
            aria-controls="clpse_1">
            WHAT SHOULD I DO TO ORDER AN ITEM I HAVE FOUND ONLINE?
          </button>
          <div class="panel collapse clpse_1">

            <p>If the items you want to buy are available, please add them to the cart and proceed with the transaction.
              You can also pre-order the items that are not available at the moment by raising a query. Our sales
              support will get in touch with you shortly and let you know when the product is expected to be back in
              stock.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_2" role="button" aria-expanded="false"
            aria-controls="clpse_2">WHERE IS MY ORDER?</button>
          <div class="panel collapse clpse_2">

            <p>Please note: tracking is only available once the order has been dispatched.
              You can check the status by logging into your account under the orders section.
            </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_3" role="button" aria-expanded="false"
            aria-controls="clpse_3">WHAT DO I DO IF I GET A DAMAGED/ DEFECTIVE OR INCORRECT PRODUCT?</button>
          <div class="panel collapse clpse_3">

            <p>
              We aim to undergo a thorough check on all the items that are ready to dispatch and make sure that your
              order reaches you safely. However, there can be unforeseen issues. We recommend you have a close look at
              the product(s) and bring any damage to our attention as soon as possible. We offer a 7-Day <a href="#"
                target="_blank" title="Return Policy">Return Policy</a> for all products if you opt for a return.
              <br /><br />
              Returns are accepted for the following reasons:
            <ul>
              <li>Damaged Product</li>
              <li>Manufacturing Defect</li>
              <li>Incomplete Product</li>
              <li>Incorrect Product</li>
            </ul>
            <br />
            Our customer support will deal with the issues and ensure maximum customer satisfaction.

            </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_4" role="button" aria-expanded="false"
            aria-controls="clpse_4">INSTEAD OF A REFUND, CAN YOU SEND ME AN EXCHANGE FOR THE PRODUCT?</button>
          <div class="panel collapse clpse_4">
            <p>Yes, customers have the right to exchange or return the purchased items within 7 days from the date of
              purchase.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_5" role="button" aria-expanded="false"
            aria-controls="clpse_5">DO I NEED TO KEEP ANYTHING IN MIND WHILE RETURNING YOUR PRODUCT?</button>
          <div class="panel collapse clpse_5">

            <p>
              The returned product should be in its original packaging and in the same condition as you received it.
              Please ensure that all tags, peripherals, warranty/ guarantee cards, and accessories are intact.
              <br /><br />

              The easy return policy at That's Living is absolutely hassle-free. Once we validate the refund, our
              in-house logistics team will pick up the order.
              <br /><br />

              Please check our <a href="#" target="_blank" title="Return Policy">return policy</a> to know more.
            </p>
          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_6" role="button" aria-expanded="false"
            aria-controls="clpse_6">HOW CAN I CANCEL MY ORDER?</button>
          <div class="panel collapse clpse_6">

            <p> You may choose to cancel one or more items in your order before it is shipped. From your Orders section,
              click on the Cancel button within 7 days from the order confirmation date.
              <br /><br />
              For more details, please refer to our <a href="#" target="_blank"
                title="Terms and Conditions">cancellation policy</a>.
            </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_7" role="button" aria-expanded="false"
            aria-controls="clpse_7">HOW DO I CHECK STOCK AVAILABILITY?</button>
          <div class="panel collapse clpse_7">

            <p>Every effort is made to maintain the availability of products, but due to popularity, some items may not
              always be available. You can check stock availability on the product information pages.</p>
          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_8" role="button" aria-expanded="false"
            aria-controls="clpse_8">DO YOU ASSIST IN DESIGN CONSULTATION?</button>
          <div class="panel collapse clpse_8">

            <p>Yes, we offer product design and interior design consultation with some advance token amount which the
              customer can redeem as a store credit in TL stores. From the right product to the right finish, we are
              here to guide you well.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_9" role="button" aria-expanded="false"
            aria-controls="clpse_9">WHAT SHOULD I DO IF I SEE THE PIECE I LIKE IS OUT OF STOCK?</button>
          <div class="panel collapse clpse_9">

            <p>We are sorry for the inconvenience. We offer limited edition products, and they may not get restocked.
              Please reach out to our customer support team to help you with the best possible choices.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_10" role="button" aria-expanded="false"
            aria-controls="clpse_10">WHAT ARE MY PAYMENT OPTIONS?</button>
          <div class="panel collapse clpse_10">

            <p>After placing your order, our customer support team will reach you and assist you with a suitable payment
              option online and offline. Also, they will help you with the delivery and installation of the products.
            </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_11" role="button" aria-expanded="false"
            aria-controls="clpse_11">CAN WE NEGOTIATE PRICES?</button>
          <div class="panel collapse clpse_11">

            <p>Our prices are non-negotiable since most of our products are unique and require a lot of time and effort
              in&nbsp;the&nbsp;making.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_12" role="button" aria-expanded="false"
            aria-controls="clpse_12">DO YOU OFFER GUARANTEE ON YOUR PRODUCTS?</button>
          <div class="panel collapse clpse_12">

            <p>Yes, we offer a guarantee on products. We are loyal to our customers and promise them that quality always
              comes first. </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_13" role="button" aria-expanded="false"
            aria-controls="clpse_13">ARE ALL OF YOUR ITEMS AVAILABLE FOR SHIPPING?</button>
          <div class="panel collapse clpse_13">

            <p>Yes, all items are available for shipping. For the shipping costs in Qatar and Saudi Arabia, refer to our
              <a href="#" target="_blank" title="Terms and Conditions">Terms &amp;
                Conditions</a>. Deliveries other than these areas are based on location and order size.
            </p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_14" role="button" aria-expanded="false"
            aria-controls="clpse_14">ARE SOME ITEMS EXPENSIVE TO SHIP?</button>
          <div class="panel collapse clpse_14">

            <p>Yes, the cost of shipping is ascertained by several factors, including taxes, packaging, border fees,
              insurance, fuel, and more. Therefore, the shipping cost of some items becomes expensive.</p>

          </div>

          <button class="accordion" data-bs-toggle="collapse" href=".clpse_15" role="button" aria-expanded="false"
            aria-controls="clpse_15">IS CONTACTLESS DELIVERY AVAILABLE?</button>
          <div class="panel collapse clpse_15">

            <p>Yes, we provide contactless delivery.</p>

          </div>


        </div>
      </div>

      <!-- Cookies policy Sec -->
    </div>
  </section>
  <CartFooter />
</template>
<script>
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/HomePage";
import BreadCrumb from '@/components/common/BreadCrumb';
export default {
  name: "b2b-Faqs",
  data() {
    return {
      cmsData: [],
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    document.title = this.$route.meta.title;
    this.getCMSData();
  },
  methods: {
    async getCMSData() {
      Api.getCMSData({ page: "terms" })
        .then((res) => {
          if (res.data.success) {
            this.cmsData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
