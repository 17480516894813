<template>
  <div class="col-lg-9">
  <div class="alert alert-success" v-if="successMsg">{{ successMsg }}</div>
    <div>
      <div class="alert alert-success" v-if="showdelMsg" id="error_msg">
        {{ delMsg }}
      </div>
      <div class="d-flex">
        <h2 class="ac_pg_head">Change defualt Address ({{(addressType==1)?'Shipping':'Billing'}}) </h2>
      </div>

      <div class="ac_det_cnt table-responsive">
        <!-- Added Address List Section -->
            <div class="added-adrs-list-sec">
              <div class="row">
                <div class="col-sm-6" v-for="item in data" :key="item.id">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="groupItem" id="groupItem"
                      @click="changeAdd(item)" />
                    <label class="form-check-label" :for="item.id">
                      <address v-html="displayAddress(item)"></address>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="ad_btns_cnt">
            <button class="btn_outline" v-on:click.prevent="cancel()">
              CANCEL
            </button>
            <button
              :disabled="isLoader"
              :class="{ 'disable-btn': isLoader }"
              class="btn_default"
              @click="changeDefault"
            >
             <div v-if="isLoader">
              <img width="30" src="../../../assets/image/loader.gif" />
            </div>
             <span  v-if="!isLoader"> Update </span>
            </button>
            
          </div>
            <!-- Added Address List Section -->
      </div>
    </div>
  </div>
</template>
<script>
import "./css/user_address.css";
import Api from "@/apis/AddressBook";
import { Base64 } from "js-base64";
import router from "@/routes/routes";
export default {
  name: "User-address",
  data() {
    return {
      data: [],
      successMsg: "",
      showdelMsg: false,
      isLoader: false,
      addressType: '',
      addressID: '',
    };
  },
  created() {
    window.scrollTo(0,0);
    this.getAddressList();
  },
  computed: {
    displayAddress() {
      return (address) => {
        let displayAddress = "";
        if (address) {
          if (address.streetAddressOne)
            displayAddress = address.firstName + " " + address.lastName;
          if (address.streetAddressOne)
            displayAddress += "<br>" + address.streetAddressOne;
          if (address.streetAddressTwo)
            displayAddress += "<br>" + address.streetAddressTwo;
          if (address.city) displayAddress += "<br>" + address.city;
          if (address.state) displayAddress += "<br>" + address.state;
          if (address.countryName)
            displayAddress += "<br>" + address.countryName;
          if (address.poBox) displayAddress += "<br>" + address.poBox;
        }
        return displayAddress;
      };
    },
  },
  methods: {
    async cancel() {
       router.push("/user/address-book");
    },
    async getAddressList() {
      this.addressType = this.$route.params.id; //1-Shipping, 2 Billing
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getAddressList({ userId: userId, trash: 0 })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
            this.data = this.data.filter((val) => val.addressType == this.addressType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async changeDefault() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      if(!this.addressID){
        alert("Please select address");
        return
      }
      this.isLoader = true;
      Api.defualtAddress({ userId: userId, id: this.addressID })
        .then((res) => {
          if (res.data.success) {
            this.isLoader = false;
            this.successMsg = res.data.message;
            setTimeout(() => {
             this.$router.push({ name: "AddressBook" });
            }, 3000);
          }
        })
        .catch((err) => {
            this.isLoader = false;
            console.log(err);
        });
    },
    async changeAdd(item) {
      this.addressID = item.id;
    },
  },
  setup() {},
};
</script>
