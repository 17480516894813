<template>
    <div class="col-lg-9">
        <div class="alert alert-success" v-if="success">{{ success }}</div>
        <h2 class="ac_pg_head">Edit Project</h2>
        <h2 class="ac_head_bar">Project Information</h2>
        <form v-on:submit.prevent="updateAddress">
            <div class="ac_det_cnt">
                <div class="row">
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>PROJECT NAME*</label>
                            <input
                                    type="text"
                                    v-model="addressObj.projectName"
                                    v-on="
                  addressObj.projectName
                    ? { change: v$.addressObj.projectName.$validate }
                    : {}
                "
                            />
                        </div>
                        <span
                                v-if="v$.addressObj.projectName.$error"
                                class="error_msg_log error_msg"
                        >
              <span>{{ v$.addressObj.projectName.$errors[0].$message }}</span>
            </span>
                    </div>
                  <div class="col-md-6 margin-bottom20">
                    <div class="input_field mb-md-3 mb-3" data-bs-toggle="dropdown" aria-expanded="false">
                      <label>COUNTRY*</label>
                      <!--                                <div class="dropdown dd_default">-->
                      <!--                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"-->
                      <!--                                            aria-expanded="false">{{(addressObj.countryId)?countryName:'Select Country'}}</button>-->
                      <!--                                    <ul class="dropdown-menu">-->
                      <!--                                        <li v-for="country in countries" :key="country.id">-->
                      <!--                                            <a @click="changeMode(country)" class="dropdown-item">{{country.name}}</a>-->
                      <!--                                        </li>-->

                      <!--                                    </ul>-->
                      <!--                                </div>-->
                      <select v-model="addressObj.countryId" class="bg-white">
                        <!--                                    <option value="">SELECT COUNTRY</option>-->
                        <option v-for="country in countries" :key="country.id" :value="country.id" :text="country.name"></option>
                      </select>

                    </div>
                  </div>

                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>EMAIL ID</label>
                            <input
                                    type="text"
                                    v-model="addressObj.email"
                                    v-on="
                  addressObj.email
                    ? { change: v$.addressObj.email.$validate }
                    : {}
                "
                            />
                        </div>
                        <span
                                v-if="v$.addressObj.email.$error"
                                class="error_msg_log error_msg"
                        >
              <span>{{ v$.addressObj.email.$errors[0].$message }}</span>
            </span>
                    </div>
<!--                    <div class="col-md-6 margin-bottom20">-->
<!--                        <div class="input_field">-->
<!--                            <label>MOBILE NUMBER*</label>-->
<!--                            <input-->
<!--                                    type="text"-->
<!--                                    v-model="addressObj.mobile"-->
<!--                                    v-on="-->
<!--                  addressObj.mobile-->
<!--                    ? { change: v$.addressObj.mobile.$validate }-->
<!--                    : {}-->
<!--                "-->
<!--                            />-->
<!--                        </div>-->
<!--                        <span-->
<!--                                v-if="v$.addressObj.mobile.$error"-->
<!--                                class="error_msg_log error_msg"-->
<!--                        >-->
<!--              <span>{{ v$.addressObj.mobile.$errors[0].$message }}</span>-->
<!--            </span>-->
<!--                    </div>-->
                    <div class="col-md-6">
                        <div class="ipt_group">
                            <div class="dd_default ipt_fld">
                                <div class="input_field" data-bs-toggle="dropdown" aria-expanded="false"><label>CODE</label>
                                    <div class="dropdown dd_default"><button class="btn btn-secondary dropdown-toggle" type="button"
                                                                             data-bs-toggle="dropdown" aria-expanded="false"><img :src="countryImage"> +{{(addressObj.phoneCode)?addressObj.phoneCode:'00'}}</button>
                                        <ul class="dropdown-menu" style="">
                                            <li v-for="country in code" :key="country.phoneCode">
                                                <a @click="changePhoneCodeMode(country)" class="dropdown-item"><img :src="country.countryImage"> +{{country.phoneCode}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="input_field">
                                <label>MOBILE NUMBER</label>
                                <input type="text" v-model="addressObj.mobile"
                                       v-on="addressObj.mobile ? { change: v$.addressObj.mobile.$validate }: {}"/>
                            </div>

                        </div>
                        <span
                                v-if="v$.addressObj.mobile.$error"
                                class="error_msg_log error_msg"
                        >
              <span v-if="v$.addressObj.mobile.$errors[0].$message">MOBILE NUMBER IS INVALID</span>
            </span>

                    </div>
                </div>
            </div>
            <h2 class="ac_head_bar">Address</h2>
            <div class="ac_det_cnt">
                <div class="row">
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STREET ADDRESS LINE 1</label>
                            <input
                                    type="text"
                                    v-model="addressObj.streetAddressOne"
                            />
                        </div>

                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STREET ADDRESS LINE 2</label>
                            <input type="text" v-model="addressObj.streetAddressTwo" />
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>CITY</label>
                            <input
                                    type="text"
                                    v-model="addressObj.city" />
                        </div>

                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>STATE/ PROVINCE</label>
                            <input type="text" v-model="addressObj.state" />
                        </div>
                    </div>
                    <div class="col-md-6 margin-bottom20">
                        <div class="input_field">
                            <label>ZIP / POSTALCODE</label>
                            <input type="text" v-model="addressObj.zipCode" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="dd_default ipt_fld">
                            <!--<div class="input_field">
                              <label>COUNTRY*</label>
                              <select
                                v-model="addressObj.countryId"
                                v-on="
                                  addressObj.countryId
                                    ? { keyup: v$.addressObj.countryId.$validate }
                                    : {}
                                "
                              >
                                <option
                                  v-for="country in countries"
                                  :key="country.id"
                                  :value="country.id"
                                  :text="country.name"
                                ></option>
                              </select>
                            </div>-->


                        </div>
                    </div>
                    
                </div>
            </div>
                    <h2 class="ac_head_bar">Logo</h2>

                    <div class="ac_det_cnt">
                       
                            <div class="container mt-2 mb-2" v-if="clientLogo != ''">
                                <div class="row">
                                    <div class="col-md-4 image-logo">
                                        <img :src="clientLogo"  class="img-thumbnail upload_img"/>
                                        <a href="#" @click="logoDelete(addressObj.id)" class="upload_imglink">
                                            <img src="../../../assets/image/delete_icon_white.svg" class="delete-icon-image" > </a>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-12">
                                <div class="file_u_cnt">
                                    <input type="file" id="reg_upload" @change="onFileSelected($event)" hidden accept="image/*, .png, .jpg" />
                                    <span class="lable_sec" id="reg_file">{{ regFile }}</span>
                                    <label for="reg_upload" class="reg_u_btn">Choose File</label>
                                </div>
                                <p class="below_input_msg">
                                    Please upload a logo of the
                                    project. Upload PNG or JPG file.
                                </p>

                            </div>
                        
                    </div>

<!--                    <div class="business_type_section mb-md-3 mb-4">-->
<!--                        <div>-->
<!--                            <input-->
<!--                                    name="isDefault"-->
<!--                                    @change="onChangeDefault"-->
<!--                                    :value="addressObj.isDefault"-->
<!--                                    :checked="addressObj.isDefault == 1"-->
<!--                                    id="b_type_freelance"-->
<!--                                    type="checkbox"-->
<!--                            /><label class="ms-2" for="b_type_freelance"-->
<!--                        >Make this project as default for Wishlist</label>-->
<!--                        </div>-->
<!--                    </div>-->


                    <div class="ad_btns_cnt">
                        <button class="btn_outline" v-on:click.prevent="cancel()">
                            CANCEL
                        </button>
                        <button
                                :disabled="isLoader"
                                :class="{ 'disable-btn': isLoader }"
                                class="btn_default"
                        >
                            <div v-if="isLoader">
                                <img width="30" src="../../../assets/image/loader.gif" />
                            </div>
                           <span  v-if="!isLoader">UPDATE</span>
                        </button>

                    </div>


        </form>
    </div>
</template>
<script>
    import router from "@/routes/routes";
    import "../address-book/css/add_address.css";
    import Master from "@/apis/Master";
    import useVuelidate from "@vuelidate/core";
    import Api from "@/apis/Project";
    import { Base64 } from "js-base64";

    import {
        email,
        numeric,
        helpers,
        maxLength,
        minLength,
        required,
    } from "@vuelidate/validators";

    export default {
        name: "edit-address",
        props: ["userData"],
        data() {
            return {
                addressObj: {
                    id: "",
                    userId: "",
                    firstName: "",
                    mobile: "",
                    projectClientName: "",
                    projectName: "",
                    email: "",
                    state: "",
                    city: "",
                    streetAddressOne: "",
                    streetAddressTwo: "",
                    zipCode: "",
                    countryId: "",
                    phoneCode: "",
                    isDefault: 0,
                },
                files: {
                    file1: "",
                },
                selected: "",
                clientLogo: "",
                countries: [],
                projectDetails: [],
                code: [],
                isLoader: false,
                success: "",
                countryName: '',
                regFile: "No file chosen",
            };
        },
        methods: {
            async logoDelete(id){
                let userId = localStorage.getItem("id");
                let projectId = localStorage.getItem("projectId");
                let payLoad = {
                    projectId: id,
                    userId:  Base64.decode(userId)
                };
                Api.projectImageDelete(payLoad)
                    .then((res) => {
                        console.log("Rolessss", res.data);
                        userId = Base64.decode(userId);
                        Api.projectDetails({ userId: userId,projectId:projectId }).then((res) => {
                            if (res.data.success) {
                                this.addressObj = res.data.data;
                                this.clientLogo = "";
                                this.countryName = this.addressObj.countryName;
                                this.addressObj.projectName = this.addressObj.projectClientName;
                                var x = this.addressObj.mobile.split(' ');
                                this.addressObj.mobile = x[1];
                                this.addressObj.phoneCode = x[0];
                            }
                        }).catch((err) => {
                            console.log("abcd",err);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async changePhoneCodeMode(data){
                this.phoneCode = data.phoneCode;
                this.countryImage = data.countryImage;
                this.addressObj.phoneCode = data.phoneCode;
            },
            onChangeDefault() {
                if(this.addressObj.isDefault==1){
                    this.addressObj.isDefault = 0;
                }else{
                    this.addressObj.isDefault = 1;
                }
            },
            async changeMode(mode){
                this.countryName = mode.name;
                this.addressObj.countryId = mode.id;
            },
            async cancel() {
                router.push("/user/projects");
            },
            async updateAddress() {
                let userId = localStorage.getItem("id");
                this.addressObj.userId = Base64.decode(userId);
                delete this.addressObj.countryName;

                delete this.addressObj.clientLogo;
                delete this.addressObj.firstName;
                delete this.addressObj.projectClientName;

                this.v$.addressObj.projectName.$validate()// checks all inputs
                if (!this.v$.addressObj.projectName.$error) {
                    // if no fail validation
                    this.isLoader = true;
                    const fd = new FormData();
                    fd.append("image",  this.files.file1);
                    fd.append("data",JSON.stringify(this.addressObj));
                    Api.updateProjectClient(fd,
                        {
                            headers: {
                                'Content-Type' : 'image/jpg'
                            }
                        })
                        .then((res) => {
                            console.log(res);
                            this.isLoader = false;
                            this.success = res.data.message;
                            setTimeout(() => {
                                this.$router.push({ name: "ProjectClient" });
                            }, 3000);
                        })
                        .catch((err) => {
                            this.isLoader = false;
                            console.log(err);
                        });
                    console.log("Form successfully submitted.");
                } else {
                    console.log("Form failed validation");
                }
            },

            getCountries: function () {
                Master.country().then(
                    function (response) {
                        this.countries = response.data.data;
                    }.bind(this)
                );
            },
            phoneCodeData: function () {
                Master.phoneCode().then(
                    function (response) {
                        this.code = response.data.data;
                    }.bind(this)
                );
            },
            onFileSelected(event) {
                    this.regFile = event.target.files[0].name;
                    this.files.file1 = event.target.files[0];

            },
        },
        async mounted() {


            let userId = localStorage.getItem("id");
            let projectId = localStorage.getItem("projectId");
            userId = Base64.decode(userId);
            Api.projectDetails({ userId: userId,projectId:projectId }).then((res) => {
                if (res.data.success) {
                    this.addressObj = res.data.data;
                    this.clientLogo = res.data.data.clientLogo;
                    this.countryName = this.addressObj.countryName;
                    this.addressObj.projectName = this.addressObj.projectClientName;
                    var x = this.addressObj.mobile.split(' ');
                    this.addressObj.mobile = x[1];
                    this.addressObj.phoneCode = x[0];


                }
            }).catch((err) => {
                console.log("abcd",err);
            });



        },
        setup: () => ({ v$: useVuelidate() }),
        validations() {
            return {
                addressObj: {
                    projectName: {
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(100),
                        // containsFirstNameRequirement: helpers.withMessage(
                        //     () => `First name is invalid`,
                        //     (value) => /^[A-Za-z\s']+$/.test(value)
                        // ),
                    },
                    mobile: {
                        numeric
                        // containsFirstNameRequirement: helpers.withMessage(
                        //     () => `Mobile is invalid`,
                        //     (value) =>
                        //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
                        //             value
                        //         )
                        // ),
                    },
                    email: {  email },
                    streetAddressOne: { required },
                    countryId: { required },
                    phoneCode: { required },
                    city: {
                        required,
                        minLength: minLength(3),
                        containsFirstNameRequirement: helpers.withMessage(
                            () => `City is invalid`,
                            (value) => /^[A-Za-z\s']+$/.test(value)
                        ),
                    },
                },
            };
        },
        created() {
            window.scrollTo(0,0);
            this.getCountries();
            this.phoneCodeData();
            document.title = this.$route.meta.title;
        },
    };
</script>
<style>
    @import "../../../assets/css/company_details_business.css";
   
</style>
