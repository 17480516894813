<template>
  <CartHeader :cartCountUpdate="cartCount" />
  <!-- Header End -->
  <section class="products_sec whistlist_sec">
    <div class="page-width">
      <!-- Breadcrumb Sec -->
      <BreadCrumb />

      <!-- Breadcrumb Sec -->

      <div class="row">
        <div class="col-lg-12">
          <!-- Start Prducts List -->
          <div class="product_wish_list">
            <div class="page_ttl-sub">My Wishlist ({{this.wishListCount1}})</div>

            <!-- Product Wish List -->
            <div class="product_list_view">
              <div class="row" v-if="this.wishListCount1 != 0">
                <div class="row" v-for="(item) in wishListData" :key="item.id">
                  <div class="prdt-name desktop_whislits" v-if="item.products != ''"><span class="list_head_wish">{{ item.project_client_name }} ({{ item.count }})</span>
<!--                    <router-link :to="`/wishlist-project/${item.clientProjectId}`"><span-->
<!--                      class="deskt_wish">View all ({{ item.count }})-->
<!--                      <button value="ADD ALL TO CART" class="btn_default">ADD ALL TO CART</button>-->
<!--                    </span></router-link>-->
                  </div>
                  <div class="prdt-name mobile_whislits" v-if="item.products != ''">{{ item.project_client_name }} ({{ item.count }})
<!--                    <span class="view_wish"><router-link to="/wishlist-project">-->
<!--                    <span class="view_lis_wish">View-->
<!--                          all</span>-->
<!--                    ({{ item.count }})</router-link></span>-->
                  </div>
                  <div v-for="(product, index) in item.products"  :key="product.id" class="col-xl-3 col-md-4 col-6">
                    <div class="prod_l_cnt"  v-if="index < 4">
                      <div class="prod_d_itm">

                        <figure>
                          <router-link :to="`/product/${sanitizeTitle(product.title)}/${product.productId}`"><img
                              class="img-fluid" :src="product.image" alt="" /></router-link>
                        </figure>

                        <div class="prdt-onr-name">{{ product.brand }}</div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="prdt-name">{{ product.title }}</div>
                            <div class="prdt-price">

                              <!--                              {{ common.formatNumber(product.price) }}-->


                              <div class="prdt-price" v-if="this.priceBool == 0 || this.priceBool == null">


                                <div v-if="product.originalPrice == product.price">
                                  {{ common.formatNumber(product.price) }}
                                </div>
                                <div v-else>
                                  <div class="or_price">{{ common.formatNumber(product.price) }}</div>
                                  <del class="dis_price"> {{ common.formatNumber(product.originalPrice) }}</del>
                                  
                                </div>



                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="add-wishlist-icon wl_pg">
                        <button id="ad-remove-from-wish-list">
                          <i>
                            <img v-on:click.prevent="deleteWishlist(product, item.clientProjectId)" class="img-fluid"
                              src="../../assets/image/SVG/times.svg" />
                          </i>
                        </button>
                      </div>
                      <div class="ordr_btn_sec">
                        <button
                          :disabled="disableButton(loaderBool[product.productId], product.stock <= 0 && product.preOrder == 0)"
                          :class="{ 'disable-btn': !checkBool[product.productId] && loaderBool[product.productId] }"
                          v-on:click.prevent="addToCart(product)" class="b_w_ust btn_default">
                          <div v-if="!checkBool[product.productId] && loaderBool[product.productId]">
                            <img width="22" src="../../assets/image/loader.gif" />
                          </div>
                          <span v-else-if="checkBool[product.productId]"><img
                              src="../../assets/image/check-mark-white.svg" alt="" />
                          </span>
                          <span v-if="!checkBool[product.productId] && !loaderBool[product.productId]">
                            {{(product?.stock <= 0) ? (product.preOrder == 1) ? 'Pre Order' : 'Out of Stock'
                            : 'Add To Cart' }} </span>
                        </button>
                      </div>
                    </div>

                  </div>
                  <div  class="text-center mb-5 mt-4" v-if="item.products.length > 4">

                    <router-link class="btn_outline"
                                 :to="`/wishlist-project/${item.clientProjectId}`">
                      View all Products ({{ item.count }}) </router-link>
                  </div>
                </div>



                <!--                <div v-for="item in wishListData" :key="item.id" class="col-xl-3 col-md-4 col-6">-->
                <!--                  <div class="prod_l_cnt">-->
                <!--                    <div class="prod_d_itm">-->

                <!--                      <figure>-->
                <!--                        <router-link :to="`/product/${sanitizeTitle(item.title)}/${item.productId}`"><img class="img-fluid" :src="item.image" alt="" /></router-link>-->
                <!--                      </figure>-->

                <!--                      <div class="prdt-onr-name">{{item.brand}}</div>-->
                <!--                      <div class="row">-->
                <!--                        <div class="col-xl-6">-->
                <!--                          <div class="prdt-name">{{item.title}}</div>-->
                <!--                          <div class="prdt-price">-->
                <!--                            {{ common.formatNumber(item.price) }}-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="add-wishlist-icon wl_pg">-->
                <!--                      <button id="ad-remove-from-wish-list">-->
                <!--                        <i>-->
                <!--                          <img v-on:click.prevent="deleteWishlist(item)" class="img-fluid" src="../../assets/image/SVG/times.svg" />-->
                <!--                        </i>-->
                <!--                      </button>-->
                <!--                    </div>-->
                <!--                    <div class="ordr_btn_sec">-->
                <!--                      <button :disabled="loaderBool[item.productId]" :class="{ 'disable-btn': !checkBool[item.productId] && loaderBool[item.productId] }"-->
                <!--                              v-on:click.prevent="addToCart(item)" class="b_w_ust btn_default">-->
                <!--                        <div v-if="!checkBool[item.productId] && loaderBool[item.productId]">-->
                <!--                          <img width="22" src="../../assets/image/loader.gif" />-->
                <!--                        </div>-->
                <!--                        <span v-else-if="checkBool[item.productId]"><img src="../../assets/image/check-mark-white.svg" alt="" />-->
                <!--                        </span>-->
                <!--                        <span v-if="!checkBool[item.productId] && !loaderBool[item.productId]">-->
                <!--                        {{(item?.stock<=0)?'Pre Order':'Add To Cart'}}-->
                <!--                        </span>-->
                <!--                      </button>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="col-md-12" align="center">No  items in your wishlist</div>-->
              </div>
              <div class="row" v-else>
                <div class="col-md-12" align="center">No items in your wishlist</div>
              </div>
            </div>
            <!-- Product List -->
          </div>

<!--          <div class="wishli_btns">-->
<!--            <button value="ADD ALL PRODUCTS TO CART" class="btn_outline" data-bs-toggle="modal"-->
<!--              data-bs-target="#wishlist_modal">ADD ALL PRODUCTS TO CART</button>-->
<!--          </div>-->

          <!-- End Prducts List -->
        </div>
      </div>
    </div>


  </section>
  <CartFooter />

  <div class="modal view-in-3d-modal fade" id="wishlist_modal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <section class="contact_sec  feedback_page modal_os">
    <div class="page-width">
      <div class="row">
        <div class="col-lg-12">



          <div class="out_of_stock_pro">
            <div class="out_head_body">
              <h3 class="out_head"> 
                <img src="../../assets/image/out_img.svg" alt="logo"> 
                Sorry for the Inconvenience</h3>
              <p>The following product(s) are out of stock. Please check back after some time and place your order again.</p>
            </div>

            <div class="product_details_stock">
              <div class="details_sto_header">
                <h5 class="header_left">PRODUCT DETAILS</h5>
                <h5 class="header_right">QTY</h5>
              </div>
              <div class="out_of_stock_left">

                <div class="out_of_stock_left_body">
                  <div class="stock_img">
                <img src="../../assets/image/stock1.svg" alt="item"> 
                  </div>
                  <div class="stock_text product_filter_sec">
                    <h6><a href="">AMANDA BEIGE 3-SEATER SOFA</a></h6>
                    <p class="item_inf">A01-AC42BG-7408</p>

              
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckChecked"> NOTIFY ME ONCE ITEM IS BACK IN STOCK 
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="stock_right_body">1</div>
                </div>


                <div class="out_of_stock_left_body">
                  <div class="stock_img">
                  <img src="../../assets/image/stock2.svg" alt="item"> 
                  </div>
                  <div class="stock_text product_filter_sec">
                    <h6><a href="">AMANDA BEIGE 3-SEATER SOFA</a></h6>
                    <p class="item_inf">A01-AC42BG-7408</p>

              
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckChecked1"> NOTIFY ME ONCE ITEM IS BACK IN STOCK 
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked1">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="stock_right_body">1</div>

                </div>
                <div class="out_of_stock_left_body">
                  <div class="stock_img">
                <img src="../../assets/image/stock1.svg" alt="item"> 
                  </div>
                  <div class="stock_text product_filter_sec">
                    <h6><a href="">AMANDA BEIGE 3-SEATER SOFA</a></h6>
                    <p class="item_inf">A01-AC42BG-7408</p>

              
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckChecked2"> NOTIFY ME ONCE ITEM IS BACK IN STOCK 
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked2">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="stock_right_body">2</div>
                </div>
                <div class="out_of_stock_left_body">
                  <div class="stock_img">
                   <img src="../../assets/image/stock3.svg" alt="item"> 
                  </div>
                  <div class="stock_text product_filter_sec">
                    <h6><a href="">AMANDA BEIGE 3-SEATER SOFA</a></h6>
                    <p class="item_inf">A01-AC42BG-7408</p>

              
                    <div class="form-check">
                      <label class="form-check-label" for="flexCheckChecked3"> NOTIFY ME ONCE ITEM IS BACK IN STOCK 
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked3">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="stock_right_body">3</div>
                </div>
              </div>



            </div>

            <div class="feedback_buttons">
              <button type="submit" value="PLACE ORDER" class="reset_btn btn_outline">PLACE ORDER</button>
              <button type="submit" value="GO TO CART" class="submit_btn btn_default">GO TO CART</button>
            </div>
          </div>
          <br/>


        </div>
      </div>
    </div>
  </section>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <!--                            <a @click="downloadImage('https://i.imgur.com/lF1GKDt.jpg')" class="btn btn-dark" >Download CAD block</a>-->
          <!-- <a @click="downloadImage(productData.variants[0]?.catBlock)" class="btn btn-dark">Download CAD block</a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../cart/css/cart.css";
import CartHeader from "@/components/layouts/cart/CartHeader.vue";
import CartFooter from "@/components/layouts/cart/CartFooter.vue";
import Api from "@/apis/WishList";
import CartApi from "@/apis/Products";
import { Base64 } from "js-base64";
import common from "@/apis/Common";
import BreadCrumb from '../common/BreadCrumb';
export default {
  name: "cart-list",
  data() {
    return {
      wishListData: [],
      common: common,
      loaderBool: [],
      checkBool: [],
      cartCount: 0,
      wishListCount: 0,
      wishListCount1: 0,
    };
  },
  components: {
    CartHeader,
    CartFooter,
    BreadCrumb
  },
  created() {
    this.projectDefault = JSON.parse(localStorage.getItem("defaultProject"));
    this.priceBool = localStorage.getItem("priceBool");
    this.getWishList();
  },
  computed: {
    disableButton() {
      return (bool1, bool2) => {
        if (bool1 || bool2) {
          return true;
        } else {
          return false;

        }
      };
    },
    itemsCount: function () {
      let count = 0;
      if (this.wishListData) {
        this.wishListData.forEach((val) => {
          count = val.products.length
        })
        // this.wishListData.products.length;
      }
      return count;
    },
  },
  methods: {
    sanitizeTitle: function (title) {
      if (title) {
        // Change to lower case
        let slug = title?.toLowerCase();
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, '');
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    },
    async addToCart(data) {
      this.loaderBool[data.productId] = true;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      const { productId, variantId } = data;
      let payLoad = {
        userId: userId,
        productId: productId,
        variantId: variantId,
        qty: 1,
      };
      CartApi.addToCart(payLoad) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.sucess = "Product Added Successfully";
            this.cartCount += 1;
            this.checkBool[data.productId] = true;
            this.loaderBool[data.productId] = false;
            setTimeout(() => {
              this.checkBool[data.productId] = false;
            }, 3000);
          }
        })
        .catch((err) => {
          this.loaderBool[data.productId] = false;
          console.log(err);
          if (err?.response?.data?.errors) {
            alert(err?.response?.data?.errors);
            return;
          }
        });
    },
    async getWishList() {
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      Api.getWishList({ userId: userId }) //{ userId: userId, trash: 0 }
        .then((res) => {
          if (res.data.success) {
            this.wishListData = res.data.data;
            // let wishlist = [];
            // this.wishListData.forEach((val)=>{
            //   val.products.forEach((val1)=>{
            //     console.log(val1.productId,'abcdef')
            //     wishlist[val1.productId] = true;
            // });
            // });
            if (this.projectDefault != null) {
              this.wishlistDefaultData = this.wishListData.find(x => x.clientProjectId === this.projectDefault.id).products
              let wishlist = [];
              this.wishlistDefaultData.forEach((val) => {
                // console.log("swaraj", val)
                wishlist[val.productId] = true;
                // val.products.forEach((val1)=>{
                //
                // });
              });
              let count = 0;
              this.wishListData.forEach((val) => {
                // console.log("swaraj", val)
                count += val.products.length
              });
              this.$store.dispatch('addTotalWishList', wishlist);
              this.wishListCount = Object.values(wishlist)?.length;
              this.wishListCount1 = count;
            }
            else {
              console.log("Default 0")
              let wishlist = [];
              this.wishListData.forEach((val) => {
                val.products.forEach((val1) => {
                  wishlist[val1.productId] = true;
                });
              });
              let count = 0;
              this.wishListData.forEach((val) => {
                count += val.products.length
              });
              this.$store.dispatch('addTotalWishList', wishlist);
              // this.wishListCount = Object.values(wishlist)?.length;
              this.wishListCount1 = count;
              this.$store.dispatch('addTotalWishListCount', count);
              this.wishListCount = count;

            }


          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteWishlist(item, clientProjectId) {
      // console.log(wishlist)
      const { productId } = item;
      let userId = localStorage.getItem("id");
      userId = Base64.decode(userId);
      //debugger; // eslint-disable-line no-debugger
      Api.deleteWishlist({ userId: userId, productId: productId, clientProjectId: clientProjectId })
        .then((res) => {
          if (res.data.success) {
            this.getWishList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup() { },
};
</script>
