import { createApp } from 'vue'
import store from './store/index.js'
import Vuelidate from '@vuelidate/validators'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import App from './App.vue'
import routes from "./routes/routes";
import VueMeta from 'vue-meta'
import VueSessionStorage from "vue-sessionstorage";
// import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'
import VueAgile from 'vue-agile';

createApp(App)
.use(routes)
.use(store)
.use(VueCookies)
// .use(VueI18n)
.use(Vuelidate,VueMeta,VueSessionStorage)
.use(VueAgile)
.mount('#app')

// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';


