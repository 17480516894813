import ApiToken from "./ApiToken";
import authHeader from "@/auth-header";
export default {
    getOrdersList(payLoad){
        return ApiToken.post("/orders", payLoad,  { headers: authHeader() })
    },
    getOrderDetails(payLoad){
        return ApiToken.post("/userOrderDetails", payLoad,  { headers: authHeader() })
    },
    addPayment(payLoad){
        return ApiToken.post("/addUserOrderPayment", payLoad,  { headers: authHeader() })
    },
    getPaymentModes(payLoad){
        return ApiToken.post("/paymentModesList", payLoad ,  { headers: authHeader() })
    },
}