import ApiToken from "./ApiToken";
import BlogsApi from "./BlogsApi";
import axios from "axios";
import authHeader from "@/auth-header";
export default {
    getSliders(){
        return ApiToken.get("/sliders",  { headers: authHeader() })
    },
    getShopByRoomsList(){
        return ApiToken.get("/shopByRoomsList",  { headers: authHeader() })
    },
    getNewArrivals(payload){
        return ApiToken.post("/collectionProducts", payload,  { headers: authHeader() })
    },
    getCollectionList(){
        return ApiToken.get("/collectionsList",  { headers: authHeader() })
    },
    megaMenu(){
        return ApiToken.get("/menuList",  { headers: authHeader() })
    },
    getCMSData(payload){
        return ApiToken.post("/pageDetails", payload,  { headers: authHeader() })
    },
    getBlogs(payload){
        return BlogsApi.get("posts", payload)
    },
    getBlogsData(url){
        let BlogsApi = axios.create({
            baseURL: url
        });
        return BlogsApi.get("")
    },
    getShopByStyle(){
        return ApiToken.get("/shopByStyle",  { headers: authHeader() })
    },
    getBrand(){
        return ApiToken.get("/brandsList",  { headers: authHeader() })
    },
}